.newsletter-page {
	min-height: 100vh;
	overflow: hidden;

	/* Central content column */
	&__content-wrapper {
		margin: auto;
		max-width: 720px;
	}

	/* Initially shown content for user actions */
	&__menu {
		box-sizing: border-box;

		&__form,
		&__signout {
			background-color: rgba(255, 255, 255, 0.9);
			padding: 0 1em;
			margin: 0;

			.standard-heading {
				padding: 48px 0;
			}
		}

		&__form {
			color: $color-black;

			input {
				background: $color-white;
				min-width: 0;
			}

			.submit {
				display: block;
				white-space: normal;
				width: auto;
				margin: 20px auto;
				text-transform: capitalize;
			}

			.mandatory-field {
				padding: 12px 0;
				height: auto;
				width: 100%;
				font-size: 12px;
			}

			#email {
				margin-bottom: 20px;
			}

			.interest-input {
				color: $color-gray-1;

				legend {
					display: block;
					margin-bottom: 1rem;
				}

				label {
					display: block;
					float: none;
					margin: 0 1rem 1rem 0;
				}

				.alt-radio {
					background: $color-white;
					color: $color-black;
				}
			}
		}

		&__signout {
			.cta {
				text-align: center;
			}

			.button.secondary {
				font-size: 1em;
				padding: 20px 20px 21px;
				text-align: center;
				color: $color-white;
				background-color: $color-base;

				&:hover {
					background-color: $color-gray-3;
				}
			}
		}
	}

	/* Confirmation message*/
	&__confirmation-message {
		margin: 2rem 0;
		padding: 2em;
		background-color: rgba(255, 255, 255, 0.9);
		max-width: 720px;
		z-index: 1;

		ul,
		ol {
			list-style-position: inside;
		}

		h1,
		h2 {
			margin: 0.5em 0;
		}

		&__next-steps {
			margin-bottom: 20px;
		}

		#closeConfirmationButton {
			display: block;
			cursor: pointer;
			margin: 2rem auto 0 auto;
		}
	}

	&__already-registered {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 40vh 2rem 0;
		padding: 2em;
		background-color: rgba(255, 255, 255, 0.9);
		max-width: 720px;
		z-index: 1;

		#is-subscriber_messsage {
			text-align: center;
		}
	}

	/* Utility classes */
	.error-message {
		display: none;
		margin-bottom: 20px;
	}

	.info-text {
		margin-bottom: 20px;
		font-size: 12px;
	}

	.hidden {
		display: none;
	}
}

/* separate styles for confirmation page that newsletter php app redirects to */
.newsletter-signup {
	display: flex;
	min-height: 100vh;
	overflow: hidden;
	position: relative;

	.standard-heading {
		padding-bottom: 12px;
	}

	&__background-image {
		left: 50%;
		min-height: 100%;
		min-width: 100%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.confirmation {
		color: $color-white;
		margin: auto;
		max-width: 720px;
		padding: 1rem;
		z-index: 1;
		position: relative;
		text-align: center;

		& p {
			margin-bottom: 12px;
		}
	}

	.preferences-settings {
		color: $color-white;
		text-align: center;

		a {
			color: $color-white;
			text-decoration: underline;
		}
	}
}

.newsletter-page__contact-updated {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 40vh 2rem 0;
	padding: 2em;
	background-color: rgba(255, 255, 255, 0.9);
	max-width: 720px;
	z-index: 1;

	#is-updated_messsage {
		text-align: center;
	}
}

@media (min-width: calc($tablet + 1px)) {
	.newsletter-page {
		&__menu {
			&__form,
			&__signout {
				padding: 0 4em;
				margin: 2em 0;
			}

			&__form {
				.input-group {
					display: flex;
					justify-content: space-between;

					input:not(:first-of-type) {
						margin-left: 1rem;
					}
				}
			}
		}
	}
}

@media (min-width: $mobile-lg) {
	.newsletter-page {
		&__menu {
			&__form {
				.interest-input {
					label {
						display: inline-block;
					}
				}
			}
		}
	}
}
