/*STORE LOCATOR PAGE*/
.store-locator-page {
	h1 {
		padding: 25px 20px 61px;
		text-align: center;
		font-size: 1.875em;
		line-height: 2.375rem;
		font-weight: 700;
	}

	nav {
		top: $header-height;
		position: fixed;
		left: 0;
		right: 0;
		z-index: 1;
		background-color: rgba(265, 265, 265, 0.9);
		text-align: center;
		border-width: 0 0 2px;
		border-style: solid;
		border-color: rgba(188, 188, 188, 0.25);
		transition: top 0.33s;

		ul {
			list-style: none;
			padding: 12px 20px;
		}

		li {
			display: inline-block;
			font-size: 0.75em;
			padding: 0 7px;
		}

		a {
			text-decoration: none;
			text-transform: uppercase;
			color: $color-gray-1;
			line-height: 1.5rem;
			font-weight: 700;

			&:hover {
				color: $color-base;
			}
		}

		a.active {
			font-weight: 700;
			color: $color-base;
		}
	}

	dl {
		position: relative;
	}

	dt {
		img {
			width: 100%;
		}
	}

	article {
		opacity: 1;

		a {
			display: block;

			dd {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				top: 0;
				background-color: rgba(38, 38, 38, 0);
			}

			&:hover {
				dd {
					background-color: rgba(38, 38, 38, 0.7);
				}
			}
		}
	}

	article.hidden {
		opacity: 0;
	}

	dd {
		.info {
			display: block;
			position: absolute;
			top: 50%;
			left: 12.5%;
			transform: translate(0, -50%);
			width: 75%;
			padding: 0;
			font-size: 1em;
			color: $color-white;
			font-weight: 700;
			text-align: center;
			margin: 0 auto;
		}

		.title {
			font-weight: 700;
			font-size: 1.875em;
			line-height: 2.5rem;
		}

		.subtitle {
			font-size: 1em;
			line-height: 1.5rem;
		}

		span {
			position: static;
			display: block;
			transform: none;
			font-weight: 400;
			line-height: 1.5rem;
			font-size: 1em;
		}
	}

	.view-store {
		display: none;
	}

	select {
		display: none;
		border-width: 1px;
		border-style: solid;
		border-color: $color-base;
		color: $color-gray-1;
		border-radius: 4px;
		padding: 20px 20px 21px 20px;
		margin: 20px auto;
		outline: 0;
		max-width: 400px;
		width: 100%;
		box-sizing: border-box;
		-moz-appearance: none;
		appearance: none;
		-webkit-appearance: none;
		background: transparent url("../images/global/dropdown-arrow-light.svg") no-repeat right 20px center;

		&:focus {
			background-image: url("../images/global/dropdown-arrow-up-light.svg");
			outline: none;
			background-color: $color-white;
			color: $color-base;
		}

		&:-webkit-autofill {
			-webkit-box-shadow: 0 0 0 1000px white inset;
		}

		&::-ms-expand {
			display: none;
		}
	}

	select[disabled] {
		border: 0;
	}
}

@media (max-width: $desktop-lg) {
	.store-locator-page {
		nav {
			ul {
				max-width: 1680px;
				margin: 0 auto;
				width: 87.34%;
			}
		}
	}
}

@media only screen and (max-width: $tablet) {
	.store-locator-page {
		padding: 60px 0 0;
		background-position: center 60px;

		.breadcrumb {
			display: none;
		}

		h1 {
			font-size: 1.5em;
			line-height: 1.875rem;
			padding: 20px 20px 10px;
		}

		nav {
			background-color: $color-white;
			padding: 0 20px;
			position: static;
			border-color: rgba(188, 188, 188, 0);

			ul {
				display: none;
			}
		}

		select {
			display: block;
			margin: 0 auto 20px;
		}

		.view-store {
			background-color: $color-white;
			color: $color-blue-1;
			padding: 11px 0;
			border-radius: 4px;
			min-width: 170px;
			font-size: 0.875em;
			text-decoration: none;
			text-align: center;
			display: inline-block;
			font-weight: 700;
		}

		dd {
			.title {
				font-size: 1.5em;
				line-height: 1.875rem;
			}

			.subtitle {
				margin-bottom: 10px;
			}
		}
	}

	.store-page {
		.phone {
			a {
				padding: 0 0 5px;
				margin: 0 0 5px;
				display: inline-block;
			}
		}
	}
}

@media (max-width: 564px) {
	.store-locator-page {
		h1 {
			span {
				display: block;
			}
		}

		dd {
			span {
				top: 50%;
				left: 0;
				right: 0;
			}

			.info {
				left: 20px;
				width: auto;
				right: 20px;
			}
		}
	}
}

@media only screen and (min-device-pixel-ratio: 1.5) and (max-width: $tablet),
	only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: $tablet) {
	.touch {
		.store-locator-page {
			h1 {
				padding: 35px 20px 35px;
			}

			select {
				font-size: 1em;
				padding: 24px 20px 25px;
			}
		}
	}

	.store-locator-page {
		.view-store {
			font-size: 1rem;
			line-height: 1.5rem;
			padding: 11px 0 10px;
			width: 170px;
		}
	}
}

@media only screen and (min-width: calc($tablet + 1px)) {
	.touch {
		.breadcrumb {
			display: none;
		}

		.store-locator-page {
			h1 {
				padding: 35px 20px 35px;
			}

			select {
				font-size: 1em;
				padding: 24px 20px 25px;
				margin: 0 auto 20px;
			}
		}
	}
}

/* stylelint-disable-next-line max-line-length */
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
	.touch {
		.store-locator-page {
			h1 {
				padding: 50px 20px 50px;
			}

			select {
				font-size: 1em;
				padding: 24px 20px 25px;
			}
		}
	}
}
