/*CHECKOUT*/
.error {
	color: $color-red-1;
}

.checkout-header {
	padding: 0;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 500;

	.logo-holder {
		background-color: rgba(26, 26, 26, 0.9);
		text-align: center;
	}

	.logo {
		padding: 17px 0;
		margin: 0 auto;

		svg {
			vertical-align: bottom;
		}
	}

	#logo {
		path {
			fill: $color-white;
		}

		polygon {
			fill: $color-white;
		}

		rect {
			fill: $color-white;
		}
	}

	.checkout-nav {
		background-color: rgba(255, 255, 255, 0.9);
		border-width: 0 0 2px;
		border-color: rgba(188, 188, 188, 0);
		border-style: solid;

		ul {
			font-size: 0;
			list-style: none;
			text-align: center;
		}

		li {
			display: inline-block;

			&.is-hidden {
				display: none;
			}
		}

		a {
			color: $color-gray-1;
			display: inline-block;
			font-size: 0.75rem;
			text-transform: uppercase;
			text-decoration: none;
			font-weight: 700;
			margin: 0;
			padding: 18px 8px;
			transition: all 200ms ease;
			cursor: default;

			&:hover {
				color: $color-gray-1;
			}
		}

		.active {
			color: $color-base;
			cursor: pointer;

			&:hover {
				color: $color-gray-1;
			}
		}

		.filled {
			color: $color-base;
			cursor: pointer;

			&:hover {
				color: $color-gray-1;
			}
		}
	}
}

.checkout-header.scrolled {
	.checkout-nav {
		border-color: rgba(188, 188, 188, 0.25);
	}
}

.checkout {
	padding: 118px 0 0;

	section {
		border-width: 0 0 1px;
		border-color: $color-gray-17;
		border-style: dashed;

		> div.content {
			background-color: $color-gray-16;
			padding: 60px 0;
		}
	}

	.main-title {
		font-size: 1.875em;
		line-height: 2.375rem;
		border-width: 0;
		border-color: $color-gray-17;
		font-weight: 700;
		border-style: dashed;
		margin: 0;
		background-color: $color-white;
		position: relative;
		transition: 0.4s background-color;

		a {
			text-decoration: none;
			display: block;
			color: $color-gray-1;
			cursor: default;
			padding: 60px 0;

			&:hover {
				color: $color-gray-1;
			}
		}

		span {
			display: block;
			max-width: 1680px;
			margin: 0 auto;
			width: 87.34%;

			span {
				display: inline-block;
				width: auto;
				margin: 0;
			}
		}

		.edit {
			display: none;
			width: auto;
			font-size: 0.75rem;
			line-height: 3.125rem;
			position: absolute;
			right: 6.33%;
			top: 30px;
			text-transform: uppercase;
			z-index: 0;
		}
	}

	.open {
		.main-title {
			border-width: 0 0 1px;
		}
	}

	.active.open {
		.main-title {
			&:hover {
				background-color: $color-white;
			}

			.edit {
				display: none;
				width: auto;
				font-size: 0.75rem;
				line-height: 3.125rem;
				position: absolute;
				right: 6.33%;
				top: 30px;
				text-transform: uppercase;
				z-index: 0;
			}
		}
	}

	.active {
		.main-title {
			&:hover {
				background-color: $color-gray-8;
			}

			a {
				color: $color-base;
				cursor: pointer;
			}

			.edit {
				display: block;
			}
		}
	}

	.section-content {
		max-width: 1680px;
		margin: 0 auto;
		width: 87.34%;
		overflow: hidden;
		font-size: 0.875em;

		&::after {
			clear: both;
			content: "";
			display: block;
		}
	}

	.column {
		width: 31.31%;
		transition: all 0.4s;
		float: left;

		&::after {
			clear: both;
			content: "";
			display: block;
		}
	}

	.single-column {
		width: 31.31%;
		transition: all 0.4s;
		min-width: 350px;
		float: none;

		&::after {
			clear: both;
			content: "";
			display: block;
		}
	}

	.submit-section {
		width: 31.31%;
	}

	.error-message {
		transition: 0.4s all;
		color: $color-red-1;
		padding: 0 5px;
		margin: -10px 0 10px;
	}

	.error-message.special-message {
		color: $color-black;
	}

	.global-error-message {
		transition: 0.4s all;
		color: $color-red-1;
		display: inline-block;
	}

	a {
		color: $color-gray-1;
		text-decoration: underline;
		transition: color 0.4s;

		&:hover {
			color: $color-base;
		}
	}

	.picker-button {
		a {
			text-decoration: none;
		}
	}

	.new-button {
		a {
			text-decoration: none;
		}
	}

	.disabled {
		display: none;
	}

	.inactive {
		opacity: 0.6;
	}

	.standard-checkbox {
		.copy {
			display: block;
			line-height: 1.75rem;
			overflow: hidden;
		}
	}

	.standard-radio {
		.copy {
			display: block;
			line-height: 1.75rem;
			overflow: hidden;
		}

		.alt-radio {
			flex-shrink: 0;
		}
	}

	.payment-page {
		#storedCreditCardPayment {
			label[for="credit-card"] {
				.standard-radio {
					label {
						span {
							span {
								font-weight: 400;
							}
						}
					}
				}
			}

			.standard-radio {
				.stored-credit-card-label__wrapper {
					display: flex;
					column-gap: 16px;
					flex-wrap: wrap;
				}

				.copy.new-credit-card-label {
					display: none;
				}

				.copy.new-credit-card-label.show {
					display: inline;
				}
			}

			.submit-section {
				input.remove-margin {
					margin-top: 0;
				}
			}

			label {
				span.copy.stored-credit-card-label {
					display: inline-block;
					font-weight: 400;
					padding: 0;
				}
			}

			button#removeStoredCreditCard {
				display: inline-block;
				padding: 0;
				margin: 0;
				text-decoration: underline;
				cursor: pointer;
				border: none;
				line-height: 1.25rem;
				vertical-align: top;
				background: none;
				font-size: 0.875rem;
				align-self: flex-start;
			}
		}

		.card-holder.error {
			.error-message {
				display: none;
			}
		}
	}

	.review-page {
		.payment-details {
			p.column {
				width: auto;
				max-width: 555px;
				padding: 0 0 0 3.035%;
			}
		}
	}
}

#page-content.empty {
	text-align: center;
}

.standard-checkbox {
	input {
		&:checked {
			& + label {
				.alt-checkbox {
					background-color: $color-white;
				}
			}
		}
	}
}

.standard-radio {
	input {
		&:checked {
			& + label {
				.alt-radio {
					background-color: $color-white;
				}
			}
		}
	}
}

section {
	.standard-form {
		input.submit {
			margin: 20px 0 0;
		}
	}
}

section.payment-page {
	input.submit {
		margin: 20px 0 0;
	}
}

.login-page {
	.forgotten {
		margin-top: 12px;
	}
}

.shipping-page {
	form {
		.submit {
			width: 31.31%;
			display: block;
			margin: 140px auto 0;
		}

		.copy {
			margin-top: 5px;

			ul {
				margin-left: 22px;
			}
		}
	}

	.standard-form {
		min-height: 315px;
	}
}

.payment-page {
	.payment-type {
		clear: left;

		&__icon {
			width: auto;
			margin: 0 10px 0 0;
		}

		.column {
			&::after {
				clear: both;
				display: block;
				content: "";
			}
		}

		.standard-radio,
		.standard-radio label {
			display: flex;
			align-items: center;
		}

		&__tooltip {
			display: none;
		}

		&.is-disabled {
			.standard-radio label {
				opacity: 0.5;
			}

			& .payment-type__tooltip {
				display: inline-block;
			}
		}
	}

	.submit {
		clear: left;

		&::after {
			clear: both;
			display: block;
			content: "";
		}

		&:hover {
			border-color: $color-blue-1;
			background-color: $color-blue-1;
		}
	}

	.section-content {
		form[name="paymentform"] {
			> div {
				&:not(:last-child) {
					section {
						border-width: 0 0 1px;
						border-style: solid;
						border-color: $color-gray-17;
						margin: 0 0 30px;
						padding: 20px 1px 40px 0;
					}
				}

				&:last-child {
					section {
						border: none;
					}
				}

				&:not(:last-child)#storedCreditCardPayment {
					section {
						> div.credit-card-hidden {
							height: 0;
							overflow: hidden;
							opacity: 0;
							padding: 0;
							margin: 0;
						}
					}
				}
			}
		}
	}

	section {
		&::after {
			clear: both;
			display: block;
			content: "";
		}

		p {
			padding: 0 0 20px;
			line-height: 1.5;
		}
	}

	.inactive {
		opacity: 0.7;
	}

	.wrapper {
		&::after {
			clear: both;
			content: "";
			display: block;
		}

		fieldset {
			width: 42.85%;
			float: left;
		}

		.error-message {
			clear: both;
		}

		.expiry-month {
			width: 48.57%;
		}

		.expiry-year {
			margin: 0 0 0 3.72%;
			width: 47.71%;
		}
	}

	.cvc {
		input {
			width: 37.14%;
			margin: 0;
		}
	}

	.cvc-help {
		width: 37.14%;
		text-align: right;
		margin: 0 0 20px;

		a {
			font-size: 0.75rem;
		}
	}

	.cvc-info-holder {
		background-color: $color-blue-1;
		color: $color-white;
		padding: 20px;
		position: relative;
		margin: 0 0 20px;

		&::before {
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 6px 6px 6px;
			border-color: transparent transparent $color-blue-1 transparent;
			position: absolute;
			left: 30.14%;
			top: -6px;
		}
	}

	.cvc-info {
		.close {
			position: absolute;
			top: 20px;
			right: 20px;
			display: block;
			color: $color-white;
			transition: 0.4s color;
			cursor: pointer;
			text-decoration: none;

			&:hover {
				color: $color-gray-17;
			}

			&::after {
				font-family: $font-secondary;
				content: "\e605";
			}

			span {
				display: none;
			}
		}

		.title {
			font-size: 1rem;
			line-height: 1rem;
			padding: 0 0 10px;
		}

		p {
			padding: 15px 0;
		}

		.image {
			background-color: $color-white;
			text-align: center;

			img {
				display: inline-block;
				margin: 20px auto;
			}
		}
	}

	.verified {
		p {
			padding: 5px 0 20px;
		}

		ul {
			list-style: none;

			li {
				display: inline-block;
				margin: 0 10px 0 0;

				a {
					display: block;
				}

				img {
					vertical-align: bottom;
				}
			}
		}
	}

	.logo {
		padding: 0 0 20px;

		img {
			border-radius: 4px;
			object-fit: cover;
		}
	}

	.info {
		dd {
			color: $color-gray-1;
			padding: 0 0 20px;
		}
	}

	.account-details {
		border-width: 0 0 1px;
		border-style: solid;
		border-color: $color-gray-17;
		padding: 0 0 13px;

		&::after {
			clear: both;
			display: block;
			content: "";
		}

		p {
			clear: both;
			padding: 0 0 25px;
		}
	}

	.international {
		p {
			clear: both;
			padding: 20px 0 25px;
		}
	}

	.post-finance {
		.title {
			text-transform: uppercase;
			padding: 10px 0 17px;
		}
	}

	.error-message {
		clear: left;
	}

	.standard-select {
		width: 100%;
		max-width: 350px;
		font-size: 0.875rem;
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		appearance: none;
		overflow: hidden;

		select {
			width: 100%;
			border-width: 1px;
			border-style: solid;
			border-color: $color-base;
			font-size: 0.875rem;
			color: $color-base;
			border-radius: 4px;
			padding: 20px 20px 21px 20px;
			margin: 0 0 20px;
			outline: 0;
			background: transparent url("../images/global/dropdown-arrow-light.svg") no-repeat 95% 50%;
			box-sizing: border-box;
			-webkit-appearance: none;
			transition: color 0.4s, background-color 0.4s;
		}
	}

	.standard-select.error {
		select {
			color: $color-red-1;
			border-color: $color-red-1;
		}
	}

	input.submit {
		opacity: 0.7;
		width: 100%;
		cursor: default;
		transition: all 0.4s;
		pointer-events: none;
		margin: 0;
	}

	.credit-card {
		.submit-section {
			input.submit {
				margin-top: 35px;
			}
		}
	}

	.standard-form[name="paymentform"] {
		.credit-card {
			section {
				label {
					&:not(.cardtype) {
						display: block;
						margin-bottom: 10px;
					}
				}
			}
		}
	}

	.submit.active {
		opacity: 1;
		cursor: pointer;
		pointer-events: auto;

		&:hover {
			border-color: $color-blue-2;
			background-color: $color-blue-2;
		}
	}

	.klarnaForm {
		width: 31.3%;

		.telephone {
			label {
				display: block;
				padding-bottom: 20px;
				padding-top: 20px;
				line-height: 1.5;
			}
		}
	}

	.vorkasse {
		.vorkasse-info {
			grid-area: vorkasse-info;
		}

		.info {
			width: 50%;

			div {
				width: 100%;
				display: grid;
				grid-template-areas: "vorkasse-info vorkasse-info" "vorkasse-account-holder vorkasse-institute" "vorkasse-iban vorkasse-bic"; /* stylelint-disable-line max-line-length */
			}
		}

		.vorkasse-account-holder {
			grid-area: vorkasse-account-holder;
		}

		.vorkasse-institute {
			grid-area: vorkasse-institute;
		}

		.vorkasse-iban {
			grid-area: vorkasse-iban;
		}

		.vorkasse-bic {
			grid-area: vorkasse-bic;
		}
	}
}

.review-page {
	border-width: 0;

	.section {
		> .column {
			&:nth-child(2) {
				padding: 0 3.035%;
			}
		}
		border-width: 0 0 1px;
		border-style: solid;
		border-color: $color-gray-17;
		margin: 0 0 40px;
		padding: 0 0 40px;

		&:last-child {
			border-width: 0;
			margin: 0;
			padding: 20px 0 0;
		}

		&::after {
			clear: both;
			display: block;
			content: "";
		}
	}

	.column {
		float: left;
		width: 265px;
		padding: 0 20px 0 0;
	}

	.delivery-date {
		dt {
			float: left;

			&::after {
				content: ":";
				padding: 0 3px 0 0;
			}
		}

		dd {
			float: left;
		}
	}

	.delivery {
		.price {
			float: right;
		}
	}

	.addresses {
		.title {
			font-size: 0.75rem;
			text-transform: uppercase;
			padding: 0 0 10px;
		}

		span {
			display: block;
		}

		.address-line {
			display: flex;

			> span {
				margin-right: 2px;
			}
		}

		.phone {
			padding: 14px 0 0;
		}
	}

	.payment-details {
		dt {
			float: left;

			&::after {
				content: ":";
				padding: 0 3px 0 0;
			}
		}

		dd {
			float: left;
		}
	}

	.order {
		padding: 0;
		margin: 0;
		border-width: 0;
	}

	.order-contents {
		list-style-type: none;

		li {
			clear: left;
			border-width: 0 0 1px;
			border-style: solid;
			border-color: $color-gray-17;
			overflow: hidden;
			position: relative;
			padding: 0 0 30px;
			margin: 0 0 30px;
		}

		.product-image {
			display: inline-block;
			height: 171px;
			overflow: hidden;
			position: relative;
			width: 150px;
			float: left;
			margin: 0 30px 0 0;

			img {
				position: relative;
				top: -16px;
				width: 100%;
			}
		}

		.product-copy {
			overflow: hidden;
		}

		.title {
			font-size: 1.875rem;
			padding: 0 0 30px;
		}

		dd {
			&::after {
				content: ",\00a0";
			}
		}

		.price {
			float: right;

			dd {
				float: none;

				&::after {
					content: "";
				}
			}

			.strikeout {
				text-decoration: line-through;
			}

			.sale {
				color: $color-red-1;
			}
		}
	}

	.product-copy {
		dt {
			display: none;
		}
	}

	.product-stats {
		dd {
			float: left;

			&:last-child {
				&::after {
					content: "";
				}
			}
		}
	}

	.order-value {
		padding: 20px 0 0;
		margin: 0;
		border-width: 0;

		.prices {
			text-align: right;
			padding: 0 0 14px;
			font-size: 0;

			&::after {
				clear: both;
				display: block;
				content: "";
			}

			dt {
				display: inline-block;
				font-size: 0.875rem;

				&::after {
					content: ":\00a0";
				}
			}

			dd {
				display: inline-block;
				font-size: 0.875rem;
				color: $color-gray-1;
			}
		}

		.grand-total {
			border-color: $color-base;
			border-width: 1px 0;
			border-style: solid;
			padding: 30px 0;
			margin: 30px 0;

			dd {
				color: $color-base;
			}
		}
	}

	form {
		.submit {
			width: 31.31%;
			margin: 30px auto 20px;
			opacity: 0.7;
			display: block;
			cursor: default;
			transition: all 0.4s;
		}
	}

	.submit {
		&:hover {
			border-color: $color-blue-1;
			background-color: $color-blue-1;
		}
	}

	.submit.active {
		opacity: 1;
		cursor: pointer;

		&:hover {
			border-color: $color-blue-2;
			background-color: $color-blue-2;
		}
	}

	.terms {
		text-decoration: none;
		border-width: 0 0 2px;
		border-style: solid;
		border-color: $color-gray-1;
		transition: color 0.4s ease, border-color 0.4s ease;

		&:hover {
			color: $color-base;
			border-color: $color-base;
		}
	}

	.error {
		.terms {
			color: $color-red-1;
			border-color: $color-red-1;

			&:hover {
				color: $color-red-2;
				border-color: $color-red-2;
			}
		}
	}

	.checkout-terms {
		margin: 20px 0;
		line-height: 1.25rem;

		p {
			margin-bottom: 1.5em;
		}
	}
}

@media (max-width: $tablet) {
	.checkout-header {
		position: static;

		.checkout-nav {
			display: none;
		}
	}

	.checkout {
		padding: 0;

		section {
			> div.content {
				padding: 20px 0;
			}
		}

		.main-title {
			font-size: 1.375em;

			span {
				width: auto;
				padding: 0 20px;

				span {
					display: none;
				}
			}

			a {
				padding: 14px 0;
			}

			.edit {
				font-size: 0.875rem;
				line-height: 3.125rem;
				top: 0;
				right: 20px;
				padding: 0;
			}
		}

		.active.open {
			.main-title {
				.edit {
					font-size: 0.875rem;
					line-height: 3.125rem;
					top: 0;
					right: 20px;
					padding: 0;
				}
			}
		}

		.section-content {
			width: auto;
			padding: 0 20px;
		}

		.column {
			width: auto;
			padding: 0 0 20px;
		}

		.single-column {
			width: auto;
			padding: 0 0 20px;
			min-width: 0;
		}
	}
}

@media only screen and (min-device-pixel-ratio: 1.5) and (max-width: $tablet) {
	.checkout {
		.standard-checkbox {
			.alt-checkbox {
				width: 39px;
				height: 39px;
			}
		}

		.standard-radio {
			.alt-radio {
				width: 39px;
				height: 39px;
			}
		}

		.standard-form {
			input {
				padding: 24px 20px 25px;
			}

			input#card-holder {
				padding: 20px;
			}
		}
	}
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: $tablet) {
	.checkout {
		.standard-checkbox {
			.alt-checkbox {
				width: 39px;
				height: 39px;
			}

			.copy {
				padding: 10px 0 0;
			}
		}

		.standard-radio {
			.alt-radio {
				width: 39px;
				height: 39px;
			}
		}

		.standard-form {
			input {
				padding: 24px 20px 25px;
			}

			input#card-holder {
				padding: 20px;
			}
		}
	}
}

@media screen and (max-width: $tablet) {
	.checkout .submit-section {
		width: 100%;
	}

	.login-page {
		.forgotten {
			margin-bottom: 20px;
		}
	}

	section {
		.standard-form {
			input.submit {
				margin: 20px 0;
			}
		}

		.existing {
			.standard-form {
				input.submit {
					margin-bottom: 0;
				}
			}
		}
	}

	section.payment-page {
		input.submit {
			margin: 20px 0 0;
		}
	}

	.shipping-page {
		form {
			.submit {
				width: 100%;
				margin: 60px auto 20px;
			}
		}

		.standard-form {
			min-height: 0;
		}
	}

	.payment-page {
		fieldset {
			.submit {
				width: 100%;
			}
		}

		input.submit {
			width: 100%;
		}

		.info {
			width: 100%;
		}

		.vorkasse {
			.info {
				width: 100%;
			}

			.submit-section {
				input.submit {
					margin-bottom: 20px;
				}
			}
		}

		.credit-card {
			.submit-section {
				input.submit {
					margin-top: 20px;
				}
			}
		}
	}
}

@media screen and (max-width: $desktop) {
	.checkout {
		.review-page {
			.payment-details {
				p.column {
					width: auto;
					max-width: 555px;
					padding: 14px 0 0;
				}
			}
		}
	}
}

@media screen and (max-width: $mobile-lg) {
	.payment-page {
		.klarnaForm {
			width: 100%;
		}
	}

	.review-page {
		.order {
			padding: 0;
		}

		.column {
			float: left;
			width: 70%;
		}

		.section {
			padding: 0 0 20px;
			margin: 0 0 20px;

			> .column {
				&:nth-child(1) {
					padding: 0 0 14px;
				}

				&:nth-child(2) {
					padding: 0;
				}
			}
		}

		.price {
			text-align: right;
			width: 30%;
		}

		.product-copy {
			.title {
				font-size: 1rem;
				padding: 0 0 14px;
			}
		}

		.product-stats {
			dd {
				float: none;
				line-height: 1.25rem;

				&::after {
					content: "";
				}
			}
		}

		.order-contents {
			.price {
				padding: 60px 0 0;
				width: auto;
			}

			.product-image {
				width: 100px;
				height: 114px;
			}
		}

		.order-value {
			.prices {
				padding: 0;
				line-height: 1.25rem;
			}

			.grand-total {
				padding: 30px 0 25px;
			}
		}

		.section.order-value {
			padding: 20px 0 0;
			margin: 0;
		}

		form {
			.submit {
				width: 100%;
			}
		}
	}

	.checkout {
		.review-page {
			.payment-details {
				p.column {
					padding: 0;
				}
			}
		}
	}
}
