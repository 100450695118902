.lookbook-landing-page {
	.breadcrumb {
		position: absolute;
		z-index: 1;

		a {
			color: $color-gray-1;

			&:hover {
				color: $color-gray-17;
			}
		}
	}

	.lookbook-promo-holder {
		&::after {
			clear: both;
			content: "";
			display: block;
		}
	}

	[data-promo-count="2"] {
		.lookbook-promo {
			width: 50%;
			float: left;
		}
	}

	.lookbook-promo {
		position: relative;
		text-decoration: none;
		display: block;

		img {
			width: 100%;
		}

		.overlay {
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(26, 26, 26, 0.7);
			color: $color-white;
			text-decoration: none;
			text-align: center;
			transform-style: preserve-3d;
			transition: 0.4s opacity;
		}

		&:hover {
			.overlay {
				opacity: 1;
			}
		}

		.info {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
		}

		span {
			display: block;
		}

		.title {
			font-size: 1.875em;
			line-height: 3.25rem;
			font-weight: 700;
		}

		.subtitle {
			font-size: 1em;
			line-height: 1.5rem;
		}
	}

	.lookbook-promo[data-light="true"] {
		.overlay {
			background-color: rgba(255, 255, 255, 0.7);
			color: $color-base;
		}
	}
}

.touch {
	.lookbook-landing-page {
		.lookbook-promo {
			.overlay {
				opacity: 1;
				background-color: rgba(26, 26, 26, 0);
			}
		}
	}
}

@media (max-width: $tablet) {
	.lookbook-landing-page {
		[data-promo-count="2"] {
			.lookbook-promo {
				float: none;
				width: 100%;
			}
		}

		.lookbook-promo[data-light="true"] {
			.overlay {
				background: none;
			}
		}

		.lookbook-promo {
			.overlay {
				opacity: 1;
				background: none;
			}
		}
	}
}
