@import url("https://fonts.googleapis.com/css?family=Karla:400,400italic,700,700italic&subset=latin,latin-ext");
/***********************************************/
/* GLOBAL
/* Styles used across all pages
/***********************************************/
/*---------------------------------------------*/
/* Fonts
/*---------------------------------------------*/

@font-face {
	font-family: "Chalet Comprime Cologne Eighty";
	src: url("../fonts/chalet_comprime_cologne_eighty.eot");
	src: url("../fonts/chalet_comprime_cologne_eighty.eot?#iefix") format("embedded-opentype"),
		url("../fonts/chalet_comprime_cologne_eighty.svg#Chalet Comprime Cologne Eighty") format("svg"),
		url("../fonts/chalet_comprime_cologne_eighty.woff") format("woff"),
		url("font/path/chalet_comprime_cologne_eighty.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: $font-secondary;
	src: url("../fonts/Carhartt-WIP.eot");
	src: url("../fonts/Carhartt-WIP.eot?#iefix") format("embedded-opentype"),
		url("../fonts/Carhartt-WIP.woff") format("woff"), url("../fonts/Carhartt-WIP.ttf") format("truetype"),
		url("../fonts/Carhartt-WIP.svg#Carhartt-WIP") format("svg");
	font-weight: normal;
	font-style: normal;
}
@import "components/media-viewer.css";
@import "components/password-toggle.css";
@import "components/tooltip.css";
@import "components/footer.css";
@import "components/social-list.css";
@import "components/newsletter.css";

/*---------------------------------------------*/
/* Basic Styles
/*---------------------------------------------*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
a,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
textarea,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
.comments,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
}

pre {
	outline: 0;
	border: 0;
}

body {
	position: relative;
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	font-size: 100%;
	background: $color-white;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;

	&.no-scroll {
		position: fixed;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}

html {
	height: 100%;

	&.no-scroll {
		position: fixed;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}

*:focus {
	outline: none !important; /* stylelint-disable-line declaration-no-important */
}

img {
	vertical-align: top;
	border-width: 0;
}

html,
body,
textarea,
pre,
select,
input,
button {
	font-family: $font-base;
	color: $color-base;
}

html,
html a {
	-webkit-font-smoothing: antialiased;
}

a {
	text-decoration: none;
}

.centered-headline {
	padding: 25px 20px 61px;
	text-align: center;
	font-size: 1.875em;
	line-height: 2.375rem;
	font-weight: 700;
}

blockquote {
	max-width: 1640px;
	margin: 0 auto;
	padding: 54px 20px 88px;
	font-size: 3.125em;
	font-style: italic;
	font-weight: 700;

	&::before {
		content: '"';
	}

	&::after {
		content: '"';
	}
}

&::selection {
	background: $color-gray-8;
}

&::-moz-selection {
	background: $color-gray-8;
}

.index {
	padding-top: 200px;

	a {
		color: $color-black;
		display: inline-block;
		margin: 0 20px 20px;
	}
}

.site.no-scroll {
	position: absolute;
	left: 0;
	width: 100%;
	overflow: hidden;
}

&::-ms-reveal,
&::-ms-clear {
	display: none;
}

input[type="password"]::-webkit-contacts-auto-fill-button,
input[type="password"]::-webkit-credentials-auto-fill-button {
	visibility: hidden;
	display: none !important; /* stylelint-disable-line declaration-no-important */
	pointer-events: none;
	height: 0;
	width: 0;
	margin: 0;
}

input[type="number"] {
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	-moz-appearance: textfield;
}

.standard-heading {
	padding-top: 24px;
	padding-bottom: 61px;
	font-size: 1.875em;
	line-height: 2.375rem;
	clear: left;
	text-align: center;
	font-weight: 700;
}

.extra-info {
	display: none;
}

.breadcrumb {
	width: 100%;
	font-size: 13px;
	font-weight: bold;

	ul {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		margin-right: 35px;
		padding: 1rem 0 0 1rem;
	}

	li {
		display: flex;
		max-width: 100%;
		margin-right: 4px;
		color: $color-gray-5;
		line-height: 1rem;

		span {
			display: inline-block;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&:not(:last-child) {
			&::after {
				display: inline-block;
				content: ",";
			}
		}
	}

	a {
		color: $text-color;
		transition: color 0.2s;
		line-height: 1rem;

		&:hover {
			color: $color-gray-17;
		}
	}
}

.breadcrumb[data-light="true"] {
	a {
		color: $color-gray-17;

		&:hover {
			color: $color-gray-1;
		}
	}
}

.container {
	width: 98%;
	box-sizing: border-box;
	max-width: 1640px;
	padding: 0 20px;
	margin: 0 auto;

	> p {
		max-width: 650px;
		margin: 0 auto;
		font-size: 1em;
		padding: 0 0 20px;
	}

	> section {
		max-width: 650px;
		margin: 0 auto;
		padding: 0 0 28px;

		> .title {
			font-weight: 700;
			font-size: 1.17em;
			line-height: 1.75rem;
			padding: 0 0 28px;
		}
	}

	> section.grid {
		max-width: none;
		margin: 0;
		padding: 0;
	}

	> .external-video-player {
		width: 90%;
		max-width: 1120px;
		margin: 0 auto;
		padding: 40px 0 34px;

		.player {
			width: 100%;
			height: 100%;
		}

		iframe {
			width: 100%;
			border: 0;
		}
	}
}

.small-image {
	padding-top: 46px;
	margin: 0 auto;

	.image-container[data-align="left"] {
		float: left;
		width: 22%;
	}

	.image-container[data-align="right"] {
		float: right;
		width: 22%;

		figure {
			margin: 0 0 0 20%;
		}
	}

	figure {
		width: 80%;

		img {
			width: 100%;
		}
	}

	figcaption {
		padding: 10px 0 24px 0;
		color: $color-gray-1;
		font-size: 0.75em;
		box-sizing: border-box;
	}
}

.button {
	padding: 8px 18px;
	border-color: $color-gray-17;
	border-style: solid;
	border-width: 1px;
	text-decoration: none;
	text-transform: uppercase;
	display: inline-block;
	color: $color-gray-17;
	font-size: 0.75rem;
	outline: none;

	&:focus {
		outline: none;
	}
}

.button-as-link {
	border: none;
	padding: 0;
	margin: 0;
	background: none;
	text-decoration: underline;
	cursor: pointer;
}

.dropdown {
	padding: 8px 18px;
	border-color: $color-gray-17;
	border-style: solid;
	border-width: 1px;
	text-decoration: none;
	text-transform: uppercase;
	display: inline-block;
	color: $color-gray-17;
	font-size: 0.75rem;
	outline: none;
	padding-right: 20px;
	background: transparent url(../images/global/dropdown-arrow-light.svg) no-repeat 90% 50%;
}

.cta {
	.button {
		border-radius: 4px;
		padding: 13px 18px;
		text-transform: none;
		font-size: 0.875em;
		font-weight: 700;
		border: 0;
		min-width: 120px;
		transition: background 200ms ease;
	}

	.button.primary {
		background: $color-blue-1;
		color: $color-white;

		&:hover {
			background: $color-blue-3;
		}
	}

	.button.inverted {
		background: $color-white;
		color: $color-blue-1;

		&:hover {
			background: $color-gray-11;
		}
	}
}

.section-title {
	padding: 50px 20px;
	text-align: center;

	.title {
		font-size: 1em;
		font-weight: 700;
	}
}

.lead-image {
	padding: 46px 0 0;
	margin: 0 0 34px;
	position: relative;

	img {
		width: 100%;
		height: auto;
	}

	figcaption {
		line-height: 1.5em;
		padding: 10px 20px 0;
		color: $color-gray-1;
		font-size: 0.75em;
		max-width: 450px;
	}
}

.lead-image.soundcloud-player {
	figcaption {
		display: none;
		padding: 0;
	}
}

.country_eur {
	&::before {
		font-size: 0.875em;
		content: "\20AC  ";
	}
}

.country_gbp {
	&::before {
		content: "\00A3  ";
	}
}

.country_usd {
	&::before {
		font-size: 0.875em;
		content: "\0024  ";
	}
}

.country_czk {
	&::before {
		content: "CZK  ";
	}
}

.country_chf {
	&::before {
		content: "CHF  ";
	}
}

.country_dkk {
	&::before {
		content: "DKK  ";
	}
}

.country_aud {
	&::before {
		content: "\0024  ";
	}
}

.country_sek {
	&::before {
		content: "SEK  ";
	}
}

.loading-overlay {
	text-align: center;
	background: rgba(265, 265, 265, 0.9);
	position: absolute;
	opacity: 0;
	transition: opacity 0.2s;

	.content {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.loading-error {
		margin: 0 auto;
		max-width: 500px;
		padding: 0 20px;
	}

	a {
		color: $color-gray-1;
	}

	.spinner {
		display: none;
	}
}

.loading-overlay.active {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 498;
	opacity: 1;

	.spinner {
		display: block;

		svg {
			-webkit-animation-name: spin;
			-webkit-animation-duration: 1000ms;
			-webkit-animation-iteration-count: infinite;
			-webkit-animation-timing-function: ease;
			-moz-animation-name: spin;
			-moz-animation-duration: 1000ms;
			-moz-animation-iteration-count: infinite;
			-moz-animation-timing-function: ease;
			-ms-animation-name: spin;
			-ms-animation-duration: 1000ms;
			-ms-animation-iteration-count: infinite;
			-ms-animation-timing-function: ease;
			animation-name: spin;
			animation-duration: 1000ms;
			animation-iteration-count: infinite;
			animation-timing-function: ease;
		}
	}
}

.touch {
	.breadcrumb {
		display: none;
	}

	.breadcrumbs {
		display: none;
	}
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.country-overlay {
	text-align: center;
	background: rgba(0, 0, 0, 0.35);
	position: absolute;
	opacity: 0;
	transition: opacity 0.2s;

	.content {
		background: $color-white;
		position: absolute;
		max-width: 506px;
		width: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.close {
		font-family: $font-secondary;
		color: $color-gray-1;
		display: block;
		height: 20px;
		padding: 0;
		position: absolute;
		right: 10px;
		text-decoration: none;
		top: 10px;
		width: 20px;
		transition: color 0.2s;
		z-index: 10;

		&::before {
			content: "\e605";
		}

		&:hover {
			&::before {
				color: $color-gray-2;
			}
		}

		span {
			display: none;
		}
	}

	.scrollable-content {
		height: 100%;
		overflow: hidden;
	}

	.scrollbar {
		bottom: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 10px;
	}

	.track {
		bottom: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 10px;
	}

	.handle {
		height: 50px;
		position: absolute;
		width: 100%;
	}

	.main-title {
		font-size: 1.125em;
		font-weight: 700;
		max-width: 406px;
		padding: 20px 0 0;
		margin: 0 auto;
	}

	p {
		font-size: 1em;
		line-height: 1.5;
		max-width: 406px;
		padding: 20px;
		margin: 0 auto;
	}

	.countries {
		background: $color-gray-16;
		padding: 0 60px;

		&::after {
			display: block;
			clear: both;
			content: "";
		}
	}

	article {
		width: 50%;
		box-sizing: border-box;
		float: left;
		padding: 30px 0;
		position: relative;

		.title {
			font-size: 1.125rem;
			font-weight: normal;
			padding: 0;
			text-transform: none;
			text-align: left;
		}
	}

	.image-holder {
		height: 65px;
		left: 0;
		position: absolute;

		img {
			position: relative;
			width: 100%;
			top: -1px;
		}

		svg {
			position: relative;
			width: 100%;
			top: -1px;
		}
	}

	.image {
		float: left;
		height: 51px;
		margin: 0;
		overflow: hidden;
		position: relative;
		width: 50px;

		a {
			display: block;
			position: relative;

			&::after {
				display: block;
				position: absolute;
				content: "";
				z-index: 1;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba(0, 0, 0, 0.06);
			}
		}
	}

	.language {
		height: 46px;
		margin: -3px 0 0;
		padding: 0 10px 0 60px;
	}

	ul {
		list-style: none;
	}

	li {
		color: $color-gray-1;
		float: left;
		font-size: 0.875rem;
		line-height: 1.5rem;

		a {
			color: $color-gray-1;
			line-height: 1.5rem;
			text-decoration: underline;
			transition: color 0.2s;

			&:hover {
				color: $color-black;
			}
		}

		&:not(:last-child) {
			&::after {
				content: ",\00a0";
				margin-left: -3px;
			}
		}
	}

	li.active {
		a {
			color: $color-black;
		}
	}
}

.country-overlay.active {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1001;
	opacity: 1;
}

.country-overlay--us-redirect {
	article {
		float: none;
		width: 61%;
	}

	.country-overlay__different-country-link {
		color: $color-gray-1;
		display: block;
		line-height: 1.5rem;
		margin-top: 1em;
		text-decoration: underline;
		transition: color 0.2s;
	}
	display: none;
}

.error-message {
	color: $color-red-3;
	padding: 5px;
}

.success-message {
	display: none;
	padding: 5px;
}

.nl-error-message {
	color: $color-red-3;
	display: none;
	padding: 5px;
}

.nl-success-message {
	color: $color-white;
	display: none;
	padding: 5px;
}

@media only screen and (min-width: 900px) {
	.mobileMenu {
		display: none !important; /* stylelint-disable-line declaration-no-important */
	}
}

@media only screen and (max-width: $tablet) {
	blockquote {
		padding: 16px 0 50px 0;
		font-size: 2.5em;
	}

	.container {
		> .external-video-player {
			padding: 16px 0 34px 0;
		}
	}

	.small-image {
		.image-container[data-align="left"] {
			width: 100%;
			max-width: none;
			padding: 31px 0 34px;
		}

		.image-container[data-align="right"] {
			width: 100%;
			max-width: none;
			padding: 31px 0 34px;
		}

		& + p {
			padding-top: 31px;
		}

		figure {
			max-width: none;
		}

		figcaption {
			padding: 10px 0 0 20px;
		}
		padding-top: 0;
	}

	.standard-article {
		.small-image {
			padding-top: 0;
		}
	}
}

@media only screen and (max-width: $mobile-lg) {
	blockquote {
		margin: 0 20px;
		padding: 0 0 34px 0;
		font-size: 1.875em;
	}

	.container {
		> .external-video-player {
			width: 100%;
			margin: 0 auto;
			padding: 6px 0 34px 0;
		}

		> .video-description {
			padding: 10px 20px 0 20px;
		}
	}
}

@media (max-width: $mobile) {
	.standard-heading {
		font-size: 1.625em;
	}

	.country-overlay {
		.content {
			width: 100%;
		}

		article {
			width: 100%;
		}
	}
}

@media (max-width: $tablet) {
	.container {
		> p {
			font-size: 1em;
			padding: 0 20px 28px;
		}

		> section {
			max-width: 650px;
			margin: 0 auto;
			padding: 0 20px 28px;
		}
	}

	.country-overlay {
		background: rgba(246, 246, 246, 0.95);

		.content {
			background: none;
			max-width: none;
			height: 100%;
			text-align: left;
		}

		.close {
			top: 20px;
			right: 20px;
		}

		.main-title {
			max-width: none;
			font-size: 0.75rem;
			font-weight: 700;
			padding: 23px 20px 17px;
			text-transform: uppercase;
			text-align: left;
		}

		p {
			max-width: none;
			padding: 20px;
			background: rgba(255, 255, 255, 0.4);
		}

		.countries {
			background: none;
			padding: 0 20px;
		}

		article {
			height: auto;
			padding-bottom: 50px;
			width: 50%;
		}

		.image {
			width: 50px;
		}

		.language {
			padding-left: 65px;
			height: auto;
		}

		li {
			font-size: 1rem;
			float: none;

			a {
				display: inline-block;
				padding: 5px 0;
			}
		}
	}
}

@media only screen and (max-width: $desktop) {
	.small-image {
		.image-container[data-align="left"] {
			float: none;
			width: 50%;
			padding: 0 0 34px;
		}

		.image-container[data-align="right"] {
			float: none;
			width: 50%;
			padding: 0 0 34px;

			figure {
				margin: 0;
			}
		}

		figure {
			width: 100%;
			margin: 0 auto;
			max-width: 480px;
		}

		figcaption {
			padding: 10px 0 0;
		}

		& + p {
			padding-top: 46px;
		}
	}
}

@-moz-keyframes spin {
	from {
		width: 30px;
		fill: $color-gray-1;
		-moz-transform: rotate(0deg);
	}

	50% {
		width: 20px;
		fill: $color-gray-1;
		-moz-transform: rotate(270deg);
	}

	to {
		width: 30px;
		fill: $color-gray-1;
		-moz-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	from {
		width: 30px;
		fill: $color-gray-1;
		-webkit-transform: rotate(0deg);
	}

	50% {
		width: 20px;
		fill: $color-base;
	}

	to {
		width: 30px;
		fill: $color-gray-1;
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	from {
		width: 30px;
		fill: $color-gray-1;
		transform: rotate(0deg);
	}

	50% {
		width: 20px;
		fill: $color-base;
	}

	to {
		width: 30px;
		fill: $color-gray-1;
		transform: rotate(360deg);
	}
}
