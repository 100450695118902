/**
 * Footer component
 */

.footer {
	border-top: 1px solid $color-gray-6;
	padding-top: 20px;

	@media (min-width: $mobile-lg) {
		padding-top: 40px;
	}

	&__top,
	&__bottom {
		padding: 0 12px;
		@media (min-width: $mobile-lg) {
			padding: 0 30px;
		}
		@media (min-width: $tablet) {
			padding: 0 40px;
		}
	}

	&__top {
		display: flex;
		justify-content: space-between;
		margin-bottom: 60px;
	}

	&__bottom {
		display: flex;
		flex-direction: column-reverse;
		margin-bottom: 30px;

		@media (min-width: $mobile-lg) {
			flex-direction: row;
			justify-content: space-between;
		}
	}

	&__social {
		margin-bottom: 60px;

		@media (min-width: $mobile-lg) {
			margin-bottom: 0;
		}
	}

	&__copy {
		font-size: 0.75rem;
		color: $color-gray-5;
		letter-spacing: 0.08px;
		font-weight: 700;
	}

	&__links-list {
		list-style: none;
	}

	&__links-item {
		line-height: 14px;
		margin-bottom: 6px;

		&:nth-child(3) {
			margin-bottom: 24px;
		}

		&:nth-last-child(3) {
			margin-top: 24px;
		}

		a {
			font-size: 0.75rem;
			color: $color-gray-5;

			&:hover {
				color: $color-gray-1;
			}
		}
	}

	&__app-link {
		display: inline-block;
		cursor: pointer;
	}
}
