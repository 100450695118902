/***********************************************/
/* Home Page
/* Styles used across all pages
/***********************************************/

/* --------------------------- */
/*  New Homepage Slideshow     */
/* --------------------------- */
.homepage {
	.standard-heading {
		display: none;
	}

	.homepage-slideshow {
		.button {
			background: $color-blue-1;
			color: $color-white;

			&:hover {
				background: $color-blue-3;
			}
		}

		.overlay {
			@media (max-width: $mobile-lg) {
				margin: 0 0 30% 0;
			}
		}
	}
	@media (max-width: $mobile-lg) {
		.article-collection {
			margin: 50px 0;
		}
	}

	.standard-tabs {
		padding-top: 50px;
		padding-bottom: 10px;

		.slides {
			margin: 20px 0 0;
		}

		.slide {
			width: 100%;
			height: 100%;
			background: $color-white;
			overflow: hidden;
		}

		.custom-pips {
			@media (max-width: $mobile-lg) {
				display: none;
				margin: 20px 0;
			}
			.pips {
				width: 220px;
			}

			li {
				a {
					display: inline-block;
					color: $color-gray-9;
					font-weight: 700;
					font-size: 1em;
					border-bottom: 2px solid transparent;
					transition: all 0.4s;

					&:hover {
						color: $color-gray-1;
						border-color: transparent;
					}
				}
			}

			li.active {
				a {
					color: $color-base;
					border-color: $color-gray-9;
					cursor: default;

					&:hover {
						border-color: $color-gray-9;
					}
				}
			}
		}
	}

	.product-grid {
		margin: 0 auto;
		margin-bottom: 60px;

		.content {
			@media (max-width: $mobile-lg) {
				padding-bottom: 10px;
			}
		}
	}

	.product-grid-item {
		.wishlist {
			svg {
				left: auto;
			}
		}
	}

	.article-collection {
		margin: 100px 0 90px 0;

		.overlay {
			margin-top: 0;
		}
	}
}

#page-content.homepage {
	padding-top: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.homepage-introduction {
	width: 100%;
	height: 80vh;
	z-index: 11;

	.standard-slideshow {
		height: 80vh;
		margin-bottom: -60px;
		overflow: hidden;
	}

	.standard-promo,
	.standard-promo-item,
	.content,
	.slides,
	.slide {
		height: 100%;
	}

	.standard-video-player {
		height: 100%;

		.video-placeholder {
			.play {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: transparent;
				border-radius: 0;
				transform: none;

				&:hover {
					background: transparent;

					&::before {
						background: $color-gray-11;
					}
				}

				&::before {
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					width: 40px;
					height: 40px;
					background: $color-white;
					font-size: 0;
					border-radius: 50%;
					transform: translate(-50%, -50%);
					transition: background 200ms ease;
				}
			}
		}

		video {
			display: block;
			width: 100%;
			height: auto;

			@media (min-width: calc($mobile-lg + 1px)) {
				height: auto;
				position: absolute;
				top: 50%;
				width: auto;
				min-height: 100%;
				min-width: 100%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	.video-placeholder {
		@media (min-width: calc($mobile-lg + 1px)) {
			height: 100%;
		}
		position: relative;
		transition: opacity 0.2s linear;

		img {
			display: block;
			width: 100%;
		}
	}

	.video-player {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.player {
		height: 100%;
	}

	video {
		height: 100%;
	}

	.options {
		margin-top: 44px;
		list-style: none;
		display: flex;
		align-items: center;
		justify-content: center;

		li {
			display: inline-block;
			margin: 0 8px 10px 8px;
		}
	}

	.slide-area {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-position: 50% 50%;
		background-color: transparent;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.media {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-position: 50% 50%;
		background-color: transparent;
		background-repeat: no-repeat;
		background-size: cover;
	}

	[data-background] {
		height: 100%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		background-color: $color-gray-11;
	}

	.pips {
		bottom: 15vh;
	}

	&.touch {
		.standard-video-player {
			[data-background-loop="true"] {
				display: none;
			}
		}

		video {
			width: 100%;
			height: 100%;
			background: $color-black;
		}
	}

	@media (max-width: $mobile-lg) {
		&.has-mobile-video {
			height: auto;

			.homepage-slideshow {
				margin-bottom: 0;
			}

			.standard-slideshow {
				height: auto;
			}

			picture {
				display: none;
			}

			.standard-video-player .video-placeholder,
			.video-player {
				position: relative;
			}
		}
	}
}

.homepage-slideshow {
	height: 80vh;
	margin-bottom: -60px;
	overflow: hidden;
	transform: translateZ(0);
	width: 100%;

	.slides {
		position: relative;
		overflow: hidden;
	}

	.slide {
		position: absolute;
		left: 100%;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;

		&:first-child {
			position: relative;
			left: 0;
			align-items: flex-end;
			display: flex;
			justify-content: center;
		}

		img {
			width: 100%;
		}

		&:hover {
			.overlay-bg {
				opacity: 1;
			}

			.overlay {
				opacity: 1;
			}
		}
	}

	.double {
		.slide {
			&:first-child {
				position: absolute;
			}
		}
	}

	.slide.double {
		article {
			float: left;
			position: relative;
			width: 50%;
			height: 100%;
			overflow: hidden;

			&:hover {
				.overlay-bg {
					opacity: 1;
				}

				.overlay {
					opacity: 1;
				}
			}
		}

		&:hover {
			.overlay-bg {
				opacity: 0;
			}

			.overlay {
				opacity: 0;
			}
		}
	}

	.navigation {
		list-style: none;
		opacity: 1;
		transition: opacity 0.2s linear;

		a {
			position: absolute;
			top: 0;
			z-index: 50;
			display: block;
			width: 10%;
			height: 100%;
			text-align: center;
			font-size: 1em;
			color: $color-white;
			transform: scale(1);
			transition: transform 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955);

			&:hover {
				transform: scale(1.25);
			}

			&::before {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				width: 0;
				height: 0;
				margin-left: -19px;
				overflow: hidden;
				border-width: 11px;
				border-color: transparent $color-white transparent transparent;
				border-style: solid;
				transform: translateX(-50%) translateY(-10px) translateZ(0);
				transition: border-color 0.2s linear;
			}

			&::after {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				width: 20px;
				height: 4px;
				margin-top: -1px;
				background: $color-white;
				overflow: hidden;
				transform: scale(1) translateX(-10px) translateZ(0);
				transition: background-color 0.2s linear;
			}
		}

		.previous {
			left: 0;
		}

		.next {
			right: 0;

			&::before {
				border-color: transparent transparent transparent $color-white;
				margin-left: 19px;
			}
		}

		span {
			display: none;
		}

		.down {
			top: auto;
			bottom: 5.5vh;
			left: 50%;
			display: block;
			width: 10%;
			height: 10%;
			transform: translateX(-50%) scale(1);

			&:hover {
				transform: translateX(-50%) scale(1.25);
			}

			&::after {
				width: 4px;
				height: 20px;
				transform: scale(1) translateY(-10px) translateZ(0);
			}

			&::before {
				margin-left: 0;
				border-color: $color-white transparent transparent transparent;
				transform: translateX(-9px) translateY(9px) translateZ(0);
			}
		}
	}

	.navigation.hidden {
		opacity: 0;
		visibility: hidden;
	}

	.overlay-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		background: rgba(255, 255, 255, 0.7);
		transition: opacity 0.2s linear;
	}

	article[data-colour="dark"] {
		.overlay-bg {
			background: rgba(38, 38, 38, 0.7);
		}

		.overlay {
			color: $color-white;
		}
	}

	.slide[data-colour="dark"] {
		&:hover {
			.overlay-bg {
				opacity: 1;
			}
		}
	}

	.overlay {
		color: $color-base;
		margin-bottom: 20vh;
		opacity: 0;
		position: absolute;
		text-align: center;
		transition: opacity 0.2s linear;
		z-index: 20;
		width: 100%;
		bottom: 0;
	}

	.title {
		font-size: 2rem;
		font-weight: 700;
		transition: color 0.2s linear;
	}

	.detail {
		max-width: 610px;
		margin: 30px auto 0 auto;
		font-size: 1rem;
		line-height: 1.5rem;
		transition: color 0.2s linear;
	}

	.image-slide {
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}

	.video-player[data-background-loop="true"] {
		opacity: 1;
	}

	&.light {
		.navigation {
			a {
				&::before {
					border-color: transparent $color-base transparent transparent;
				}

				&::after {
					background: $color-base;
				}
			}

			.next {
				&::before {
					border-color: transparent transparent transparent $color-base;
				}
			}

			.down {
				&::before {
					border-color: $color-base transparent transparent transparent;
				}
			}
		}
	}
}

.touch {
	.homepage-slideshow {
		.slide {
			.overlay,
			.overlay-bg {
				opacity: 1;
			}
		}

		.detail {
			display: none;
		}

		.navigation {
			a {
				width: 70px;
			}

			.down {
				height: 70px;
				bottom: 0;
			}
		}
	}

	.homepage-introduction {
		.pips {
			bottom: 77px;
		}
	}
}

@media (max-width: $tablet) {
	.homepage-slideshow {
		height: 100%;

		.title {
			font-size: 1.75rem;
			font-weight: 300;
			line-height: 1.5;
			text-align: center;
			padding: 0.5rem;
		}

		.detail {
			display: none;
		}

		.navigation {
			a {
				width: 70px;
			}

			.down {
				height: 70px;
				bottom: 70px;
			}
			display: none;
		}

		.image-slide {
			align-items: center;
		}

		.standard-video-player {
			align-items: center !important; /* stylelint-disable-line declaration-no-important */
		}
	}

	.homepage.touch {
		padding-bottom: 53px;
	}

	.homepage-introduction {
		height: calc(70vh - 55px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
		padding-bottom: 0;

		.pips {
			display: none;
		}

		.options {
			flex-direction: column;
			margin: 0;

			li {
				margin: 0.5rem;
			}

			.button {
				text-align: center;
			}
		}

		.slide-area {
			background-position: 50% 0%;
		}

		.media {
			background-position: 50% 0%;
		}
	}
}

@media (max-width: $desktop) {
	.homepage {
		.product-grid {
			margin: 0 auto;
		}

		.epoq-recommendations-widget.product-slider {
			overflow-x: hidden;
		}
	}
}
