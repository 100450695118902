/*STORE PAGE*/
.stockists-page {
	min-height: 100vh;
	position: relative;
	z-index: 10;

	.standard-heading {
		padding-top: 84px;
	}

	td:last-of-type {
		padding-bottom: 8px !important; /* stylelint-disable-line declaration-no-important */
	}
}
