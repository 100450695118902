/* Styles for main navigation inside header */
.header {
	$transition-function: cubic-bezier(0, 1, 0.5, 1);
	--text-color: $light-color;
	box-sizing: border-box;

	.main-nav {
		&__list {
			list-style-type: none;
			padding: 0;
			margin: 0;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			&__item {
				height: $header-height;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				font-size: $defaultFontSize;
				font-weight: 700;

				> a {
					color: var(--text-color);
					margin: auto 16px;
					position: relative;
					cursor: pointer;
				}

				> a:empty {
					display: none;
				}

				&.sale > a {
					color: $color-red-6;
				}

				/* not hovered items and those that do not represent current URL */
				&:not(.active) > a,
				&:not(:hover) > a {
					--text-color: $dark-color;
					border-radius: 2.5px;
				}

				/* highlight hovered items and those that represent current URL */
				&.active > a,
				&:hover > a {
					--text-color: $light-color;

					&.is-special {
						opacity: 0.85;
					}
				}
			}

			&__item:first-child {
				> a {
					margin-left: 0;
				}
			}

			&__subcategories {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				align-content: flex-start;
				height: $submenu-height;
				padding-left: 0;
				margin-left: -8px;

				.main-nav__list__item {
					font-size: $defaultFontSize;
					font-weight: 400;
					margin-left: 0;
				}

				a {
					font-size: $defaultFontSize;
					margin: auto 1.1rem;
					position: relative;
					cursor: pointer;
				}
			}

			&__extra-content {
				flex: 0 0 50%;
				align-self: stretch;
			}

			&--second-level.is-open {
				max-height: $submenu-height;
				visibility: visible;
				transition: max-height 100ms $transition-function;

				.main-nav__list__item {
					opacity: 1;
					transition: opacity 3s $transition-function;
				}
			}

			&--second-level.is-active-submenu {
				height: $submenu-height;
				max-height: $submenu-height;
				visibility: visible;

				.main-nav__list__item {
					transition: none;
					opacity: 1;
				}
			}

			&--second-level.is-inactive {
				visibility: hidden;

				.main-nav__list__item {
					opacity: 0;
				}
			}

			&--second-level {
				max-height: 0;
				visibility: hidden;
				padding: 15px 0 0;
				display: flex;
				align-items: flex-start;
				flex-direction: row;
				background-color: $color-white;
				border-bottom: $item-border;
				position: absolute;
				top: $header-height;
				left: 0;
				width: 100%;
				text-transform: none;
				z-index: 110;

				.has-children {
					margin-right: 60px;
					margin-left: 0;
				}

				.main-nav__list__item {
					opacity: 0;
					justify-content: flex-start;
					align-items: flex-start;
					height: 23px;
					margin-left: 8px;
					margin-bottom: 5px;

					.active {
						&:hover,
						&:focus {
							background-color: $light-gray-3;
						}
					}

					> a {
						color: $color-gray-5;
						margin: 1px -0.5rem;
						padding: 5px 0.5rem;
						font-weight: bold;
					}

					&.sale > a {
						color: $color-red-6;
					}

					/* highlight hovered items and those that represent current URL */
					&.active > a,
					&:hover > a,
					a:hover {
						background-color: $light-gray-3;

						&::after {
							display: none;
						}
					}

					/*breadcrumb like link*/
					&--category {
						display: flex;
						flex: 0 0 210px;
						justify-content: flex-start;
						padding-left: 0;
						font-weight: 700;
						margin-left: 0;

						a,
						span {
							margin: 0;
							padding-left: 1rem;
							padding-top: 5px;
						}

						&:hover a {
							background-color: unset;
						}
					}

					/* hide all links on desktop */
					&--overview {
						display: none;
					}
				}
			}

			/* hide third level on desktop */
			&--third-level {
				display: none;
			}

			&.main-nav__list--first-level {
				> li {
					margin: auto 8px;

					.active {
						--text-color: $color-gray-6;
					}

					> a {
						margin: 0;
					}
				}

				> li:first-child {
					margin-left: 0;
				}
			}
		}

		&__toggle-btn {
			display: none;
		}
	}
}

/* Styles for main navigation inside flyout menu */
.flyout-menu {
	.main-nav {
		&__toggle-btn {
			background-color: transparent;
			border: none;
			cursor: pointer;
			padding: 0 22.5px 0 15px;
			border-bottom: $item-border;
			height: 55px;
			align-items: center;
			display: flex;
			justify-content: space-between;
			flex: 1 1;
			font-size: $defaultFontSize;
			font-weight: 700;

			> svg {
				pointer-events: none;
				transform: rotate(0deg);
				transition-duration: 0.2s;
				width: 10px;
				z-index: 101;
			}
		}

		&__list {
			list-style-type: none;
			padding: 0;
			margin: 0;

			&--third-level li {
				font-weight: 400;
				border-bottom: 1px solid $color-gray-9;
			}

			&__item {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				font-weight: 700;

				> a {
					display: block;
					padding: 0 20.5px 0 15px;
					flex: 1;
					color: $color-base;
					border-bottom: $item-border;
					font-size: 13px;
					line-height: 54px;
				}

				&--category {
					display: none;
				}

				.main-nav__list {
					overflow-y: hidden;
					width: 100%;
					background-color: $color-gray-16;
					max-height: 0;
					transition-duration: 0.5s;
					transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

					.main-nav__list {
						background-color: $color-gray-8;
					}
				}

				&.is-open {
					> .main-nav__list {
						overflow-y: hidden;
						max-height: 2000px; /* approximate max height */
						transition-property: all;
						transition-duration: 2.5s;
						transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
					}

					> .main-nav__toggle-btn > svg {
						transform: rotate(-180deg);
						transition-duration: 0.2s;
					}
				}

				&.sale {
					& > .main-nav__list__item__link,
					& > .main-nav__toggle-btn {
						color: $color-red-6;
					}
				}
			}

			&--second-level button,
			.main-nav__list__item--overview {
				font-weight: 400 !important; /* stylelint-disable-line declaration-no-important */
			}

			&__subcategories {
				.main-nav__list__item {
					font-weight: 400 !important; /* stylelint-disable-line declaration-no-important */
				}
			}
		}
	}
}

@media screen and (min-width: calc($desktop-lg + 1px)) {
	.header {
		.main-nav {
			&__list {
				&--second-level {
					.main-nav__list__item {
						&--category {
							flex: 0 0 260px;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1640px) {
	.header {
		.main-nav {
			&__list {
				&--second-level {
					.main-nav__list__item {
						&--category {
							flex: 0 0 290px;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 2000px) {
	.header {
		.main-nav {
			&__list {
				&--second-level {
					.main-nav__list__item {
						&--category {
							flex: 0 0 350px;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: calc($desktop-lg + 1px)) {
	.header {
		.main-nav {
			&__list {
				&--second-level {
					.main-nav__list__item {
						&--category {
							flex: 0 0 260px;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1640px) {
	.header {
		.main-nav {
			&__list {
				&--second-level {
					.main-nav__list__item {
						&--category {
							flex: 0 0 290px;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 2000px) {
	.header {
		.main-nav {
			&__list {
				&--second-level {
					.main-nav__list__item {
						&--category {
							flex: 0 0 350px;
						}
					}
				}
			}
		}
	}
}
