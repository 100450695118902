/*GENDER LANDING PAGE*/
.gender-landing-page {
	.breadcrumb {
		ul {
			padding-bottom: 1rem;
		}
	}

	.grid {
		> .content {
			min-height: 330px;
			background: transparent url("../images/gender-landing-page/loader.gif") no-repeat 50% 50%;
			margin-bottom: 60px;
		}
	}

	.standard-tabs {
		padding-top: 50px;
		padding-bottom: 10px;

		.slides {
			margin: 20px 0 0;
		}

		.slide {
			width: 100%;
			height: 100%;
			background: $color-white;
		}

		.custom-pips {
			.pips {
				width: 240px;
			}

			li {
				a {
					display: inline-block;
					color: $color-gray-9;
					font-weight: 700;
					font-size: 1em;
					border-bottom: 2px solid transparent;
					transition: all 0.4s;

					&:hover {
						color: $color-gray-1;
						border-color: transparent;
					}
				}
			}

			li.active {
				a {
					color: $color-base;
					border-color: $color-gray-9;
					cursor: default;

					&:hover {
						border-color: $color-gray-9;
					}
				}
			}
		}
	}

	.product-grid {
		margin: 0 auto;
		background: $color-white;

		.content {
			padding: 0;
		}
	}

	.epoq-recommendations-widget {
		margin-top: 140px;
	}

	.product-grid-item {
		.wishlist {
			svg {
				left: auto;
				transform: translateY(-50%);
			}
		}
	}
}

@media (max-width: $desktop) {
	.gender-landing-page {
		.epoq-recommendations-widget.product-slider {
			overflow-x: hidden;
		}
	}
}

@media (max-width: 1265px) {
	.gender-landing-page {
		.product-grid {
			max-width: 760px;

			.product-grid-item {
				width: 50%;
			}
		}

		.grid {
			padding: 0 10px;
		}
	}
}

@media (max-width: $tablet) {
	.gender-landing-page {
		.product-grid {
			.content {
				padding-left: 5px;
				padding-right: 5px;
			}
		}
	}
}

@media (max-width: $mobile) {
	.gender-landing-page {
		.product-grid {
			.product-grid-item {
				width: 100%;
			}
		}
	}
}
