.faqs {
	margin: 0 auto;
	padding-top: 60px;

	.section-content {
		width: 100%;
		max-width: 1680px;
		padding: 0 6.33% 0 6.33%;
		box-sizing: border-box;
	}

	.main-title {
		padding: 16px 6.33% 67px 6.33%;

		h1 {
			color: $color-base;
			font-weight: 700;
			font-size: 1.875em;
			text-align: center;
		}
	}

	.trigger {
		display: block;
		padding: 22px 20px 21px 20px;
		color: $color-base;
		font-size: 0.875em;
		border-radius: 4px;
		background: $color-gray-16;
		transition: background 0.1s linear;

		&:hover {
			background: $color-gray-8;
		}
	}

	.faq-group {
		padding-bottom: 38px;
	}

	.question {
		max-width: 650px;
		margin: 0 auto;
		padding: 0 0 20px 0;

		.expander {
			height: 0;
			overflow: hidden;
		}

		.content {
			padding: 20px 46px 6px 46px;

			a {
				color: $color-gray-1;
				transition: color 0.1s linear;

				&:hover {
					color: $color-black;
				}
			}
		}

		p {
			padding: 0 0 34px 0;
		}

		table {
			width: 100%;
			margin: 3px 0 34px 0;
			padding: 0;
			border-spacing: 0;
			border-collapse: collapse;
			border-width: 1px 1px 1px 1px;
			border-color: $color-gray-17;
			border-style: solid;
			text-align: left;
		}

		th {
			width: 50%;
			padding: 19px 20px 20px 20px;
			box-sizing: border-box;

			& + th {
				border-left: 1px solid $color-gray-17;
			}
		}

		td {
			padding: 19px 20px 20px 20px;
			box-sizing: border-box;
			border-width: 1px 0 0 0;
			border-style: solid;
			border-color: $color-gray-17;

			& + td {
				border-left: 1px solid $color-gray-17;
			}
		}
	}

	.note {
		padding-right: 3px;
		color: $color-gray-17;
	}

	.note-explanations {
		padding-top: 2px;

		.note {
			padding-left: 3px;

			&:first-child {
				padding-left: 0;
			}
		}
	}
}

.sub-title {
	padding: 0 0 27px 0;
	color: $color-base;
	font-size: 1em;
	font-weight: 700;
	text-align: center;
}

.faqs-nav {
	a {
		color: $color-base;
		text-decoration: none;
		text-transform: uppercase;
		transition: color 0.1s linear;

		&:hover {
			color: $color-black;
		}
	}
}

.page-section-nav {
	display: none;

	ul {
		scrollbar-width: none;
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
		overflow-x: scroll;
		white-space: nowrap;
		padding: 7px 0 8px 0;
		list-style: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	li {
		display: inline;

		&:first-child {
			margin-left: 20px;
		}
	}

	a {
		display: inline-block;
		padding: 8px 7px;
		color: $color-base;
		font-size: 0.75rem;
		text-transform: uppercase;
		text-decoration: none;
		transition: color 0.1s linear;

		&:hover {
			color: $color-gray-1;
		}
	}

	a.active {
		color: $color-gray-1;
	}
}

.page-nav-visible {
	display: block;
	position: fixed;
	top: $header-height;
	left: 0;
	right: 0;
	padding: 0;
	text-align: center;
	border-width: 0 0 2px 0;
	border-style: solid;
	border-color: rgba(188, 188, 188, 0.25);
	background: rgba(265, 265, 265, 0.9);
	transition: top 0.33s;
	z-index: 99;
}

@media (max-width: $desktop) {
	.faqs {
		padding-top: 60px;
	}
}

@media (max-width: $tablet) {
	.faqs {
		.main-title {
			padding-bottom: 28px;
			font-size: 1.5em;
		}

		.inner {
			padding-left: 20px;
			padding-right: 20px;
		}

		.question {
			table {
				overflow-x: auto;
				white-space: nowrap;
				display: block;
				max-width: fit-content;
			}
		}
	}
}
