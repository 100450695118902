.carhartt-wip {
	width: 75% !important; /* stylelint-disable-line declaration-no-important */
}

.carhartt {
	width: 75% !important; /* stylelint-disable-line declaration-no-important */
	float: right;
}

.logo-image {
	width: 100%;
	margin-bottom: 20px;
}

.logo-image-container {
	width: 75% !important; /* stylelint-disable-line declaration-no-important */
}

.logo-image-container--carhartt {
	float: right;
	padding: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.two-brands {
	font-size: 12px !important; /* stylelint-disable-line declaration-no-important */
	font-weight: 700;
	overflow: auto;

	p {
		a {
			text-decoration: none;
		}
	}
}

.two-brands-button {
	display: block;
	background: $color-blue-1;
	border-radius: 4px;
	transition: background 0.2s ease;
	border: 0;
	color: $color-white;
	font-size: 14px !important; /* stylelint-disable-line declaration-no-important */
	font-weight: 700;
	margin: 20px 0 0 0;
	min-width: 120px;
	padding: 13px 18px;
	text-transform: none;

	&:hover {
		cursor: pointer;
		background-color: $color-blue-2;
		border-color: $color-blue-2;
		color: $color-white;
	}
}

.two-brands-headline {
	font-size: 26px !important; /* stylelint-disable-line declaration-no-important */
	font-weight: 700;
}

@media (min-width: calc($tablet + 1px)) {
	.carhartt-wip {
		display: block;
		margin-right: 25px !important; /* stylelint-disable-line declaration-no-important */
	}

	.carhartt {
		float: none;
		margin-left: 25px !important; /* stylelint-disable-line declaration-no-important */
	}

	.right-shift {
		.carhartt-container {
			margin-top: 125px;
		}
	}

	.left-shift {
		.carhartt-wip-container {
			margin-top: 125px;
		}
	}

	.carhartt-container {
		display: inline-block;
		width: 50%;
	}

	.carhartt-wip-container {
		display: inline-block;
		float: left;
		width: 50%;
	}

	.logo-image-container {
		padding: 0 !important; /* stylelint-disable-line declaration-no-important */
	}

	.logo-image-container--carhartt-wip {
		margin-right: 25px !important; /* stylelint-disable-line declaration-no-important */
	}

	.logo-image-container--carhartt {
		float: none;
		margin-left: 25px !important; /* stylelint-disable-line declaration-no-important */
	}

	.two-brands {
		max-width: 1000px;
		margin: 0 auto;
	}

	.two-brands-headline {
		font-size: 40px !important; /* stylelint-disable-line declaration-no-important */
		max-width: none !important; /* stylelint-disable-line declaration-no-important */
		width: 87% !important; /* stylelint-disable-line declaration-no-important */
		line-height: normal !important; /* stylelint-disable-line declaration-no-important */
		margin-top: 46px !important; /* stylelint-disable-line declaration-no-important */
		margin-bottom: 46px !important; /* stylelint-disable-line declaration-no-important */
	}

	.two-brands-description {
		max-width: 800px !important; /* stylelint-disable-line declaration-no-important */
		width: auto !important; /* stylelint-disable-line declaration-no-important */
	}
}
