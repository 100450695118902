.radio-carousel {
	position: relative;

	.main-title {
		position: absolute;
		top: 37px;
		left: 0;
		width: 100%;
		font-size: 1em;
		text-align: center;
	}

	> .content {
		position: relative;
		width: 100%;
		margin: 0 auto;
		overflow: hidden;
	}

	.section-title {
		background: $color-gray-8;
		padding-bottom: 0;
		margin-bottom: -20px;
	}

	.slides-container {
		position: relative;
		padding: 105px 0 24px 0;
		background: $color-gray-8;
	}

	.slides {
		position: relative;
		left: 0;
		display: table;
		list-style: none;

		&::after {
			content: "";
			display: block;
			clear: both;
		}
	}

	.slide {
		position: relative;
		display: table-cell;
		width: 200px;
		padding: 0;
		overflow: hidden;
		opacity: 1;
		transition: opacity 0.3s linear;

		a {
			position: relative;
			z-index: 2;
			display: block;
			padding: 0 5px;
			width: 100%;
			height: 100%;
			color: $color-base;
			font-size: 0.875em;
			box-sizing: border-box;
		}

		img {
			width: 100%;
			opacity: 1;
			vertical-align: top;
			transition: opacity 0.2s linear;
		}

		.read-article {
			display: block;
			color: $color-gray-1;
			text-decoration: underline;
			background: $color-gray-8;

			&:hover {
				color: $color-black;
			}
		}
	}

	.slide.loaded {
		opacity: 1;
	}

	.background {
		position: absolute;
		top: 0;
		left: 5px;
		right: 5px;
		bottom: 0;
		z-index: 1;
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		opacity: 0;
		display: block;
		background: rgba(38, 38, 38, 0.7);
		transition: opacity 0.1s linear;

		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			content: "";
			display: block;
			width: 40px;
			height: 40px;
			color: $color-gray-2;
			font-family: $font-secondary;
			font-size: 10px;
			line-height: 40px;
			text-align: center;
			text-indent: 3px;
			transform: translateX(-50%) translateY(-50%);
		}
	}

	a {
		&:hover {
			.overlay {
				opacity: 1;

				&::after {
					content: "\e606";
					border-radius: 50%;
					background: $color-white;
				}
			}
		}
	}

	.media {
		position: relative;
	}

	.next-button {
		position: absolute;
		top: 105px;
		right: 0;
		z-index: 99;
		text-indent: 105%;
		overflow: hidden;
		white-space: nowrap;
		width: 100px;
		background: rgba(255, 255, 255, 0.7);

		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			content: "\e60a";
			font-family: $font-secondary;
			font-size: 22px;
			color: $color-gray-1;
			text-indent: 0;
			transform: translateX(-50%) translateY(-50%);
			transition: color 0.1s linear;
		}
	}

	.previous-button {
		position: absolute;
		top: 105px;
		z-index: 99;
		text-indent: 105%;
		overflow: hidden;
		white-space: nowrap;
		width: 100px;
		background: rgba(255, 255, 255, 0.7);
		right: auto;
		left: 0;

		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			font-family: $font-secondary;
			font-size: 22px;
			color: $color-gray-1;
			text-indent: 0;
			transform: translateX(-50%) translateY(-50%);
			transition: color 0.1s linear;
			content: "\e609";
		}
	}

	.cta {
		position: absolute;
		top: 50%;
		width: 100%;
		padding: 0 10px;
		text-align: center;
		box-sizing: border-box;
		transform: translateY(-50%);
		display: none;
	}

	.loaded {
		.overlay {
			display: block;
		}

		.cta {
			display: none;
		}

		img {
			opacity: 1;
		}

		.details {
			padding: 17px 0 0 0;
		}
	}

	.details {
		position: relative;
		z-index: 10;
		padding: 5px 10px 0 0;
		background: $color-gray-8;

		dt {
			display: none;
		}

		dd {
			display: block;
			padding: 0 0 5px 0;
		}

		.artist {
			font-weight: 700;
		}
	}

	.mouse-tooltip {
		position: absolute;
		top: 0;
		left: 0;
		color: $color-blue-1;
		font-size: 0.813em;
		padding: 5px 7px 5px 7px;
		text-align: center;
		z-index: 200;
		border-radius: 4px;
		background: $color-white;
		cursor: pointer;
	}
}

.touch.radio-carousel {
	a {
		.overlay {
			background: rgba(38, 38, 38, 0);
			opacity: 1;

			&::after {
				content: "\e606";
				border-radius: 50%;
				background: $color-white;
			}
		}
	}

	.mouse-tooltip {
		opacity: 0;
	}
}
