.breadcrumbs {
	width: 87%;
	max-width: 1680px;
	margin: 0 auto;

	li {
		display: inline-block;
		font-size: 0.688em;

		&::after {
			content: " /";
		}

		&:last-child {
			&::after {
				display: none;
			}
		}
	}

	a {
		color: $color-gray-1;

		&:hover {
			color: $color-black;
		}
	}
}

@media only screen and (max-width: $mobile) {
	.breadcrumbs {
		display: none;
	}
}
