.standard-seo {
	padding: 60px 20px;
	line-height: 1.3em;
	background: $color-gray-16;

	> .content {
		width: 95%;
		margin: 0 auto;
	}

	h1 {
		font-size: 1.25em;
	}

	h2 {
		font-size: 1.25em;
	}

	h4 {
		font-size: 1.25em;
	}

	h5 {
		font-size: 1.25em;
	}

	h3 {
		font-size: 15px;
		font-weight: normal;
		color: $color-gray-13;
		margin-top: 15px;
	}

	ul {
		list-style: none;
		margin: 15px 0;
		font-size: 0.875em;
	}

	li {
		position: relative;
		padding-left: 14px;

		&::before {
			content: "•";
			position: absolute;
			top: 9px;
			left: 0;
			color: $color-gray-1;
			font-size: 1.5rem;
			line-height: 0em;
		}
	}

	p {
		margin: 15px 0;
		font-size: 0.875em;
		line-height: 1.375rem;
	}

	a {
		color: $color-gray-1;

		&:active {
			color: $color-gray-1;
		}

		&:visited {
			color: $color-gray-1;
		}

		&:hover {
			color: $color-base;
		}
	}

	blockquote {
		margin: 15px 0;
		padding-left: 20px;
		border-left: 4px solid $color-gray-1;
		font-size: 0.875em;

		&::before {
			content: '"';
		}

		&::after {
			content: '"';
		}
	}

	.extra {
		height: auto;

		.inner {
			padding: 20px 0;
		}
	}

	.options {
		width: 100%;
		margin: 5px 0 30px 0;
		text-align: left;

		.read-more {
			display: none;
			font-size: 1em;
			line-height: 1.4em;
			color: $color-base;
			padding: 0;
			text-transform: none;
			border-width: 0 0 2px;
			border-style: solid;
			border-color: $color-gray-17;
		}
	}
}

@media (max-width: 555px) {
	.standard-seo {
		padding: 20px;

		.extra {
			height: 0;
			overflow: hidden;
		}

		.options {
			.read-more {
				display: inline-block;
			}
		}
	}
}

@media (min-width: 950px) {
	.standard-seo {
		> .content {
			padding: 0;
			max-width: 555px;
		}
	}
}

@media (min-width: 1039px) {
	.standard-seo {
		h3 {
			font-size: 14px;
		}
	}
}

@media only screen and (min-device-pixel-ratio: 1.5) and (max-width: $tablet),
	only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: $tablet) {
	.standard-seo {
		ul {
			font-size: 0.938em;
		}

		p {
			font-size: 0.938em;
		}

		blockquote {
			font-size: 0.938em;
		}
	}
}
