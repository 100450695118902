/*TEXT PAGE*/
.text-page {
	padding-top: 60px;

	.breadcrumb {
		a {
			text-decoration: none;
		}
	}

	nav {
		top: 0;
		background: rgba(255, 255, 255, 0.9);
		position: fixed;
		z-index: 400;
		left: 0;
		right: 0;
		border-width: 0 0 2px;
		border-color: rgba(188, 188, 188, 0.25);
		border-style: solid;

		ul {
			text-align: center;
			padding: 10px 0;
			width: 100%;
		}

		li {
			display: inline-block;
			text-align: center;
			margin: 8px 7px;
			font-size: 0.75rem;
			font-weight: 700;
		}

		a {
			text-decoration: none;
			font-weight: 700;
			text-transform: uppercase;
			color: $color-gray-1;
			line-height: 1em;
			transition: 0.2s all;

			&:hover {
				color: $color-base;
			}
		}

		a.active {
			color: $color-base;
		}
	}

	header {
		text-align: center;
		padding: 24px 10px 61px;

		.title {
			text-align: center;
			font-size: 1.875em;
			font-weight: 700;
			line-height: 2.375rem;
		}
	}

	section {
		max-width: 650px;
		margin: 0 auto;
		padding: 0 0 28px;

		.main-title {
			span {
				font-weight: 700;
				font-size: 1.125em;
				line-height: 1.75rem;
			}
		}

		.title {
			font-weight: 700;
			font-size: 1em;
			line-height: 1.75rem;
			padding: 0 0 28px;
		}

		li {
			position: relative;
			margin: 0 0 0 80px;
			list-style: none;

			li {
				position: relative;
				margin: 0 0 0 42px;
				list-style: none;

				span {
					left: -42px;
					width: 42px;
				}

				p {
					font-size: 1em;
					line-height: 1.5rem;
				}
			}

			span {
				color: $color-gray-17;
				display: block;
				width: 32px;
				position: absolute;
				top: 0;
				left: -32px;
				font-size: 0.875em;
				line-height: 1.25rem;
			}

			p {
				font-size: 1em;
				line-height: 1.5rem;
			}
		}

		ul {
			li {
				&::before {
					color: $color-gray-17;
					display: block;
					width: 32px;
					position: absolute;
					top: 0;
					left: -32px;
					font-size: 0.875em;
					line-height: 1.25rem;
					content: "●";
				}
			}
		}
	}

	.main-title {
		padding: 0 0 28px;

		.subtitle {
			span {
				color: $color-gray-17;
				font-size: 0.75rem;
				line-height: 1em;
				font-weight: normal;
			}
		}
	}

	a {
		color: $color-gray-1;
		text-decoration: underline;
		transition: color 0.4s;

		&:hover {
			color: $color-base;
		}
	}

	p {
		font-size: 1em;
		line-height: 1.5rem;
		padding: 0 0 20px;
	}

	.form {
		border-width: 1px;
		border-style: solid;
		border-color: $color-gray-17;
		padding: 40px 42px 12px;
		margin: 0 0 28px;

		.title {
			text-align: center;
			line-height: 1em;
			padding: 0 0 10px;
		}

		.subtitle {
			text-align: center;
			padding: 0 0 28px;
			line-height: 1.5rem;
		}

		span {
			display: block;
		}

		.user-input {
			display: inline-block;
			border-width: 0 0 1px;
			border-style: solid;
			border-color: $color-gray-17;
			color: $color-white;
			padding: 0;
			margin: 28px 0;

			span {
				color: $color-gray-17;
				display: inline-block;
			}
		}

		.user-input.full {
			width: 100%;
			padding: 28px 0 0;

			& + .user-input.full {
				padding: 0;
			}
		}

		.user-input.half {
			width: 50%;
			padding: 28px 0 0;
		}
	}

	table {
		width: 100%;
		border-spacing: 0;
		border-collapse: separate;
		border-width: 1px 1px 0 0;
		border-color: $color-gray-17;
		border-style: solid;
		margin: 0 0 28px;
	}

	td {
		border-width: 0 0 1px 1px;
		border-color: $color-gray-17;
		border-style: solid;
		font-size: 1em;
		line-height: 1em;
		padding: 22px 20px 21px;
	}

	th {
		border-width: 0 0 1px 1px;
		border-color: $color-gray-17;
		border-style: solid;
		font-size: 1em;
		line-height: 1em;
		padding: 22px 20px 21px;
		text-align: left;
	}
}

.text-page-subtitle {
	color: $color-gray-17;
	font-size: 0.75rem;
	line-height: 1em;
	font-weight: normal;
}

.jobs {
	ul {
		line-height: 1.3em;
		list-style: none;

		li {
			padding-bottom: 15px;

			&::before {
				content: "";
			}
		}
	}
}

@media only screen and (min-device-pixel-ratio: 1.5) and (max-width: $tablet),
	only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: $tablet) {
	.text-page {
		header {
			padding: 28px 0;
		}
	}
}

@media (max-width: $tablet) {
	.text-page {
		.breadcrumb {
			display: none;
		}

		nav {
			display: none;
			background-color: $color-white;
		}

		header {
			.title {
				padding: 28px 20px;
				font-size: 1.5em;
			}
		}

		section {
			max-width: 650px;
			margin: 0 auto;
			padding: 0 20px 28px;

			.title {
				font-size: 1em;
				line-height: 1.75rem;
				padding: 0 0 28px;
			}

			li {
				margin: 0 0 0 32px;
				list-style: none;

				p {
					font-size: 0.813rem;
					line-height: 1.25rem;
				}

				li {
					p {
						font-size: 0.875rem;
					}
					position: relative;
					margin: 0 0 0 22px;
					list-style: none;
				}
			}
		}

		p {
			font-size: 0.938em;
			line-height: 1.75rem;
			padding: 0 0 20px;
		}

		.form {
			padding: 40px 20px 12px;
		}

		td {
			font-size: 0.938em;
			line-height: 1em;
			padding: 10px 10px 11px;
		}

		th {
			font-size: 0.938em;
			line-height: 1em;
			padding: 10px 10px 11px;
		}
	}
}
