.lookbook-page {
	padding: 0;

	header {
		position: relative;
		margin: 0 0 50px;

		p {
			text-align: left;
			font-size: 1em;
			line-height: 1.5rem;
			padding: 0 20px;
			margin: 0 auto 24px;
			color: $color-white;
		}
	}

	h1 {
		text-align: center;
		font-size: 1.875em;
		line-height: 1em;
		padding: 38px 0;
	}

	.look-container {
		display: flex;
		flex-wrap: wrap;
	}

	.share {
		margin: 0 auto 20px;
		position: relative;
		overflow: hidden;
		width: 20px;
		padding: 24px 0 0;
		transition: width 0.4s;

		&:hover {
			width: 130px;
		}

		.container {
			position: relative;
			width: 130px;
			float: left;
		}

		ul {
			position: absolute;
			left: 24px;
		}

		li {
			float: left;
			text-align: center;
			list-style: none;
			margin: 0 0 0 15px;

			a {
				padding: 0;

				&::after {
					content: "";
				}
			}
		}

		a {
			padding: 2px 0;
			text-decoration: none;
			display: block;
			transition: color 0.4s;
			color: $color-gray-17;

			span {
				display: none;
			}

			&::after {
				font-family: $font-secondary;
				content: "\e615";
				padding: 0 2px;
				display: block;
			}
		}
	}

	.container {
		> a {
			float: left;
		}
	}

	.lookbook {
		overflow: hidden;

		> div {
			&::after {
				clear: both;
				content: "";
				display: block;
			}
		}

		.mouse-tooltip {
			color: $color-gray-5;
			font-weight: 700;
			margin: 0;
			padding: 6px 12px;
			display: block;
			position: absolute;
			background-color: $color-white;
			font-size: 0.75em;
			border-radius: 3px;
			opacity: 0;
			transition: opacity 0.4s, z-index 0.4s;
			z-index: -1;
			line-height: 0.875em;
		}

		.tooltip {
			display: none;
		}

		.mouse-tooltip.active {
			opacity: 1;
			z-index: 10;
		}
	}

	.lookbook.clone {
		overflow: hidden;
	}

	.overlay {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $color-gray-5;

		ul {
			top: 50%;
			position: absolute;
			left: 0;
			right: 0;
			transform: translateY(-50%);
		}

		li {
			display: block;
			text-align: center;
			padding: 10px 0;
		}

		span {
			z-index: 11;
			color: $color-white;
			font-size: 1em;
			line-height: 1.5rem;
			transition: color 0.4s;
		}

		.disabled {
			text-decoration: none;
		}

		a {
			&:hover {
				span {
					color: $color-gray-17;
				}
			}
		}
	}

	.image-holder {
		position: relative;
		display: block;

		&:hover {
			.overlay {
				transition: 0.4s opacity;
				opacity: 20%;

				span {
					display: none;
				}
			}
		}
	}

	.item {
		position: relative;
		overflow: visible;

		.inner {
			box-sizing: border-box;
		}

		img {
			width: 100%;
		}
	}

	.item.left {
		width: 50%;
	}

	.item.right {
		width: 50%;
	}

	.item.center {
		width: 100%;

		.inner {
			padding: 3vw 0;
		}
	}

	.left {
		.inner {
			padding: 3vw 3vw 3vw 10vw;
			margin: 0;
			width: 100%;
		}

		.product-slideshow {
			left: 0;
		}
	}

	.right {
		.inner {
			padding: 3vw 10vw 3vw 3vw;
			margin: 0;
			width: 100%;
		}

		.product-slideshow {
			right: 0;
		}
	}

	.small {
		.left {
			.image-holder {
				width: 64.29%;
			}
		}

		.right {
			.image-holder {
				width: 64.29%;
			}
		}
	}

	.triple {
		position: relative;

		.container {
			margin: 0 5vw;
			width: 90vw;
			padding: 0;
			max-width: none;
			display: flex;
			justify-content: center;
			column-gap: 3vw;
		}

		.item {
			overflow: visible;
			position: static;
		}

		.inner {
			padding: 3vw 0;
		}

		.product-slideshow {
			margin: 0;
			left: 0;
		}
	}

	.product-slideshow {
		position: absolute;
		z-index: 1;
		bottom: 0;
		background-color: $color-white;
		text-align: center;
		overflow: auto;
		overflow-x: hidden;
		opacity: 0;
		pointer-events: none;
		width: 100vw;

		.close {
			position: absolute;
			color: $color-black;
			right: 5px;
			top: 5px;
			z-index: 10;
			transition: color 0.4s;
			padding: 15px;

			&::after {
				font-family: $font-secondary;
				content: "\e605";
				font-size: 1.25em;
			}

			span {
				display: none;
			}
		}

		.content {
			max-width: 710px;
			position: relative;
			margin: 60px auto;

			&::after {
				clear: both;
				content: "";
				display: block;
			}
		}

		.product-indicator {
			position: relative;
			z-index: 11;
			text-align: center;
			font-size: 0.875em;
			line-height: 1rem;
			padding: 0 0 10px;
			color: $color-base;
		}

		ul {
			list-style: none;
		}

		li {
			position: absolute;
			top: 40%;
			transform: translateY(-50%);
			z-index: 2;
			padding: 15px;
		}

		.nav {
			span {
				display: none;
			}

			a {
				display: block;

				&::after {
					color: $color-base;
					font-family: $font-secondary;
					content: "\e60a";
					font-size: 1.25rem;
					transition: 0.4s all;
					display: block;
					width: 100%;
				}

				&:hover {
					&::after {
						font-size: 1.45rem;
					}
				}
			}
		}

		.prev {
			left: -15px;

			a {
				&::after {
					content: "\e609";
				}
			}
		}

		.next {
			right: -15px;
		}

		.product-holder {
			.image {
				.image-holder {
					z-index: 9;
					width: 23vw;
					max-width: 335px;
					margin: 30px auto;
					float: none;
				}
			}

			.image-holder {
				img {
					height: 100%;
				}
			}
		}

		form {
			position: relative;
			z-index: 13;
		}

		.product-submit {
			border-color: $color-blue-1;
			background-color: $color-blue-1;
			color: $color-white;
			cursor: pointer;
			margin: 0 0 12px;
			width: 100%;
			transition: opacity 0.2s;
			padding: 17px;
		}

		.submit-disabled {
			opacity: 0.5;
			cursor: default;
			border-color: $color-gray-10;
			background-color: $color-gray-10;
			color: $color-gray-7;
			pointer-events: none;
		}

		.cta {
			display: block;
			font-size: 0.875em;
			color: $color-gray-1;
			text-decoration: underline;
			padding: 20px 0 0;
			transition: 0.4s color;

			&:hover {
				color: $color-base;
			}
		}
	}

	.product-slideshow.visible {
		pointer-events: unset;
		display: block;
	}

	.product-holder {
		position: relative;
	}

	.disabled {
		visibility: visible;
		opacity: 0.5;
		cursor: default;
		text-decoration: none;
	}

	.product {
		width: 100%;
		position: relative;
		top: 0;

		.title {
			position: relative;
			z-index: 10;
			font-size: 1.125em;
			line-height: 21px;
			font-weight: 700;
			color: $color-base;

			span {
				color: $color-gray-13;
			}

			span.colourDesc {
				color: $color-gray-5;
			}

			.strike-through {
				text-decoration: line-through;
			}

			.sale {
				color: $color-red-1;
			}
		}

		& + .product {
			position: absolute;
		}
	}

	form {
		width: 350px;
		margin: 0 auto;
		padding: 0 20px;
	}

	select {
		display: block;
		border-width: 1px;
		border-style: solid;
		border-color: $color-base;
		color: $color-base;
		border-radius: 4px;
		padding: 20px 20px 21px 20px;
		margin: 0 0 20px;
		outline: 0;
		box-sizing: border-box;
		-ms-appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		transition: color 0.4s, background-colour 0.4s;
		font-size: 1em;
		width: 100%;
		background: transparent url("../images/global/dropdown-arrow-light.svg") no-repeat 95% 50%;

		&:focus {
			background-image: url("../images/global/dropdown-arrow-light-up.svg");
			outline: none;
			color: $color-base;
		}

		&:-webkit-autofill {
			-webkit-box-shadow: 0 0 0 1000px white inset;
		}
	}

	button {
		display: block;
		border-width: 1px;
		border-style: solid;
		border-color: $color-base;
		color: $color-base;
		border-radius: 4px;
		padding: 20px 20px 21px 20px;
		margin: 0 0 20px;
		outline: 0;
		box-sizing: border-box;
		-ms-appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		transition: color 0.4s, background-colour 0.4s;
		font-size: 1em;
	}

	select[disabled] {
		border: 0;
	}

	option {
		font-size: 1em;
		line-height: 1.5rem;
	}

	option[disabled] {
		text-decoration: line-through;
	}

	.clone {
		display: none;
	}

	button.open-slideshow-cta {
		display: none;
	}
}

.collab-page {
	padding: 0;

	.breadcrumb {
		position: absolute;
		z-index: 1;
		top: 13px;
		left: 0;
		right: 0;
	}

	header {
		position: relative;
		padding: 0 0 20px;
		margin: 0 0 100px;

		.placeholder {
			min-height: 575px;
			max-height: 920px;
			overflow: hidden;
			position: relative;

			img {
				width: 100%;
				min-width: 1280px;
				position: relative;
				left: 50%;
				transform: translate(-50%, 0);
			}

			h1 {
				img {
					min-width: 0;
					width: auto;
					position: static;
					left: 50%;
					transform: none;
				}
			}
		}

		.item {
			margin: 0;
		}

		.item.left {
			left: 0;
			right: 50%;
		}

		.item.right {
			left: 50%;
			right: 0;
		}

		&::after {
			content: "";
			display: block;
			clear: both;
		}

		.content-holder {
			position: absolute;
			min-height: 575px;
			max-height: 920px;
			top: 0;
			left: 0;
			right: 0;
		}

		.content {
			&::after {
				clear: both;
				display: block;
				content: "";
			}
		}

		.intro {
			p {
				text-align: left;
				font-size: 1em;
				line-height: 1.5rem;
				padding: 0 20px;
				margin: 0 auto 24px;
				color: $color-white;
			}
		}
	}

	h1 {
		text-align: center;
		font-size: 1.875em;
		line-height: 1em;
		padding: 38px 0;
	}

	.intro {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.intro-content {
		position: absolute;
		top: 50%;
		left: 20px;
		right: 20px;
		transform: translateY(-50%);
	}

	.intro.left {
		left: 6.33%;
		right: 50%;
	}

	.intro.right {
		left: 50%;
		right: 6.33%;
	}

	.standard-video-player {
		.video-placeholder {
			position: relative;
		}

		.video-player {
			position: absolute;

			.close {
				display: none;
			}
		}

		.video-player.active {
			opacity: 1;
			z-index: 5;
			transition: opacity 0.4s;
		}
	}

	.lookbook {
		overflow: hidden;

		> div {
			&::after {
				clear: both;
				content: "";
				display: block;
			}
		}

		p {
			max-width: 720px;
			padding: 0 20px;
			margin: 0 auto 60px;
			font-size: 1em;
			line-height: 1.5rem;
			box-sizing: border-box;
		}

		.copy {
			max-width: 100%;
			width: 87.43%;
			padding: 0;
			margin: 0 auto 100px;
			font-size: 1em;
			line-height: 1.5rem;
		}

		.left {
			.copy {
				float: right;
			}
		}

		.right {
			.copy {
				float: left;
			}
		}

		.mouse-tooltip {
			color: $color-gray-5;
			font-weight: 700;
			margin: 0;
			padding: 6px 12px;
			display: block;
			position: absolute;
			background-color: $color-white;
			font-size: 0.75em;
			border-radius: 3px;
			opacity: 0;
			transition: opacity 0.4s, z-index 0.4s;
			z-index: -1;
			line-height: 0.875em;
		}

		.tooltip {
			display: none;
		}

		.mouse-tooltip.active {
			opacity: 1;
			z-index: 10;
		}
	}

	.lookbook.clone {
		overflow: hidden;
	}

	.overlay {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $color-gray-5;

		ul {
			top: 50%;
			position: absolute;
			left: 0;
			right: 0;
			transform: translateY(-50%);
		}

		li {
			display: block;
			text-align: center;
			padding: 10px 0;
		}

		span {
			z-index: 11;
			color: $color-white;
			font-size: 1em;
			line-height: 1.5rem;
			transition: color 0.4s;
		}

		.disabled {
			text-decoration: none;
		}

		a {
			&:hover {
				span {
					color: $color-gray-17;
				}
			}
		}
	}

	.image-holder {
		position: relative;
		display: block;

		&:hover {
			.overlay {
				transition: 0.4s opacity;
				opacity: 20%;

				span {
					display: none;
				}
			}
		}
	}

	.item {
		position: relative;
		overflow: visible;

		> p {
			padding: 20px 0;

			a {
				color: $color-base;
				text-decoration: underline;
				transition: color 0.2s;

				&:hover {
					color: $color-gray-1;
				}
			}
		}

		.inner {
			box-sizing: border-box;
		}

		img {
			width: 100%;
		}
	}

	.copy {
		&:first-child {
			padding-top: 96px;
		}
	}

	.blockquote {
		padding-top: 87px;

		p {
			font-size: 3.125em;
			line-height: 4.375rem;
			font-weight: 700;
			text-align: left;
			margin: 0 auto 100px;
			width: 87.43%;
			max-width: 1680px;

			a {
				color: $color-base;
				text-decoration: underline;
			}
		}
	}

	.item.left {
		width: 50%;
	}

	.item.right {
		width: 50%;
	}

	.item.center {
		width: 87.43%;
		max-width: 1680px;
		margin: 0 auto;
	}

	.item.center.info-box {
		width: 100%;
		max-width: 100%;
	}

	.left {
		.inner {
			padding: 3vw 3vw 3vw 10vw;
			margin: 0;
			width: 100%;
		}

		.product-slideshow {
			left: 0;
		}
	}

	.right {
		.inner {
			padding: 3vw 10vw 3vw 3vw;
			margin: 0;
			width: 100%;
		}

		.product-slideshow {
			right: 0;
		}
	}

	.small {
		.left {
			.image-holder {
				width: 64.29%;
			}
		}

		.right {
			.image-holder {
				width: 64.29%;
			}
		}
	}

	.triple {
		position: relative;

		.container {
			margin: 0 5vw;
			width: 90vw;
			padding: 0;
			max-width: none;
			display: flex;
			justify-content: center;
			column-gap: 3vw;
		}

		.item {
			overflow: visible;
			position: static;
		}

		.inner {
			padding: 3vw 0;
		}

		.product-slideshow {
			margin: 0;
			left: 0;
		}
	}

	.info {
		position: absolute;
		width: 30px;
		height: 30px;
		text-align: center;

		a {
			display: block;
			overflow: hidden;
			border-radius: 50%;
			width: 30px;
			height: 23px;
			background: $color-gray-17;
			transition: all 0.4s;
			padding: 7px 0 0 0;

			svg {
				circle {
					fill: $color-white;
				}

				path {
					fill: $color-white;
				}
			}

			&:hover {
				background-color: $color-gray-1;
			}
		}

		span {
			display: none;
		}

		.info-window {
			position: absolute;
			text-align: left;
			background-color: rgba(188, 188, 188, 0.9);
			border-radius: 4px;
			padding: 10px 0;
			width: 272px;
			bottom: 11px;
			left: 50%;
			transform: translate(-50%, -50%);
			opacity: 0;
			z-index: -1;
			transition: opacity 0.4s, z-index 0.4s;
		}

		&:hover {
			.info-window {
				opacity: 1;
				z-index: 100;
			}
		}
	}

	.info[data-position="left"] {
		.info-window {
			left: auto;
			bottom: auto;
			transform: translate(0, -50%);
			right: calc(100% + 15px);
			top: 50%;
		}
	}

	.info[data-position="right"] {
		.info-window {
			bottom: auto;
			transform: translate(0, -50%);
			top: 50%;
			left: calc(100% + 15px);
		}
	}

	.info[data-position="down"] {
		.info-window {
			bottom: auto;
			top: calc(100% + 15px);
			transform: translate(-50%, 0);
		}
	}

	.info-window {
		&::after {
			content: "";
			background-color: rgba(188, 188, 188, 0.9);
			width: 2px;
			height: 15px;
			display: block;
			position: absolute;
			bottom: -15px;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	[data-position="right"] {
		.info-window {
			&::after {
				width: 20px;
				height: 2px;
				transform: translate(0, 0);
				top: 50%;
				left: -15px;
			}
		}
	}

	[data-position="left"] {
		.info-window {
			&::after {
				width: 15px;
				height: 2px;
				transform: translate(0, 0);
				bottom: auto;
				left: auto;
				right: -15px;
				top: 50%;
			}
		}
	}

	[data-position="down"] {
		.info-window {
			&::after {
				top: -15px;
				bottom: auto;
			}
		}
	}

	.info-inner {
		p {
			font-size: 0.875em;
			line-height: 1.5rem;
			color: $color-white;
			margin: 0;
		}
	}

	.product-slideshow {
		position: absolute;
		z-index: 1;
		bottom: 0;
		background-color: $color-white;
		text-align: center;
		overflow: auto;
		overflow-x: hidden;
		opacity: 0;
		pointer-events: none;
		width: 100vw;

		.close {
			position: absolute;
			color: $color-black;
			right: 5px;
			top: 5px;
			z-index: 10;
			transition: color 0.4s;
			padding: 15px;

			&::after {
				font-family: $font-secondary;
				content: "\e605";
				font-size: 1.25em;
			}

			span {
				display: none;
			}
		}

		.content {
			max-width: 710px;
			position: relative;
			margin: 60px auto;

			&::after {
				clear: both;
				content: "";
				display: block;
			}
		}

		.product-indicator {
			position: relative;
			z-index: 11;
			text-align: center;
			font-size: 0.875em;
			line-height: 1rem;
			padding: 0 0 10px;
		}

		ul {
			list-style: none;
		}

		li {
			position: absolute;
			top: 40%;
			transform: translateY(-50%);
			z-index: 2;
			padding: 15px;
		}

		.prev {
			left: -15px;

			a {
				&::after {
					content: "\e609";
				}
			}
		}

		.next {
			right: -15px;
		}

		.nav {
			span {
				display: none;
			}

			a {
				display: block;

				&::after {
					color: $color-base;
					font-family: $font-secondary;
					content: "\e60a";
					font-size: 1.25rem;
					transition: 0.4s all;
					display: block;
					width: 100%;
				}

				&:hover {
					&::after {
						font-size: 1.45rem;
					}
				}
			}
		}

		.product-holder {
			.image {
				.image-holder {
					z-index: 9;
					width: 23vw;
					max-width: 335px;
					margin: 30px auto;
					float: none;
				}
			}

			.image-holder {
				img {
					height: 100%;
				}
			}
		}

		form {
			position: relative;
			z-index: 13;
		}

		.product-submit {
			border-color: $color-blue-1;
			background-color: $color-blue-1;
			color: $color-white;
			cursor: pointer;
			margin: 0 0 12px;
			width: 100%;
			transition: opacity 0.2s;
			padding: 17px;
		}

		.submit-disabled {
			opacity: 0.5;
			cursor: default;
			border-color: $color-gray-10;
			background-color: $color-gray-10;
			color: $color-gray-7;
			pointer-events: none;
		}

		.cta {
			display: block;
			font-size: 0.875em;
			color: $color-gray-1;
			text-decoration: underline;
			padding: 20px 0 0;
			transition: 0.4s color;

			&:hover {
				color: $color-base;
			}
		}
	}

	.product-slideshow.visible {
		pointer-events: unset;
		display: block;
	}

	.product-holder {
		position: relative;
	}

	.disabled {
		visibility: visible;
		opacity: 0.5;
		cursor: default;
		text-decoration: none;
	}

	.product {
		width: 100%;
		position: relative;
		top: 0;

		.title {
			position: relative;
			z-index: 10;
			font-size: 1.125em;
			line-height: 21px;
			font-weight: 700;
			color: $color-base;

			span {
				color: $color-gray-13;
			}

			span.colourDesc {
				color: $color-gray-5;
			}

			.strike-through {
				text-decoration: line-through;
			}

			.sale {
				color: $color-red-1;
			}
		}

		& + .product {
			position: absolute;
		}
	}

	form {
		width: 350px;
		margin: 0 auto;
		padding: 0 20px;
	}

	select {
		display: block;
		border-width: 1px;
		border-style: solid;
		border-color: $color-base;
		color: $color-base;
		border-radius: 4px;
		padding: 20px 20px 21px 20px;
		margin: 0 0 20px;
		outline: 0;
		box-sizing: border-box;
		-ms-appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		transition: color 0.4s, background-colour 0.4s;
		font-size: 1em;
		width: 100%;
		background: transparent url("../images/global/dropdown-arrow-light.svg") no-repeat 95% 50%;

		&:focus {
			background-image: url("../images/global/dropdown-arrow-light-up.svg");
			outline: none;
			color: $color-base;
		}

		&:-webkit-autofill {
			-webkit-box-shadow: 0 0 0 1000px white inset;
		}
	}

	button {
		display: block;
		border-width: 1px;
		border-style: solid;
		border-color: $color-base;
		color: $color-base;
		border-radius: 4px;
		padding: 20px 20px 21px 20px;
		margin: 0 0 20px;
		outline: 0;
		box-sizing: border-box;
		-ms-appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		transition: color 0.4s, background-colour 0.4s;
		font-size: 1em;
	}

	select[disabled] {
		border: 0;
	}

	option {
		font-size: 1em;
		line-height: 1.5rem;
	}

	option[disabled] {
		text-decoration: line-through;
	}

	.clone {
		display: none;
	}
}

.touch {
	.lookbook-page {
		h1 {
			padding: 61px 0 0;
		}
	}

	.lookbook-size-select {
		.standard-dropdown {
			> .active {
				border: 1px solid $color-base;
				border-radius: 4px;
				color: $color-base;
				background-image: url(../images/global/dropdown-arrow-light.svg);
			}
		}
	}
}

.container {
	.item {
		.inner {
			picture {
				img {
					max-width: 95vw;
					margin: auto;
				}
			}
		}
	}
}

.inner {
	.overlay {
		ul {
			list-style-type: none;
			list-style: none;
		}
	}
}

select {
	&::-ms-expand {
		display: none;
	}
}

.lookbook-size-select {
	.standard-dropdown {
		font-size: 1em;
		margin-bottom: 15px;

		> .current {
			line-height: normal;
			border: 1px solid $color-base;
			border-radius: 4px;
			transition: none;
			background: $color-white;
			padding: 17px;
			font-weight: bold;
		}

		> .current.active {
			line-height: normal;
			border: 1px solid $color-base;
			border-radius: 4px;
			transition: none;
			background: $color-white;
			padding: 17px;
			font-weight: bold;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			border-bottom-color: transparent;
		}

		> .active {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			border-bottom-color: transparent;
		}

		> select {
			& ~ .active {
				border: 1px solid $color-base;
				border-radius: 4px;
			}

			& ~ .current.active {
				border: 1px solid $color-base;
				border-radius: 4px;
			}
		}

		.content {
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			margin: 0;
			border-width: 0 1px 1px 1px;
			border-style: solid;
			border-color: $color-base;
			box-shadow: none;
			visibility: hidden;
		}

		li {
			position: static;
			transform: none;
			border-width: 1px 0 0 0;
			border-style: solid;
			border-color: $color-gray-17;
			transition: border-color 0.2s linear;

			&:first-child {
				display: none;
			}

			&:nth-child(2) {
				border-top: 0;
			}

			a {
				padding: 8px;
				display: block;
				background: $color-white;
				color: $color-base;

				&:hover {
					margin: 0;
					padding: 8px;
					color: $color-gray-1;
					background: $color-white;
					border-color: $color-gray-1;
				}
			}
		}

		.scrollable {
			position: relative;
			border-top: 1px solid $color-gray-17;
			transition: border-color 0.2s linear;

			&:hover {
				border-top-color: $color-base;

				li {
					border-color: $color-gray-2;
				}
			}
		}

		.scrollbar {
			right: 0;
			top: 1px;
			bottom: 0;
			visibility: visible;
		}

		li.na {
			a {
				opacity: 0.3;
				text-decoration: line-through;
				cursor: default;
			}
		}

		.active {
			color: $color-gray-1;
		}
	}

	&.select-box {
		position: relative;
		padding: 0;
		z-index: 101;
	}
}

@media (max-width: 1240px) {
	.touch {
		.collab-page {
			header {
				margin: 0 0 200px;

				.image-holder {
					padding: 70px 0 0;
				}
			}

			.image-holder {
				.overlay {
					position: static;
					background-color: transparent;
					opacity: 1;
				}
			}

			.overlay {
				ul {
					position: static;
					transform: translateY(0);
					padding: 20px 0;
				}

				li {
					text-align: left;
				}

				a {
					text-decoration: underline;
				}

				.disabled {
					text-decoration: none;
				}
			}

			.lookbook {
				.tooltip {
					display: inline-block;
				}

				.disabled {
					opacity: 1;
					text-decoration: none;
				}
			}
		}

		.lookbook-page {
			.image-holder {
				.overlay {
					position: static;
					background-color: transparent;
					opacity: 1;
				}
			}

			.overlay {
				ul {
					position: static;
					transform: translateY(0);
					padding: 20px 0;
				}

				li {
					text-align: left;
				}

				a {
					text-decoration: underline;
				}

				.disabled {
					text-decoration: none;
				}
			}

			.lookbook {
				.tooltip {
					display: inline-block;
				}

				.disabled {
					opacity: 1;
					text-decoration: none;
				}
			}
		}
	}
}

@media (max-width: $tablet) {
	.lookbook-page {
		header {
			margin: 54px 0 0;
			padding: 82px 0;

			h1 {
				font-size: 1.5em;
				line-height: 28px;
				color: $color-gray-5;
				padding: 0;
				margin: 0;
			}
		}

		h1 {
			font-size: 1.625em;
			line-height: 2.375rem;
			padding: 96px 0 0;
		}

		.item {
			position: static;

			.inner {
				margin: 0;
				padding: 10px 20px;
				position: relative;
			}
		}

		.left {
			.left {
				.image-holder {
					width: 100%;
					float: none;
				}
			}

			.right {
				.image-holder {
					width: 100%;
					float: none;
				}
			}
		}

		.right {
			.left {
				.image-holder {
					width: 100%;
					float: none;
				}
			}

			.right {
				.image-holder {
					width: 100%;
					float: none;
				}
			}
		}

		.triple {
			.container {
				width: 100%;
				position: static;
				margin: 0;
				padding: 0 40px;
			}

			.item {
				margin: 0;
			}
		}

		.product-slideshow {
			display: none;
			width: auto;
			height: auto;
			padding: 0 20px env(safe-area-inset-bottom);
			position: fixed;
			top: 54px;
			left: 0;
			right: 0;
			z-index: 90;
			background-color: $color-white;
			text-align: center;
			overflow: auto;
			overflow-x: hidden;
			touch-action: pan-y;

			.close {
				&::after {
					font-size: 1.15em;
				}
			}

			.product-holder {
				.image {
					padding: 0 60px;
					margin: 0 -30px 30px;

					.image-holder {
						width: 100%;
						margin: 0;
						float: none;
						max-width: none;
					}
				}
				z-index: 9;

				.image-holder {
					img {
						height: auto;
					}
				}
			}

			a.cta {
				padding: 0;
			}

			.nav {
				z-index: 10;

				li {
					z-index: 10;
				}

				a {
					&:hover {
						&::after {
							font-size: 1.25rem;
						}
					}
				}
			}
		}

		.pips {
			display: none;
		}

		.product {
			form {
				padding: 0;
			}
		}

		.lookbook {
			p.mouse-tooltip {
				width: auto;
			}
		}

		select {
			margin-bottom: 16px;
		}

		button {
			margin-bottom: 16px;
		}
	}

	.standard-article {
		p {
			width: 80%;
			margin: auto;
		}
	}

	.triple {
		margin: 0;
		width: 100%;

		.container {
			display: flex;
			flex-direction: column;
			align-content: flex-start;
			justify-content: center;
			position: relative;
			width: 100%;

			.item {
				width: 100%;
				float: none;
				position: relative;
				margin: 0 0 60px;
			}
		}
	}

	.touch {
		.lookbook-page {
			h1 {
				font-size: 1.625em;
				line-height: 2.375rem;
				padding: 96px 0 0;
			}

			header {
				margin: 0 0 40px;
				padding: 0;
			}

			.share {
				padding: 0;
			}

			.image-holder {
				.overlay {
					position: static;
					background-color: transparent;
					opacity: 1;
				}
			}

			.overlay {
				ul {
					position: static;
					transform: translateY(0);
					padding: 20px;
				}

				li {
					text-align: left;
				}

				span {
					text-decoration: underline;
				}

				.disabled {
					text-decoration: none;
				}
			}

			.lookbook {
				.mouse-tooltip {
					display: inline-block;
				}

				.disabled {
					opacity: 1;
				}
			}
		}

		.collab-page {
			header {
				margin: 0;
				padding: 0;

				.image-holder {
					padding: 0;
				}
			}

			.image-holder {
				.overlay {
					position: static;
					background-color: transparent;
					opacity: 1;
				}
			}

			.overlay {
				ul {
					position: static;
					transform: translateY(0);
					padding: 20px;
				}

				li {
					text-align: left;
				}

				span {
					text-decoration: underline;
				}

				.disabled {
					text-decoration: none;
				}
			}

			.lookbook {
				.mouse-tooltip {
					display: inline-block;
				}

				.disabled {
					opacity: 1;
				}
			}
		}
	}

	.collab-page {
		.breadcrumb {
			display: none;
		}

		h1 {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: calc(50% - 55px);
			font-size: 1.625em;
			line-height: 2.375rem;
			padding: 96px 0 0;
		}

		.intro {
			position: static;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			.holder {
				position: static;
				transform: none;
			}
		}

		picture {
			display: block;
		}

		header {
			.intro.left {
				width: 100%;
			}

			.intro.right {
				width: 100%;
			}
			margin: 0;
			padding: 0;

			.content-holder {
				min-height: 0;
				max-height: none;
				position: static;
			}

			.placeholder {
				h1 {
					img {
						position: relative;
						top: 50%;
						left: 0;
						transform: translateY(-50%);
					}
				}

				img {
					min-width: 0;
				}
				min-height: 0;
				max-height: none;
			}

			.intro {
				.inner {
					padding: 20px 0;
					margin: 0;
					width: 100%;
					float: none;
				}

				p {
					color: inherit;

					&:last-child {
						margin: 0 auto;
					}
				}
			}

			.item {
				margin: 0 auto 60px;
			}
		}

		.intro-content {
			position: static;
			top: 50%;
			transform: none;
		}

		.paragraph-wrapper {
			padding: 60px 0;
		}

		.item.center {
			width: 100%;
		}

		.blockquote {
			p {
				font-size: 1.875em;
				line-height: 2.625rem;
				width: 100%;
			}
			padding-top: 60px;
		}

		.lookbook {
			p {
				padding: 0 20px;
				width: 100%;
				max-width: 100%;
				margin: 0 auto 60px;
			}

			.copy {
				padding: 0 20px;
				width: 100%;
				max-width: 100%;
				margin: 0 auto 60px;
			}

			.left {
				.copy {
					float: none;
				}
			}

			.right {
				.copy {
					float: none;
				}
			}

			p.mouse-tooltip {
				width: auto;
			}
		}

		.info {
			display: none;
		}

		.item {
			position: static;
		}

		.copy {
			&:first-child {
				padding-top: 60px;
			}
		}

		.left {
			.left {
				.image-holder {
					width: 100%;
					float: none;
				}
			}

			.right {
				.image-holder {
					width: 100%;
					float: none;
				}
			}
		}

		.right {
			.left {
				.image-holder {
					width: 100%;
					float: none;
				}
			}

			.right {
				.image-holder {
					width: 100%;
					float: none;
				}
			}
		}

		.triple {
			.container {
				width: 100%;
				position: static;
				margin: 0;
				padding: 0 40px;
			}
		}

		.product-slideshow {
			display: none;
			width: auto;
			height: auto;
			padding: 0 20px env(safe-area-inset-bottom);
			position: fixed;
			top: 54px;
			left: 0;
			right: 0;
			z-index: 90;
			background-color: $color-white;
			text-align: center;
			overflow: auto;
			overflow-x: hidden;
			touch-action: pan-y;

			.close {
				&::after {
					font-size: 1.15em;
				}
			}

			.product-holder {
				.image {
					padding: 0 60px;
					margin: 0 -30px 30px;

					.image-holder {
						width: 100%;
						margin: 0;
						float: none;
						max-width: none;
					}
				}
				z-index: 9;
			}

			a.cta {
				padding: 0;
			}

			.nav {
				z-index: 10;

				li {
					z-index: 10;
				}

				a {
					&:hover {
						&::after {
							font-size: 1.25rem;
						}
					}
				}
			}
		}

		.pips {
			display: none;
		}

		.product {
			form {
				padding: 0;
			}
		}

		.standard-video-player {
			.video-player {
				.close {
					font-size: 20px;
				}
			}
		}

		select {
			margin-bottom: 16px;
		}

		button {
			margin-bottom: 16px;
		}
	}

	.breadcrumb {
		ul {
			display: none;
		}
	}

	.slideshow-visible {
		overflow: hidden;
	}
}

@media (max-width: $mobile) {
	.collab-page {
		h1 {
			height: calc(100vw - 55px);
		}

		.intro.right {
			float: none;
			width: 100%;
		}

		.intro.left {
			float: none;
			width: 100%;
		}

		.item.left {
			float: none;
			width: 100%;

			.inner {
				margin: 0;
				width: 100%;
				float: none;
			}
		}

		.item.right {
			float: none;
			width: 100%;

			.inner {
				margin: 0;
				width: 100%;
				float: none;
			}
		}

		.left {
			.product-slideshow {
				right: 0;
			}
		}

		.right {
			.product-slideshow {
				left: 0;
			}
		}

		.product {
			form {
				width: 100%;
				padding: 0;
			}

			.title {
				font-size: 18px;
				line-height: 21.04px;
				display: flex;
				flex-direction: column;
				align-items: center;

				.colourDesc {
					color: $color-gray-5;
					white-space: pre;
				}
			}
		}

		.product-slideshow {
			.product-indicator {
				font-size: 14px;
				line-height: 16px;
			}
		}

		.triple {
			.item {
				width: 100%;
				float: none;
				position: relative;
			}

			.inner {
				margin: 0;
				width: 100%;
			}
		}
	}

	.lookbook-page {
		.item.left {
			float: none;
			width: 100%;

			.inner {
				margin: 0;
				width: 100%;
				float: none;
			}
		}

		.item.right {
			float: none;
			width: 100%;

			.inner {
				margin: 0;
				width: 100%;
				float: none;
			}
		}

		.left {
			.product-slideshow {
				right: 0;
			}
		}

		.right {
			.product-slideshow {
				left: 0;
			}
		}

		.product {
			form {
				width: 100%;
				padding: 0;
			}

			.title {
				font-size: 18px;
				line-height: 21.04px;
				display: flex;
				flex-direction: column;
				align-items: center;

				.colourDesc {
					color: $color-gray-5;
					white-space: pre;
				}
			}
		}

		.product-slideshow {
			.product-indicator {
				font-size: 14px;
				line-height: 16px;
			}
		}

		.triple {
			.item {
				width: 100%;
				float: none;
				position: relative;
			}

			.inner {
				margin: 0;
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: $desktop) {
	.lookbook-page {
		.lookbook {
			.mouse-tooltip {
				display: none;
			}
		}

		button.open-slideshow-cta {
			display: block;
			position: absolute;
			bottom: 10px;
			right: 10px;
			background-color: $color-white;
			color: $color-gray-5;
			font-size: 0.75em;
			line-height: 14px;
			font-weight: 700;
			margin: 0;
			padding: 6px 12px;
			border: none;
			border-radius: 3px;
			opacity: 0;
			cursor: pointer;
		}

		button.open-slideshow-cta.-visible {
			opacity: 1;
		}
	}
}

@media screen and (min-width: calc($desktop + 1px)) {
	.lookbook-page {
		.product-slideshow {
			li {
				top: 50%;
			}

			.content {
				margin: 0 auto 60px auto;
			}

			.product-indicator {
				padding: 0 0 10px 0;
				top: 60px;
			}

			.product-holder {
				top: 60px;

				.image {
					.image-holder {
						margin: 0 auto;
					}
				}

				.image-holder {
					img {
						height: auto;
					}
				}
			}

			.cta {
				padding: 20px 0 60px 0;
			}
		}
	}
}
