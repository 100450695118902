/*---------------------------------------------*/
/* Shared Styles
/*---------------------------------------------*/
.my-account {
	.filters {
		text-align: center;
		border-bottom: 0;
		position: static;
	}

	.filters.sticky {
		top: 60px;
		left: 0;
		width: 100%;
		z-index: 98;
		position: fixed;
		border-bottom: 2px solid $color-gray-14;
	}

	.categories {
		display: block;
		float: none;
		width: auto;
	}

	.standard-heading {
		width: 100%;
		max-width: 1680px;
		margin: 0 auto;
		padding: 36px 6.33% 47px 6.33%;
		text-align: center;
		font-weight: 700;
		box-sizing: border-box;

		span {
			color: $color-gray-1;
		}
	}

	.secondary-logout-button {
		position: absolute;
		top: 40px;
		right: 6.33%;
		min-width: 120px;
		color: $color-white;
	}

	.fields {
		display: grid;
		grid-template-columns: 1fr;
		@media (min-width: calc($mobile-lg)) {
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 20px;
		}
	}

	.solo-column {
		width: 350px;
	}

	.breadcrumb {
		li {
			&:not(:first-child) {
				margin-left: 2px;
			}
		}
	}

	.messageBox {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 0;
		position: fixed;
		top: 59px;
		left: 0;
		right: 0;
		background-color: $color-blue-1;
		color: $color-white;
		text-align: center;
		z-index: 100;
		overflow: hidden;
	}

	.messageBox.success {
		background-color: $color-green-1;
	}

	.messageBox.error {
		background-color: $color-red-1;
	}

	&.show-message {
		.messageBox {
			animation: 2s show-message;
		}
	}

	.change-password {
		margin-top: 32px;
		clear: both;

		&__controls,
		&__content-fieldset {
			display: flex;
			justify-content: space-between;
		}

		&__controls {
			margin-bottom: 24px;
		}

		&__content {
			max-height: 0;
			opacity: 0;
			transition-property: max-height, opacity;
			transition-duration: 0.2s;
			transition-timing-function: ease-out;
			overflow: hidden;
			padding-bottom: 50px;

			.error-message {
				padding-bottom: 0;
				p {
					padding-bottom: 0;
				}
			}
		}

		&__content-fieldset {
			flex-direction: column;

			@media (min-width: calc($mobile-lg)) {
				gap: 20px;
				flex-direction: row;
			}

			.field {
				width: 100%;
				@media (min-width: calc($mobile-lg)) {
					width: 50%;
				}

				&__description {
					position: absolute;
					top: 66px;
					left: 21px;
					font-size: 0.75rem;
					color: $color-gray-5;

					&.is-hidden {
						display: none;
					}
				}
			}
		}

		&.is-expanded {
			.change-password__content {
				max-height: 200px;
				opacity: 1;
			}
		}
	}

	.button-as-link {
		color: $color-gray-5;
		transition: color 0.1s linear;

		&:hover {
			color: $color-base;
		}

		&.is-hidden {
			display: none;
		}
	}
}

@keyframes show-message {
	0% {
		height: 0;
	}

	10% {
		height: $header-height;
	}

	90% {
		height: $header-height;
	}

	100% {
		height: 0;
	}
}

.account-header {
	position: relative;
}

.standard-button {
	display: inline-block;
	padding: 11px 16px 12px 16px;
	margin: 0 0 20px;
	color: $color-white;
	font-size: 0.875rem;
	border-radius: 4px;
	background-color: $color-blue-1;
	font-weight: 700;
	outline: 0;
	text-align: center;
	transition: background-color 0.1s linear;
	box-sizing: border-box;

	&:focus {
		background-color: $color-blue-2;
	}

	&:hover {
		background-color: $color-blue-2;
	}
}

/*---------------------------------------------*/
/* About You
/*---------------------------------------------*/
.account-section {
	margin: 20px 0 0 0;
	padding: 60px 0 58px 0;
	background: $color-gray-16;

	.standard-button {
		min-width: 110px;
	}

	&::after {
		content: "";
		display: block;
		clear: both;
	}

	.section-content {
		width: 100%;
		max-width: 1680px;
		margin: 0 auto;
		padding: 0 6.33%;
		box-sizing: border-box;
	}

	.standard-heading {
		width: auto;
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 8px;
	}

	.sub-heading {
		font-size: 0.875em;
	}

	.form-sub-heading {
		margin-bottom: 0.875rem;
	}

	.standard-radio {
		width: 200px;
		float: left;
	}

	dt {
		display: inline;
		font-size: 0.875rem;
	}

	dd {
		display: inline;
		font-size: 0.875rem;
	}

	form {
		font-size: 0.875em;
	}

	#newsletterPreferencesForm {
		max-width: 768px;

		.submit {
			max-width: 156px;
			margin-top: 90px;
			margin-bottom: 30px;
		}

		.standard-select {
			select {
				background-image: url(../images/global/chevron-down.svg);
				background-position: center right 1rem;
				background-repeat: no-repeat;
				padding: 20px 35px 21px 20px;
			}
		}
	}

	.info-label {
		padding: 17px 0 14px 0;
	}

	.standard-checkbox {
		clear: left;
	}

	.standard-checkbox.preferences {
		min-width: 192px;
		label {
			line-height: 1.813rem;
		}
	}

	.unsubscribe-link {
		text-decoration: underline;
	}

	.email-disabled {
		border: 1px solid $color-gray-7 !important; /* stylelint-disable-line declaration-no-important */
		color: $color-gray-7;
	}

	.newsletter-disclaimer {
		margin-top: 90px;
	}

	.preferences {
		.preferences-checkboxes {
			display: flex;
			flex-wrap: wrap;
		}

		.submit {
			margin-top: 20px;
		}
	}

	.dob-fields {
		input {
			float: left;
			width: 30%;
			padding-left: 0;
			padding-right: 0;
			text-align: center;
			box-sizing: border-box;

			&:nth-child(2) {
				margin: 0 5%;
			}
		}
	}

	.error {
		input {
			margin-bottom: 7px;
		}
	}

	.submit-holder {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		.submit {
			max-width: 350px;
		}
	}

	&.about-you {
		form {
			max-width: 720px;
			padding: 0;
			margin: 0 auto;
		}
	}

	.error-message {
		display: none;
		padding: 0 6px 5px;
		p {
			text-align: left;
			font-size: 0.75rem;
		}
	}
}

.account-section.preferences {
	background: $color-white;
	margin-top: 0;
	@media only screen and (max-width: $tablet) {
		padding-top: 0;
	}
}

.preferences-heading-wrapper {
	height: 210px;
	padding-top: 60px;
	background: $color-gray-16;

	.preferences-heading {
		height: 210px;
		display: flex;
		align-items: center;
		justify-content: center;
		.preferences-heading-title {
			height: 50px;
		}
	}
}

.filters {
	.categories {
		.standard-button {
			line-height: normal;
			text-transform: none;
			font-weight: 400;
		}
	}
}

.touch {
	.standard-button {
		padding: 21px 26px 21px 26px;
	}
}

@media only screen and (max-width: 950px) {
	.account-section .standard-button {
		min-width: 0;
	}
}
@media screen and (min-width: calc($desktop + 1px)) {
	.account-section {
		.email-disabled {
			margin-bottom: 100px;
		}
	}
}

@media only screen and (max-width: $tablet) {
	.my-account {
		padding-top: 60px;

		.standard-heading {
			padding: 36px 20px;

			span {
				display: inline-block;
				white-space: pre-wrap;
			}
		}

		.filters {
			top: 60px;
		}

		.breadcrumb {
			display: none;
		}
	}
}

/* Return the log out button back to normal nav style */
@media only screen and (max-width: $mobile-lg) {
	.my-account {
		.filters {
			position: static;

			.logout {
				display: inline-block;
			}
		}

		.secondary-logout-button {
			display: none;
		}

		.logout {
			.standard-button {
				position: static;
				margin: 0;
				padding: 0;
				color: $color-base;
				line-height: 1em;
				font-size: 0.75rem;
				font-weight: 700;
				text-transform: uppercase;
				background-color: transparent;

				&:hover {
					color: $color-gray-1;
				}
			}
		}
	}

	.account-section {
		.standard-heading {
			padding-bottom: 17px;
		}
	}

	.account-header {
		.filters {
			display: table-footer-group;
		}

		.categories {
			padding-left: 40px;
			padding-right: 40px;
		}

		.page-header {
			display: table-header-group;
		}
	}
}

@media only screen and (max-width: $mobile) {
	.my-account .solo-column {
		width: 100%;
	}
}

@media only screen and (max-width: $mobile-lg) {
	.account-section {
		.section-content {
			padding: 0 20px;
		}

		.submit-holder {
			input {
				max-width: none;
			}
		}

		&.about-you {
			form {
				max-width: none;
			}
		}
	}

	.my-account .messageBox {
		top: 54px;
	}
}

@media only screen and (max-width: $mobile-lg) {
	.account-section {
		.standard-heading {
			padding: 17px 0 8px 0;
		}

		.section-content {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}

/*---------------------------------------------*/
/* Addresses
/*---------------------------------------------*/
.account-addresses {
	.column {
		width: 30.33%;
		margin: 0 1.5%;
		box-sizing: border-box;
	}
	list-style: none;

	li {
		float: left;
		padding: 0 0 38px 0;
	}

	span {
		display: block;
	}

	.title {
		display: inline;
	}

	.firstname {
		display: inline;
	}

	.surname {
		display: inline;
	}

	.streetnumber {
		display: inline-block;
	}

	.address1 {
		display: inline-block;
	}

	dl {
		padding-bottom: 15px;
	}

	dt {
		display: none;
	}

	dd {
		display: block;
		font-weight: 400;
		line-height: 1.5em;
	}
}

.address-book {
	position: relative;

	.options {
		list-style: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: background-color 0.4s;

		a {
			color: $color-white;
		}

		.edit-button {
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.delete-button {
			position: absolute;
			top: 30px;
			right: 30px;
			color: $color-base;
			font-size: 0.875rem;
			transition: color 0.4s;
			z-index: 1;

			span {
				display: none;
			}

			&::after {
				font-family: $font-secondary;
				content: "\e605";
			}
		}

		li {
			padding-bottom: 0;
			box-sizing: border-box;
		}

		.standard-button {
			margin-bottom: 0;
		}
	}

	.address {
		&:hover {
			.options {
				background-color: rgba(36, 36, 36, 0.9);
				opacity: 1;

				.delete-button {
					color: $color-white;
				}
			}
		}

		dl {
			position: relative;
			box-sizing: border-box;
			display: block;
			border-width: 1px;
			height: 245px;
			border-style: solid;
			border-color: $color-gray-1;
			text-decoration: none;
			margin: 0 0 30px;
			background: $color-white;
			transition: all 0.2s;
			padding: 30px;
			line-height: 1.25rem;
		}

		dl.selected {
			border-color: $color-base;
			background-color: $color-white;
			color: $color-base;
		}
	}

	.new-button {
		box-sizing: border-box;
		display: block;
		border-width: 1px;
		height: 245px;
		border-style: solid;
		border-color: $color-gray-1;
		text-decoration: none;
		margin: 0 0 30px;
		text-align: center;
		transition: all 0.2s ease;

		a {
			color: $color-base;
			height: 100%;
			display: block;
			transition: background-color 0.3s;
			font-size: 1rem;
			line-height: 1.375rem;
			text-decoration: none;

			> span {
				display: block;
				position: relative;
				top: 50%;
				transform: translateY(-50%);

				&::before {
					font-family: $font-secondary;
					content: "\e61b";
					display: block;
				}
			}

			&:hover {
				background-color: $color-white;
			}

			span {
				span {
					display: block;
				}
			}
		}
	}

	.phone {
		padding-top: 20px;
	}

	.address.selected {
		a {
			color: $color-base;
		}
	}
}

.address-page {
	.address {
		.phone {
			padding-top: 14px;
		}
	}
}

/* 2 columns */
@media only screen and (max-width: 1080px) {
	.account-addresses {
		.column {
			width: 46.5%;
		}

		.options {
			li {
				width: 50%;
			}

			.standard-button {
				display: block;
			}
		}
	}
}

@media only screen and (max-width: $tablet) {
	.touch {
		.account-addresses {
			.options {
				opacity: 1;
				position: static;
				top: 100%;
				bottom: auto;
				background: transparent;
			}
		}

		.address-book {
			.options {
				li {
					width: 100%;
				}

				a {
					color: $color-base;
				}

				.edit-button {
					position: static;
					transform: translate(0, 0);
					padding: 11px 16px 12px;
					margin: 67px -31px 20px -31px;
					font-size: 0.875em;
					border-radius: 4px;
					background-color: $color-blue-1;
					color: $color-white;
					font-weight: 700;
					text-align: center;
				}
			}

			.address {
				&:hover {
					.options {
						.delete-button {
							color: $color-base;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: $mobile-lg) {
	.account-addresses {
		.column {
			width: 100%;
			margin: 0;
			padding-top: 17px;
			padding-bottom: 20px;
			padding-right: 0;
			padding-left: 0;
			border-width: 1px 0 0;
			border-style: solid;
			border-color: $color-gray-17;
			margin-bottom: 0;

			&:nth-child(1) {
				padding-top: 0;
				border-width: 0;
			}
		}

		ul {
			width: 100%;
		}

		li {
			float: left;
			width: 50%;
			box-sizing: border-box;
		}

		.options {
			li {
				&:nth-child(2n + 2) {
					padding-left: 10px;
					padding-right: 0;
				}
			}
		}

		dd {
			line-height: 1.625em;
		}
	}

	.address-book {
		padding-bottom: 40px;

		.new-button {
			position: static;
			display: block;
			margin-bottom: auto;
			text-align: center;
		}

		.address {
			dl {
				margin: 0;
			}
		}

		.options {
			.standard-button {
				display: block;
				text-align: center;
			}

			.delete-button {
				top: 10px;
				right: 10px;
			}
		}
	}

	.touch {
		.account-addresses {
			.column {
				padding-bottom: 20px;
			}
		}

		.address-book {
			.options {
				.edit-button {
					margin: 60px -31px 0 -31px;
					padding: 20px;
				}
			}
		}
	}
}

/*---------------------------------------------*/
/* Add a new address
/*---------------------------------------------*/
.address-form {
	display: none;
	clear: left;
	height: 0;
	overflow: hidden;

	.solo-column {
		position: relative;
	}

	.close-button {
		position: absolute;
		display: block;
		top: 35px;
		right: 0;
		z-index: 1;
		width: 14px;
		height: 14px;

		span {
			display: none;
		}

		&::after {
			content: "\e605";
			font-family: $font-secondary;
			color: $color-gray-1;
			font-size: 14px;
			transition: color 0.1s linear;
		}

		&:hover {
			&::after {
				color: $color-base;
			}
		}
	}

	.section-content {
		padding: 0;
		border-top: 1px solid $color-gray-17;
	}

	.sub-heading {
		padding-top: 35px;
		padding-bottom: 15px;
		font-weight: 700;
		color: $color-base;
		text-transform: uppercase;
	}

	.standard-checkbox {
		padding-top: 11px;
	}

	.standard-form {
		input[disabled] {
			border-width: 1px;
			border-color: $color-base;
			border-style: solid;
		}
	}

	.street-name-street-number {
		display: flex;
		column-gap: 20px;
		width: 100%;
	}

	.postcode {
		position: relative;
		box-sizing: border-box;

		input {
			width: 37.14%;
		}
	}

	.error-message {
		width: 350px;
		box-sizing: border-box;
	}

	.county {
		float: left;
		width: 62.85714285714286%;
		padding-left: 10px;
		box-sizing: border-box;
	}
}

.street-name {
	flex: 2;
}

div.street-name {
	div.error-message {
		width: 210px;
	}
}

.street-number {
	flex: 1;
}

div.street-number {
	div.error-message {
		width: 100px;
	}
}

@media only screen and (max-width: $mobile-lg) {
	.address-form {
		.sub-heading {
			display: block;
		}

		.postcode {
			width: 100%;
			padding: 0;
		}

		.county {
			width: 100%;
			padding: 0;
		}

		.error-message {
			width: 100%;
		}
	}
}

/*---------------------------------------------*/
/* Order History
/*---------------------------------------------*/
/* Order status colours */
.order-list {
	width: 90.46875%;
	max-width: 1520px;
	margin: 0 auto;
	list-style: none;
	box-sizing: border-box;
	line-height: 1.5em;

	.extra {
		display: none;
	}

	&__toggle {
		display: block;
		width: 100%;
		padding: 18px 20px;
		color: $color-base;
		text-decoration: none;
		background-color: $color-white;
		border-radius: 4px;
		border: none;
		transition: background-color 0.1s linear;

		&::after {
			content: "";
			display: block;
			clear: both;
		}

		&:hover {
			background-color: $color-gray-9;
		}
	}

	li {
		position: relative;
		padding: 0 0 20px 0;
	}

	.info {
		float: left;
		width: 12.3488773747841%;
		font-size: 0.875em;
		text-align: right;
	}

	dl {
		float: left;
		width: 23.48877374784111%;
		padding: 0;
	}

	.date {
		width: 16.48877374784111%;

		dt {
			display: none;
		}
	}

	.order-number {
		width: 29.48877374784111%;
	}

	.order-status {
		width: 18.18480138169257%;

		dt {
			display: none;
		}
	}

	.progress {
		color: $color-blue-1;
	}

	.delivered {
		color: $color-green-4;
	}

	.error {
		margin-bottom: 0;
		color: $color-red-1;
	}

	dt {
		&::after {
			content: ":";
		}
	}

	dd {
		font-weight: 400;
	}
}

/* Switch to white blocks stacked on top of each other */
@media only screen and (max-width: $tablet) {
	.order-history {
		background-color: $color-gray-16;

		.sub-heading {
			padding-bottom: 28px;
			display: block;
		}
	}

	.order-list {
		&__toggle {
			padding: 13px 20px;
		}

		.total {
			float: right;
			width: auto;
		}

		.order-status {
			position: absolute;
			right: 20px;
			top: 38px;
			text-align: right;
		}

		.order-number {
			clear: both;
			float: left;
			width: auto;
			padding-bottom: 6px;
		}

		.info {
			width: 100%;
			padding: 18px 0 0 0;
			text-align: left;
		}
	}
}

/* Switch to white blocks stacked on top of each other, but with price on the left */
@media only screen and (max-width: $mobile-lg) {
	.order-history {
		.standard-heading {
			padding-bottom: 8px;
		}
	}

	.my-account {
		.solo-column {
			width: auto;
		}
	}

	.order-list {
		width: 100%;
		padding: 0 20px;

		.order-status {
			position: static;
			text-align: left;
			width: 100%;
		}

		.order-number {
			float: none;
			width: 100%;
		}

		a {
			padding: 16px 20px;
		}

		.total {
			float: left;
			width: 100%;
		}

		.info {
			width: 100%;
			font-size: 0.938em;
			line-height: 1.625em;
			text-align: left;
		}

		dl {
			width: 100%;
		}

		dt {
			font-size: 0.938em;
			line-height: 1.625em;
			text-align: left;
		}

		dd {
			font-size: 0.938em;
			line-height: 1.625em;
			text-align: left;
		}
	}
}

/*---------------------------------------------*/
/* Basic Accordion
/*---------------------------------------------*/
.accordion {
	list-style: none;

	.toggled-content {
		height: 0;
		overflow: hidden;
	}
}

/*---------------------------------------------*/
/* Order Details
/*---------------------------------------------*/
.full-order-details {
	.hidden-content {
		padding-top: 8px;
	}

	.section {
		margin: 0 20px;
		padding: 31px 0 34px 0;
		border-bottom: 1px solid $color-gray-17;

		&:last-child {
			padding-bottom: 70px;
			border-bottom: 0;
		}

		&::after {
			content: "";
			display: block;
			clear: left;
		}
	}

	.column {
		float: left;
		width: 25.22361359570662%;
		padding: 0 20px 0 0;
		box-sizing: border-box;
	}

	h5 {
		padding: 0 0 8px 0;
		font-size: 0.75em;
		font-weight: 400;
		text-transform: uppercase;
	}

	p {
		font-size: 0.875em;
	}

	dl {
		float: none;
		width: auto;
	}

	.address {
		dt {
			display: none;
		}

		dd {
			display: block;
			font-size: 0.875em;
			line-height: 1.438em;

			span.title {
				display: inline;
			}

			span.firstname {
				display: inline;
			}

			span.surname {
				display: inline;
			}

			span:not(.address1, .streetnumber) {
				display: block;
			}
		}

		.phone {
			padding-top: 20px;
		}
	}

	.order-contents {
		list-style: none;

		li {
			display: table;
			width: 100%;
			padding-top: 37px;
			padding-bottom: 30px;
			border-bottom: 1px solid $color-gray-17;

			&:first-child {
				padding-top: 0;
			}

			&:last-child {
				padding-bottom: 0;
				border: 0;
			}

			&::after {
				content: "";
				display: block;
				clear: both;
			}
		}

		h6 {
			margin-top: -9px;
			padding: 0 0 26px 0;
			font-size: 1.875em;
			line-height: 1.313em;
		}
	}

	.product-stats {
		float: left;
		display: inline-block;

		dt {
			display: none;
		}

		dd {
			display: inline;

			&::after {
				content: ", ";
			}

			&:last-child {
				&::after {
					content: "";
				}
			}
		}
	}

	.price {
		float: right;

		.strikeout {
			text-decoration: line-through;
		}

		.sale {
			color: $color-red-1;
		}
	}

	.prices {
		padding-bottom: 15px;
		text-align: right;

		dt {
			color: $color-base;
		}

		dd {
			color: $color-gray-1;
		}
	}

	.order-value {
		padding-top: 46px;
	}

	.grand-total {
		margin: 33px 0 0 0;
		padding: 32px 0;
		border-width: 1px 0;
		border-style: solid;
		border-color: $color-gray-5;

		dd {
			color: $color-base;
		}
	}

	.product-copy {
		display: table-cell;
		vertical-align: top;
	}

	.product-image {
		display: table-cell;
		vertical-align: top;
		width: 158px;
	}
}

@media only screen and (max-width: 1200px) {
	.full-order-details {
		.column {
			width: 40%;
		}

		.payment-details {
			.column {
				&:nth-child(4) {
					float: right;
					width: auto;
					padding-right: 0;
				}
			}
		}
	}
}

@media only screen and (max-width: $tablet) {
	.full-order-details {
		.column {
			width: 50%;
		}

		.payment-details {
			.column {
				&:nth-child(4) {
					float: left;
					width: 100%;
				}
			}
		}

		.order-contents {
			h6 {
				font-size: 1.5em;
			}
		}
	}
}

@media only screen and (max-width: $tablet) {
	.full-order-details {
		.shipping-method {
			.column {
				width: 100%;
			}

			p {
				padding-bottom: 20px;
			}
		}

		.price {
			float: none;
			clear: left;
			display: block;
		}
	}
}

@media only screen and (max-width: $mobile-lg) {
	.full-order-details {
		.column {
			width: 100%;
		}

		.addresses {
			padding-bottom: 4px;
		}

		.address {
			padding-bottom: 30px;
		}

		.order-contents {
			h6 {
				margin-top: -6px;
				padding-bottom: 20px;
				font-size: 1.25em;
				line-height: 1.25em;
			}
		}

		.price {
			float: none;
			display: block;
		}

		.product-image {
			width: 100px;

			img {
				width: 72px;
			}
		}
	}
}

@media only screen and (max-width: $mobile) {
	.full-order-details .product-image {
		width: 0;
		display: none;
	}
}

/*---------------------------------------------*/
/* Wishlist
/*---------------------------------------------*/
.account-section.wishlist {
	margin: 21px 0 0;
	padding: 60px 0 60px;
}

.wishlist {
	.content {
		max-width: 1512px;
		position: relative;
		width: 90%;
		margin: 0 auto;

		.content {
			width: 100%;
		}
	}

	.wishlist-item {
		position: relative;
		float: left;
		width: 25%;
		padding: 0 5px 10px 5px;
		box-sizing: border-box;

		&:nth-child(4n + 1) {
			clear: left;
		}
	}

	img {
		width: 100%;
	}

	h4 {
		padding: 19px 0 3px 0;
		color: $color-gray-15;
		font-size: 0.875em;
		text-align: center;

		a {
			color: $color-gray-15;
			text-decoration: none;
		}
	}

	dl {
		padding: 0;
	}

	dt {
		display: none;
	}

	dd {
		display: block;
		text-align: center;
	}

	.sale {
		dd {
			display: inline;
			font-size: 1em;
		}
	}

	.original-price {
		text-decoration: line-through;
	}

	.sale-price {
		color: $color-red-1;
		text-decoration: none;
	}

	.tag {
		padding: 15px 0 0 0;
		color: $color-gray-1;
		font-size: 0.75em;

		a {
			color: $color-gray-1;
			transition: color 0.1s linear;

			&:hover {
				color: $color-base;
			}
		}
	}

	.remove-button {
		position: absolute;
		right: 10px;
		top: 10px;
		display: block;
		padding: 0;
		width: 20px;
		height: 17px;

		svg {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		.heart {
			path {
				fill: $color-gray-1;
			}
		}

		.remove {
			path {
				fill: $color-gray-1;
			}
		}
	}

	.remove {
		display: none;

		path {
			fill: $color-gray-1;
		}
	}

	.heart {
		padding: 0 0 0 2px;

		path {
			fill: $color-gray-1;
			transition: fill 0.2s linear;
		}
	}

	svg {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		opacity: 0;
		background: rgba(38, 38, 38, 0.9);
		transition: opacity 0.4s linear;
	}
}

.touch.wishlist {
	.remove-button {
		right: 0;

		svg {
			position: static;
			transform: translateY(0);
		}
	}

	svg {
		position: static;
		transform: translateY(0);
	}
}

.wishlist-item {
	&:hover {
		.heart {
			path {
				fill: $color-red-5;
			}
		}

		.overlay {
			opacity: 1;
		}
	}

	a {
		position: relative;
		display: block;
	}

	.overlay {
		dl {
			position: absolute;
			left: 0;
			right: 0;
			top: 58%;
			transform: translateY(-50%);
		}

		dt {
			display: block;
			text-align: center;
			font-size: 0.75rem;
			font-weight: normal;
			line-height: 1.125rem;
			color: $color-white;
		}

		dt.extra-info {
			display: none;
		}

		dd {
			font-size: 0.75rem;
			font-weight: normal;
			line-height: 1.125rem;
			color: $color-white;
		}

		ul {
			list-style: none;
			max-width: 249px;
			margin: 0 auto;
			font-size: 0;
		}

		li {
			width: 16.66%;
			margin: 0;
			text-align: center;
			color: $color-white;
			display: inline-block;
			font-size: 0.75rem;
		}
	}
}

/* 3 Columns */
@media only screen and (max-width: 1000px) {
	.wishlist {
		.wishlist-item {
			width: 33.33%;

			&:nth-child(4n + 1) {
				clear: none;
			}

			&:nth-child(3n + 1) {
				clear: left;
			}
		}
	}
}

@media only screen and (min-device-pixel-ratio: 1.5) and (max-width: $tablet),
	only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: $tablet) {
	.touch {
		.wishlist {
			.product-info {
				position: relative;
			}

			.remove-button {
				&::after {
					text-align: right;
				}

				.remove {
					display: none;
				}

				.heart {
					display: block;

					path {
						fill: $color-red-5;
					}
				}
			}
		}
	}

	.wishlist {
		h4 {
			padding-right: 40px;
			text-align: left;
		}

		.price {
			padding-right: 40px;
			text-align: left;
		}

		.tag {
			padding-right: 40px;
			text-align: left;
		}

		dd {
			text-align: left;
		}

		.remove-button {
			top: 9px;
		}
	}
}

/* 2 columns */
@media only screen and (max-width: 750px) {
	.wishlist {
		.standard-heading {
			font-size: 2em;
		}

		.wishlist-item {
			width: 50%;

			&:nth-child(3n + 1) {
				clear: none;
			}

			&:nth-child(2n + 1) {
				clear: left;
			}
		}
	}
}

@media only screen and (max-width: $mobile-lg) {
	.my-account {
		.wishlist {
			background-color: $color-gray-16;
		}
	}

	.wishlist {
		.standard-heading {
			padding-bottom: 8px;
			font-size: 1.5em;
		}

		.sub-heading {
			display: block;
		}
	}
}

@media only screen and (max-width: $mobile) {
	.wishlist {
		.wishlist-item {
			width: 100%;
			padding-bottom: 47px;
		}

		h4 {
			text-align: left;
			padding-top: 14px;
		}

		.price,
		.tag,
		dd {
			text-align: left;
		}
	}
}
