/* Password toggle eye icon used in password field component */
.password-toggle {
	width: 15px;
	height: 16px;
	position: absolute;
	top: 21px;
	right: 20px;
	background: url(../images/global/showpassword-icon.svg) center center no-repeat;
	border: none;
	cursor: pointer;

	.sr-only {
		display: none;
	}

	&.is-visible {
		background: url(../images/global/hidepassword-icon.svg) center center no-repeat;
	}
}
