::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(187, 84, 84, 0.5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.filter-sort-area {
	$list-item-height: 50px;
	display: flex;
	margin: 0 1rem 0 auto;
	width: 280px;

	#puffer {
		display: none;
	}

	@media screen and (max-width: $desktop) {
		width: 100vw;
		margin: 1px 8px 0 14px;
		font-size: 13px;
		color: $color-gray-5;
		display: grid;
		grid-template-columns: 1fr 0.8fr 1fr;
		grid-template-areas: "gridoptions productcount refine";
	}
	@media screen and (min-width: calc($desktop + 1px)) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-areas: "productcount refine";
	}

	@media screen and (min-width: calc($desktop + 1px)) {
		font-weight: bold;
	}

	@media screen and (max-width: $desktop) {
		.filter-result-count:not(.active) {
			margin-left: -10px;
		}

		.filter-result-count {
			justify-self: center;
		}
	}
	.filter-result-count {
		font-size: 13px;
		margin-left: 8px;
		grid-area: productcount;
		align-self: center;
		font-weight: 400;

		.counter {
			margin: 0.25rem;
		}

		.clear {
			display: none;
		}

		&:not(.active) {
			@media screen and (min-width: calc($desktop + 1px)) {
				padding: 0 13px;
			}
		}

		&.active {
			display: flex;
			align-items: center;
			opacity: 1;
			justify-content: center;
			border-radius: 14px;
			height: 32px;
			background-color: $color-gray-10;
			width: fit-content;
			padding: 0 12px;
			margin-left: 10px;

			.counter {
				margin-left: 2px;
			}

			.filter-name {
				padding-left: 4px;
			}

			.clear {
				display: flex;
				margin-left: 12px;
				padding: 0;
				border: none;
				background: none;
				cursor: pointer;
				font-size: 13px;

				svg {
					transform: rotate(45deg);
					path {
						fill: $color-gray-5;
					}
				}
			}
		}

		@media (max-width: $desktop) {
			text-align: center;
		}
	}

	.filter-sort-toggle {
		background: none;
		border: none;
		font-size: 13px;
		cursor: pointer;
		align-items: center;
		margin-left: auto;
		color: $color-gray-5;
		padding: 0;
		width: 75px;
		display: flex;
		justify-content: space-between;

		@media screen and (max-width: $desktop) {
			grid-area: refine;
			span {
				display: none;
			}
		}

		.slider-icon {
			height: 30px;
			display: flex;
			justify-content: center;
			align-content: center;
			width: 30px;
			background-color: $color-gray-10;
			border-radius: 15px;

			@media screen and (max-width: $desktop) {
				margin-left: 20px;
			}

			svg {
				width: 100%;
				height: 100%;
				transform: rotate(90deg) scale(0.5);
			}
		}

		.slider-icon:hover,
		.slider-icon.active {
			background-color: $color-gray-5;
			white-space: nowrap;
			transition: all 0.5s;
			color: $color-white;

			svg {
				line {
					color: $color-white;
				}
			}
		}
	}

	&__wrapper {
		position: fixed;
		top: 0;
		left: 0;
		background-color: transparent;
		width: 100vw;
		transform: translateX(-200%);
		transition: transform 0.66s;
		overflow: hidden;
		box-sizing: border-box;
		bottom: 0;
		height: calc(var(--vh, 1vh) * 100);
		z-index: 600;
		pointer-events: none;

		@media screen and (min-width: calc($desktop + 1px)) {
			position: absolute;
			top: 50px;
			transform: none;
			width: 0;
			z-index: 97;

			form {
				overflow: hidden;
				opacity: 0;
				transition: max-height 0.23s ease-in-out, opacity 0.3s ease-in-out;
			}
		}

		&.active {
			transition: transform 0.66s ease-in;
			transform: none;
			pointer-events: all;

			&::after {
				content: "";
				width: 500vw;
				height: 100vh;
				background-color: rgba(0, 0, 0, 0.5);
				position: absolute;
				left: 0;
				top: 0;
				z-index: -1;
			}

			@media screen and (min-width: calc($desktop + 1px)) {
				width: 100vw;
				form {
					opacity: 1;
					max-height: 325px;
					transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
					overflow: auto;
				}
			}
		}
	}

	&-button--menu {
		font-size: 13px;
		color: $color-gray-13;
		border: 1px solid $color-gray-13;
		border-right: 0;
		background: $color-white;
		z-index: 5;
		padding: 16px;
		width: 50%;
		cursor: pointer;
		text-transform: capitalize;
		height: 55px;
		font-weight: 700;

		&:hover {
			@media screen and (min-width: calc($desktop + 1px)) {
				background-color: $color-gray-8;
			}
		}

		&.clear {
			left: 0;
			border-left: 0;
		}

		&.apply {
			left: 50%;
		}
	}

	&__menu {
		display: flex;
		flex-direction: column;
		justify-content: stretch;
		width: calc(100% - 55px);
		background-color: $color-white;
		transition: width 0.33s;
		overflow: hidden;
		position: relative;
		box-shadow: $shadow;

		@media screen and (min-width: $mobile-lg) and (max-width: $tablet) {
			max-width: 320px;
		}

		@media screen and (max-width: $desktop) {
			height: calc(100% - 55px);
			left: 0;
			top: 0;
			bottom: 0;
			overflow: auto;
		}

		.loading-overlay {
			width: 0;
			overflow: hidden;

			@media screen and (min-width: calc($desktop + 1px)) {
				height: 100%;
				position: absolute;
				right: 0;
				top: 0;
				display: none;
				left: 0;
			}
		}

		.loading-overlay.active {
			@media screen and (max-width: $desktop) {
				position: fixed;
				width: 100%;
				max-width: calc(100% - 55px);
				z-index: 1000;
				height: 100vh;
			}

			@media screen and (min-width: $mobile-lg) and (max-width: $tablet) {
				max-width: 320px;
			}

			@media screen and (min-width: calc($desktop + 1px)) {
				margin-top: 1px;
				height: 100%;
				width: 100%;
				display: block;
				.content {
					height: 100% !important; /* stylelint-disable-line declaration-no-important */
				}
			}
		}

		.button-container {
			@media screen and (max-width: $tablet) {
				position: fixed;
				display: flex;
				bottom: 0;
				backface-visibility: hidden;
				z-index: 100;
				width: calc(100% - 55px);
			}

			@media screen and (min-width: $mobile-lg) {
				max-width: 320px;
			}
		}
		@media screen and (max-width: $desktop) {
			@keyframes repaint {
				from {
					width: 99.999%;
				}
				to {
					width: 100%;
				}
			}
		}

		@media screen and (min-width: calc($desktop + 1px)) {
			width: 100%;
			height: auto;
			box-shadow: $shadow;
			box-sizing: border-box;
			max-width: none;
			padding-bottom: 50px;

			&-button {
				display: none;
			}
		}
	}

	&__fieldset-wrapper {
		overflow: auto;
		-webkit-transform: translateZ(0);

		@media (min-width: calc($desktop + 1px)) {
			display: flex;
			flex-direction: row;
			height: auto;
		}
	}

	&__fieldset {
		border: $item-border;
		border-right: none;

		@media screen and (max-width: $desktop) {
			border-top: none;
			border-left: none;
		}

		@media screen and (min-width: calc($desktop + 1px)) {
			flex: 1;
			overflow-y: scroll;
			-ms-overflow-style: none; /* for Internet Explorer, Edge */
			scrollbar-width: none; /* for Firefox */
			&::-webkit-scrollbar {
				display: none; /* for Chrome, Safari, and Opera */
			}
		}

		&:last-of-type {
			@media screen and (max-width: $desktop) {
				border-bottom: none;
			}
		}

		@media screen and (min-width: calc($desktop + 1px)) {
			padding-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */

			&:last-of-type {
				padding-bottom: 50px;
			}
		}

		.title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 49px;
			padding: 0 20px;
			border-bottom: $item-border;
			position: sticky;
			top: 0;
			z-index: 1;
			background: $color-white;
			line-height: 14px;
			font-weight: bold;
			color: $color-gray-5;

			@media screen and (max-width: $desktop) {
				height: 55px;
				padding: 0 14px;

				.sticky {
					position: sticky;
				}
			}

			.clear-facet {
				border: none;
				background: none;
				text-decoration: underline;
				cursor: pointer;
				padding: 0;
				font-size: 13px;
				text-transform: capitalize;

				&.hidden {
					display: none;
				}
			}
		}

		ul::-webkit-scrollbar {
			display: none;
		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: wrap;
			overflow: scroll;

			@media screen and (max-width: $desktop) {
				overflow: hidden;
			}

			@media screen and (min-width: calc($desktop + 1px)) {
				padding-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
				scrollbar-width: none;
				max-height: calc(var($list-item-height) * 4 + 26px);
				overflow-y: scroll;
				-ms-overflow-style: none;

				&.is-overflown {
					li:last-of-type {
						border-bottom: none;
					}
				}
			}

			li {
				flex-basis: 100%;
				border-bottom: $item-border;

				label {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 20px;
					height: 49px;
					font-weight: 400;
					color: $color-gray-13;
					cursor: pointer;
					white-space: nowrap;
					-webkit-user-select: none;

					@media screen and (max-width: $desktop) {
						height: 50px;
						padding: 0 14px;
					}
				}
			}

			@media screen and (max-width: $desktop) {
				li:last-child {
					border-bottom: none;
				}
			}

			/* Color filters */
			&.colours {
				padding: 15px 12px;
				border-bottom: none;
				display: grid;
				grid-template-columns: repeat(auto-fill, 55px);
				grid-template-rows: auto;

				@media screen and (max-width: $desktop) {
					border-bottom: none;
					white-space: nowrap;
					overflow: auto;
				}

				@media screen and (min-width: calc($desktop + 1px)) {
					grid-template-columns: repeat(auto-fill, minmax(56px, 1fr));
				}

				li {
					height: 50px;
					border: none;
					padding: 0 16px;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-basis: auto;

					@media screen and (min-width: calc($desktop + 1px)) {
						height: $list-item-height;
					}

					label {
						padding: 0;
						justify-content: center;
						align-items: center;
					}
				}
			}
		}

		&.sort {
			@media screen and (min-width: calc($desktop + 1px)) {
				flex: 1;
			}
			@media screen and (min-width: calc($desktop-lg + 1px)) {
				flex: 1;
			}

			@media screen and (min-width: 1640px) {
				flex: 1;
			}

			@media screen and (min-width: 2000px) {
				flex: 1;
			}
		}

		&[data-type="filter_size"],
		&[data-type="filter_width"],
		&[data-type="filter_length"] {
			flex: 1;

			ul {
				display: flex;

				@media screen and (min-width: calc($desktop + 1px)) {
					border-bottom: $item-border;
				}

				li {
					&:not(.single-item) {
						flex-basis: calc(50% - 1px);
						@media screen and (min-width: calc($desktop + 1px)) {
							flex-basis: 100%;
						}
					}

					&:not(:nth-of-type(2n + 2)),
					&:last-of-type {
						border-right: $item-border;

						@media screen and (min-width: calc($desktop + 1px)) {
							border-right: none;
						}

						&:nth-of-type(even) {
							border-right: none;
						}
					}

					&:last-child {
						border-bottom: none;
					}

					&:nth-last-child(2):nth-child(odd) {
						@media (max-width: $desktop) {
							/* targets last row odd item in mobile view */
							border-bottom: none;
						}
					}
				}
			}

			&.two-columns {
				display: block;

				@media screen and (min-width: calc($desktop + 1px)) {
					ul {
						li:not(.single-item) {
							flex-basis: calc(50% - 1px);

							&:not(:nth-of-type(2n + 2)) {
								border-right: $item-border;
							}

							&:last-child,
							&:nth-last-child(2):nth-child(odd) {
								/* targets last row odd item */
								border-bottom: none;
							}
						}
					}
				}
			}
		}

		&[data-type="filter_length"] ul li {
			&:nth-child(odd) {
				@media (max-width: $ desktop) {
					border-right: $ item-border;
				}
			}
		}

		&[data-type="filtermaterial"] {
			@media screen and (min-width: calc($desktop + 1px)) {
				flex: 1;
			}
		}
	}

	label {
		.check-icon,
		> .close-icon {
			width: 1rem;
			height: 1rem;
			display: none;
		}

		svg.close-icon {
			width: 0.75rem;
			height: 0.75rem;
			margin: 0.125rem;
		}

		&[data-colour] {
			width: 1.5rem;
			height: 1.5rem;
			border-radius: 50%;
			position: relative;
			background-size: cover; /* in case color swatch image is not a square */
			background-position: center center;
			background-repeat: no-repeat;

			@media screen and (max-width: $desktop) {
				width: 21px;
				height: 21px;
			}

			&::after {
				content: "";
				width: 100%;
				display: none;
				transform: translateY(-50%) rotate(-45deg);
				transform-origin: center;
				position: absolute;
				top: 50%;
				left: 0;
			}

			&.dark {
				color: $color-gray-5;

				.check-icon svg path,
				.close-icon svg polygon {
					fill: $color-gray-5;
					stroke: $color-gray-5;
				}

				&::after {
					background-color: $color-gray-5;
				}
			}

			&.light {
				color: $color-white;

				.check-icon svg path,
				.close-icon svg polygon {
					fill: $color-white;
					stroke: $color-white;
				}

				&::after {
					background-color: $color-white;
				}
			}
		}

		/* white color label needs special styles to contrast to background*/
		&[data-colour="#ffffff"] {
			border: 1px solid $color-gray-9;
			margin: -1px;
		}
	}

	input[type="radio"],
	input[type="checkbox"] {
		display: none;

		&:not(:disabled):hover + label {
			@media screen and (min-width: calc($desktop + 1px)) {
				background-color: $color-gray-10;
			}
		}

		&:disabled + label {
			opacity: 0.3;
			cursor: initial;

			&::after {
				display: block;
			}
		}

		&:checked + label {
			background-color: $color-gray-10;
			position: relative;

			.check-icon {
				display: block;
			}

			&:hover {
				@media screen and (min-width: calc($desktop + 1px)) {
					background-color: $color-gray-10;
				}

				.check-icon {
					@media screen and (min-width: calc($desktop + 1px)) {
						display: none;
					}
				}

				.close-icon {
					@media screen and (min-width: calc($desktop + 1px)) {
						display: block;
					}
				}
			}
		}
	}

	.sort {
		@media screen and (min-width: calc($desktop + 1px)) {
			input[type="radio"],
			input[type="checkbox"] {
				&:checked + label {
					&:hover {
						@media screen and (min-width: calc($desktop + 1px)) {
							.check-icon {
								@media screen and (min-width: calc($desktop + 1px)) {
									display: block;
								}
							}
						}
					}
				}
			}
		}
	}

	@media screen and (min-width: calc($mobile + 1px)) {
		&-button--menu {
			width: 50%;
		}
	}

	@media screen and (min-width: calc($desktop + 1px)) {
		&-button--menu {
			display: none;
		}
	}
}

.fieldset-empty {
	display: none;
}

.total-count-products-category {
	&.hidden {
		opacity: 0;
	}
	@media screen and (max-width: $desktop) {
		place-self: center;
		opacity: 1;
		grid-area: productcount;
	}
}

@media (max-width: $desktop) {
	.grid-nav.mobile {
		display: flex;
		align-items: center;
		height: 100%;
		ul {
			margin: 0;
			font-size: 13px;
			display: flex;
		}
		.side-nav {
			li {
				display: flex;
			}
			a {
				font-size: 13px;
			}
			.mobile-view-picker {
				a[data-product-view="uniform-small-mobile"] {
					&::before {
						content: "\e603";
					}
				}
			}
		}
		side-nav {
			li {
				a[data-product-view="uniform-large-mobile"] {
					&::before {
						content: "\e604";
						transition: color 200ms ease;
					}
				}
			}
		}
		.side-nav.choose-view.mobile {
			position: static;
			float: none;
			width: 100%;
			top: 0;
		}
		.choose-view {
			a {
				font-size: 13px;
			}
		}
	}
	.grid-nav {
		.side-nav {
			li {
				a {
					font-size: 13px;
				}
			}
		}
		.choose-view {
			li {
				a {
					font-size: 13px;
				}
			}
		}
	}
}

/* Styles below remove "double border" effect for overflow/long filter lists in Desktop view */
@media screen and (min-width: calc($desktop + 1px)) {
	.filter {
		&--overflow {
			&.filter-sort-area__fieldset {
				&[data-type="filter_size"],
				&[data-type="filter_width"],
				&[data-type="filter_length"] {
					ul {
						border-bottom: none;
					}
				}
			}

			li {
				&:last-of-type {
					border-bottom: none;
				}
			}

			&[data-type="filter_size"],
			&[data-type="filter_width"] {
				li {
					&:last-of-type {
						border-bottom: none;
					}
				}

				&.even {
					li {
						&:nth-last-child(-n + 2) {
							border-bottom: none;
						}
					}
				}
			}
		}
	}
}
