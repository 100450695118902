/* Media Viewer with slider and zoom for large images. Uses SwiperJS v6.8.4 https://swiperjs.com/ */
@import "../vendor/swiper-bundle.min.css";
/* Swiper UI overrides */
.media-viewer {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	background: $color-white;
	cursor: pointer;
	overflow: hidden;

	.close {
		display: block;
		width: 20px;
		height: 20px;
		position: absolute;
		top: 20px;
		right: 20px;
		background: url(../images/media-viewer/close.svg) no-repeat center center;
		color: $color-base;
		text-decoration: none;
		z-index: 1001;
		transition: 0.3s all;
		border: none;

		&:hover {
			cursor: pointer;
		}

		span {
			display: none;
		}
	}

	.instruction {
		opacity: 0;
		visibility: hidden;
		display: inline-block;
		position: absolute;
		text-align: center;
		font-size: 0.875em;
		line-height: 1.429;
		background: $color-white;
		border-radius: 4px;
		color: $color-blue-1;
		padding: 3px 10px;
		min-width: 180px;
		transform: translateY(50%);

		span {
			display: block;
		}

		&.active {
			opacity: 1;
			visibility: visible;
		}
	}

	.swiper-button {
		width: 22px;
		height: 22px;
		border: none;
		border-radius: 50%;
		background: $color-gray-10 no-repeat center center;
		padding: 0;
	}

	.swiper-button-prev {
		left: 20px;
		background-image: url(../images/media-viewer/prev.svg);

		&::after {
			display: none;
		}

		span {
			display: none;
		}
	}

	.swiper-button-next {
		right: 20px;
		background-image: url(../images/media-viewer/next.svg);

		&::after {
			display: none;
		}

		span {
			display: none;
		}
	}

	.swiper-container-horizontal {
		> .swiper-pagination-bullets {
			display: flex;
			max-width: 300px;
			left: 50%;
			transform: translateX(-50%);

			.swiper-pagination-bullet {
				flex: 1 1 auto;
				border-radius: 0;
				height: 22px;
				position: relative;
				background: none;
				margin: 0;

				&::after {
					display: block;
					width: 100%;
					height: 2px;
					content: "";
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					background-color: $color-gray-13;
					opacity: 1;
				}
			}

			.swiper-pagination-bullet-active {
				&::after {
					opacity: 1;
					background-color: $color-white;
				}
			}
		}
	}

	img {
		cursor: zoom-in;
	}

	.swiper-zoom-container.is-zoomed {
		display: block;
		position: fixed;
		top: 0;
		width: 100vw;
		height: 100vh;
		z-index: 10;
		overflow: auto;

		img {
			max-width: none;
			max-height: none;
			object-fit: none;
			cursor: zoom-out;
		}
	}

	&.active {
		display: flex;
	}
}

@media (max-width: $mobile) {
	.media-viewer {
		.swiper-button {
			display: none;
		}

		.swiper-zoom-container {
			background-color: $color-gray-18;

			&:not(.is-zoomed) {
				> img {
					object-fit: cover;
					height: 100%;
				}
			}
		}
	}
}
