.search-area {
	background: $color-white;
	transition: border-color 400ms ease;

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 1px;
		background: rgba(188, 188, 188, 0.25);
	}
}

.search-form {
	&__fieldset {
		width: 100%;
		border: 0;
		box-sizing: border-box;
		height: 52px;
		display: flex;
		align-items: center;
	}

	&__container {
		position: relative;
		flex: 1 1 auto;
		text-align: left;
	}

	&__label {
		position: absolute;
		top: 50%;
		left: 1rem;
		transform: translateY(-50%);
		cursor: text;
		color: $dark-color;
		line-height: 1em;
		text-transform: uppercase;
		font-size: $defaultFontSize;

		&--hidden {
			opacity: 0;
			visibility: hidden;
		}
	}

	&__input {
		-webkit-appearance: none;
		border: 0;
		background: none;
		/* Hack to align epoq search suggestion container with input box */
		width: 100%;
		max-width: 700px;
		font-size: $defaultFontSize;
		padding: 1rem 15px;
		color: $dark-color;
		outline: none;
		border-radius: 0;
		box-sizing: border-box;
		text-align: left;
		text-transform: uppercase;

		&--error {
			border-bottom-color: $color-red-3;
		}
	}

	&__submit-wrapper {
		display: none;

		@media screen and (min-width: calc($desktop + 1px)) {
			display: flex;
			align-items: center;
			flex: 0 0 230px;
		}

		@media screen and (min-width: calc($desktop-lg + 1px)) {
			flex: 0 0 260px;
		}

		@media screen and (min-width: 1640px) {
			flex: 0 0 290px;
		}

		@media screen and (min-width: 2000px) {
			flex: 0 0 350px;
		}
	}

	&__submit {
		width: 1rem;
		height: 1rem;
		margin-left: 1.5rem;
		-webkit-appearance: none;
		-moz-appearance: none;
		background: none;
		border: 0;
		padding: 0;
		outline: none;
		cursor: pointer;
	}

	&__submit-icon {
		width: 1rem;
		height: 1rem;

		svg {
			fill: $color-gray-13;
			height: 20px;
			width: 19px;
			margin-top: -1px;
		}
	}

	&__extra {
		display: none;
	}

	&__error {
		position: absolute;
		top: 100%;
		left: 0;
		margin-top: 10px;
		font-size: 14px;
		color: $color-red-3;
		display: none;

		&--active {
			display: block;
		}
	}
}

@media screen and (min-width: calc($desktop-lg + 1px)) {
	.search-form {
		&__fieldset {
			height: 53px;
		}
	}
}

@media screen and (min-width: calc($desktop + 1px)) {
	.search-form__input {
		padding: 1rem 0;
	}
}

@media (max-width: $tablet) {
	.search-area {
		top: 55px;
	}
}
