/**
 * Social Icons List component
 */

.social-list {
	display: flex;
	gap: 28px;

	&__item {
		list-style: none;
		line-height: 1em;
	}

	&__icon {
		display: block;

		svg {
			height: 10px;
		}

		path,
		polygon,
		circle {
			fill: $color-base;
			transition: fill 0.4s;
		}

		&:hover {
			path,
			polygon,
			circle {
				fill: $color-gray-1;
			}
		}
	}
}

.confirmation-page {
	.social-list {
		display: none;
	}
}
