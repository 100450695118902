.header {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	background: transparent;
	transform: translate3d(0, 0, 0);

	&.popover-active {
		transform: none;
	}

	.header-menu-wrapper {
		display: grid;
		grid-template: "menu logo actions" 1fr / 33% 33% 34%;
		font-size: 0.875rem;
		background: $color-gray-5;
	}

	/* header logo */
	&__logo {
		grid-area: logo;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			fill: $color-white;
		}

		@media screen and (max-width: $desktop) {
			svg {
				width: 90px;
				height: 22px;
			}
		}
	}

	&__nav {
		display: none;
		text-transform: uppercase;
	}

	/* header button */
	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
		height: $header-height;
		width: 60px;
		padding: 0;
		border: 0;
		background: transparent;
		cursor: pointer;
		transition: background-color 0.1s;

		&.open-flyout-btn {
			grid-area: menu;
		}

		&--account,
		&--music-player {
			display: none;
		}

		&--country {
			display: none;
		}

		&--search {
			.close-icon {
				display: none;
			}

			.search-icon {
				svg {
					height: 22px;
					margin-bottom: -4px;

					@media screen and (max-width: $desktop) {
						height: 24px;
						width: 21px;
						margin-bottom: -4px;
					}
				}
			}
		}

		svg {
			fill: $color-gray-6;
			transition: fill 0.1s;
		}

		svg,
		div {
			pointer-events: none;
		}

		@media screen and (max-width: $desktop) {
			width: 55px;
			height: 55px;

			svg {
				width: 16px;
				height: 16px;
				fill: $color-gray-14;
			}

			&.open-flyout-btn {
				svg {
					width: 26px;
					height: 26px;
					fill: $color-gray-14;
				}
			}
		}
	}

	.heading {
		font-size: $defaultFontSize;
		border-bottom: 1px solid $color-gray-9;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		text-transform: uppercase;
	}

	/* header actions */
	&__actions {
		grid-area: actions;
		display: flex;
		justify-content: flex-end;
	}

	&__country-overlay {
		display: none;
		grid-column: 2 / 4;
		background-color: $color-white;
		border-left: $item-border;
		border-bottom: $item-border;

		.heading {
			font-size: $defaultFontSize;
			border-bottom: 1px solid $color-gray-9;
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: bold;
			text-transform: uppercase;
			background-color: $color-gray-10;

			&:hover {
				background-color: $color-gray-19;
			}
		}

		.currency-overlay {
			height: 375px;
			overflow-y: auto;
		}
	}

	/* Header Overlays */
	.header-overlay {
		display: none;
		position: absolute;
		transition: top 0.33s;
		width: 100%;
		z-index: 102;

		&--search {
			width: 100%;
			display: block;
			max-height: 0;
			overflow: hidden;

			&.is-open {
				max-height: 200px;
			}
		}

		&.header__country-overlay,
		&--login {
			right: 0;
			width: 376px;
			min-width: 376px;

			&.is-open {
				@media screen and (min-width: calc($desktop-lg + 1px)) {
					width: 376px;
				}
			}
		}

		&.is-open {
			&:not(.header__country-overlay) {
				display: block;
				text-align: center;
			}

			&.header__country-overlay {
				@media screen and (min-width: calc($desktop-lg + 1px)) {
					width: 376px;
				}
			}
		}
	}

	&.touch-device {
		.header-menu-wrapper {
			grid-template: "menu logo actions" 1fr / 33% 33% 34%;
			height: 55px;
		}

		.header {
			&__logo {
				svg {
					width: 90px;
					height: 22px;
				}
			}

			&__button {
				width: 55px;
				height: 55px;

				&--search {
					.search-icon {
						svg {
							height: 24px;
							width: 21px;
							margin-bottom: -4px;
						}
					}
				}

				svg {
					width: 16px;
					height: 16px;
					fill: $color-gray-14;
				}

				&.open-flyout-btn {
					svg {
						width: 26px;
						height: 26px;
						fill: $color-gray-14;
					}
				}
			}
		}
	}

	&:not(.touch-device) {
		.header__country-overlay {
			&.header-overlay {
				&.is-open-but-not-visible {
					display: block;
				}
			}
		}
	}
}

@media screen and (min-width: calc($desktop + 1px)) {
	.header {
		&:not(.touch-device) {
			.header-menu-wrapper {
				grid-template: "logo nav actions" 1fr / 210px auto 30%;
				position: relative;
			}

			.header {
				&__logo {
					justify-content: flex-start;
					max-width: 104px; /* based on Figma */

					svg {
						margin-left: 1rem;
					}
				}

				&__nav {
					display: block;
					grid-area: nav;
				}

				&__button {
					&--country {
						.country-flag {
							width: 16px;
							height: 16px;
							border-radius: 100%;
							background-position: 53%;
							background-size: cover;
						}
					}

					&.open-flyout-btn {
						display: none;
					}

					&--account,
					&--country,
					&--music-player {
						display: flex;
					}
				}

				&__country-overlay {
					display: block;
				}
			}

			.header-overlay {
				display: none;

				&.is-open {
					display: block;

					&.header-overlay--login {
						width: 376px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: calc($desktop-lg + 1px)) {
	.header {
		&:not(.touch-device) {
			.header-menu-wrapper {
				grid-template: "logo nav actions" 1fr / 260px auto 30%;
			}
		}
	}
}

@media screen and (min-width: 1640px) {
	.header {
		&:not(.touch-device) {
			.header-menu-wrapper {
				grid-template: "logo nav actions" 1fr / 290px auto 30%;
			}
		}
	}
}

@media screen and (min-width: 2000px) {
	.header {
		&:not(.touch-device) {
			.header-menu-wrapper {
				grid-template: "logo nav actions" 1fr / 350px auto 30%;
			}
		}
	}
}
