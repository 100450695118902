/* Product size guide */
.product-size-guide {
	background: $color-gray-12;
	color: $color-white;
	padding: 50px 0;

	> .content {
		width: 100%;
		max-width: 1680px;
		margin: 0 auto;
		text-align: center;
		box-sizing: border-box;
		padding: 0 10px 0 20px;
	}

	.loading {
		margin-top: 40px;
	}

	.loaded {
		.loading {
			display: none;
		}
	}

	.standard-dropdown {
		> .current {
			color: $color-white;
			background: transparent;
			border-color: $color-gray-17;

			&::before {
				position: absolute;
				z-index: -1;
				bottom: 11px;
				right: 23px;
				display: block;
				content: "";
				border-top: 6px solid $color-gray-17;
				border-right: 5px solid transparent;
				border-left: 5px solid transparent;
				transition: border-color 0.2s;
			}

			&:hover {
				border-color: $color-white;

				&::before {
					border-top-color: $color-white;
				}
			}
		}

		> .current.active {
			&::before {
				right: 3px;
				border-top: none;
				border-bottom: 6px solid $color-gray-17;
			}
			color: $color-base;
		}

		.content {
			text-align: left;
		}
	}

	.size-options {
		max-width: 360px;
		margin: 40px auto;
		display: block;
	}

	.product-size {
		text-align: left;
		border-bottom-left-radius: 0;
		z-index: 9;
		display: block;
	}

	.technical-drawing {
		width: 14%;
		min-width: 170px;
		margin: 20px auto 40px;
		position: relative;

		img {
			width: 100%;
		}
	}

	.content {
		.detail {
			text-align: left;
			position: absolute;
			width: 100px;
			overflow: hidden;
			opacity: 0;
			transition: width 0.5s ease-out, opacity 0.2s;
			cursor: pointer;

			.detail-text {
				display: inline-block;
				font-size: 14px;
				vertical-align: top;
			}

			.title {
				font-weight: 700;
				background: $color-gray-12;
				padding-left: 10px;
				padding-right: 10px;
				display: inline-block;
			}

			p {
				display: none;
				padding-left: 10px;
			}
		}

		.detail.show {
			width: 300px;
			opacity: 1;
		}
	}

	.detail.right {
		.title {
			min-width: 74px;
		}

		.detail-text {
			float: right;
		}
	}
}

.technical-drawing-wrapper {
	position: relative;
	z-index: 0;
	display: block;
	width: 100%;
	height: 0;
	transition: height 0.4s;
	padding-top: 10px;
	white-space: nowrap;
}

.product-size-guide.standard-tabs {
	.size-guide-heading {
		display: inline-block;
		font-size: 1em;
		font-weight: 700;
		line-height: 1.5em;
		border-bottom: 2px solid $color-gray-13;
	}
}

.detail {
	.line {
		position: absolute;
		left: 0;
		top: 8px;
		z-index: -1;
		border: 1px $color-gray-4 dashed;
		width: 500px;
	}
}

.detail.right {
	.line {
		left: auto;
		right: 0;
	}
}

.arrow-line-wrapper {
	position: absolute;
	transform-origin: center center;
}

.arrow-line {
	width: 1px;
	height: 1px;
	top: 50%;
	left: 50%;
	background-color: $color-white;
	border: none;
	outline: none;
	position: absolute;
	opacity: 0;
	transform-origin: center center;
	transition: height 0.5s ease-in, width 0.5s ease-in, opacity 0.1s, top 0.5s ease-in, left 0.5s ease-in;

	&::before {
		content: "";
		position: absolute;
		display: block;
		left: -8px;
		top: -3px;
		border-right: 8px solid $color-white;
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
	}

	&::after {
		content: "";
		position: absolute;
		display: block;
		left: 100%;
		top: -3px;
		border-left: 8px solid $color-white;
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
	}
}

.arrow-line-wrapper.show {
	.arrow-line {
		top: 50%;
		left: 0;
		width: 100%;
		opacity: 1;
		height: 1px;
	}
}

@media (max-width: $desktop-lg) {
	.product-size-guide {
		.technical-drawing {
			width: 20%;
		}
	}
}

@media (max-width: 1000px) {
	.product-size-guide {
		.technical-drawing {
			width: 25%;
		}
	}
}

@media (max-width: $tablet) {
	.product-size-guide {
		> .content {
			max-width: 100%;
			padding: 0 20px;
		}

		.size-options {
			margin: 20px auto;
			max-width: initial;
		}

		.product-size.open {
			width: 103.2%;
		}

		.technical-drawing {
			font-size: 0.875em;
		}

		.content {
			.detail.show {
				width: 200px;
			}
		}
	}

	.product-size-guide.standard-tabs {
		.size-guide-heading {
			margin-bottom: 10px;
		}
	}
}

@media (max-width: $mobile-lg) {
	.product-size-guide {
		.content {
			.detail {
				.imperial {
					visibility: hidden;
				}
			}

			.detail.show {
				width: 170px;
			}
		}

		.product-size.open {
			width: 105.7%;
		}
	}
}

@media (max-width: $mobile) {
	.product-size-guide {
		.content {
			.detail.show {
				width: 120px;
			}
		}
	}
}

@media (max-width: 420px) {
	.product-size-guide {
		.content {
			.detail.show {
				width: 95px;
			}

			.detail.show.right {
				width: 105px;
			}

			.detail {
				.title {
					padding-left: 0;
					padding-right: 0;
					display: inline;
				}

				.detail-text {
					max-width: 65px;
				}

				p {
					padding-left: 0;
				}
			}
		}

		.detail.right {
			.detail-text {
				text-align: left;
				background: $color-gray-12;
			}
		}
	}
}
