/***********************************************/
/* Category Landing Page
/* Styles used across all pages
/***********************************************/

.category-landing-page {
	@media screen and (min-width: calc($desktop + 1px)) {
		padding: 55px 0 0 0;
	}

	.standard-heading,
	.empty-pop-heading {
		padding-top: 36px;
		padding-bottom: 32px;
	}

	.empty-pop-heading {
		text-align: center;
	}

	.category-nav-wrapper {
		display: flex;
		flex-direction: column;
		padding-top: 1px;

		@media screen and (min-width: calc($desktop + 1px)) {
			top: 59px;
			position: fixed;
			z-index: 99;
			left: 0;
			right: 0;
			background-color: $color-white;
		}

		.standard-heading {
			padding: 6px 0;
			font-size: 13px;
			text-transform: capitalize;

			@media screen and (min-width: calc($desktop + 1px)) {
				display: none;
			}
		}

		.standard-heading.has-subcategories {
			border-bottom: 1px solid $color-gray-9;
		}

		.autoscroll-left,
		.autoscroll-right {
			display: none;
			margin-right: 5px;
			margin-top: 1px;
			margin-left: 2px;
			cursor: pointer;
			width: 20px;
			height: 20px;
			border-radius: 10px;
			background-color: $color-gray-10;

			svg {
				@media screen and (max-width: $desktop) {
					path {
						fill: $color-gray-5;
					}
				}
			}
		}

		.autoscroll-left {
			position: absolute;
			left: 10px;

			@media screen and (min-width: calc($desktop + 1px)) {
				left: -30px;
			}

			svg {
				transform: translate(4px, 4px) rotate(-180deg);
			}
		}

		.autoscroll-left:hover,
		.autoscroll-right:hover {
			background-color: $color-gray-5;
			white-space: nowrap;
			transition: all 0.5s;

			svg {
				path {
					fill: $color-white;
				}
			}
		}

		.autoscroll-right {
			position: absolute;
			right: -40px;
			top: 12px;

			@media screen and (min-width: calc($desktop + 1px)) {
				top: auto;
				right: -35px;
			}

			svg {
				transform: translate(4px, 1px);

				@media screen and (min-width: calc($desktop + 1px)) {
					transform: translate(4px, 4px);
				}
			}
		}

		.arrow-icon:hover {
			opacity: 1;
		}

		.categories-all {
			display: flex;
			align-items: center;
			width: 45%;
			background-color: $color-white;
			height: 45px;
			position: relative;

			li:first-child {
				text-transform: capitalize;
			}

			@media screen and (max-width: $desktop) {
				display: none;
			}
		}

		.categories-all.categories-appear {
			position: relative;

			@media screen and (min-width: calc($desktop + 1px)) {
				display: flex;
			}
		}

		.categories::-webkit-scrollbar {
			display: none;
		}

		.scroll-button-categories-container {
			position: relative;

			.autoscroll-left {
				left: 10px;
				position: absolute;
				top: 15px;
				z-index: 10;

				svg {
					transform: translate(4px, 1px) rotate(-180deg);
				}
			}

			.autoscroll-right {
				position: absolute;
				right: 10px;
				top: 15px;
				z-index: 10;
			}
		}

		.categories-container {
			display: inline-block;
			width: 100vw;
			position: relative;
			scrollbar-width: none;
			overflow: hidden;
			@media screen and (min-width: calc($tablet + 1px)) and (max-width: $desktop) {
				width: calc(100vw - 100px);
				margin-left: 50px;
			}

			@media screen and (max-width: $tablet) {
				width: 100vw;
				overflow: scroll;
			}
		}

		.categories {
			display: flex;
			align-items: center;
			overflow: scroll;
			list-style-type: none;
			height: 50px;
			width: fit-content;
			padding-left: 0;
			scroll-snap-align: none;
			overflow-x: scroll;
			scrollbar-width: none;

			@media screen and (min-width: calc($desktop + 1px)) {
				border-bottom: 1px solid $color-gray-9;
			}

			li {
				padding: 0 10px 0 0;

				@media screen and (max-width: $desktop) {
					padding: 0 10px 0;
				}

				a {
					color: $color-gray-5;
					white-space: nowrap;
					padding: 7px 10px;
					border-radius: 2px;
					background-color: $color-gray-10;

					&.active {
						color: $color-gray-6;
						background-color: $color-gray-5;
						border-radius: 2px;
					}
				}

				a:hover {
					background-color: $color-gray-5;
					color: $color-white;
					white-space: nowrap;
					transition: all 0.5s;
				}

				:hover {
					color: $color-gray-5;

					@media screen and (max-width: $desktop) {
						background-color: $light-gray-3;
						border-radius: 2px;
					}
				}

				@media screen and (max-width: $desktop) {
					font-size: 13px;
				}
			}

			@media screen and (min-width: calc($desktop + 1px)) {
				display: none;
			}
		}

		.tertiary-nav .categories {
			display: none;
			border: none;

			@media screen and (min-width: calc($desktop + 1px)) {
				display: flex;
			}
		}
	}

	.coming-soon {
		text-align: center;

		&__text {
			padding-bottom: 32px;
			max-width: 720px;
			margin: 0 auto;
		}

		section {
			border: none !important; /* stylelint-disable-line declaration-no-important */
			border-width: 0;
		}

		fieldset:not(.interest-input) {
			border: none;
			margin: 0 auto;
		}
	}

	.content {
		max-width: 1680px;
		margin: 0 auto;

		@media screen and (max-width: $desktop) {
			padding: 0 7px;
		}

		&::after {
			display: block;
			clear: both;
			content: "";
		}
	}

	.standard-heading.mobile {
		display: none;
	}

	.product-grid-item .wishlist svg {
		left: auto;
		transform: translateY(-50%);
	}

	.product-grid .content {
		justify-content: flex-start;
	}

	/* avoid messing with epoq filter results which includes this class as well */
	&:not(#epoq_filterresult) .tertiary-nav {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		border-bottom: 1px solid $color-gray-9;
		border-top: 0;
		box-sizing: border-box;
		font-size: $defaultFontSize;
		line-height: $defaultFontSize;
		height: 50px;
		background-color: $color-white;

		@media screen and (max-width: $desktop) {
			height: 50px;
			margin-top: -2px;
			order: 3;
			border-top: 1px solid $color-gray-9;
			border-bottom: 1px solid $color-gray-9;
		}

		@media screen and (min-width: calc($desktop + 1px)) {
			order: 0;
		}

		.breadcrumb {
			color: $color-gray-5;
			font-weight: 400;
			width: auto;
			max-width: 210px;
			flex: 0 0 210px;
			font-size: 13px;

			ul {
				margin-left: 1rem;
				padding: 0;
				max-width: 100%;

				li:first-child {
					text-transform: capitalize;
				}
			}

			@media screen and (min-width: calc($desktop-lg + 1px)) {
				flex: 0 0 260px;
				max-width: 260px;
			}

			@media screen and (min-width: 1640px) {
				flex: 0 0 290px;
				max-width: 290px;
			}

			@media screen and (min-width: 2000px) {
				flex: 0 0 350px;
				max-width: 350px;
			}
		}
	}

	@media (max-width: $desktop) {
		.standard-heading {
			padding: 60px 0 5px 0;
			font-size: 24px;
		}

		.breadcrumb {
			display: none;
		}
	}

	@media (max-width: $tablet) {
		.standard-heading {
			padding: 60px 0 5px 0;
		}
	}
}

.breadcrumb.breadcrumb-appear {
	@media screen and (min-width: calc($desktop + 1px)) {
		display: block !important; /* stylelint-disable-line declaration-no-important */
	}
}

.options-container .content {
	position: relative;
}

.filters {
	display: block;
	position: relative;
	clear: left;
	min-height: 50px;
	background: rgba(265, 265, 265, 0.9);

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	.view {
		position: absolute;
		top: 0;
		left: 0;
		padding: 10px 0 0 0;

		a {
			margin: 0 0 0 20px;
			color: $color-base;
			font-weight: 700;
			border-color: $color-base;
			padding: 8px 12px;
			transition: border-color 200ms ease, color 200ms ease;
			width: auto;

			&:hover {
				span {
					border-color: $color-gray-1;
					color: $color-gray-1;
				}
			}
		}
	}

	.categories {
		width: 100%;
		list-style: none;
		text-align: center;
		padding: 10px 0;
		box-sizing: border-box;

		li {
			text-align: center;
			margin: 8px 7px;
			font-size: $defaultFontSize;
		}

		a {
			text-decoration: none;
			text-transform: uppercase;
			color: $color-base;
			line-height: 1em;
			transition: 0.2s all;
			font-weight: 700;

			&:hover {
				color: $color-gray-1;
			}
		}

		a.active {
			color: $color-gray-1;

			span {
				border-width: 0 0 2px;
				border-style: solid;
				border-color: $color-gray-1;
			}
		}
	}

	li {
		display: inline-block;
	}

	.page-size {
		float: left;
		transition: all 0.2s;
		padding: 10px;
		text-align: center;
		font-size: $defaultFontSize;

		li {
			text-align: center;
			margin: 8px 7px;
			font-size: $defaultFontSize;
		}

		a {
			text-decoration: none;
			text-transform: uppercase;
			color: $color-base;
			line-height: 1em;
			transition: 0.2s all;
			font-weight: 700;

			&:hover {
				color: $color-gray-1;
			}
		}

		a.active {
			color: $color-gray-1;

			span {
				border-width: 0 0 2px;
				border-style: solid;
				border-color: $color-gray-1;
			}
		}

		.page-size-button {
			&::after {
				position: absolute;
				top: 2px;
				right: 0;
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 8px 6px 0 6px;
				border-color: $color-base transparent transparent transparent;
				transition: border-color 0.2s ease;
			}

			&:hover {
				&::after {
					border-color: $color-gray-1 transparent transparent transparent;
				}
			}
		}
	}

	.filter {
		float: right;
		transition: all 0.2s;
		margin-left: 6px;

		&:hover {
			.filter-button {
				color: $color-gray-1;
			}

			&:hover {
				.filter-button {
					color: $color-gray-1;
				}
			}
		}

		.filter-button {
			&::after {
				position: absolute;
				top: 2px;
				right: 0;
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 8px 6px 0 6px;
				border-color: $color-base transparent transparent transparent;
				transition: border-color 0.2s ease;
			}

			&:hover {
				&::after {
					border-color: $color-gray-1 transparent transparent transparent;
				}
			}
		}
	}

	.sort {
		float: right;
		transition: all 0.2s;

		&:hover {
			.sort-button {
				color: $color-gray-1;
			}

			&:hover {
				.sort-button {
					color: $color-gray-1;
				}
			}
		}

		.sort-button {
			&::after {
				position: absolute;
				top: 2px;
				right: 0;
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 8px 6px 0 6px;
				border-color: $color-base transparent transparent transparent;
				transition: border-color 0.2s ease;
			}

			&:hover {
				&::after {
					border-color: $color-gray-1 transparent transparent transparent;
				}
			}
		}
	}

	.options {
		li {
			text-align: center;
			font-size: $defaultFontSize;
			margin: 0;
		}

		a {
			text-decoration: none;
			text-transform: uppercase;
			color: $color-base;
			line-height: 1em;
			font-weight: 700;
			transition: border-color 200ms ease, color 200ms ease;
			width: auto;

			&:hover {
				color: $color-gray-1;
			}
		}

		a.active {
			color: $color-gray-1;
			position: relative;
			z-index: 1;

			span {
				border-width: 0 0 2px;
				border-style: solid;
				border-color: $color-gray-1;
			}
		}
		position: absolute;
		top: 0;
		right: 0;
		float: right;
		padding: 10px 20px;
		font-size: 0;
		box-sizing: border-box;

		ul {
			text-align: right;
			list-style: none;
		}

		.filter-button {
			margin: 8px 12px;
			border: none;
			text-decoration: none;
			text-transform: uppercase;
			display: inline-block;
			color: $color-base;
			font-size: $defaultFontSize;
			outline: none;
			padding: 0 16px 0 0;
			position: relative;
		}

		.sort-button {
			border: none;
			text-decoration: none;
			text-transform: uppercase;
			display: inline-block;
			color: $color-base;
			font-size: $defaultFontSize;
			outline: none;
			padding: 0 16px 0 0;
			position: relative;
			margin: 8px 16px;
		}

		.clear-filters-button {
			margin: 8px 12px;
			border: none;
			text-decoration: none;
			text-transform: uppercase;
			display: inline-block;
			color: $color-base;
			font-size: $defaultFontSize;
			outline: none;
			position: relative;
			border-color: $color-gray-1;
			border-style: solid;
			border-width: 0 0 0 1px;
			padding: 8px 10px 9px;

			span {
				display: none;
			}

			&::after {
				transition: border-color 0.2s ease;
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 12px 9px 0 9px;
				border-color: $color-base transparent transparent transparent;
			}
		}

		.clear-sort-button {
			margin: 8px 12px;
			border: none;
			text-decoration: none;
			text-transform: uppercase;
			display: inline-block;
			color: $color-base;
			font-size: $defaultFontSize;
			outline: none;
			position: relative;
			border-color: $color-gray-1;
			border-style: solid;
			border-width: 0 0 0 1px;
			padding: 8px 10px 9px;

			span {
				display: none;
			}

			&::after {
				transition: border-color 0.2s ease;
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 12px 9px 0 9px;
				border-color: $color-base transparent transparent transparent;
			}
		}

		.filter {
			&:hover {
				.clear-filters-button {
					border-color: $color-gray-1;
					color: $color-gray-1;
				}
			}
		}

		.sort {
			&:hover {
				.clear-sort-button {
					border-color: $color-gray-1;
					color: $color-gray-1;
				}
			}
		}

		.active {
			.clear-filters-button {
				border-color: $color-gray-1;
				color: $color-gray-1;
			}

			.clear-sort-button {
				border-color: $color-gray-1;
				color: $color-gray-1;
			}
		}

		.disabled {
			width: 0;
			padding: 0;
		}

		.clear {
			display: none;
			padding: 8px;
			margin-left: -4px;
			border-color: $color-gray-2;

			img {
				margin-top: -2px;
				vertical-align: middle;
			}
		}

		.clear.active {
			display: inline-block;
		}

		.clear-filters {
			display: none;
		}

		.clear-sort {
			display: none;
		}
	}

	.page-size-button {
		margin: 8px 12px;
		border: none;
		text-decoration: none;
		text-transform: uppercase;
		display: inline-block;
		color: $color-base;
		font-size: $defaultFontSize;
		outline: none;
		padding: 0 16px 0 0;
		position: relative;
	}

	.filter.active {
		&:hover {
			color: $color-gray-1;

			.filter-button {
				color: $color-gray-1;
			}
		}

		.filter-button {
			color: $color-gray-1;

			&::after {
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 6px 8px 6px;
				border-color: transparent transparent $color-base transparent;
			}

			&:hover {
				&::after {
					border-color: transparent transparent $color-gray-1 transparent;
				}
			}
		}
	}

	.sort.active {
		&:hover {
			color: $color-gray-1;

			.sort-button {
				color: $color-gray-1;
			}
		}

		.sort-button {
			color: $color-gray-1;

			&::after {
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 6px 8px 6px;
				border-color: transparent transparent $color-base transparent;
			}

			&:hover {
				&::after {
					border-color: transparent transparent $color-gray-1 transparent;
				}
			}
		}
	}

	.page-size.active {
		.page-size-button {
			&::after {
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 6px 8px 6px;
				border-color: transparent transparent $color-base transparent;
			}

			&:hover {
				&::after {
					border-color: transparent transparent $color-gray-1 transparent;
				}
			}
		}
	}

	.filter.filtered {
		.filter-button {
			span {
				border-width: 0 0 2px;
				border-style: solid;
				border-color: $color-gray-1;
			}
		}
	}

	.sort.filtered {
		.sort-button {
			span {
				border-width: 0 0 2px;
				border-style: solid;
				border-color: $color-gray-1;
			}
		}
	}
}

.filters.sticky {
	position: fixed;
	z-index: 400;
	left: 0;
	right: 0;
	border-width: 0 0 2px;
	border-color: rgba(188, 188, 188, 0.25);
	border-style: solid;
}

.filters.active {
	z-index: 400;
}

.filters.active.sticky {
	border-width: 0;
}

.grid {
	clear: both;

	&::after {
		clear: both;
		display: block;
		content: "";
	}
}

.grid-nav {
	max-width: 1680px;
	@media screen and (min-width: calc($desktop + 1px)) {
		position: relative;
		z-index: -1;
	}

	.side-nav {
		float: left;
		width: 5%;
		max-width: 85px;
		top: 50%;
		position: fixed;
		text-align: center;

		.mobile-view-picker {
			display: none;
		}

		ul {
			list-style: none;
			margin: 50px 0 0;
		}

		li {
			display: block;
			margin: 7px;
			text-align: center;

			a {
				display: block;
				font-weight: 700;
				color: $color-base;
				transition: color 0.2s linear;

				&::before {
					transition: color 0.2s linear;
					color: $color-gray-17;
					font-family: $font-secondary;
					font-weight: normal;
				}

				&:hover {
					&::before {
						color: $color-gray-1;
					}
				}

				span {
					display: none;
				}
			}

			a.active {
				&::before {
					color: $color-base;
				}
			}
		}

		a {
			display: block;
			font-weight: 700;
			color: $color-base;
			transition: color 0.2s linear;

			&::before {
				transition: color 0.2s linear;
				color: $color-gray-17;
				font-family: $font-secondary;
				font-weight: normal;
			}

			&:hover {
				&::before {
					color: $color-gray-1;
				}
			}

			span {
				display: none;
			}
		}

		a.active {
			&::before {
				color: $color-base;
			}
		}

		.button {
			-webkit-transform: rotate(90deg);
			-moz-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			-o-transform: rotate(90deg);
			transform-origin: 0 100%;
			width: 66px;
			position: absolute;
			left: 50%;
			margin-left: -16px;
			color: $color-gray-1;
			transition: all 0.2s;
			border: none;

			&:hover {
				display: block;
				color: $color-gray-2;
				border-color: $color-gray-2;
			}
		}
	}

	.choose-view {
		left: 0;

		li {
			a {
				font-weight: 400;
				font-size: 0.813em;
				transition: color 200ms ease;
			}
		}

		a {
			font-weight: 400;
			font-size: 0.813em;
			transition: color 200ms ease;
		}
	}

	.show-info {
		right: 0;

		a {
			&:hover {
				color: $color-gray-1;
			}
		}
	}

	.side-nav.show-info {
		a {
			padding: 8px 12px;
			color: $color-base;
			text-transform: uppercase;
		}
	}
}

@media (min-width: calc($tablet + 1px)) {
	.grid-nav {
		.side-nav {
			li {
				a[data-product-view="default-grid"] {
					&::before {
						content: "\e618";
						transition: color 200ms ease;
					}

					&:hover {
						&::before {
							color: $color-gray-1;
						}
					}
				}

				a[data-product-view="uniform-small"] {
					&::before {
						content: "\e602";
						transition: color 200ms ease;
					}

					&:hover {
						&::before {
							color: $color-gray-1;
						}
					}
				}

				a[data-product-view="uniform-large"] {
					&::before {
						content: "\e603";
						transition: color 200ms ease;
					}

					&:hover {
						&::before {
							color: $color-gray-1;
						}
					}
				}
			}
		}
	}
}

.mobile-sort-dropdown,
.mobile-page-size-dropdown {
	display: none;
}

@media (max-width: 1680px) {
	.filters .categories {
		padding-left: 26%;
		padding-right: 26%;
	}
}

@media (max-width: $desktop) {
	.options-container .content {
		padding: 0 20px;
	}

	.filters {
		position: fixed;
		top: 54px;
		width: 100%;
		left: 0;
		z-index: 50;

		.filter {
			margin-left: 10px;
		}

		.options {
			position: static;
			width: 100%;
			float: right;
			padding: 10px 0;

			.clear-filters {
				display: none;
			}

			.clear-sort {
				display: none;
			}
		}

		.categories {
			display: none;
		}

		.view {
			display: none;
		}

		.page-size {
			display: none;
		}
	}

	.grid-nav {
		margin: 0;

		.side-nav {
			ul {
				margin: 10px 0;
			}

			li {
				display: none;
			}

			.button {
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-ms-transform: rotate(0deg);
				-o-transform: rotate(0deg);
			}
		}
	}

	.show-info {
		display: none;
	}
}

@media only screen and (min-device-pixel-ratio: 1.5) and (max-width: $tablet),
	only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: $tablet) {
	.filters .options .filter-button,
	.filters .options .sort-button,
	.filters .options .page-size-button,
	.filters .options .clear-filters-button,
	.filters .options .clear-sort-button {
		font-size: 0.938rem;
	}

	.grid-nav .side-nav .mobile-view-picker a {
		font-size: 0.938rem;
	}
}

@media (max-width: $tablet) {
	.filters {
		top: 54px;

		.filter {
			margin-left: 6px;

			.filter-button {
				&::after {
					display: none;
				}
			}
		}

		.options {
			.filter-button {
				padding: 0;
				margin: 8px 0;
			}

			.sort-button {
				padding: 0;
				margin: 8px 0;
				display: none;
			}

			.page-size-button {
				padding: 0;
				margin: 8px 0;
			}

			.sort {
				float: none;
				width: 172px;
				margin: 0 auto;
			}

			a.active {
				span {
					border: none;
				}
			}
		}

		.sort {
			.sort-button {
				&::after {
					display: none;
				}
			}

			li {
				display: inline-block;
			}
		}

		.page-size {
			.page-size-button {
				&::after {
					display: none;
				}
			}

			a.active {
				span {
					border: none;
				}
			}
		}

		.filter.filtered {
			.current {
				span {
					border-width: 0 0 2px;
					border-style: solid;
					border-color: $color-gray-1;
				}
			}
		}

		.sort.filtered {
			.current {
				span {
					border-width: 0 0 2px;
					border-style: solid;
					border-color: $color-gray-1;
				}
			}
		}

		.categories {
			a.active {
				span {
					border: none;
				}
			}
		}
	}

	.grid-nav.mobile {
		.side-nav {
			a[data-product-view="uniform-small"] {
				&::before {
					content: "\e603" !important; /* stylelint-disable-line declaration-no-important */
				}
			}

			a[data-product-view="uniform-large"] {
				&::before {
					content: "\e604" !important; /* stylelint-disable-line declaration-no-important */
				}
			}
		}
	}

	.mobile-sort-dropdown {
		display: block;
		position: relative;
		min-width: 172px;

		.current {
			display: block;
			padding: 8px 16px;

			span {
				display: inline-block;
				margin: 0;
				position: relative;
			}
		}

		.current.active {
			border-width: 1px 1px 0;
			padding: 7px 16px 8px;
			border-style: solid;
			border-color: $color-base;
			background-color: $color-white;
		}

		.dropdown-holder {
			ul {
				margin: 0;
				min-width: 142px;
			}
		}

		.content {
			a {
				padding: 8px;
				text-decoration: none;
				text-transform: uppercase;
				display: block;
				color: $color-gray-17;
				background: $color-white;
				outline: none;
			}
		}

		ul {
			li {
				a.active {
					color: $color-base;
				}
			}
		}
	}

	.sort {
		.mobile-sort-dropdown {
			.content {
				top: 100%;
				overflow: hidden;
				position: absolute;
				border-width: 0 1px;
				border-color: $color-base;
				border-style: solid;
				margin: 0;
				padding: 0;
				width: calc(100% - 2px);
			}

			.dropdown-holder {
				li {
					display: block;
					text-align: left;
					border-width: 1px 0 0;
					border-color: $color-gray-17;
					border-style: solid;

					&:last-child {
						border-color: $color-gray-17 transparent $color-base;
						border-width: 1px 0;
					}
				}
			}
		}

		&:hover {
			.mobile-sort-dropdown {
				.content {
					border-color: $color-base;
				}

				.dropdown-holder {
					li {
						border-color: $color-gray-2;

						&:last-child {
							border-color: $color-gray-2 transparent $color-base;
						}
					}
				}
			}
		}
	}

	.filtered {
		.mobile-sort-dropdown {
			.current {
				span {
					border-width: 0 0 2px;
					border-style: solid;
					border-color: $color-gray-1;
				}
			}
		}
	}
}

@media only screen and (min-device-pixel-ratio: 1.5) and (max-width: $tablet),
	only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: $tablet) {
	.mobile-sort-dropdown,
	.sort .mobile-sort-dropdown .content,
	.mobile-sort-dropdown a {
		font-size: 0.938rem;
	}
}

@media (max-width: $mobile-lg) {
	.filters {
		.options {
			width: auto;
			margin: 0;

			.sort {
				float: left;
			}
		}

		.options-container {
			position: relative;
			left: 50%;
			float: left;

			.grid-nav {
				float: left;
				position: relative;
				left: -50%;
			}

			.options {
				float: left;
				position: relative;
				left: -50%;
			}
		}
	}

	.options-container {
		.content {
			max-width: 744px;
			padding: 0;
		}
	}

	.grid-nav {
		.side-nav {
			padding-left: 0;
			position: relative;

			.mobile-view-picker {
				position: static;
			}
		}
	}
}

.seo-image-block {
	background-position: top;
	background-repeat: no-repeat;

	&::after {
		content: "";
		clear: both;
		display: block;
	}

	.content {
		max-width: 1680px;
		margin: 0 auto;
		position: relative;

		.background {
			position: relative;

			img {
				width: 100%;
			}
		}

		.content {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}

	.copy-holder {
		width: 100%;
		padding: 100px;
		box-sizing: border-box;
		color: $color-gray-15;
		height: 100%;

		a {
			color: $color-gray-15;
			text-decoration: underline;
		}

		.scrollable-holder {
			height: 100%;
			position: relative;
		}

		> h1 {
			font-size: 0.75em;
			text-align: center;
			padding-bottom: 20px;
			text-transform: uppercase;
			font-weight: normal;
		}

		> h2 {
			font-size: 0.75em;
			color: $color-gray-15;
			font-weight: 400;
			text-align: center;
			text-transform: uppercase;
			padding-bottom: 20px;
		}

		p {
			font-size: 0.75em;
			padding-bottom: 20px;
			line-height: 1.125rem;
		}

		ul {
			margin-left: 1.3em;
			font-size: 0.75em;
			line-height: 1.125rem;
			padding-bottom: 20px;
		}
	}

	.copy-holder.float {
		width: 50%;
	}

	.scrollable-content {
		height: 100%;
		overflow: hidden;
	}

	.scrollbar {
		bottom: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 10px;
	}

	.track {
		bottom: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 10px;
	}

	.handle {
		height: 50px;
		position: absolute;
		width: 100%;
	}
}

@media (max-width: $desktop) {
	.seo-image-block .copy-holder {
		padding: 75px;
	}
}

@media (max-width: $tablet) {
	.seo-image-block {
		.background {
			display: none;
		}

		.content {
			position: relative;

			.content {
				position: relative;
			}
		}

		.copy-holder {
			width: 100%;
			padding: 40px 20px;
		}

		.copy-holder.float {
			width: 100%;
			padding: 40px 20px;
		}
	}
}

.product-pagination {
	display: block;
	position: relative;
	width: 100%;
	margin: 30px auto 60px;

	.total-pages {
		display: none;
	}

	.next {
		display: block;
		position: absolute;
		text-transform: uppercase;
		bottom: 0;
		left: auto;
		right: 20px;

		a {
			position: relative;
			padding: 0 12px 0 0;

			&::after {
				display: block;
				position: absolute;
				top: 1px;
				right: 0;
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5px 0 5px 6px;
				border-color: transparent transparent transparent $color-gray-17;
				transition: border-color 0.2s;
			}

			&:hover {
				&::after {
					border-color: transparent transparent transparent $color-gray-1;
				}
			}
		}

		span {
			display: block;
			padding: 0 0 1px;
		}

		.inactive {
			&:hover {
				&::after {
					border-color: transparent transparent transparent $color-gray-17;
				}
			}
		}
	}

	.prev {
		display: block;
		position: absolute;
		text-transform: uppercase;
		bottom: 0;
		left: 20px;
		right: auto;

		a {
			position: relative;
			padding: 0 0 0 12px;

			&::after {
				display: block;
				content: "";
				position: absolute;
				top: 1px;
				left: 0;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5px 6px 5px 0;
				border-color: transparent $color-gray-17 transparent transparent;
				transition: border-color 0.2s;
			}

			&:hover {
				&::after {
					border-color: transparent $color-gray-1 transparent transparent;
				}
			}
		}

		span {
			display: block;
			padding: 0 0 1px;
		}

		.inactive {
			&:hover {
				&::after {
					border-color: transparent $color-gray-17 transparent transparent;
				}
			}
		}
	}

	.inactive {
		color: $color-gray-17;
		cursor: default;

		&:hover {
			color: $color-gray-17;
			cursor: default;
		}

		a {
			&:hover {
				color: $color-gray-17;
				cursor: default;
			}
		}
	}

	ul {
		list-style-type: none;
		max-width: 400px;
		margin: 0 auto;
		text-align: center;
	}

	li {
		display: inline-block;
		text-align: center;
		padding: 0 3px;
	}

	a {
		text-decoration: none;
		padding: 2px;
		display: inline-block;
		color: $color-gray-17;
		text-transform: uppercase;
		font-size: 0.75em;
		line-height: 1em;
		transition: 0.2s all;
		font-weight: 700;

		&:hover {
			color: $color-gray-1;
		}
	}

	.active {
		a {
			color: $color-base;
		}
	}
}

.search {
	.product-pagination {
		display: block;
		position: relative;
		width: 100%;
		margin: 30px auto 60px;

		.next {
			display: block;
			position: absolute;
			text-transform: uppercase;
			bottom: 0;
			left: auto;
			right: 0;

			a {
				position: relative;
				padding: 0 12px 0 0;

				&::after {
					display: block;
					position: absolute;
					top: 1px;
					right: 0;
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 5px 0 5px 6px;
					border-color: transparent transparent transparent $color-gray-17;
					transition: border-color 0.2s;
				}

				&:hover {
					&::after {
						border-color: transparent transparent transparent $color-gray-1;
					}
				}
			}

			span {
				display: block;
				padding: 0 0 1px;
			}

			.inactive {
				&:hover {
					&::after {
						border-color: transparent transparent transparent $color-gray-17;
					}
				}
			}
		}

		.prev {
			display: block;
			position: absolute;
			text-transform: uppercase;
			bottom: 0;
			right: auto;
			left: 0;

			a {
				position: relative;
				padding: 0 0 0 12px;

				&::after {
					display: block;
					content: "";
					position: absolute;
					top: 1px;
					left: 0;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 5px 6px 5px 0;
					border-color: transparent $color-gray-17 transparent transparent;
					transition: border-color 0.2s;
				}

				&:hover {
					&::after {
						border-color: transparent $color-gray-1 transparent transparent;
					}
				}
			}

			span {
				display: block;
				padding: 0 0 1px;
			}

			.inactive {
				&:hover {
					&::after {
						border-color: transparent $color-gray-17 transparent transparent;
					}
				}
			}
		}

		.inactive {
			color: $color-gray-17;
			cursor: default;

			&:hover {
				color: $color-gray-17;
				cursor: default;
			}

			a {
				&:hover {
					color: $color-gray-17;
					cursor: default;
				}
			}
		}

		li {
			display: inline-block;
			text-align: center;
			padding: 0 3px;
		}

		a {
			text-decoration: none;
			padding: 2px;
			display: inline-block;
			color: $color-gray-17;
			text-transform: uppercase;
			font-size: 0.75em;
			line-height: 1em;
			transition: 0.2s all;
			font-weight: 700;

			&:hover {
				color: $color-gray-1;
			}
		}

		.active {
			a {
				color: $color-base;
			}
		}
	}

	.productpagination {
		ul {
			list-style-type: none;
			max-width: 400px;
			margin: 0 auto;
			text-align: center;
		}
	}
}

@media screen and (max-width: $desktop) {
	.product-pagination {
		.next {
			a {
				&::after {
					top: 22px;
				}
			}

			span {
				padding: 20px 3px 20px 20px;
			}
		}

		.prev {
			span {
				padding: 20px 20px 20px 3px;
			}

			a {
				&::after {
					top: 22px;
				}
			}
		}

		ul {
			padding-bottom: 17px;
		}
	}
}

@media (max-width: $tablet) {
	.product-pagination {
		box-sizing: border-box;
		padding: 18px 20px;
		text-align: center;

		.total-pages {
			display: inline-block;
			font-size: 0.75em;
			color: $color-gray-17;

			&::before {
				display: inline-block;
				content: "/";
				color: $color-base;
			}
		}

		ul {
			display: inline-block;
			text-align: center;
			padding: 0;
		}

		li {
			display: none;
			text-align: center;
			padding: 0;
		}

		a {
			padding: 0;
		}

		.active {
			display: block;
		}
	}

	.search {
		.product-pagination {
			ul {
				display: inline-block;
				text-align: center;
				padding: 0;
			}

			li {
				display: none;
				text-align: center;
				padding: 0;
			}

			a {
				padding: 0;
			}

			.active {
				display: block;
			}
		}
	}

	@media screen and (min-width: calc($desktop-lg + 1px)) {
		.autoscroll-left,
		.autoscroll-right {
			display: none;
		}
	}

	@media screen and (max-width: $tablet) {
		.autoscroll-left,
		.autoscroll-right {
			display: none !important; /* stylelint-disable-line declaration-no-important */
		}
	}
}

.tertiary-nav .categorySlug:not(:first) {
	@media screen and (min-width: calc($desktop + 1px)) {
		margin-left: 3px;
	}
}
