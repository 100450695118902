/*CART*/
.cart-page {
	border-width: 0 0 1px;
	border-color: $color-gray-6;
	border-style: solid;
	color: $color-gray-5;

	.minimum-order-value-error {
		color: $color-red-1;

		a {
			color: $color-red-1;
			text-decoration: underline;

			&:visited {
				color: $color-red-1;
				text-decoration: underline;
			}
		}
	}
}

.cart-page-content {
	.main-title {
		border-bottom: 1px solid $color-gray-6;
		margin: 0;
		background-color: $color-white;

		> span {
			font-weight: 700;
			font-size: 0.875em;
			line-height: 1rem;
			color: $color-gray-5;
			display: block;
			padding: 20px;
		}

		.delivery-notice {
			padding: 20px;
			border-top: 1px solid $color-gray-6;
			min-height: 55px;
			box-sizing: border-box;

			> p {
				max-width: 1680px;
				margin: 0 auto;
				font-size: 0.75rem;
				line-height: 0.875rem;
				font-weight: 400;
				color: $color-gray-5;

				&.error {
					color: $color-red-1;
				}
			}
		}
	}

	.notification {
		&__message {
			padding: 20px;
			font-size: 0.75em;

			@media (min-width: calc($desktop + 1px)) {
				max-width: 1100px;
				margin: 0 auto;
			}
		}

		&__border {
			display: none;
			border: none;
			border-bottom: 1px solid $color-gray-6;
		}

		.notification__message + .notification__border {
			display: block;
		}
	}

	.total-items {
		display: none;
		padding: 2px 8px;
		background-color: $color-gray-6;
		margin-left: 0.5rem;
		border-radius: 4rem;
		font-size: 0.857em;

		&.visible {
			display: inline-block;
		}
	}

	#emptyCart {
		width: 100%;
		border-bottom: 1px solid $color-gray-6;
		background-color: transparent;
		min-height: 432px;

		.empty-cart_content-wrapper {
			color: $color-gray-5;
			font-size: 0.875em;
			font-weight: 700;
			line-height: 1rem;
			padding: 20px;

			p {
				margin-bottom: 6px;
			}

			.continue-shopping {
				color: $color-gray-5;
				font-weight: 400;
				text-decoration: underline;
			}
		}
	}

	.cart {
		padding-bottom: 105px;
	}

	article {
		overflow: hidden;
		padding: 20px;
		display: flex;
		border-bottom: 1px solid $color-gray-6;

		&::after {
			clear: both;
			content: "";
			display: block;
		}

		&.deletedLineItems {
			position: relative;

			&::after {
				display: block;
				width: 4px;
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				background-color: $color-red-1;
			}

			.image-holder,
			.info-text,
			.info-row-bottom {
				opacity: 0.3;
			}
		}

		.title {
			display: inline;
			font-size: 1em;
			font-weight: 700;
			line-height: 1.143em;
			margin: 0;
			padding: 0;
			text-transform: none;
			display: -webkit-box; /* stylelint-disable-line */
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;

			a {
				color: $color-gray-5;
				transition: color 0.4s;

				&:hover {
					color: $color-gray-1;
				}
			}

			&::after {
				clear: both;
				content: "";
				display: block;
			}
		}

		dd {
			box-sizing: border-box;
		}

		dt {
			box-sizing: border-box;
		}

		.price {
			text-align: right;
			font-weight: 700;

			dd {
				&::before {
					display: inline;
				}
			}
		}

		.info {
			font-size: 0.875em;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;

			.price {
				dd {
					display: inline-block;
					padding: 0;
				}
			}

			> .info-row-top {
				display: flex;
				justify-content: space-between;

				> .info-text {
					display: flex;
					flex-direction: column;
					max-height: 141px;
					margin-right: 10px;
					min-width: 0;
				}
			}

			> .info-row-bottom {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
			}
		}

		.sale {
			&::before {
				display: inline;
			}
			color: $color-red-1;
		}

		.strikeout {
			color: $color-gray-5;
			text-decoration: line-through;
		}
	}

	.image-holder {
		height: 100%;

		img {
			width: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}

	.image {
		float: left;
		overflow: hidden;
		position: relative;
		width: 140px;
		height: 175px;
		background-color: $color-gray-18;
		border-radius: 6px;
	}

	.product-info {
		flex-grow: 1;
		padding-left: 10px;
		min-width: 0;
	}

	.color {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.size {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: flex;
		flex-direction: column;
	}

	.one-piece-left {
		font-size: 0.625rem;
		line-height: 1rem;
		color: $color-red-1;
	}

	.removed-item-placeholder {
		height: 216px;
		border-bottom: 1px solid $color-gray-6;
		transition: height 0.25s;
		overflow: hidden;
		padding: 0 20px;

		.placeholder-content {
			padding: 20px 0;

			p {
				font-size: 0.875em;
				font-weight: 700;
				line-height: 1em;
				margin-bottom: 6px;
			}

			button {
				border: none;
				background-color: transparent;
				padding: 0;
				margin: 0;
				font-size: 0.875em;
				line-height: 1em;
				text-decoration: underline;
				cursor: pointer;

				&:hover {
					color: $color-gray-1;
				}
			}
		}
	}

	.quantity-holder {
		.error {
			margin-bottom: 6px;
			display: inline-block;
		}
		dt {
			float: left;
			padding: 1px 0 0;
			line-height: 1.75rem;
		}

		dd {
			float: left;
			padding: 1px 0 0;
			line-height: 1.75rem;
		}

		ul {
			list-style: none;
		}

		li {
			float: left;

			a {
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				width: 15px;
				height: 15px;
				padding: 4px 0 5px;
				text-decoration: none;
				font-size: 12px;
				font-weight: 700;
				line-height: 16px;
				color: $color-gray-5;
				background-color: $color-gray-10;
			}

			span {
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;
				width: 15px;
				height: 15px;
				padding: 4px 0 5px;
				text-decoration: none;
				font-size: 12px;
				font-weight: 700;
				line-height: 16px;
				color: $color-gray-5;
				background-color: $color-gray-10;
			}
		}

		.quantity {
			> span {
				width: 17px;
				padding-left: 4px;
				padding-right: 4px;
			}
		}

		.minus {
			a {
				span {
					display: none;
				}
				border-radius: 4px 0 0 4px;
				padding-left: 5px;

				&::after {
					font-family: $font-secondary;
					color: $color-gray-5;
					font-size: 0.4rem;
					content: "\e61c";
				}
			}

			a.disabled {
				cursor: default;
				opacity: 1;
				visibility: visible;
				color: $color-gray-7;

				&::after {
					color: $color-gray-7;
				}
			}
		}

		.plus {
			a {
				span {
					display: none;
				}
				border-radius: 0 4px 4px 0;
				padding-right: 5px;

				&::after {
					font-family: $font-secondary;
					content: "\e61b";
					color: $color-gray-5;
					font-size: 0.4rem;
				}
			}

			a.disabled {
				cursor: default;
				opacity: 1;
				visibility: visible;
				color: $color-gray-7;

				&::after {
					color: $color-gray-7;
				}
			}
		}
	}

	.remove {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $color-gray-10;
		transition: 0.2s all;
		padding: 5px;

		> p {
			color: $color-gray-5;
			font-size: 1.65rem;
			margin-top: -4px;
		}

		span {
			display: none;
		}

		&:hover {
			color: $color-base;
		}
	}

	.promo-code-area {
		max-width: 1100px;
		margin: 0 auto;
		padding: 42px 20px;
		background-color: $color-white;
		min-height: 24px;
	}

	.promo-code-holder {
		display: flex;
		flex-direction: column;
		position: relative;
	}

	.promo-code {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 300px;
		align-self: flex-end;

		&__messages {
			box-sizing: border-box;
			width: 100%;

			.error-message {
				display: block;
				width: auto;
				margin: 5px;
				padding: 0;
				border-width: 0;
				color: $color-red-1;
				font-size: 0.75rem;
				line-height: 14px;
				box-sizing: border-box;

				&.is-hidden {
					display: none;
				}
			}
		}

		&__cta {
			position: absolute;
			right: 0;
			top: 0;
			border: 0;
			display: flex;
			align-items: center;
			min-height: 54px;
			color: $color-gray-5;

			p {
				font-size: 0.875rem;
				line-height: 1rem;
				font-weight: 700;
				margin-right: 10px;
			}

			&.is-hidden {
				display: none;
			}
		}

		&__cta-btn {
			width: 24px;
			height: 24px;
			background-color: $color-gray-10;
			content: "";
			font-weight: 700;
			cursor: pointer;
			border: none;
			padding: 0;

			&::after {
				content: "\e61b";
				font-family: $font-secondary;
				width: 24px;
				height: 24px;
				color: $color-gray-5;
				font-size: 0.563rem;
			}
		}

		&__fieldset {
			position: relative;
			display: flex;
			width: 100%;
			border: 1px solid $color-gray-1;
			border-radius: 5px;

			&.is-hidden {
				display: none;
			}
		}

		&__input {
			width: 100%;
			min-height: 54px;
			box-sizing: border-box;
			padding: 16px 15px;
			border: none;
			background: transparent;
			-webkit-appearance: none;
			font-size: 0.75rem;
			line-height: 14px;
			margin: 1px;

			&:focus {
				background-color: $color-white;
			}
		}

		&__btn-apply,
		&__btn-remove,
		&__btn-clear {
			display: none;
			border: none;
			padding: 0;
			margin-right: 15px;
			background: none;
			color: $color-gray-5;
			cursor: pointer;
			width: auto;
			right: 0;
			top: 0;
			float: right;
			font-size: 0.75rem;
			line-height: 14px;
			font-weight: 700;
			text-align: right;

			&:focus {
				outline: none;
			}

			&.is-active {
				display: inline-block;
			}
		}

		&__btn-remove {
			display: none;
		}

		&.verified .promo-code__btn-remove {
			display: inline-block;
		}

		&.error {
			.promo-code__fieldset {
				border-color: $color-red-1;
			}

			.promo-code__input {
				color: $color-red-1;

				&::-webkit-input-placeholder {
					color: $color-red-1;
				}

				&:-moz-placeholder {
					color: $color-red-1;
				}

				&:-ms-input-placeholder {
					color: $color-red-1;
				}
			}
		}
	}

	.total-area {
		width: 100%;
		background-color: $color-white;
		@media (max-width: $desktop) {
			border-top: 1px solid $color-gray-9;
		}
	}

	.subtotal-holder {
		dl {
			padding: 40px 0;
			font-size: 0.875rem;
			text-align: right;
			line-height: 1.875rem;
		}

		dd {
			display: inline-block;
			font-size: 0.875rem;
			line-height: 1rem;
			min-width: 58px;
			text-align: right;
			color: $color-gray-5;

			span {
				&::before {
					font-size: 0.875rem;
					line-height: 1rem;
				}
			}
		}

		dt {
			display: inline-block;
			font-size: 0.875rem;
			line-height: 1rem;
			margin-right: 6px;
			color: $color-gray-5;
		}
		background-color: $color-white;
		border-top-width: 1px;
		border-top-style: solid;
		border-top-color: $color-gray-9;
	}

	.subtotal-holder__inner-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		max-width: 1100px;
		padding: 20px;
		margin: 0 auto;

		.discounts {
			font-weight: 700;
		}
	}

	.total {
		font-weight: 700;
		color: $color-gray-5;
		padding: 16px 20px 20px;
		text-align: right;
		max-width: 1100px;
		margin: 0 auto;

		p.disabled {
			opacity: 0;
			visibility: hidden;
		}

		p.error {
			color: $color-red-1;
		}

		dt {
			display: inline-block;
		}

		dd {
			display: inline-block;
			color: $color-gray-5;
		}
	}

	.total.error {
		color: $color-red-1;

		dd {
			color: $color-red-1;
		}
	}

	.subtotal.error {
		color: $color-red-1;

		dd {
			color: $color-red-1;
		}
	}

	.submit-holder {
		margin: 0 auto;
		padding: 20px 0 40px;
		width: 100%;
		font-size: 0.875rem;
		border-bottom-width: 1px;
		border-bottom-style: solid;
		border-bottom-color: $color-gray-9;

		ul {
			list-style: none;
			position: relative;
			display: flex;
			align-items: center;
			flex-direction: column;
		}
	}

	.checkout-button {
		text-align: center;
		color: $color-gray-18;

		a {
			background: $color-blue-1;
			border-radius: 4px;
			color: $color-white;
			cursor: pointer;
			display: inline-block;
			font-size: 1em;
			padding: 17px 0;
			width: 335px;
			transition-property: color, background-color;
			transition-duration: 0.2s;
			margin-bottom: 20px;

			&:hover {
				background-color: $color-blue-2;
				color: $color-white;
			}
		}
	}

	.checkout-button.disabled {
		a {
			background-color: $color-gray-10;
			color: $color-gray-7;
			pointer-events: none;
			border-color: $color-gray-10;
		}
	}

	.minimum-order-value-error {
		font-size: 0.75rem;
		line-height: 0.875rem;
		padding: 20px;
		border-bottom-width: 1px;
		border-bottom-style: solid;
		border-bottom-color: $color-gray-9;

		.inner-container {
			max-width: 1100px;
			width: 100%;
			margin: 0 auto;
		}
	}

	.minimum-order-value-error.disabled {
		display: none;
	}
}

.country_eur {
	&::before {
		font-size: 1em;
	}
}

#epoq-widget-cart {
	margin: 0 auto;
	padding-left: 20px;

	.title {
		font-size: 1em;
	}
}

@media (min-width: calc($desktop + 1px)) {
	.cart-page-content {
		.main-title {
			> span {
				max-width: 1100px;
				margin: 0 auto;
			}

			.delivery-notice {
				> p {
					max-width: 1100px;
					margin: 0 auto;
				}

				> p.error {
					font-size: 1rem;
					color: $color-red-1;
				}
			}
		}

		.empty-cart_content-wrapper {
			max-width: 1100px;
			margin: 0 auto;
		}

		article {
			max-width: 1100px;
			margin: 0 auto;

			&.deletedLineItems::after {
				width: 2px;
				top: 20px;
				bottom: 20px;
			}
		}

		.removed-item-placeholder {
			max-width: 1100px;
			margin: 0 auto;
		}

		#epoq_cart_widget--is-styled {
			.section-title {
				text-align: left;
				padding: 10px 0 30px;
				font-size: 0.875rem;
				line-height: 1rem;
			}
		}
	}
}

@media (max-width: $desktop) {
	.total-area {
		position: sticky;
		bottom: 0;
	}

	#epoq-widget-cart {
		width: 100%;
		max-width: none;
		overflow-x: hidden;
	}

	.cart-page-content {
		.quantity-holder {
			dt {
				span {
					display: none;
				}
			}
		}
	}
}

@media (max-width: $tablet) {
	.cart-page-content {
		.cart {
			padding-bottom: 60px;
		}

		article {
			min-height: 125px;
		}

		.checkout {
			background: $color-white;
			text-align: center;
			width: 100%;
		}

		.checkout.scrolling {
			border-width: 0;
		}
	}
}

@media (max-width: $mobile-lg) {
	.cart-page-content {
		.checkout {
			background: $color-gray-10;
			position: static;

			a {
				display: block;
				max-width: 350px;
				margin: 0 auto;
			}
		}

		& section.promo-code-area {
			padding: 20px;
		}

		.promo-code {
			width: 100%;
			align-items: unset;
			align-self: unset;

			&__cta {
				min-height: unset;
				justify-content: space-between;
				width: 100%;
				position: relative;
				right: unset;
				top: unset;
			}
		}

		.subtotal-holder__inner-wrapper {
			> div {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
			}
		}

		.submit-holder {
			padding: 0 20px 40px;
			width: auto;
		}

		.checkout-button {
			width: 100%;

			a {
				width: 100%;
			}
		}

		.subtotal-holder {
			dl {
				line-height: 1.25rem;
				padding: 18px 0;
			}
		}

		article {
			min-height: 120px;
		}

		.image {
			width: 80px;
			height: 120px;
		}

		#emptyCart {
			min-height: 416px;
		}
	}
}
