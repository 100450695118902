/*---------------------------------------------*/
/* Standard Promo - Inline image version
/*---------------------------------------------*/
.standard-promo {
	picture {
		display: block;
	}

	a {
		display: block;
	}

	.content {
		width: 100%;
		max-width: 1920px;
		margin: 0 auto;
	}

	> .content {
		&::after {
			content: "";
			display: table;
			clear: both;
		}
	}
}

.standard-promo[data-items="1"] {
	.standard-promo-item {
		width: 100%;
	}
}

.standard-promo[data-items="2"] {
	.standard-promo-item {
		width: 50%;
	}
}

.standard-promo[data-items="3"] {
	.standard-promo-item {
		width: 33.333333%;
	}
}

.standard-promo[data-video="true"] {
	.standard-promo-item {
		width: 100%;
	}

	.inner {
		opacity: 0;
		visibility: hidden;
		transition: opacity 200ms ease, visibility 200ms ease;
	}

	.inner.active {
		opacity: 1;
		visibility: visible;
	}
}

.standard-promo[data-video="true"].mobile {
	.standard-promo-item {
		display: none;
	}
}

.standard-promo-item {
	position: relative;
	float: left;
	width: 100%;
	overflow: hidden;
	height: 100%;

	picture {
		height: 100%;
	}

	img {
		height: 100%;
		width: 100%;
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
		visibility: hidden;
		transition: opacity 200ms ease, visibility 200ms ease;
	}

	&:hover {
		&::after {
			opacity: 1;
			visibility: visible;
			pointer-events: none;
		}
	}

	.link {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10;

		> .cta {
			position: absolute;
			left: 50%;
			min-width: 80px;
			text-align: center;
			transform: translateX(-50%);
			z-index: 10;
		}
	}

	.inner {
		position: absolute;
		left: 50%;
		min-width: 80px;
		text-align: center;
		transform: translateX(-50%);
		z-index: 10;
		max-width: 90%;
	}

	.title {
		font-size: 1.125em;
		font-weight: 700;
		text-align: center;
		transition: color 200ms ease, border-color 200ms ease;
	}

	.detail {
		max-width: 610px;
		font-size: 1em;
		line-height: 1.5rem;
		margin: 30px auto 0 auto;
		text-align: center;
		transition: color 200ms ease, border-color 200ms ease;
	}

	.options {
		list-style: none;
		margin-top: 44px;

		li {
			display: inline-block;
			margin: 0 0 10px 0;
		}
	}

	video {
		width: 100%;
		height: auto;
		vertical-align: top;
	}
}

.standard-promo-item[data-colour="dark"] {
	&::after {
		background: rgba(38, 38, 38, 0.7);
	}

	&:hover {
		.title {
			color: $color-white;
			border-color: $color-white;
		}

		.detail {
			color: $color-white;
			border-color: $color-white;
		}
	}

	.title {
		color: $color-base;
		border-color: $color-base;
	}

	.detail {
		color: $color-base;
		border-color: $color-base;
	}
}

.standard-promo-item[data-colour="light"] {
	&::after {
		background: rgba(255, 255, 255, 0.7);
	}

	.title {
		color: $color-white;
		border-color: $color-white;
	}

	.detail {
		color: $color-white;
		border-color: $color-white;
	}

	&:hover {
		.title {
			color: $color-base;
			border-color: $color-base;
		}

		.detail {
			color: $color-base;
			border-color: $color-base;
		}
	}
}

.standard-promo-item[data-vertical-align="top"] {
	.inner {
		top: 40px;
	}

	.link {
		> .cta {
			top: 40px;
		}
	}
}

.standard-promo-item[data-vertical-align="middle"] {
	.inner {
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.link {
		> .cta {
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.standard-promo-item[data-vertical-align="middle-bottom"] {
	.inner {
		bottom: 20%;
	}

	.link {
		> .cta {
			bottom: 20%;
		}
	}
}

.standard-promo-item[data-vertical-align="bottom"] {
	.inner {
		bottom: 40px;
	}

	.link {
		> .cta {
			bottom: 40px;
		}
	}
}

.standard-promo-item[data-horizontal-align="left"] {
	.title {
		text-align: left;
	}

	.detail {
		text-align: left;
		margin-left: 0;
	}

	.inner {
		text-align: left;
	}

	.link {
		> .cta {
			text-align: left;
		}
	}
}

.standard-promo-item[data-horizontal-align="right"] {
	.title {
		text-align: right;
	}

	.detail {
		text-align: right;
		margin-right: 0;
	}

	.inner {
		text-align: right;
	}

	.link {
		> .cta {
			text-align: right;
		}
	}
}

@media (max-width: $desktop) {
	.standard-promo-item {
		.title {
			font-size: 2em;
		}

		.detail {
			font-size: 1em;
			margin-top: 20px;
		}

		.options {
			list-style: none;
			margin-top: 24px;

			li {
				display: inline-block;
			}
		}
	}

	.standard-promo-item[data-vertical-align="bottom"] {
		.inner {
			bottom: 20px;
		}
	}
}

@media (max-width: $tablet) {
	.standard-promo-item {
		img {
			object-fit: cover;
		}

		&:hover {
			&::after {
				opacity: 0;
				visibility: hidden;
			}
		}

		.title {
			font-size: 1.875em;
			line-height: 3.25rem;
		}

		.detail {
			font-size: 1.125em;
		}

		.button {
			font-size: 1em;
		}
	}

	.standard-promo[data-items="2"] {
		.standard-promo-item {
			width: 100%;
		}
	}

	.standard-promo[data-items="3"] {
		.standard-promo-item {
			width: 100%;
		}
	}

	.standard-promo-item[data-vertical-align="bottom"] {
		.inner {
			bottom: 40px;
		}
	}

	.standard-promo-item[data-colour="dark"] {
		&:hover {
			.title {
				color: $color-base;
				border-color: $color-base;
			}

			.detail {
				color: $color-base;
				border-color: $color-base;
			}
		}
	}

	.standard-promo-item[data-colour="light"] {
		&:hover {
			.title {
				color: $color-white;
				border-color: $color-white;
			}

			.detail {
				color: $color-white;
				border-color: $color-white;
			}
		}
	}
}

@media (max-width: 560px) {
	.standard-promo-item {
		.title {
			font-size: 1.125em;
			line-height: 1.25em;
		}

		.detail {
			font-size: 1em;
			line-height: 1.25em;
			margin-top: 10px;
			display: none;
		}

		.button {
			font-size: 1em;
		}

		.options {
			list-style: none;
			margin-top: 14px;

			li {
				margin: 0 0 20px;
				display: block;

				&:last-child {
					margin: 0 0 10px;
				}
			}
		}
	}

	.standard-promo-item[data-vertical-align="bottom"] {
		.inner {
			bottom: 20px;
		}
	}
}

@media (max-width: $mobile) {
	.standard-promo-item {
		float: none;
	}

	.standard-promo[data-items="2"] {
		.standard-promo-item {
			width: 100%;
		}
	}
}

@media only screen and (min-device-pixel-ratio: 1.5) and (max-width: $tablet),
	only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: $tablet) {
	.standard-promo-item {
		.inner {
			font-size: 1rem;
			line-height: 1.5rem;
			padding: 11px 0 10px;
			min-width: 170px;
		}

		.link {
			> .cta {
				font-size: 1rem;
				line-height: 1.5rem;
				padding: 11px 0 10px;
				min-width: 170px;
			}
		}
	}
}
