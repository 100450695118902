.error-page {
	padding: 30px 0;
	min-height: calc(70vh - 60px);
	color: $color-blue-1;
	background: url(../placeholders/404/background.jpg) center 60px repeat-x;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.content {
		max-width: 1680px;
		margin: 0 auto;
		width: 87.34%;

		&::after {
			clear: both;
			content: "";
			display: block;
		}
	}

	h1 {
		color: $color-base;
		font-size: 1.875em;
		line-height: 2.375rem;
		font-weight: 700;
		text-align: center;
		margin: 0 auto 20px;
	}

	p {
		color: $color-base;
		width: 50%;
		font-size: 1em;
		line-height: 1.5rem;
		margin: 0 auto 15px;
		text-align: center;
	}

	ul {
		list-style: none;
		margin: 0 auto;

		&::after {
			clear: both;
			content: "";
			display: block;
		}
	}

	li {
		float: left;
		margin: 0 10px 20px 0;
	}

	.cta {
		background: $color-blue-1;
		color: $color-white;
		padding: 12px 0 11px;
		font-size: 0.875em;
		width: 120px;
		text-align: center;
		display: block;
		border-radius: 4px;
		font-weight: 700;
		transition: 0.4s background-color;

		&:hover {
			background-color: $color-blue-2;
		}
	}
}

@media screen and (max-width: $tablet) {
	.error-page {
		background-position: center 60px;
		background-image: url(../placeholders/404/background-mob.jpg);

		h1 {
			font-size: 1.5rem;
			line-height: 1.875rem;
			padding: 0 20px;
			margin: 50px auto;
		}

		p {
			width: calc(100% - 40px);
			padding: 0 20px;
		}
	}
}

@media screen and (max-width: $mobile) {
	.error-page {
		ul {
			text-align: center;
			margin: 0 0 40px;
		}

		li {
			display: block;
			float: none;
		}

		a {
			margin: 0 auto;
		}
	}
}

@media only screen and (min-device-pixel-ratio: 1.5) and (max-width: $tablet),
	only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: $tablet),
	only screen and (-webkit-min-device-pixel-ratio: 1.5),
	only screen and (min-device-width: $tablet) and (max-device-width: $desktop) and (-webkit-min-device-pixel-ratio: 1) {
	.error-page {
		.cta {
			font-size: 1rem;
			line-height: 1.5rem;
			padding: 11px 0 10px;
			width: 170px;
		}
	}
}
