.standard-video-player {
	display: block;
	overflow: hidden;

	.video-player {
		transition: 0.5s all;
		opacity: 0;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.content {
		position: relative;
		margin: 0 auto;
		text-align: center;
	}

	.close {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 20px;
		height: 20px;
		font-size: 1.25em;
		color: $color-white;
		text-decoration: none;
		z-index: 1;
		font-weight: normal;
		transition: 0.2s all;

		&:hover {
			color: $color-gray-13;
		}

		&::after {
			font-family: $font-secondary;
			content: "\e605";
		}

		span {
			display: none;
		}
	}

	.video-placeholder {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: all 350ms 100ms ease;
		z-index: 4;

		.play {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 40px;
			height: 40px;
			background: $color-white;
			font-size: 0;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			transition: background 200ms ease;

			&::after {
				font-family: $font-secondary;
				content: "\e606";
				position: absolute;
				font-size: 0.625rem;
				top: 50%;
				left: 50%;
				color: $color-base;
				margin-left: -3px;
				margin-top: -5px;
			}

			&:hover {
				background: $color-gray-11;
			}
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	video {
		width: 100%;
		height: auto;
	}

	.controls-container {
		position: absolute;
		bottom: 60px;
		left: 50%;
		width: 250px;
		margin-left: -125px;
	}
}

.standard-video-player.full-screen {
	.video-player {
		z-index: 10000;
		opacity: 1;
	}
}

.standard-video-player[data-max-width="full"] {
	.content {
		max-width: 100%;
	}
}

.standard-video-player[data-max-width="1680"] {
	.content {
		max-width: 1680px;
	}
}

.standard-video-player[data-max-width="1000"] {
	.content {
		max-width: 1000px;
	}
}

.standard-video-player.active {
	.video-placeholder {
		opacity: 0;
		visibility: hidden;
	}
}

.controls {
	.handle {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 40px;
		height: 40px;
		background: $color-white;
		font-size: 0;
		border-radius: 50%;
		transform: translate(-50%, -50%);
		transition: background 200ms ease;

		&::after {
			font-family: $font-secondary;
			content: "\e606";
			position: absolute;
			font-size: 0.625rem;
			top: 50%;
			left: 50%;
			color: $color-base;
			margin-left: -3px;
			margin-top: -5px;
		}

		&:hover {
			background: $color-gray-11;
		}
	}

	.play-button {
		display: none;
	}
}

[data-colour="light"] {
	.standard-video-player {
		.video-placeholder {
			.play {
				background: $color-base;

				&::after {
					color: $color-white;
				}

				&:hover {
					background: $color-black;
				}
			}
		}
	}
}

.progress-bar {
	position: relative;
	height: 3px;
	background: $color-white;

	.progress {
		position: absolute;
		top: 0;
		left: 0;
		height: 3px;
		background: $color-gray-12;
		z-index: 2;
	}

	.loaded {
		position: absolute;
		top: 0;
		left: 0;
		height: 3px;
		background: $color-gray-13;
	}

	.hitarea {
		position: absolute;
		top: -3px;
		left: 0;
		width: 100%;
		height: 8px;
		z-index: 3;
		cursor: pointer;
	}

	.handle {
		position: absolute;
		top: 1px;
		left: auto;
		z-index: 3;
		cursor: pointer;
	}

	.handle.active {
		&::after {
			content: "\e607";
			font-size: 0.75rem;
			margin-left: -2px;
			-webkit-font-smoothing: antialiased;
		}
	}
}

@media (max-width: $tablet) {
	.standard-video-player {
		.close {
			padding: 0 10px;
			font-size: 40px;
		}

		.controls-container {
			bottom: 30px;
		}
	}
}
