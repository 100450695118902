/*---------------------------------------------*/
/* Reset Password
/*---------------------------------------------*/
.reset-password {
	padding-top: 60px;

	form {
		font-size: 0.875em;
	}

	.field {
		text-align: left;

		&__description {
			display: inline-block;
			font-size: 0.75rem;
			color: $color-gray-5;
			margin: 20px 0 20px 20px;
		}
	}
}

.centered-column {
	width: 350px;
	margin: 0 auto;
	padding-top: 80px;
	text-align: center;
}

@media only screen and (max-width: $mobile-lg) {
	.reset-password {
		padding-left: 20px;
		padding-right: 20px;
	}

	.centered-column {
		width: 100%;
	}
}

/*---------------------------------------------*/
/* Complete Message Page
/*---------------------------------------------*/
.complete-message {
	max-width: 700px;
	margin: 0 auto;
	padding: 80px 20px 60px 20px;
	text-align: center;

	.content {
		padding-top: 80px;
	}

	.standard-heading {
		padding-bottom: 30px;
	}

	p {
		padding: 0 0 50px 0;
	}
}
