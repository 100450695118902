.has-edit-button {
	position: relative;
}

.containerFrame {
	font-size: 11px;
	text-align: center;
	color: $color-red-4;
	padding: 5px;
	text-decoration: solid;
	border: solid;
	border-color: $color-red-4;
}

.hst-fab {
	display: block !important; /* stylelint-disable-line declaration-no-important */
}
