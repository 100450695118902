.history-page {
	background: $color-base;
	color: $color-white;
	padding: 60px 0 0;

	.breadcrumb {
		a {
			text-decoration: none;

			&:hover {
				color: $color-white;
			}
		}
	}

	h1 {
		text-align: center;
		padding: 30px 0;

		img {
			width: 165px;
		}
	}

	.timeline {
		position: relative;

		&::after {
			clear: both;
			content: "";
			display: block;
		}

		&::before {
			position: absolute;
			display: block;
			left: 50%;
			content: "";
			background: $color-blue-1;
			width: 2px;
			margin-left: -1px;
			height: 100%;
		}
	}

	article.disabled {
		padding-top: 200px;
		opacity: 0;
	}

	article {
		position: relative;
		margin: 0 auto;
		transition: all 1s;
		padding-top: 0;
		opacity: 1;

		p {
			font-size: 1em;
			line-height: 1.5rem;
			padding: 10px 0 14px;
			margin: 0 auto;
			background: $color-base;
			max-width: 840px;

			&:last-child {
				padding: 10px 0;
			}
		}

		img {
			width: 100%;

			& + .copy {
				padding: 15px 0 0;
			}
		}

		.title {
			padding: 12px 0;
			text-align: center;
			background: $color-base;
			clear: both;
		}

		.copy {
			background: $color-base;
			padding: 10px 0 0;
		}

		em {
			color: $color-gray-1;
		}
	}

	.center {
		clear: both;

		.inner {
			margin: 0 auto 150px;
		}
	}

	a {
		color: $color-white;
		text-decoration: underline;
		transition: 0.4s color;

		&:hover {
			color: $color-gray-17;
		}
	}

	article.center {
		.copy {
			padding: 0 20px 0;
		}

		picture {
			& + .copy {
				padding: 10px 20px 0;
			}
		}

		p {
			&:last-child {
				padding: 10px 0;
			}
		}

		p.pullquote {
			font-size: 3.125em;
			line-height: 4.375rem;
			font-weight: 700;
			font-style: italic;
			width: 87.34%;
			max-width: 1680px;
			padding: 0 0 10px;
		}
	}

	.top {
		position: relative;
		z-index: 1;
		margin: 0 auto 150px;

		.title {
			margin: 0 auto 30px;
		}
	}

	.title {
		margin: 0 auto;
		font-size: 0.875em;
		line-height: 1.5rem;
		width: 120px;
		font-weight: 700;
	}

	.left {
		margin: 0 auto;
		float: left;
		width: 50%;
		clear: left;

		&::after {
			clear: both;
			content: "";
			display: block;
		}

		.title {
			position: absolute;
			top: 60px;
			left: auto;
			right: -60px;
		}

		.inner {
			box-sizing: border-box;
			padding: 0 60px 0 0;
			margin: 0 0 75px 6.33%;
			width: 87.43%;
			max-width: 840px;
			float: right;
		}
	}

	.right {
		margin: 0 auto;
		width: 50%;
		float: right;
		clear: right;

		&::after {
			clear: both;
			content: "";
			display: block;
		}

		.title {
			position: absolute;
			top: 60px;
			left: -60px;
		}

		.inner {
			box-sizing: border-box;
			width: 87.43%;
			max-width: 840px;
			float: left;
			padding: 0 0 0 60px;
			margin: 0 6.33% 75px 0;
		}
	}

	.left.small {
		.inner {
			width: 67.43%;
			margin: 0 0 75px 26.33%;
		}
	}

	.right.small {
		.inner {
			width: 67.43%;
			margin: 0 0 75px 26.33%;
		}
	}

	span {
		color: $color-gray-17;
		font-weight: 700;
	}

	.standard-video-player {
		.video-placeholder {
			position: relative;
		}

		.video-player {
			position: absolute;
		}

		.video-player.active {
			opacity: 1;
			z-index: 5;
			transition: opacity 0.4s;
		}
	}

	.today {
		position: relative;
		z-index: 2;
		clear: both;
		text-align: center;
		background: $color-base;
		padding: 10px 0 155px;
	}
}

.touch {
	.history-page {
		h1 {
			text-align: center;
			padding: 50px 0;
		}
	}
}

@media (max-width: $tablet) {
	.history-page {
		padding: 60px 0 0;

		article {
			margin: 0 0 40px;

			.copy {
				padding: 10px 20px 0;
			}
		}

		article.center {
			.copy {
				padding: 10px 20px 0;
			}

			p.pullquote {
				font-size: 1.875em;
				line-height: 2.5rem;
			}
		}

		.top {
			margin: 0 0 40px;

			.title {
				margin: 0 auto 40px;
			}
		}

		.left {
			float: none;
			width: 100%;

			.title {
				position: static;
			}

			.inner {
				width: 100%;
				padding: 0;
				margin: 0 0 40px;
			}
		}

		.right {
			float: none;
			width: 100%;

			.title {
				position: static;
			}

			.inner {
				width: 100%;
				padding: 0;
				margin: 0 0 40px;
			}
		}

		.left.small {
			.inner {
				width: 100%;
			}
		}

		.right.small {
			.inner {
				width: 100%;
			}
		}

		.center {
			.inner {
				margin: 0;
			}
		}
	}

	.touch {
		.history-page {
			.breadcrumb {
				display: none;
			}
		}
	}
}
