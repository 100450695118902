.cart-info {
	$blue: $color-blue-1;
	$message-arrow-size: 0.75rem;

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		right: 1rem;
		border: $message-arrow-size solid transparent;
		border-bottom-width: 0;
		border-bottom-color: $blue;
	}

	&__count {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
		@media screen and (min-width: calc($desktop + 1px)) {
			top: 7px;
			right: 9px;
		}
		color: $color-white;
		background-color: $blue;
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 0.75rem;
		pointer-events: none;

		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border: 1rem solid $blue;
			z-index: -1;
			border-radius: 50%;
			opacity: 0;
		}

		&:empty {
			display: none;
		}
	}

	&__message {
		position: absolute;
		right: 0;
		left: 0;
		z-index: 999;
		overflow: hidden;
		height: 0;
		width: 100%;
		color: $color-white;
		background-color: $blue;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&.show-message {
		&::after {
			animation: 2s show-arrow;
		}

		.cart-info__count::before {
			animation: 2s blink-count;
		}

		.cart-info__message {
			animation: 2s show-message;
		}
	}

	&__wrapper {
		position: relative;
	}
}

@keyframes show-arrow {
	0% {
		border-bottom-width: 0;
	}

	10% {
		border-bottom-width: $message-arrow-size;
	}

	90% {
		border-bottom-width: $message-arrow-size;
	}

	100% {
		border-bottom-width: 0;
	}
}

@keyframes show-message {
	0% {
		height: 0;
	}

	10% {
		height: $header-height;
	}

	90% {
		height: $header-height;
	}

	100% {
		height: 0;
	}
}

@keyframes blink-count {
	0% {
		opacity: 0;
	}

	20% {
		opacity: 0.5;
	}

	40% {
		opacity: 0;
	}

	60% {
		opacity: 0.5;
	}

	80% {
		opacity: 0;
	}
}
