/* ========================= */
/* Journal landing page - global */
/* ========================= */

.journal-landing-page {
	.standard-promo {
		.content {
			max-width: none;
		}
	}

	.breadcrumb {
		position: absolute;
		z-index: 1;
		display: none;
	}

	.pagination {
		ul {
			list-style: none;
			text-align: center;
		}

		a {
			text-align: center;
			display: inline-block;
			color: $color-white;
			text-decoration: none;
			padding: 11px 30px 12px;
			background: $color-blue-1;
			border-radius: 2px;
			font-size: 0.875em;
			transition: all 0.2s;
			font-weight: 700;

			&:hover {
				background-color: $color-blue-2;
			}
		}
	}

	.cmsjournalContainer {
		.cta {
			display: none;
		}
	}
}

#page-content.homepage {
	.cmsjournalContainer {
		.pagination {
			.next {
				display: none;
			}
		}
	}
}

.corporateresponsibility {
	color: $color-base;

	.main-title {
		text-align: center;
		padding-top: 49px;
		padding-bottom: 49px;

		h1 {
			padding: 0;
			line-height: 36px;
			font-size: 30px;
		}

		br {
			display: none;
		}
	}

	.buttonBox {
		margin: 40px 0;
		display: flex;
		width: 100%;
		justify-content: center;
	}

	.lead-image {
		span.button {
			border-radius: 4px;
			padding: 13px 18px;
			text-transform: none;
			font-size: 0.875em;
			font-weight: 700;
			border: 0;
			min-width: 120px;
			transition: background 0.2s ease;
			background: $color-blue-1;
			color: $color-white;

			&:hover {
				background: $color-blue-3;
			}
		}
		position: relative;
		margin-bottom: 60px;
		padding-top: 0 !important; /* stylelint-disable-line declaration-no-important */

		img {
			height: 600px;
			object-fit: cover;
		}

		.options {
			position: absolute;
			left: 50%;
			transform: translate(-50%, 0);
			top: 55%;
			list-style: none;
			display: flex;

			.cta {
				margin: 0 10px;

				a {
					text-align: center;
				}
			}
		}
	}

	h2 {
		position: absolute;
		top: 45%;
		left: 50%;
		transform: translate(-50%, 0);
		color: $color-white;
		width: 80%;
		text-align: center;
		font-size: 1.125em;
	}

	.standard-article {
		padding-bottom: 0;

		&::after {
			display: none;
		}
	}

	.container {
		p {
			padding-bottom: 40px;
		}
	}

	.article-collection {
		.title {
			margin-top: 16px;
			margin-bottom: 6px;
			display: block;
		}

		article {
			margin-left: 30px;
			margin-right: 30px;
			margin-bottom: 0;

			.subtitle {
				max-width: 480px;
			}

			> a {
				.image {
					max-width: none;
				}
			}
		}

		.content {
			padding-top: 60px;
		}

		.tags {
			margin-top: 12px;
		}
	}

	.buttonBox-item {
		margin: 0 10px;
	}
}

@media (max-width: $tablet) {
	.journal-landing-page {
		.breadcrumb {
			display: none;
		}

		.pagination {
			padding: 40px 0;

			a {
				padding: 13px 30px;
			}
		}

		.standard-promo-item {
			.title {
				font-size: 2em;
				line-height: 2.5rem;
			}
		}
	}
}

@media (max-width: $desktop) {
	.corporateresponsibility {
		.main-title {
			h1 {
				font-size: 26px;
				line-height: 31px;
				min-height: 93px;
				display: flex;
				align-items: center;
				text-align: center;
				justify-content: center;
			}
		}

		.buttonBox {
			flex-direction: column;
			margin-top: 20px;
		}

		.lead-image {
			img {
				height: 450px;
			}

			.options {
				flex-direction: column;
				top: 60%;

				.cta {
					margin: 10px 0;
				}
			}
		}

		h2 {
			top: 30%;
			font-size: 1.5em;
			line-height: 2.5rem;
		}

		.container {
			p {
				padding-bottom: 50px;
			}
		}

		.standard-article {
			p {
				padding-left: 34px;
				padding-right: 34px;
				width: auto;
			}
		}

		.buttonBox-item {
			margin: 10px 0;
		}

		.article-collection {
			.content {
				padding-top: 70px;
			}

			.subtitle {
				max-width: none !important; /* stylelint-disable-line declaration-no-important */
			}

			article {
				margin: 0 34px 60px;
			}
		}

		.breadcrumb {
			display: none;
		}
	}
}

@media screen and (min-width: calc($desktop + 1px)) {
	.corporateresponsibility {
		.main-title {
			text-align: center;
			padding-top: 49px;
			padding-bottom: 49px;
		}

		.breadcrumb {
			height: 50px;
			display: flex;
			align-items: center;
			margin-top: 2px;

			li {
				&:not(:last-child) {
					&::after {
						margin-left: 2px;
					}
				}
			}
		}

		.lead-image {
			figure {
				&::after {
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					opacity: 0;
					visibility: hidden;
					transition: opacity 200ms ease, visibility 200ms ease;
					background: rgba(255, 255, 255, 0.7);
				}

				&:hover {
					&::after {
						opacity: 1;
						visibility: visible;
						pointer-events: none;
					}
				}
			}

			&:hover {
				h2 {
					color: $color-black;
				}
			}
		}
	}
}
