/*---------------------------------------------*/
/* Standard Message - Global
/*---------------------------------------------*/
.standard-message {
	margin: 0 0 0 0;
	clear: both;
	position: relative;
	transition: background-color 200ms ease;
	z-index: 1;

	.content {
		position: relative;
		z-index: 1;
	}

	.title {
		font-size: 0.75em;
		font-weight: 700;
		text-transform: uppercase;
	}

	.detail {
		a {
			display: inline-block;
			border-bottom: 2px solid transparent;
			transition: border-color 200ms ease;
		}

		strong {
			font-weight: 700;
		}
		font-size: 0.75em;
	}

	.link-list {
		font-size: 0.75em;
		display: inline-block;
		max-width: 700px;

		> li {
			display: inline-block;
			margin-left: 13px;

			a {
				display: block;
				border-bottom: 2px solid transparent;
				transition: border-color 200ms ease;
			}
		}
	}

	.close {
		display: none;
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		width: 100%;
		max-width: 1680px;
		margin: 0 auto;
		transform: translateY(-50%);
		text-align: right;
		font-size: 0.875em;
		transition: all 0.2s;
		z-index: 10;

		&:hover {
			color: $color-gray-9;
		}

		&::after {
			content: "\e605";
			font-family: $font-secondary;
			margin: 10px 20px;
			cursor: pointer;
		}

		span {
			position: relative;
			z-index: 2;
			padding: 10px 23px;
			cursor: pointer;
			display: none;
		}
	}
}

.standard-message[data-text-colour="light"] {
	color: $color-white;

	a {
		color: $color-white;
	}

	.detail {
		a {
			&:hover {
				border-bottom: 2px solid $color-white;
			}
		}
	}

	.link-list {
		> li {
			a {
				&:hover {
					border-bottom: 2px solid $color-white;
				}
			}
		}
	}

	.link {
		color: $color-white;
	}
}

.standard-message[data-text-colour="dark"] {
	color: $color-gray-15;

	a {
		color: $color-gray-15;
	}

	.detail {
		a {
			&:hover {
				border-bottom: 2px solid $color-gray-15;
			}
		}
	}

	.link-list {
		> li {
			a {
				&:hover {
					border-bottom: 2px solid $color-gray-15;
				}
			}
		}
	}

	.link {
		color: $color-gray-15;
	}
}

.standard-message[data-max-width="true"] {
	max-width: 1680px;
	margin: 0 auto;
}

.standard-message[data-closable="true"] {
	.close {
		display: block;
	}
}

/*---------------------------------------------*/
/* Standard Message - single link
/*---------------------------------------------*/
.standard-message[data-links="single"] {
	.link {
		display: block;
		padding: 12px 0 13px 0;
		line-height: 1.3em;
		text-align: center;
		transition: padding 200ms 100ms ease;

		&:hover {
			padding: 25px 0 26px 0;
		}
	}
}

/*---------------------------------------------*/
/* Standard Message - multiple links
/*---------------------------------------------*/
.standard-message[data-links="multiple"] {
	padding: 12px 0 13px 0;
	line-height: 1.3em;
	text-align: center;
	transition: background-color 200ms ease, padding 200ms 100ms ease;

	.content {
		max-width: 700px;
		margin: 0 auto;
		text-align: center;
	}

	&:hover {
		padding: 25px 0 26px 0;
	}

	.title {
		display: inline-block;
	}
}

/*---------------------------------------------*/
/* Standard Message - no links
/*---------------------------------------------*/
.standard-message[data-links="none"] {
	padding: 12px 0 13px 0;
	line-height: 1.3em;
	text-align: center;
	transition: background-color 200ms ease, padding 200ms 100ms ease;

	&:hover {
		padding: 25px 0 26px 0;
	}

	.content {
		max-width: 900px;
		margin: 0 auto;
		text-align: center;
	}
}

.standard-message[data-capitalised="true"] {
	.content {
		text-transform: uppercase;
	}
}

/*---------------------------------------------*/
/* Standard Message - repeating text
/*---------------------------------------------*/
.standard-message[data-repeating-text="true"] {
	.detail {
		display: inline-block;
		margin-left: 3px;
		margin-right: 3px;
	}
}

@media (max-width: $desktop) {
	.standard-message[data-links="multiple"] {
		.content {
			max-width: 80%;
		}
	}

	.standard-message[data-links="none"] {
		.content {
			max-width: 80%;
		}
	}

	.standard-message[data-links="single"] {
		.title {
			max-width: 90%;
			margin: 0 auto;
		}

		.detail {
			max-width: 90%;
			margin: 0 auto;
		}
	}
}

@media (max-width: $tablet) {
	.standard-message[data-links="single"] {
		.title {
			font-size: 1em;
			margin: 0 auto 10px auto;
		}

		.link {
			padding: 12px 0 13px 0;

			&:hover {
				padding: 12px 0 13px 0;
			}
		}
	}

	.standard-message {
		.detail {
			font-size: 0.875em;
		}

		.link-list {
			> li {
				margin: 0 6px;
			}
		}
	}

	.standard-message[data-links="multiple"] {
		padding: 12px 0 13px 0;

		&:hover {
			padding: 12px 0 13px 0;
		}
	}

	.standard-message[data-links="none"] {
		padding: 12px 0 13px 0;

		&:hover {
			padding: 12px 0 13px 0;
		}
	}
}

@media (max-width: $mobile-lg) {
	.standard-message {
		.close {
			span {
				padding: 10px 20px;
			}
		}
	}
}

@media (max-width: $mobile) {
	.standard-message[data-closable="true"] {
		.content {
			max-width: 70%;
		}
	}
}
