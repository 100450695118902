.standard-information {
	padding: 51px 0;

	&::after {
		display: table;
		content: "";
		clear: both;
	}

	.content {
		max-width: 1680px;
		margin: 0 auto;
	}
}

.standard-information[data-items="1"] {
	.standard-information-item {
		width: 100%;
	}
}

.standard-information[data-items="2"] {
	.standard-information-item {
		width: 50%;
	}
}

.standard-information[data-items="3"] {
	.standard-information-item {
		width: 33.333%;
	}
}

.standard-information-item {
	float: left;

	> .inner {
		max-width: 250px;
		margin: 0 auto;
		padding: 0 20px;
		font-size: 0.875em;
	}

	.title {
		font-weight: 700;
		line-height: 1.45em;
		color: $color-base;
	}

	p {
		color: $color-gray-1;
		line-height: 1.4em;
	}

	a {
		color: $color-base;
		transition: color 0.4s;
		text-decoration: underline;

		&:hover {
			color: $color-gray-1;
		}
	}
}

@media (max-width: $mobile-lg) {
	.standard-information[data-items="2"] {
		.standard-information-item {
			width: 100%;
		}
	}

	.standard-information[data-items="3"] {
		.standard-information-item {
			width: 100%;
		}
	}

	.standard-information-item {
		margin: 20px 0;
	}
}

@media (max-width: $mobile) {
	.standard-information {
		padding: 40px 0;
	}

	.standard-information-item {
		> .inner {
			max-width: none;
		}
	}
}

@media only screen and (min-device-pixel-ratio: 1.5) and (max-width: $tablet),
	only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: $tablet) {
	.standard-information-item {
		> .inner {
			font-size: 0.938em;
		}
	}
}
