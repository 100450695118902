/*SHIPPING & BILLING */
.address-page {
	.content-title {
		font-size: 1rem;
		font-weight: 700;
		padding: 10px 0 20px;
		text-transform: uppercase;
	}

	.half-column {
		float: left;
		width: 50%;
	}

	.shipping {
		&::after {
			content: "";
			display: block;
			clear: both;
		}
	}

	.billing {
		&::after {
			content: "";
			display: block;
			clear: both;
		}
	}

	.existing {
		display: flex;
		flex-wrap: wrap;
		clear: both;

		&::after {
			content: "";
			display: block;
			clear: both;
		}

		.new-column {
			float: left;
			width: 31.31%;
			transition: all 0.4s;
		}
	}

	.column {
		&:nth-child(2) {
			padding: 0 3.035%;
		}

		&:nth-child(3n + 2) {
			padding: 0 3.035%;
		}

		&:nth-child(1) {
			padding: 0;
			clear: left;
		}

		&:nth-child(3n + 1) {
			padding: 0;
			clear: left;
		}
	}

	.new-column {
		&:nth-child(2) {
			padding: 0 3.035%;
		}
	}

	.wrapper {
		&::after {
			clear: both;
			display: block;
			content: "";
		}
	}

	.new-addresses {
		.dob.disabled {
			height: auto;
		}
	}

	.street-address-street-number {
		display: flex;
		column-gap: 20px;
		width: 100%;
	}

	.street-address-street-number--reverse {
		flex-direction: row-reverse;
	}

	.street-address {
		flex: 3;
		display: flex;
		flex-direction: column;
	}

	.street-number {
		flex: 1;
		display: flex;
		flex-direction: column;
	}

	.postcode {
		.postcode-input {
			width: 37.14%;
		}
	}

	.county {
		float: right;
		width: 57.14%;
	}

	.country {
		input {
			background: $color-white;
		}
	}

	.new-button {
		height: 100%;

		a {
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 0 30px;
			transition: all 0.2s;
			height: 100%;

			span {
				display: block;

				span {
					display: block;
				}
			}

			> span {
				&::before {
					font-family: $font-secondary;
					content: "\e61b";
					display: block;
				}
				display: block;
			}

			&:hover {
				background-color: $color-white;
				border-width: 2px;
			}
		}
	}

	.picker-button {
		a {
			&:hover {
				background-color: $color-white;
				border-width: 2px;
			}

			> span {
				display: block;
			}

			span {
				span {
					display: block;
				}
			}
		}
	}

	.inactive {
		opacity: 0.6;
	}

	.address {
		box-sizing: border-box;
		display: block;
		height: 247px;
		border-width: 1px;
		border-style: solid;
		border-color: $color-gray-1;
		text-decoration: none;
		margin: 0 0 30px;
		transition: border-width 0.2s ease;

		&:hover {
			border-color: $color-base;
			background-color: $color-white;
			color: $color-base;
			border-width: 2px;

			dl {
				padding: 29px;
			}
		}

		dl {
			transition: all 0.2s;
			padding: 30px;
			line-height: 1.25rem;

			span {
				display: block;
			}
		}

		.address-line {
			display: flex;

			> span {
				margin-right: 6px;
			}
		}

		.phone {
			padding-top: 14px;
		}
	}

	.address.selected {
		border-color: $color-base;
		background-color: $color-white;
		color: $color-base;
		border-width: 4px;

		&:hover {
			border-width: 6px;

			dl {
				padding: 28px;
			}
		}

		a {
			color: $color-base;
		}
	}

	.new-address {
		clear: both;
		padding-top: 30px;
		border-width: 1px 0 0;
		border-style: solid;
		border-color: $color-gray-1;
		overflow: hidden;
	}

	.submit-section {
		clear: both;
	}

	.submit {
		width: 31.31%;
		margin: 20px auto 0;
		opacity: 0.7;
		display: block;
		cursor: default;
		transition: all 0.4s;

		&:hover {
			border-color: $color-blue-1;
			background-color: $color-blue-1;
		}
	}

	.submit.active {
		opacity: 1;
		cursor: pointer;

		&:hover {
			border-color: $color-blue-2;
			background-color: $color-blue-2;
		}
	}
}

@media screen and (max-width: $desktop) {
	.address-page {
		.street-address {
			flex: 1.5 1;
		}
	}
}

@media screen and (max-width: $tablet) {
	.address-page {
		.column {
			float: left;
			clear: none;

			&:nth-child(3) {
				float: left;
				clear: none;
			}

			&:nth-child(2n + 1) {
				padding: 0 10px 0 0;
				width: 50%;
				box-sizing: border-box;
			}

			&:nth-child(2n) {
				padding: 0 0 0 10px;
				width: 50%;
				box-sizing: border-box;
			}
		}

		.new-addresses {
			.column {
				&:nth-child(1) {
					padding: 0 10px 0 0;
				}

				&:nth-child(2) {
					padding: 0 0 0 10px;
				}
			}
		}

		.existing {
			.column {
				&:nth-child(1) {
					padding: 0 10px 0 0;
					width: 50%;
					box-sizing: border-box;
				}

				& + .column {
					& + .new-column {
						box-sizing: border-box;
						padding: 0 0 0 10px;
						width: 50%;
					}
				}
			}

			.existing-column {
				box-sizing: border-box;
				padding: 0 0 0 10px;
				width: 50%;
			}

			.new-column {
				box-sizing: border-box;
				width: 50%;
				padding: 0 10px 0 0;
			}
		}

		.existing-column {
			padding: 0 10px 0 0;
			width: 50%;
			box-sizing: border-box;
		}

		.new-column {
			padding: 0 0 0 10px;
			width: 50%;
			box-sizing: border-box;
		}

		.submit {
			margin: 0 auto 20px;
		}

		.street-address {
			flex: 2.2 1;
		}
	}
}

@media screen and (max-width: $mobile-lg) {
	.address-page {
		.column {
			padding: 0;
			width: 100%;
			clear: left;

			&:nth-child(2n + 1) {
				padding: 0;
				width: 100%;
				clear: left;
			}

			&:nth-child(2n) {
				padding: 0;
				width: 100%;
				clear: left;
			}

			&:nth-child(2) {
				padding: 0;
				width: 100%;
				clear: left;
			}

			&:nth-child(1) {
				position: static;
				width: 100%;
				padding: 0;
			}
		}

		.existing {
			.column {
				&:nth-child(1) {
					padding: 0;
					width: 100%;
					clear: left;
				}

				&:nth-child(2) {
					padding: 0;
					width: 100%;
					clear: left;
				}

				& + .column {
					& + .new-column {
						position: static;
						width: 100%;
						padding: 0;
					}
				}
			}

			.existing-column {
				position: static;
				width: 100%;
				padding: 0;
			}

			.new-column {
				position: static;
				width: 100%;
				padding: 0;
			}
		}

		.existing-column {
			padding: 0;
			width: 100%;
			clear: left;
		}

		.new-column {
			&:nth-child(2) {
				padding: 0;
				width: 100%;
				clear: left;
			}
		}

		.new-addresses {
			.column {
				&:nth-child(2) {
					padding: 0;
					width: 100%;
					clear: left;
				}
			}

			.dob.disabled {
				height: 0;
			}
		}

		.address {
			margin: 0 0 20px;
		}

		.street-address {
			flex: 2 1;
		}

		.submit {
			width: 100%;
			margin: 0 auto 20px;
		}
	}
}
