/*CONFIRMATION PAGE*/
.confirmation-page {
	padding-top: 145px;

	.info {
		max-width: 574px;
		margin: 0 auto 90px;
		background-color: $color-gray-14;

		.content {
			padding: 0 20px 20px;

			&::after {
				font-family: $font-secondary;
				content: "\e601";
				color: $color-gray-17;
				text-align: center;
				display: block;
				padding: 30px;
				font-size: 1.25em;
				line-height: 1em;
			}
		}

		&::before,
		&::after {
			content: "";
			display: block;
			position: relative;
			top: 0;
			left: 0;
			width: 100%;
			height: 24px;
			/* stylelint-disable */
			background: linear-gradient($color-white 0%, transparent 0%),
				linear-gradient(135deg, $color-gray-14 33.33%, transparent 33.33%) 0 0%,
				$color-gray-14 linear-gradient(45deg, $color-gray-14 33.33%, $color-white 33.33%) 0 0%;
			background: -webkit-linear-gradient($color-white 0%, transparent 0%),
				-webkit-linear-gradient(135deg, $color-gray-14 33.33%, transparent 33.33%) 0 0%,
				$color-gray-14 -webkit-linear-gradient(45deg, $color-gray-14 33.33%, $color-white 33.33%) 0 0%;
			background: -o-linear-gradient($color-white 0%, transparent 0%),
				-o-linear-gradient(135deg, $color-gray-14 33.33%, transparent 33.33%) 0 0%,
				$color-gray-14 -o-linear-gradient(45deg, $color-gray-14 33.33%, $color-white 33.33%) 0 0%;
			background: -moz-linear-gradient($color-white 0%, transparent 0%),
				-moz-linear-gradient(135deg, $color-gray-14 33.33%, transparent 33.33%) 0 0%,
				$color-gray-14 -moz-linear-gradient(45deg, $color-gray-14 33.33%, $color-white 33.33%) 0 0%;
			background-repeat: repeat-x;
			background-size: 0 100%, 12px 24px, 12px 24px;
			/* stylelint-enable */
		}

		&::after {
			/* stylelint-disable */
			bottom: 0;
			background: linear-gradient($color-white 0%, transparent 0%),
				linear-gradient(135deg, $color-white 33.33%, transparent 33.33%) 0 0%,
				$color-white linear-gradient(45deg, $color-white 33.33%, $color-gray-14 33.33%) 0 0%;
			background: -webkit-linear-gradient($color-white 0%, transparent 0%),
				-webkit-linear-gradient(135deg, $color-white 33.33%, transparent 33.33%) 0 0%,
				$color-white -webkit-linear-gradient(45deg, $color-white 33.33%, $color-gray-14 33.33%) 0 0%;
			background: -o-linear-gradient($color-white 0%, transparent 0%),
				-o-linear-gradient(135deg, $color-white 33.33%, transparent 33.33%) 0 0%,
				$color-white -o-linear-gradient(45deg, $color-white 33.33%, $color-gray-14 33.33%) 0 0%;
			background: -moz-linear-gradient($color-white 0%, transparent 0%),
				-moz-linear-gradient(135deg, $color-white 33.33%, transparent 33.33%) 0 0%,
				$color-white -moz-linear-gradient(45deg, $color-white 33.33%, $color-gray-14 33.33%) 0 0%;
			background-repeat: repeat-x;
			background-size: 0 100%, 12px 24px, 12px 24px;
			/* stylelint-enable */
		}

		header {
			max-width: 450px;
			margin: 0 auto;
			font-size: 0.875em;
			line-height: 1.25rem;
			border-width: 0 0 1px 0;
			border-style: solid;
			border-color: $color-gray-9;
			text-align: center;
			padding: 50px 0 10px;
		}

		article {
			max-width: 450px;
			margin: 0 auto;
			font-size: 0.875em;
			line-height: 1.25rem;
			padding: 25px 0;
			border-width: 0 0 1px 0;
			border-style: solid;
			border-color: $color-gray-9;
		}

		h1 {
			font-size: 1.25em;
			font-weight: 700;
			line-height: 1.875rem;
			padding: 0 10px;

			span {
				display: inline-block;
			}
		}

		time {
			color: $color-gray-1;
			padding: 20px 0;
			display: block;
		}

		dt {
			display: inline-block;

			&::after {
				content: ":";
			}
		}

		dd {
			display: inline-block;
			color: $color-gray-1;
		}

		a {
			color: $color-gray-1;
			text-decoration: underline;

			&:hover {
				color: $color-base;
			}
		}

		.email {
			display: inline-block;
		}
	}

	.social {
		display: flex;

		&::after {
			clear: both;
			content: "";
			display: block;
		}

		.title {
			display: inline-block;
			line-height: 1.25rem;
			padding-right: 10px;
			float: left;

			&::after {
				content: ":";
			}
		}

		ul {
			display: flex;
			flex-grow: 1;
			@media screen and (max-width: $mobile-lg) {
				margin-top: 3px;
			}
		}

		li {
			float: left;
			font-size: 1.25rem;
			line-height: 1rem;
			padding: 0 10px;
			display: flex;
		}

		a {
			border-width: 0;
			font-size: 1.25rem;
			line-height: 1rem;
			text-transform: none;
			text-decoration: none;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				&::after {
					color: $color-base;
				}

				path {
					fill: $color-base;
				}

				circle {
					fill: $color-base;
				}
			}

			span {
				display: none;
			}

			path {
				fill: $color-gray-1;
				transition: 0.2s fill;
			}

			circle {
				fill: $color-gray-1;
				transition: 0.2s fill;
			}

			&.instagram {
				svg {
					width: 14px;
					height: auto;
				}
			}

			&.twitter {
				svg {
					width: 14px;
					height: auto;
				}

				path {
					stroke: $color-gray-1;
					fill: none;
					transition: 0.2s stroke;
				}

				&:hover path {
					fill: none;
					stroke: $color-base;
				}
			}
		}
	}

	ul {
		list-style-type: none;
	}
}

@media screen and (max-width: $mobile-lg) {
	.confirmation-page {
		padding: 95px 0 20px;

		.info {
			margin: 0 20px 60px;
		}
	}
}

@media screen and (max-width: $mobile-lg) {
	.confirmation-page .social li {
		float: left;
		width: 33.33%;
		padding: 0 0 5px;
	}
}

@media only screen and (min-device-pixel-ratio: 1.5) and (max-width: $tablet),
	only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: $tablet) {
	.confirmation-page {
		.info {
			header {
				font-size: 1em;
				line-height: 1.75rem;
			}

			article {
				font-size: 1em;
				line-height: 1.75rem;
			}
		}

		.social {
			.title {
				line-height: 1.875rem;
			}

			a {
				padding: 5px;
			}
		}
	}
}

/* Styles for zenloop survey */
#zl-container {
	font-family: inherit;
	max-width: 690px;
	margin: 0 auto;

	.zl-votes {
		display: flex;
		flex-wrap: wrap;

		ul {
			margin: 20px;
			order: 1;
		}

		.zl-extrem-side {
			align-self: flex-start;
			max-width: 120px;
			text-align: right;
			margin: 0 20px;
			order: 2;

			&.zl-extrem-size-left {
				text-align: left;
			}
		}
	}

	.zl-footer {
		display: none;
	}
}
