.country-select {
	background-color: $color-white;
	display: flex;
	flex-direction: column;

	.flyout-menu__footer-country-select {
		width: 100%;
		background-color: $color-white;
		height: 55px;
		font-size: 13px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 20.5px 0 15px;
		box-sizing: border-box;
	}

	@media screen and (min-width: calc($desktop + 1px)) {
		overflow-y: auto;

		.flyout-menu__footer-country-select {
			display: none;
		}
	}

	.country-item {
		align-items: center;
		cursor: pointer;
		display: flex;
		position: relative;
		border: none;
		border-bottom: $item-border;
		line-height: 55px;
		background-color: $color-gray-10;

		&:hover {
			background-color: $color-gray-19;
		}

		&.filling-item {
			display: none;
		}

		&.is-active {
			background-color: $light-gray;

			.country-languages {
				transform: translateX(0);
			}
		}

		&.is-clicked {
			animation: 2s showBlinking;
			background-color: $color-gray-10;

			@keyframes showBlinking {
				0% {
					background-color: $color-white;
				}

				25% {
					background-color: $color-gray-16;
				}

				50% {
					background-color: $color-gray-16;
				}

				100% {
					background-color: $color-white;
				}
			}

			.country-languages {
				display: flex;
				animation: 1s translate;
				transform: translate(0%);

				@keyframes translate {
					from {
						transform: translateX(100%);
					}

					to {
						transform: translateX(0%);
					}
				}
			}
		}

		.country-link {
			align-items: center;
			display: flex;
			padding: 16px 0;
			margin: -16px 0;
			width: 100%;
			color: $text-color;
		}

		.country-flag {
			display: flex;
			padding: 0 15px;
			line-height: 50px;

			@media screen and (max-width: $desktop) {
				line-height: 50px;
			}

			.flag-image {
				height: 20px;
				width: 20px;
				border-radius: 100%;
				background-repeat: no-repeat;
				background-position: 48%;
				background-size: cover;
			}
		}

		.country-name {
			font-size: $defaultFontSize;
			display: flex;
			flex-basis: 85%;
		}

		.country-languages {
			width: calc(100vw - 50px);
			position: absolute;
			left: 50px;
			background: $color-gray-10;
			top: 0;
			transition: transform 0.5s ease;
			transform: translateX(100%);
			height: 100%;
			display: none;

			.country-language-item:hover {
				background-color: $color-gray-19;
			}

			.country-language-item {
				flex-basis: 50%;
				border-left: $item-border;
				display: flex;
				position: relative;

				.check-icon {
					display: none;
				}

				&.is-active {
					background-color: $light-gray;

					.check-icon {
						display: inline-block;
						position: absolute;
						right: 15px;

						svg {
							width: 15px;
							margin-bottom: -2px;
						}
					}
				}

				a {
					width: 100%;
					color: $text-color;
					display: block;
					padding-left: 15px;
				}
			}
		}
	}
}

@media screen and (min-width: calc($desktop + 1px)) {
	.country-link {
		height: 50px;
		margin: 0;
		padding: 0;
	}

	.country-select {
		.country-item {
			padding: 0;
			border-right: $item-border;
			overflow: hidden;
			line-height: 50px;

			&:last-child {
				border-bottom: none;
			}

			&:nth-child(4n) {
				flex-grow: 1;
				border-right: 0;
			}

			.country-flag {
				line-height: 50px;
				padding-left: 15px;
			}

			.country-name {
				flex-basis: 85%;
			}

			.country-languages {
				width: calc(100% - 50px);
			}
		}
	}
}

.header__country-overlay.header-overlay.is-open-but-not-visible {
	display: block;
	border: none;

	.heading,
	.currency-overlay {
		opacity: 0;
		height: 0;
		border: none;
	}
}

@media screen and (min-width: calc($desktop-lg + 1px)) {
	.country-select {
		.country-item {
			&:nth-child(4n) {
				flex-grow: 0;
				border-right: $item-border;
			}

			&:nth-child(6n) {
				flex-grow: 1;
				border-right: 0;
			}

			&.filling-item {
				display: block;
			}

			.country-name {
				padding-left: 0;
			}
		}
	}
}
