:-moz-autofill,
:-moz-autofill-preview {
	filter: unset;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-transition-delay: 9999s;
	transition-delay: 9999s;

	border: 1px solid $color-black;
	-webkit-text-fill-color: $color-black;
	-webkit-box-shadow: 0 0 0 1000px rgba(15, 63, 81, 0.3) inset;
	transition: white 9999s ease-in-out 0s;
}

login-overlay-section__form::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 7px;
}

login-overlay-section__form::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.5);
	box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.login-overlay {
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		padding-top: 14px !important; /* stylelint-disable-line declaration-no-important */
	}

	background-color: $color-gray-16;
	font-size: $defaultFontSize;

	@media screen and (max-width: $desktop) {
		z-index: 1000;
		position: relative;
		height: 100%;
	}

	input {
		filter: none;
	}

	input[type="text"] {
		filter: none;
	}
	input[type="text"],
	input[type="button"] {
		-webkit-appearance: none !important; /* stylelint-disable-line declaration-no-important */
	}

	$error-color: $color-red-4;
	$success-color: $color-green-2;

	&-section {
		display: none;
		position: relative;
		min-height: 560px;
		height: auto;
		flex-direction: column;
		@media screen and (max-width: $desktop) {
			height: 100%;
			min-height: auto;
			overflow-y: auto;
		}

		/* make a section visible */
		&.is-active {
			display: flex;
		}

		&.login-overlay__signup {
			@media screen and (max-width: $desktop) {
				z-index: 1001;
			}

			.login-overlay__options {
				.message {
					color: $color-red-4;
				}
				@media screen and (min-width: calc($desktop + 1px)) {
					font-size: 12px;
				}
			}
		}

		&__title {
			line-height: 49px;
			font-size: $defaultFontSize;
			font-weight: 700;
			border-bottom: $item-border;
			text-align: center;
			text-transform: uppercase;
			margin-bottom: 2px;

			@media screen and (max-width: $desktop) {
				line-height: 54px;
				color: $color-gray-12;
			}
		}

		&__form {
			flex-grow: 1;
			padding: 25px 21px calc(80px + env(safe-area-inset-bottom));
			display: flex;
			flex-direction: column;
		}

		&__btn {
			-webkit-appearance: none;
			-webkit-border-radius: 0;
			font-weight: 700;
			font-size: $defaultFontSize;
			position: absolute;
			right: 0;
			bottom: 0;
			width: 50%;
			border-bottom: none;
			margin: 0;
			cursor: pointer;
			text-transform: uppercase;
			height: 50px;
			background-color: $color-gray-12;
			color: $color-white;
			border: 1px solid $color-gray-12;
			margin-right: -1px;
			@media screen and (max-width: $desktop) {
				margin-bottom: 0;
				margin-right: 0;
				height: 55px;
				background-color: $color-gray-12;
				color: $color-white;
				border: 1px solid $color-gray-12;
				position: fixed;
			}

			&:disabled {
				background-color: $dark-color;
				border-color: $dark-color;
				cursor: auto;
			}

			&:hover {
				background-color: $color-gray-1;
				border: 1px solid $color-gray-1;
				@media screen and (max-width: $desktop) {
					background-color: $color-gray-12;
					color: $color-white;
				}
			}

			&.is-hidden {
				display: none;
			}
		}
	}

	.login-overlay__login {
		&__form {
			/* fill available space to push down toggle button */
			flex: 1 1 auto;
			padding: 3.3rem 1.5rem 0 1.5rem;
		}
	}

	&__fieldset {
		position: relative;
		border: none;
		font-size: 12px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;

		&.fieldSetInFocus {
			.floating-label {
				display: block;
			}
		}

		&.username {
			&.is-hidden {
				display: none;
			}

			input {
				margin-bottom: 20px;
			}
		}

		&.password input {
			margin-bottom: 15px;
		}
	}

	&__input {
		caret-color: $color-gray-13 !important; /* stylelint-disable-line declaration-no-important */
		box-shadow: none;
		font-size: 12px;
		-webkit-appearance: none;
		padding: 0 22px;
		border: $item-border;
		border-radius: 5px;
		opacity: 1;
		transition: all 0.33s, margin-bottom 1ms;
		height: 55px;
		line-height: normal;
		outline: none;
		background-image: url(../images/global/transparent.svg);
		background-repeat: no-repeat;
		background-position: 96% center;
		background-size: 15px 15px;
		color: $color-base;
		@media screen and (max-width: $desktop) {
			height: 55px;
			line-height: normal;
			border: 1px solid $color-gray-9;
			padding: 0 22px;
			background-position: calc(100% - 22px);
			color: $color-base;
		}

		&-message {
			padding-right: 10px;
			font-size: 12px;
			text-align: left;
			display: none;
			margin-top: 4px;
			padding-left: 22px;
			color: $error-color !important; /* stylelint-disable-line declaration-no-important */
			@media screen and (max-width: $desktop) {
				color: $color-red-4 !important; /* stylelint-disable-line declaration-no-important */
			}
		}

		&.has-error {
			border: 1px solid $color-red-4;
			margin-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */

			& ~ .login-overlay__input-message {
				display: block;
				margin: 15px 0 14px;
				line-height: 14px;
			}

			@media screen and (max-width: $desktop) {
				border-color: $color-red-4;
			}
		}

		&.has-success {
			border-color: $success-color;
			@media screen and (max-width: $desktop) {
				border-color: $color-green-3;
			}
		}

		&.hidden {
			display: none;
		}

		&.inFocus {
			padding: 14px 35px 0 22px;
			height: 42px;
			font-size: 14px;
		}

		&.darkBorder {
			border-color: $color-gray-12;
		}
	}

	&__show-password {
		width: 15px;
		height: 16px;
		position: absolute;
		top: 21px;
		right: 20px;
		background: url(../images/global/showpassword-icon.svg) center center no-repeat;
		border: none;
		cursor: pointer;

		.sr-only {
			display: none;
		}

		&.is-visible {
			background: url(../images/global/hidepassword-icon.svg) center center no-repeat;
		}
	}

	&__btn-as-link {
		padding: 0;
		border: none;
		background: none;
		cursor: pointer;
		font-size: 0.75rem;
		text-decoration: underline;
	}

	@media screen and (min-width: calc($desktop + 1px)) {
		&__input:not(.has-error):not(.has-success):hover {
			border-color: $color-gray-12;
		}
	}

	.floating-label {
		font-size: 12px;
		position: absolute;
		top: 11px;
		left: 23px;
		display: none;
		color: $color-gray-12;

		@media screen and (max-width: $desktop) {
			left: 23px;
		}
	}

	input[type="email"]::-webkit-contacts-auto-fill-button,
	input[type="email"]::-webkit-credentials-auto-fill-button,
	input[type="password"]::-webkit-contacts-auto-fill-button,
	input[type="password"]::-webkit-credentials-auto-fill-button {
		visibility: hidden;
		display: none !important; /* stylelint-disable-line declaration-no-important */
		pointer-events: none;
		height: 0;
		width: 0;
		margin: 0;
	}

	&__options {
		list-style-type: none;
		margin: 0 0 20px 0;
		display: flex;
		color: $color-gray-12;

		li {
			margin-right: 0.5rem;
		}

		.message.is-hidden {
			display: none;
		}
	}

	&__options,
	&__fieldset.consent,
	&__info {
		padding-left: 22px;
		font-size: 12px;
		text-align: left !important; /* stylelint-disable-line declaration-no-important */
		padding-right: 10px;

		@media screen and (max-width: $desktop) {
			padding-left: 22px;
			padding-right: 25px;
		}
	}

	a {
		text-decoration: underline;
		color: $color-gray-12;
	}

	.standard-checkbox.terms-agreement {
		margin-bottom: 10px;
	}

	&__fieldset.consent,
	&__info {
		color: $color-gray-12;
	}

	.scrollable {
		overflow: auto;
		max-height: 58px;
		margin-bottom: 10px;
	}

	&__error-message {
		display: none;
		color: $error-color !important; /* stylelint-disable-line declaration-no-important */
		padding-left: 22px;
		font-size: 12px;
		text-align: left;
		margin-top: 10px;

		@media screen and (min-width: calc($desktop + 1px)) {
			padding-left: 22px;
		}

		a {
			color: $error-color;
			text-decoration: underline;
		}

		&.is-active {
			display: block;
		}
	}

	&__toggle-button {
		color: $color-gray-12;
		-webkit-appearance: none !important; /* stylelint-disable-line declaration-no-important */
		-webkit-border-radius: 0 !important; /* stylelint-disable-line declaration-no-important */
		font-weight: 700;
		height: 50px;
		width: 50%;
		border: none;
		margin: 0;
		background-color: $color-gray-16;
		border-top: $item-border;
		cursor: pointer;
		text-transform: uppercase;
		font-size: $defaultFontSize;
		position: absolute;
		bottom: 0;
		flex-shrink: 0;
		@media screen and (max-width: $desktop) {
			height: 55px;
			border-bottom: $item-border;
			position: fixed;
		}
	}

	&__toggle-button:hover {
		background-color: $color-gray-6;
		color: $color-gray-12;
		@media screen and (max-width: $desktop) {
			background-color: $color-gray-16;
		}
	}
}

.login-overlay__forgotten-password {
	position: relative;

	.loading-overlay {
		background: #f5f5f5;
		position: absolute;
		top: 55px;
		bottom: 55px;
	}

	.login-overlay__input.error.has-error {
		& + .login-overlay__input-message {
			display: none;
		}
	}

	.message.error {
		color: $color-red-4;
		margin-top: 17px;
	}

	.login-overlay__options {
		.message.success {
			color: $success-color;
		}
	}

	input.has-error + .login-overlay__input-message {
		display: none;
	}
}

.submittedAndLoginFailed {
	display: none !important; /* stylelint-disable-line declaration-no-important */
}

.login-overlay__login.loginSubmittedAndLoginFailed {
	.login-overlay__input.has-error {
		margin-bottom: 20px !important; /* stylelint-disable-line declaration-no-important */
	}
}
