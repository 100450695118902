/* Standard tabs - base settings */
.standard-tabs {
	width: 100%;

	> .content {
		width: 100%;
		max-width: 1680px;
		margin: 0 auto;
		text-align: center;
	}

	.slideshow {
		overflow: hidden;
	}

	.slides {
		position: relative;
		list-style: none;
		z-index: 0;
		right: auto;
	}

	.slide {
		&:first-child {
			position: relative;
		}
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	.custom-pips {
		display: inline-block;
		margin: 20px 0;

		.pips {
			display: block;
			position: relative;
			z-index: 0;
			right: auto;
		}

		li {
			top: 0;
			width: 100%;
			z-index: 0;

			a {
				display: inline-block;
				font-weight: 700;
				font-size: 1em;
				border-bottom: 2px solid transparent;
				transition: all 0.4s;
			}
		}

		li.active {
			> a {
				color: $color-base;
				border-color: $color-gray-9;
				cursor: default;
			}
		}
	}
}

@media (max-width: $tablet) {
	.standard-tabs {
		.custom-pips {
			.pips {
				position: static;
			}

			li {
				position: static;
				padding: 5px 0;
				transition: 0.3s all;

				&:nth-child(1) {
					transform: translateY(100%);
				}

				&:nth-child(1).active {
					transform: translateY(0);
				}

				&:nth-child(2) {
					transform: translateY(0);
				}

				&:nth-child(2).active {
					transform: translateY(-100%);
				}
			}
		}
	}
}
