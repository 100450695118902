.jobs-landing-page {
	margin: 0 auto;
	padding-top: 60px;

	.section-content {
		width: 100%;
		max-width: 1680px;
		margin: 0 auto;
		padding: 0 20px;
		box-sizing: border-box;
	}

	.column {
		float: left;
		width: 50%;
		padding: 68px 20px 60px 0;
		box-sizing: border-box;

		& + .column {
			padding-right: 0;
			padding-left: 20px;
		}
	}

	.main-title {
		padding: 25px 0 0;
		color: $color-base;
		font-weight: 700;
		font-size: 1.875em;
		line-height: 2.375rem;
		text-align: center;
	}

	.sub-title {
		padding: 0 0 20px 0;
		color: $color-base;
		font-size: 1em;
		text-align: left;
		font-weight: 700;
	}

	.job-list {
		list-style: none;

		li {
			padding: 0 0 46px 0;
		}

		a {
			color: $color-gray-1;
			text-decoration: underline;
			-webkit-transtion: color 0.1s linear;
			transition: color 0.1s linear;

			&:hover {
				color: $color-black;
			}
		}

		dt {
			display: none;
		}

		dd {
			display: inline;
		}
	}

	.create-application {
		position: relative;
		display: block;
		clear: left;

		picture {
			width: 100%;
		}

		img {
			width: 100%;
		}

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			background: rgba(0, 0, 0, 0.75);
			transition: opacity 0.1s linear;
		}

		&:hover {
			.overlay {
				opacity: 1;
			}
		}

		.prompt {
			position: absolute;
			top: 50%;
			left: 50%;
			max-width: 90%;
			padding: 21px 30px 22px 30px;
			color: $color-blue-1;
			text-align: center;
			font-size: 1em;
			line-height: 1em;
			border: 0;
			font-weight: 700;
			border-radius: 4px;
			cursor: pointer;
			background: $color-white;
			transform: translateX(-50%) translateY(-50%);
		}
	}
}

@media (max-width: $tablet) {
	.jobs-landing-page {
		padding-top: 60px;

		.main-title {
			font-size: 1.5em;
		}
	}
}

@media (max-width: 860px) {
	.jobs-landing-page {
		.column {
			width: 100%;
			padding: 28px 0 0;

			& + .column {
				width: 100%;
				padding: 28px 0 60px;
			}
		}
	}
}
