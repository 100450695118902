.sort-area {
	width: 100%;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	display: none;
	overflow: hidden;
	z-index: 100;
	background: rgba(255, 255, 255, 0.9);

	.container {
		margin: 0 auto;
	}

	.title {
		font-size: 0.75em;
		font-weight: 700;
		margin: 0 0 10px 0;
		text-transform: uppercase;
		color: $color-base;
		margin-left: 20px;
	}

	ul {
		text-align: left;
		margin: 0 10px 20px 10px;
		list-style: none;
	}

	li {
		display: inline-block;
		margin: 0 12px 12px 0;

		a {
			text-transform: none;
			color: $color-base;
			border-radius: 4px;
			padding: 10px;
			font-size: 1.563em;
			transition: all 0.4s;
			cursor: pointer;

			&:hover {
				background-color: $color-gray-8;
			}
		}

		a.active {
			background-color: $color-gray-7;
		}
	}

	.close-area {
		border-width: 0;

		ul {
			text-align: center;
			margin: 0;
		}

		li {
			display: inline-block;
		}

		a {
			background: $color-blue-1;
			border-color: $color-blue-1;
			border-radius: 4px;
			border-style: solid;
			border-width: 1px;
			color: $color-white;
			cursor: pointer;
			display: inline-block;
			font-size: 0.875em;
			font-weight: 700;
			line-height: 1em;
			margin: 30px 10px;
			padding: 23px 0;
			width: 272px;
			-webkit-appearance: none;
			-moz-appearance: none;
			text-transform: none;
			transition: all 0.2s;

			&:hover {
				background-color: $color-blue-2;
				border-color: $color-blue-2;
			}
		}

		.clear {
			a {
				color: $color-blue-1;
				background: none;

				&:hover {
					color: $color-blue-2;
				}
			}
		}

		.active {
			color: $color-white;
		}
	}

	.scrollable-content {
		overflow: hidden;
		height: 100%;

		div {
			padding-top: 40px;
		}
	}

	.scrollbar {
		bottom: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 10px;

		.handle {
			border-style: none;
			height: 50px;
			padding: 0;
			position: absolute;
			width: 100%;
		}
	}

	.track {
		bottom: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 10px;
	}

	.disabled {
		display: none;
	}
}

.sort-area.active {
	display: block;
}

.sort-area-inner {
	margin-top: 10px;
	width: 100%;
	margin: 0 auto;
	padding: 20px 0 0 0;
	max-width: 1680px;
}

.sticky {
	.sort-area.active {
		.sort-area-inner {
			border-width: 0 0 2px;
			border-style: solid;
			border-bottom-color: rgba(188, 188, 188, 0.25);
		}
	}
}

@media (max-width: $tablet) {
	.sort-area {
		display: none;
		visibility: hidden;
	}

	.sort-area.active {
		display: none;
		visibility: hidden;
	}
}
