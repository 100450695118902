/* ========================= */
/* Journal Permalink Page */
/* ========================= */
.journal-permalink {
	margin: 0 auto;

	.breadcrumb {
		span {
			margin-right: -2px;
		}
	}
}

.standard-article {
	padding-bottom: 79px;
	color: $color-base;

	header {
		padding: 31px 20px 0;
		width: 87%;
		max-width: 1640px;
		margin: 0 auto;

		&::after {
			clear: both;
			display: block;
			content: "";
		}
	}

	.container {
		width: 98%;
		box-sizing: border-box;
		max-width: 1640px;
		padding: 0 20px;
		margin: 0 auto;
	}

	&::after {
		content: "\e601";
		display: block;
		width: 100%;
		padding: 40px 0 0 0;
		text-align: center;
		font-family: $font-secondary;
		font-size: 20px;
		color: $color-gray-1;
		clear: both;
	}

	h1 {
		padding: 0 0 9px 0;
		font-size: 80px;
		font-weight: 700;
		line-height: 100px;
	}

	.article-details {
		float: left;
		padding-right: 13px;
		padding-bottom: 38px;
		color: $color-gray-1;
		font-size: 0.75em;

		dt {
			display: none;
		}
	}

	p {
		width: 56.25%;
		max-width: 720px;
		margin: 0 auto;
		padding: 0 0 34px 0;
		font-size: 1em;
		line-height: 1.5rem;

		a {
			text-decoration: underline;
		}
	}

	.questionnaire {
		width: 56.25%;
		max-width: 720px;
		margin: 0 auto;
		font-size: 1em;
		line-height: 1.5em;

		dt {
			font-weight: 700;
		}

		dd {
			padding: 0 0 34px 34px;
		}
	}

	a {
		color: $color-base;

		&:hover {
			color: $color-gray-1;
		}
	}

	.share-panel {
		float: left;

		.share-panel-trigger {
			font-style: normal;
		}
	}

	.soundcloud-player {
		.player-holder {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 2px;
			z-index: 1;
		}

		.info-holder {
			background-color: $color-base;

			&::after {
				clear: both;
				display: block;
				content: "";
			}
		}

		.header {
			padding: 10px;
			position: absolute !important; /* stylelint-disable-line declaration-no-important */
		}

		.logo-holder {
			width: 79px;
			height: 79px;
			border-radius: 10px;
			background-color: $color-gray-12;
			text-align: center;
			box-sizing: border-box;
			padding: 5px;

			img {
				width: 100%;
				position: relative;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		.player {
			color: $color-white;
		}

		.info {
			height: 99px;

			&::after {
				clear: both;
				display: block;
				content: "";
			}

			p {
				position: relative;
				top: 50%;
				transform: translateY(-50%);
				padding: 0 10px 0 5px;
				box-sizing: border-box;
				width: 100%;
				left: 100px;
				max-width: 200px;
				margin: 0;
			}

			span {
				display: block;
				font-size: 0.875em;
				line-height: 1.125rem;
			}

			.artist {
				font-weight: 700;
			}
		}

		.play-button {
			background-color: $color-base;

			a {
				position: absolute;
				top: 50%;
				width: 40px;
				height: 40px;
				background: $color-white;
				font-size: 0;
				border-radius: 50%;
				transform: translate(-50%, -50%);
				transition: background 200ms ease;
				z-index: 10000;
				right: 10px;

				&::after {
					font-family: $font-secondary;
					content: "\e606";
					position: absolute;
					font-size: 0.625rem;
					top: 50%;
					left: 50%;
					color: $color-gray-2;
					margin-left: -3px;
					margin-top: -5px;
				}
			}
		}
	}

	.double-image {
		padding: 46px 0 34px 0;
		max-width: 1120px;
		margin: 0 auto;

		.images {
			display: table;
			width: 100%;
		}

		.image {
			display: table-cell;
			vertical-align: top;

			span {
				display: block;
				padding: 0 0 0 5px;
			}

			&:first-child {
				span {
					padding: 0 5px 0 0;
				}
			}
		}

		img {
			width: 100%;
		}

		figcaption {
			padding: 10px 0 0 0;
			color: $color-gray-1;
			font-size: 0.75em;
		}
	}

	.external-video-player {
		width: 90%;
		max-width: 1120px;
		margin: 0 auto;
		padding: 40px 0 34px 0;

		.player-holder {
			position: relative;
			padding-bottom: 56.25%; /* 16:9 aspect ratio */

			&__btn {
				width: 13px;
				height: 13px;
				padding: 0;
				border: none;
				position: absolute;
				top: 12px;
				left: 0;
				background: url(../images/global/icon-sound-unmuted.svg) center center no-repeat;
				cursor: pointer;
				z-index: 1;
				opacity: 0;

				&.is-muted {
					background-image: url(../images/global/icon-sound-muted.svg);
				}
			}

			iframe,
			div.player {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		/* For Amplience Vertical Videos */
		&.vertical {
			max-width: 650px;

			.player-holder div.player {
				position: static;
				transform: none;
			}
		}
	}

	.video-description {
		padding: 10px 0 0 0;
		color: $color-gray-1;
		font-size: 0.75em;

		a {
			display: block;
			color: $color-gray-1;
			text-decoration: none;
		}

		span {
			display: block;
		}
	}

	.grid {
		padding-top: 31px;
		padding-bottom: 44px;
		color: $color-white;
		margin: 0 auto;

		a {
			color: $color-white;
		}
	}

	.product-grid {
		width: 100%;

		.message {
			width: auto;
		}
	}

	.product-grid-item {
		.wishlist {
			svg {
				left: auto;
				transform: translateY(-50%);
			}
		}
	}

	.standard-message {
		margin-bottom: 34px;
		padding-left: 7.5%;
		padding-right: 7.5%;
		box-sizing: border-box;

		a {
			font-style: normal;
		}

		p {
			padding-bottom: 8px;
		}

		.title {
			& + p {
				margin-top: -8px;
			}
			padding-top: 8px;
			padding-bottom: 8px;
			font-size: 0.875em;
			font-style: normal;
			text-transform: none;
		}
	}

	/* To improve CLS for legacy Journal Article images */
	carhartt-image {
		display: block;
		width: 580px;
		height: 435px;
		background-color: #dddbdd;
		position: relative;

		&::after {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transform: translateX(-100%);
			background: linear-gradient(
				90deg,
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 0.75) 35%,
				rgba(255, 255, 255, 0) 100%
			);
			animation: shimmer 3s infinite;
			content: "";
		}

		@keyframes shimmer {
			100% {
				transform: translateX(100%);
			}
		}
		/* Hide image ID text */
		span {
			display: none;
		}
	}
}

h1 {
	padding-top: 0;
}

.buttonBox {
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.buttonBox-item {
	float: left;
	margin: 20px;
	list-style-type: none;
}

.article-image {
	position: relative;
	padding: 46px 0 34px 0;
	clear: both;

	& + p {
		padding-top: 46px;
	}

	&::after {
		content: "";
		display: block;
		clear: both;
	}

	img {
		float: left;
		width: 50%;
		max-width: 480px;
		box-sizing: border-box;
	}

	picture {
		box-sizing: border-box;
	}

	figcaption {
		display: block;
		max-width: 50%;
		padding: 0 0 0 10px;
		color: $color-gray-1;
		font-size: 0.75em;
		box-sizing: border-box;

		a {
			text-decoration: underline;
		}
	}
}

.article-image.right {
	img {
		float: right;
	}

	figcaption {
		float: right;
		padding: 0 10px 0 0;
		text-align: right;
	}
}

.article-image.large {
	figure {
		width: 100%;
		max-width: 1120px;
		margin: 0 auto;
		display: block;
	}

	img {
		width: 100%;
		max-width: none;
		float: none;
	}

	figcaption {
		max-width: none;
		float: none;
		box-sizing: border-box;
		padding: 10px 0 0;
		text-align: left;
	}
}

.article-image.large.left {
	figure {
		float: left;
	}

	figcaption {
		padding: 10px 0 0;
		text-align: left;
	}
}

.article-image.large.middle-left {
	figure {
		float: left;
	}

	figcaption {
		padding: 10px 0 0;
		text-align: left;
	}
}

.article-image.large.right {
	figure {
		float: right;
	}

	figcaption {
		padding: 10px 0 0;
		text-align: left;
	}
}

.article-image.large.middle-right {
	figure {
		float: right;
	}

	figcaption {
		padding: 10px 0 0;
		text-align: left;
	}
}

.article-image.medium {
	figure {
		text-align: left;
		display: table;
	}

	.spacer {
		display: table-cell;
		width: 30.185%;
	}

	picture {
		text-align: right;
		display: table-cell;
		width: 39.63%;
		min-width: 650px;
	}

	img {
		width: 100%;
		max-width: 650px;
		float: none;
	}

	figcaption {
		max-width: none;
		float: none;
		box-sizing: border-box;
		padding: 0 10px;
		text-align: left;
		width: 30.185%;
		margin: 0 auto;
		display: table-cell;
	}
}

.article-image.medium.left {
	figure {
		text-align: left;
		display: block;
	}

	.spacer {
		display: none;
	}

	picture {
		display: block;
		width: 50%;
		text-align: right;
		float: left;
	}

	img {
		width: 100%;
		float: none;
	}

	figcaption {
		float: left;
		display: block;
		margin: 0;
		max-width: none;
		padding: 0 10px;
	}
}

.article-image.medium.middle-left {
	figure {
		text-align: left;
		display: block;
	}

	.spacer {
		display: none;
	}

	picture {
		display: block;
		width: 50%;
		text-align: right;
		float: left;
	}

	img {
		width: 100%;
		float: none;
	}

	figcaption {
		float: left;
		display: block;
		margin: 0;
		max-width: none;
		padding: 0 10px;
	}
}

.article-image.medium.right {
	figure {
		text-align: left;
		display: block;
	}

	.spacer {
		display: none;
	}

	picture {
		display: block;
		width: 50%;
		float: right;
		text-align: left;
	}

	img {
		width: 100%;
		float: none;
	}

	figcaption {
		float: right;
		text-align: right;
		margin: 0;
		width: auto;
		max-width: none;
		padding: 0 10px;
	}
}

.article-image.medium.middle-right {
	figure {
		text-align: left;
		display: block;
	}

	.spacer {
		display: none;
	}

	picture {
		display: block;
		width: 50%;
		float: right;
		text-align: left;
	}

	img {
		width: 100%;
		float: none;
	}

	figcaption {
		float: right;
		text-align: right;
		margin: 0;
		width: auto;
		max-width: none;
		padding: 0 10px;
	}
}

.article-image.small {
	figure {
		text-align: left;
		display: table;
	}

	.spacer {
		width: 36.28%;
		display: table-cell;
	}

	picture {
		display: table-cell;
		text-align: right;
		margin: 0 auto;
		width: 27.44%;
		min-width: 450px;
	}

	img {
		width: 100%;
		max-width: 450px;
		float: none;
	}

	figcaption {
		max-width: none;
		float: none;
		box-sizing: border-box;
		text-align: left;
		margin: 0 auto;
		display: table-cell;
		width: 36.28%;
		padding: 0 10px;
	}
}

.article-image.small.left {
	figure {
		display: block;
	}

	.spacer {
		display: none;
	}

	picture {
		display: block;
		width: 50%;
		float: left;
		text-align: right;
	}

	img {
		width: 100%;
		float: none;
	}

	figcaption {
		float: left;
		margin: 0;
		width: 50%;
		display: block;
	}
}

.article-image.small.middle-left {
	figure {
		display: block;
	}

	.spacer {
		display: none;
	}

	picture {
		display: block;
		width: 50%;
		float: left;
		text-align: right;
	}

	img {
		width: 100%;
		float: none;
	}

	figcaption {
		float: left;
		margin: 0;
		width: 50%;
		display: block;
	}
}

.article-image.small.right {
	figure {
		display: block;
	}

	.spacer {
		display: none;
	}

	picture {
		display: block;
		width: 50%;
		float: right;
		text-align: left;
	}

	img {
		width: 100%;
		float: none;
	}

	figcaption {
		float: right;
		text-align: right;
		margin: 0;
		width: auto;
		max-width: none;
		padding: 0 10px;
	}
}

.article-image.small.middle-right {
	figure {
		display: block;
	}

	.spacer {
		display: none;
	}

	picture {
		display: block;
		width: 50%;
		float: right;
		text-align: left;
	}

	img {
		width: 100%;
		float: none;
	}

	figcaption {
		float: right;
		text-align: right;
		margin: 0;
		width: auto;
		max-width: none;
		padding: 0 10px;
	}
}

.next-article-preview {
	background: $color-blue-1;

	a {
		display: block;
		margin: 0 auto;
		padding: 47px 0;
		color: $color-white;
	}

	h2 {
		width: 87%;
		max-width: 1680px;
		margin: 0 auto;
		padding: 0 0 9px 0;
		font-size: 0.75em;
		text-transform: uppercase;
	}

	h3 {
		width: 87%;
		max-width: 1680px;
		margin: 0 auto;
		padding: 0 0 35px 0;
		font-size: 5em;
		font-weight: 700;
	}
}

.scroll-progress-bar {
	left: 0;
	display: none;
	width: 100%;
	height: 4px;
	z-index: 20;
	background: $color-gray-6;
	position: sticky;
	overflow: hidden;

	.progress {
		width: 0;
		height: 100%;
		background: $color-blue-1;
	}
}

.container {
	& + .article-slideshow {
		clear: both;
	}
}

.article-slideshow {
	position: relative;
	padding: 46px 0 80px 0;
	overflow: hidden;

	.slides-navigation-container {
		position: relative;
		max-width: 1410px;
		margin: 0 auto;
	}

	.slides {
		position: relative;
		width: 100%;
		height: 920px;
		margin: 0 auto;
		overflow: hidden;
		list-style: none;
	}

	.slide {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;

		&:first-child {
			position: relative;
		}

		img {
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-width: 100%;
			max-height: 100%;
			cursor: zoom-in;
			box-sizing: border-box;
		}
	}

	.media {
		height: 100%;
		position: relative;
		width: 80%;
		max-width: 1120px;
		margin: 0 auto;
	}

	picture {
		height: 100%;
	}

	.caption {
		display: none;
		padding: 10px 20px 0 20px;
		font-size: 12px;
		color: $color-gray-1;
	}

	.caption.slide-caption--hidden {
		display: none;
	}

	.navigation {
		&::after {
			content: "";
			clear: both;
			display: table;
		}

		li {
			font-size: 0;
		}

		span {
			display: none;
		}
	}

	.next {
		position: absolute;
		top: 50%;
		padding: 20px;
		transform: translateY(-50%);
		z-index: 2;
		right: 0;

		&::after {
			display: block;
			font-family: $font-secondary;
			font-size: 22px;
			color: $color-gray-1;
			transition: color 0.1s linear;
			content: "\e60a";
		}

		&:hover {
			&::after {
				color: $color-base;
			}
		}
	}

	.previous {
		position: absolute;
		top: 50%;
		padding: 20px;
		transform: translateY(-50%);
		z-index: 2;
		left: 0;

		&::after {
			display: block;
			font-family: $font-secondary;
			font-size: 22px;
			color: $color-gray-1;
			transition: color 0.1s linear;
			content: "\e609";
		}

		&:hover {
			&::after {
				color: $color-base;
			}
		}
	}

	.slideshow-info {
		position: relative;
		color: $color-gray-1;
		font-size: 12px;
		text-align: center;
		padding-top: 10px;
		z-index: 4;

		.captions {
			float: left;
		}

		.counter {
			float: left;
			padding-left: 5px;
		}
	}

	.slideshow-info-inner {
		display: inline-block;
		margin: 0 auto;

		&::after {
			content: "";
			clear: both;
			display: table;
		}
	}

	.grid-button {
		display: block;
		margin: 10px;
		padding: 10px;

		&::before {
			content: "\e602";
			font-family: $font-secondary;
			font-size: 18px;
			font-style: normal;
		}
	}

	.grid-button.active {
		&::before {
			content: "\e604";
		}
	}
}

.article-slideshow.is-not-slideshow {
	img {
		cursor: auto;
	}
}

.grid-display {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	visibility: hidden;
	background: rgb(255, 255, 255);
	transition: opacity 150ms ease, visibility 150ms ease;
	z-index: 3;

	.centered-grid {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
	}

	.grid {
		position: relative;
		left: 50%;
		float: left;
		max-width: 600px;
		list-style: none;

		li {
			position: relative;
			right: 50%;
			float: left;
			padding: 5px;
			box-sizing: border-box;
		}
	}

	.thumbnail {
		display: block;

		img {
			max-height: 100px;
		}

		span {
			display: none;
		}
	}
}

.grid-display-active {
	opacity: 1;
	visibility: visible;
}

.grid-container {
	display: table;
	width: 100%;
	height: 100%;
}

.large-display {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100000;
	background: $color-white;
	overflow: auto;
	opacity: 0;
	visibility: hidden;
}

.large-display-active {
	visibility: visible;
}

.large-display-image {
	margin-bottom: 4px;

	&:last-child {
		margin-bottom: 0;
	}

	img {
		width: 100%;
		cursor: zoom-out;
	}
}

.large-display-close {
	position: fixed;
	top: 0;
	right: 0;
	font-size: 1.25em;
	color: $color-base;
	text-decoration: none;
	z-index: 1;
	font-weight: normal;
	transition: 0.3s all;
	padding: 20px 32px;
	cursor: pointer;

	&:hover {
		color: $color-gray-1;
	}

	&::after {
		content: "\e605";
		font-family: $font-secondary;
	}
}

.soundcloud {
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
	padding: 28px 0 34px 0;

	& + p {
		padding-top: 28px;
	}

	iframe {
		width: 100%;
	}
}

.lead-image {
	& + .soundcloud {
		padding-top: 0;
	}
}

.corporateresponsibility {
	.main-title {
		text-align: center;
		padding-top: 49px;
		padding-bottom: 49px;

		h1 {
			padding: 0;
			line-height: 36px;
			font-size: 30px;
		}

		br {
			display: none;
		}
	}
	color: $color-base;

	.buttonBox {
		margin: 40px 0;
		display: flex;
		width: 100%;
		justify-content: center;
	}

	.lead-image {
		position: relative;
		margin-bottom: 60px;
		padding-top: 0 !important; /* stylelint-disable-line declaration-no-important */

		span.button {
			border-radius: 4px;
			padding: 13px 18px;
			text-transform: none;
			font-size: 0.875em;
			font-weight: 700;
			border: 0;
			min-width: 120px;
			transition: background 0.2s ease;
			background: $color-blue-1;
			color: $color-white;

			&:hover {
				background: $color-blue-3;
			}
		}

		img {
			height: 600px;
			object-fit: cover;
		}

		.options {
			position: absolute;
			left: 50%;
			transform: translate(-50%, 0);
			top: 55%;
			list-style: none;
			display: flex;

			.cta {
				margin: 0 10px;

				a {
					text-align: center;
				}
			}
		}
	}

	h2 {
		position: absolute;
		top: 45%;
		left: 50%;
		transform: translate(-50%, 0);
		color: $color-white;
		width: 80%;
		text-align: center;
		font-size: 1.125em;
	}

	.standard-article {
		padding-bottom: 0;

		&::after {
			display: none;
		}
	}

	.container {
		p {
			padding-bottom: 40px;
		}
	}

	.article-collection {
		.title {
			margin-top: 16px;
			margin-bottom: 6px;
			display: block;
		}

		article {
			.subtitle {
				max-width: 480px;
			}

			> a {
				.image {
					max-width: none;
				}
			}
			margin-left: 30px;
			margin-right: 30px;
			margin-bottom: 0;
		}

		.content {
			padding-top: 60px;
		}

		.tags {
			margin-top: 12px;
		}
	}
}

.journal-landing-page {
	.breadcrumb {
		display: none;
	}
}

@media only screen and (min-width: calc($tablet + 1px)) {
	.standard-article {
		.soundcloud-player {
			.player-holder {
				right: 60%;
			}
		}
	}
}

@media only screen and (min-width: calc($desktop + 1px)) {
	.standard-article {
		.soundcloud-player {
			.player-holder {
				right: 70%;
			}
		}
	}
}

@media only screen and (max-width: $tablet) {
	.lead-image.soundcloud-player.boxed {
		padding-bottom: 100px;
	}

	.standard-article {
		h1 {
			font-size: 3.125em;
		}

		.container {
			width: 100%;
			padding: 0;
		}

		.lead-image {
			margin-bottom: 34px;
		}

		.soundcloud-player {
			.content {
				width: 100%;
			}

			.player {
				position: relative;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		.questionnaire {
			dd {
				padding-left: 0;
			}
		}

		.double-image {
			padding: 0 0 34px;

			.images {
				display: block;
			}

			.image {
				display: block;
				padding: 31px 0 34px 0;

				&:first-child {
					span {
						padding-left: 0;
						padding-right: 0;
					}

					figcaption {
						padding: 10px 20px 0;
					}
				}

				span {
					padding-left: 0;
					padding-right: 0;
				}

				&:last-child {
					padding-bottom: 0;
				}
			}

			figcaption {
				padding: 10px 20px 0;
			}
		}

		.standard-message {
			.title {
				margin-bottom: 0;
			}
		}
	}

	.article-image.large {
		margin: 0;
		max-width: none;
		width: 100%;

		figure {
			margin: 0;
			width: 100%;
		}

		figcaption {
			max-width: none;
			text-align: left;
			padding: 10px 20px 0;
			float: none;
			width: 100%;
			display: block;
		}
	}

	.article-image {
		width: 100%;
		text-align: center;
		padding: 31px 0 34px;

		.spacer {
			display: none;
		}

		img {
			width: 100%;
			max-width: none;
			display: block;
		}
	}

	.article-image.left {
		figcaption {
			position: static;
			float: left;
			clear: left;
			width: 100%;
			display: block;
			max-width: none;
			padding: 14px 20px 0 20px;
			text-align: left;
			box-sizing: border-box;
		}
	}

	.article-image.right {
		figcaption {
			position: static;
			float: left;
			clear: left;
			width: 100%;
			display: block;
			max-width: none;
			padding: 14px 20px 0 20px;
			text-align: left;
			box-sizing: border-box;
		}
	}

	.article-image.middle-left {
		figcaption {
			position: static;
			float: left;
			clear: left;
			width: 100%;
			display: block;
			max-width: none;
			padding: 14px 20px 0 20px;
			text-align: left;
			box-sizing: border-box;
		}
	}

	.article-image.middle-right {
		figcaption {
			position: static;
			float: left;
			clear: left;
			width: 100%;
			display: block;
			max-width: none;
			padding: 14px 20px 0 20px;
			text-align: left;
			box-sizing: border-box;
		}
	}

	.article-image.small {
		max-width: none;
		width: 100%;

		figure {
			display: block;
		}

		picture {
			display: block;
			min-width: 0;
			float: none;
			width: 100%;
		}

		img {
			padding: 0;
			max-width: none;
		}

		figcaption {
			max-width: none;
			text-align: left;
			padding: 10px 20px 0;
			float: none;
			width: 100%;
			display: block;
		}
	}

	.article-image.small.left {
		max-width: none;
		width: 100%;

		picture {
			width: 100%;
			float: none;
		}

		img {
			padding: 0;
			max-width: none;
		}

		figcaption {
			max-width: none;
			text-align: left;
			padding: 10px 20px 0;
			float: none;
			width: 100%;
			display: block;
		}
	}

	.article-image.small.right {
		max-width: none;
		width: 100%;

		picture {
			width: 100%;
			float: none;
		}

		img {
			padding: 0;
			max-width: none;
		}

		figcaption {
			max-width: none;
			text-align: left;
			padding: 10px 20px 0;
			float: none;
			width: 100%;
			display: block;
		}
	}

	.article-image.medium {
		max-width: none;
		width: 100%;

		figure {
			display: block;
		}

		picture {
			display: block;
			min-width: 0;
			float: none;
			width: 100%;
		}

		img {
			padding: 0;
			max-width: none;
		}

		figcaption {
			max-width: none;
			text-align: left;
			padding: 10px 20px 0;
			float: none;
			width: 100%;
			display: block;
		}
	}

	.article-image.medium.left {
		max-width: none;
		width: 100%;

		picture {
			width: 100%;
			float: none;
		}

		img {
			padding: 0;
			max-width: none;
		}

		figcaption {
			max-width: none;
			text-align: left;
			padding: 10px 20px 0;
			float: none;
			width: 100%;
			display: block;
		}
	}

	.article-image.medium.right {
		max-width: none;
		width: 100%;

		picture {
			width: 100%;
			float: none;
		}

		img {
			padding: 0;
			max-width: none;
		}

		figcaption {
			max-width: none;
			text-align: left;
			padding: 10px 20px 0;
			float: none;
			width: 100%;
			display: block;
		}
	}

	.article-image.large.left {
		max-width: none;
		width: 100%;

		figcaption {
			max-width: none;
			text-align: left;
			padding: 10px 20px 0;
			float: none;
			width: 100%;
			display: block;
		}
	}

	.article-image.large.right {
		max-width: none;
		width: 100%;

		figcaption {
			max-width: none;
			text-align: left;
			padding: 10px 20px 0;
			float: none;
			width: 100%;
			display: block;
		}
	}

	.article-image.medium.middle-left {
		picture {
			width: 100%;
			float: none;
		}

		img {
			padding: 0;
			max-width: none;
		}

		figcaption {
			max-width: none;
			text-align: left;
			padding: 10px 20px 0;
			float: none;
			width: 100%;
			display: block;
		}
	}

	.article-image.small.middle-left {
		picture {
			width: 100%;
			float: none;
		}

		img {
			padding: 0;
			max-width: none;
		}

		figcaption {
			max-width: none;
			text-align: left;
			padding: 10px 20px 0;
			float: none;
			width: 100%;
			display: block;
		}
	}

	.article-image.medium.middle-right {
		picture {
			width: 100%;
			float: none;
		}

		img {
			padding: 0;
			max-width: none;
		}

		figcaption {
			max-width: none;
			text-align: left;
			padding: 10px 20px 0;
			float: none;
			width: 100%;
			display: block;
		}
	}

	.article-image.small.middle-right {
		picture {
			width: 100%;
			float: none;
		}

		img {
			padding: 0;
			max-width: none;
		}

		figcaption {
			max-width: none;
			text-align: left;
			padding: 10px 20px 0;
			float: none;
			width: 100%;
			display: block;
		}
	}

	.article-image.large.middle-left {
		figcaption {
			max-width: none;
			text-align: left;
			padding: 10px 20px 0;
			float: none;
			width: 100%;
			display: block;
		}
	}

	.article-image.large.middle-right {
		figcaption {
			max-width: none;
			text-align: left;
			padding: 10px 20px 0;
			float: none;
			width: 100%;
			display: block;
		}
	}
}

@media only screen and (max-width: $mobile-lg) {
	.standard-article {
		p {
			width: auto;
			margin: 0 20px;
		}

		.questionnaire {
			width: auto;
			margin: 0 20px;
		}
		width: auto;

		h1 {
			margin: 0 20px;
			font-size: 1.875em;
			line-height: 36px;
		}

		.lead-image {
			width: 100%;
			margin: 0;
			padding-top: 31px;
			margin-bottom: 34px;

			figcaption {
				padding-left: 20px;
			}
		}

		.article-details {
			margin-left: 20px;
		}

		&::after {
			padding: 26px 0 0 0;
		}

		.soundcloud-player {
			.player {
				float: none;
			}

			.info {
				p {
					margin: 0;
				}
			}

			.play-button {
				float: right;
			}
		}

		.external-video-player {
			width: 100%;
			margin: 0 auto;
			padding: 6px 0 34px 0;
		}

		.video-description {
			padding: 10px 20px 0 20px;
		}

		.grid {
			padding-top: 0;
			padding-bottom: 0;
		}

		.standard-message {
			width: 100%;
			margin-left: 0;
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.article-image {
		& + p {
			padding-top: 31px;
		}
	}

	.double-image {
		& + p {
			padding-top: 31px;
		}
	}

	.standard-message {
		& + p {
			padding-top: 31px;
		}
	}

	.soundcloud {
		width: auto;
		margin: 20px;
		padding-top: 0;
		padding-bottom: 0;
	}
}

@media only screen and (min-width: 1640px) {
	.article-image {
		width: 1430px;
		margin: 0 auto;
	}

	.article-image.large {
		width: 100%;
	}

	.article-image.medium {
		width: 100%;
	}

	.article-image.small {
		width: 100%;
	}

	.standard-article {
		.double-image {
			width: 1430px;
			margin: 0 auto;
		}
	}
}

@media only screen and (max-width: $desktop) {
	.article-image.large {
		margin: 0 -30px;

		figure {
			margin: 0;
		}

		figcaption {
			padding: 10px 20px 0;
			text-align: left;
		}
	}

	.article-image.medium {
		figure {
			display: block;
			max-width: 650px;
			margin: 0 auto;
		}

		.spacer {
			display: none;
		}

		picture {
			display: block;
			width: auto;
		}

		figcaption {
			display: block;
			width: auto;
			padding: 10px 0;
			clear: both;
			float: none;
			text-align: left;
		}
	}

	.article-image.medium.left {
		figure {
			margin: 0;
		}

		figcaption {
			display: block;
			width: auto;
			padding: 10px 0;
			clear: both;
			float: none;
			text-align: left;
		}
	}

	.article-image.medium.middle-left {
		figure {
			margin: 0;
		}

		figcaption {
			display: block;
			width: auto;
			padding: 10px 0;
			clear: both;
			float: none;
			text-align: left;
		}
	}

	.article-image.medium.middle-right {
		figure {
			margin: 0;
			float: right;
		}

		figcaption {
			display: block;
			width: auto;
			padding: 10px 0;
			clear: both;
			float: none;
			text-align: left;
		}
	}

	.article-image.medium.right {
		figure {
			margin: 0;
			float: right;
		}

		figcaption {
			display: block;
			width: auto;
			padding: 10px 0;
			clear: both;
			float: none;
			text-align: left;
		}
	}

	.article-image.small {
		figure {
			display: block;
			max-width: 450px;
			margin: 0 auto;
		}

		figcaption {
			display: block;
			width: auto;
			padding: 10px 0;
			clear: both;
			float: none;
			text-align: left;
		}
	}

	.article-image.small.left {
		figure {
			margin: 0;
		}

		figcaption {
			display: block;
			width: auto;
			padding: 10px 0;
			clear: both;
			float: none;
			text-align: left;
		}
	}

	.article-image.small.middle-left {
		figure {
			margin: 0;
		}

		figcaption {
			display: block;
			width: auto;
			padding: 10px 0;
			clear: both;
			float: none;
			text-align: left;
		}
	}

	.article-image.small.middle-right {
		figure {
			margin: 0;
			float: right;
		}

		figcaption {
			display: block;
			width: auto;
			padding: 10px 0;
			clear: both;
			float: none;
			text-align: left;
		}
	}

	.article-image.small.right {
		figure {
			margin: 0;
			float: right;
		}

		figcaption {
			display: block;
			width: auto;
			padding: 10px 0;
			clear: both;
			float: none;
			text-align: left;
		}
	}

	.article-image {
		figcaption {
			display: block;
			width: auto;
			padding: 10px 0;
			clear: both;
			float: none;
			text-align: left;
		}
	}

	.article-image.large.right {
		figcaption {
			padding: 10px 20px 0;
			text-align: left;
		}
	}

	.article-image.large.left {
		figcaption {
			padding: 10px 20px 0;
			text-align: left;
		}
	}
}

@media (max-width: 1200px) {
	.article-slideshow {
		.slides {
			height: 800px;
		}
	}
}

@media (max-width: $desktop) {
	.next-article-preview {
		h3 {
			padding-bottom: 15px;
			font-size: 3em;
		}
	}

	.article-slideshow {
		.slides {
			height: 600px;
		}
	}

	.corporateresponsibility {
		.main-title {
			h1 {
				font-size: 26px;
				line-height: 31px;
				min-height: 93px;
				display: flex;
				align-items: center;
				text-align: center;
				justify-content: center;
			}
		}

		.buttonBox {
			flex-direction: column;
			margin-top: 20px;
		}

		.lead-image {
			img {
				height: 450px;
			}

			.options {
				flex-direction: column;
				top: 60%;

				.cta {
					margin: 10px 0;
				}
			}
		}

		h2 {
			top: 30%;
			font-size: 1.5em;
			line-height: 2.5rem;
		}

		.container {
			p {
				padding-bottom: 50px;
			}
		}

		.standard-article {
			p {
				padding-left: 34px;
				padding-right: 34px;
				width: auto;
			}
		}

		.buttonBox-item {
			margin: 10px 0;
		}

		.article-collection {
			.content {
				padding-top: 70px;
			}

			.subtitle {
				max-width: none !important; /* stylelint-disable-line declaration-no-important */
			}

			article {
				margin: 0 34px 60px;
			}
		}

		.breadcrumb {
			display: none;
		}
	}
}

@media (max-width: 860px) {
	.article-slideshow {
		.slides {
			height: 500px;
		}
	}
}

@media (max-width: $tablet) {
	.standard-article {
		.external-video-player {
			padding: 16px 0 34px 0;
		}
	}

	.next-article-preview {
		a {
			display: block;
			width: 100%;
		}

		h2 {
			width: auto;
			padding-left: 20px;
			padding-right: 20px;
		}

		h3 {
			width: auto;
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 10px;
			font-size: 1.875em;
		}
	}

	.article-slideshow {
		padding-bottom: 0;

		.slides {
			position: static;
			height: auto;
			overflow: auto;
		}

		.slide {
			position: static;
			top: auto;
			left: auto;
			text-align: center;
			padding-bottom: 65px;

			&:first-child {
				position: static;
			}

			img {
				position: static;
				top: auto;
				left: auto;
				width: 100%;
				max-width: 100%;
				max-height: none;
				transform: translate(0);
				border: 0;
				cursor: auto;
			}
		}

		.media {
			width: 100%;
		}

		.caption {
			display: block;
			text-align: left;
		}

		.caption.slide-caption--hidden {
			display: none;
		}

		.navigation {
			display: none;
		}

		.slideshow-info {
			display: none;
		}

		.grid-display {
			display: none;
		}

		.grid-button {
			display: none;
		}
	}
}

@media only screen and (max-width: 1200px) and (min-width: 849px) {
	.grid-display {
		.grid {
			max-width: none;
		}
	}
}

@media (min-width: calc($desktop + 1px)) {
	.corporateresponsibility {
		.main-title {
			padding-top: 37px;
			padding-bottom: 87px;
		}
	}
}

@media screen and (min-width: calc($desktop + 1px)) {
	.corporateresponsibility {
		.breadcrumb {
			height: 50px;
			display: flex;
			align-items: center;
			margin-top: 2px;

			li {
				&:not(:last-child) {
					&::after {
						margin-left: 2px;
					}
				}
			}
		}

		.lead-image {
			figure {
				&::after {
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					opacity: 0;
					visibility: hidden;
					transition: opacity 200ms ease, visibility 200ms ease;
					background: rgba(255, 255, 255, 0.7);
				}

				&:hover {
					&::after {
						opacity: 1;
						visibility: visible;
						pointer-events: none;
					}
				}
			}

			&:hover {
				h2 {
					color: $color-black;
				}
			}
		}
	}
}
