/**
 * Product detail page
 */
#page-content.product-detail-page {
	position: relative;
}

.product-detail-page {
	.breadcrumb {
		position: absolute;
		z-index: 11;
		font-weight: 700;
		font-size: 13px;
		height: 50px;
		display: flex;
		align-items: center;
		line-height: 13px;
		padding-top: 1px;
		width: auto;
		top: 0;

		ul {
			list-style: none;
			margin-left: 1rem;
			padding: 0;
			max-width: 100%;
		}

		li {
			color: $color-gray-5;
		}

		a {
			color: $text-color;
			transition: color 0.2s;

			&:hover {
				color: $color-gray-17;
			}
		}
	}

	.breadcrumb[data-light="true"] {
		a {
			color: $color-gray-17;

			&:hover {
				color: $color-gray-1;
			}
		}
	}

	.background-colour {
		&:nth-child(odd) {
			background: $color-white;
		}

		&:nth-child(even) {
			background: $color-gray-16;
		}
	}
}

.product-header {
	position: relative;
	z-index: 10;
	display: flex;
	flex-direction: column;

	@media (min-width: 850px) {
		background-color: $color-gray-18;
		flex-direction: row;
		align-content: center;
	}

	&.active {
		z-index: auto;
	}
}

.product-media {
	@media (min-width: 850px) {
		width: 60%;
	}

	@media (min-width: calc($tablet + 1px)) {
		width: 55%;
	}

	@media (min-width: 1900px) {
		width: 70%;
	}

	&.loading {
		.main img,
		.main .hover-area::after .additional img {
			cursor: wait;
		}
	}

	.slides {
		position: relative;
		overflow: hidden;
		width: 100vw;
		max-width: 100%;

		@media (min-width: calc($mobile-lg + 1px)) {
			min-height: 85vh;
		}

		@media (min-width: 850px) {
			display: flex;
			justify-content: center;
			flex-direction: column;
			min-height: 96vh;
		}

		@media (min-aspect-ratio: 21/9) and (min-width: 2480px) {
			min-height: 140vh;
		}

		@media (min-width: 1900px) {
			margin-top: -60px;
		}

		@media (min-width: 2400px) {
			min-height: 100vh;
			margin-top: 0;
		}

		@media (min-width: 3100px) {
			min-height: 85vh;
		}

		@media (min-width: 4000px) and (min-height: 3000px) {
			min-height: 65vh;
		}

		li img {
			width: 100%;
			height: auto;
			text-indent: 100%;
			white-space: nowrap;
			overflow: hidden;

			@media (min-width: calc($tablet + 1px)) {
				object-fit: cover;
				height: 90vh;
			}

			@media (min-width: 850px) {
				width: 100%;
				height: auto;
				object-fit: none;
			}
		}
	}

	.slideshow {
		position: relative;

		.pip {
			a {
				border-color: $color-gray-1;
			}

			&.active a {
				background: $color-gray-1;
			}
		}

		.slides li {
			display: block;
		}
	}

	.mouse-tooltip {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		color: $color-blue-1;
		padding: 3px 5px;
		border-radius: 4px;
		opacity: 0;
		font-weight: 400;
		font-size: 0.875em;
		z-index: 20;
		transition: opacity 0.1s ease, z-index 0.1s ease;
		background: $color-white;
	}

	.content {
		.image {
			height: 100%;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;
		}
	}

	.image {
		&::after {
			clear: both;
			display: block;
			content: "";
		}
	}

	.image-holder {
		width: 65%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		padding-bottom: 154%;
		@media (min-width: calc($desktop + 1px)) {
			padding-bottom: 0;
		}
		img {
			width: 100%;
			position: absolute;
			top: 0;
			transform: none;

			@media (min-width: calc($desktop + 1px)) {
				position: static;
			}
		}
		&.type-of {
			padding-bottom: 135%;
			@media (min-width: calc($desktop + 1px)) {
				padding-bottom: 0;
			}
		}
	}

	.main {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 50% 50%;

		a {
			&::after {
				display: block;
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 200%;
				height: 100%;
				background: rgba(0, 0, 0, 0.1);
				opacity: 0;
				visibility: hidden;
				transition: opacity 200ms 100ms ease, visibility 200ms 100ms ease;
			}
		}

		.hover-area {
			display: block;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 50%;
			height: 100%;
			transition: all 200ms ease;
			z-index: 1;
			cursor: pointer;
		}
	}

	.additional {
		position: absolute;
		top: 0;
		left: 20px;
		width: 50px;
		height: 50px;
		z-index: 1;

		@media (min-width: 850px) {
			position: relative;
			margin-bottom: 14px;
		}

		img {
			width: 100%;
			display: block;
			border: 2px solid $color-gray-9;
			transition: border-color 200ms ease;
		}

		&:hover {
			img {
				border-color: $color-base;
			}
		}
	}
}

.product-details {
	@media (min-width: 850px) {
		max-width: 400px;
		width: 38%;
		margin-right: 2%;
	}

	@media (min-width: calc($tablet + 1px)) {
		margin-right: 7%;
	}

	@media (min-width: 1900px) {
		width: 28%;
		margin-right: 5%;
	}

	.holder {
		display: table;
		width: 100%;
		height: 100%;

		> .content-holder {
			display: table-cell;
			width: 100%;
			height: 100%;
			vertical-align: middle;
		}

		.content-holder {
			> .content {
				width: 100%;
				max-width: 400px;
				margin: 0 auto;
			}
		}
	}

	> .content {
		&::after {
			content: "";
			display: block;
			clear: both;
		}
	}

	.product-title {
		margin: 0 0 10px 0;
		font-size: 1.5em;
		line-height: 2.063rem;
		font-weight: 700;
		padding: 0 20px;

		h1 {
			font-size: 1em;
			display: inline;
			font-weight: 700;
		}
	}

	.product-title.sale {
		> .price {
			text-decoration: line-through;
		}

		> .currency {
			color: $color-red-1;
		}
	}

	.price {
		display: inline;
		color: $color-gray-1;
	}

	.sale-price {
		color: $color-red-1;
		display: inline;
		margin-left: 5px;
	}

	.strikeout {
		text-decoration: line-through;
		color: $color-gray-3;
		padding: 0 2px;

		.sale-price {
			text-decoration: line-through;
		}

		.product-title.sale {
			> .currency {
				text-decoration: line-through;
			}
		}
	}

	select {
		width: 100%;
		background: transparent;
		display: block;
		color: $color-base;
		line-height: 2em;
		border-width: 0 0 2px;
		border-style: solid;
		border-color: $color-gray-6;
		transition: border-color 200ms ease;
		font-size: 0.875em;
		padding: 5px 0;
	}

	.product-colour {
		list-style: none;
		margin: 17px 0 10px 0;
		padding: 0 25px 0 20px;
	}

	.colour {
		position: relative;
		display: inline-block;
		margin: 5px 10px 5px 0;

		.color--sold-out {
			.tip {
				white-space: nowrap;

				span {
					text-decoration: line-through;
				}
			}

			.alt-radio {
				&::before {
					background-color: $color-base;
					content: "";
					height: 3px;
					left: -5px;
					position: absolute;
					top: 7px;
					transform: rotate(45deg);
					width: 25px;
				}
			}
		}

		input {
			display: none;

			&:checked {
				& + label {
					.alt-radio {
						&::after {
							content: "";
							position: absolute;
							top: -5px;
							left: -5px;
							width: 22px;
							height: 22px;
							border: 2px solid $color-base;
							border-radius: 50%;
						}
					}
				}
			}
		}

		label {
			.alt-radio {
				position: relative;
				width: 16px;
				height: 16px;
				border-radius: 50%;
				cursor: pointer;
			}

			.alt-radio.border {
				border-width: 1px;
				border-color: $color-base;
				border-style: solid;
			}
		}

		.tip {
			display: none;
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			padding: 10px;
			margin-top: 13px;
			background: $color-white;
			border-radius: 4px;
			box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.2);
			text-align: center;
			font-size: 0.75em;
			z-index: 1;
		}

		&:hover {
			> .tip {
				display: inline;
			}
		}
	}

	.tip {
		span {
			white-space: nowrap;
		}
	}

	.selected-colour {
		display: none;
		margin-bottom: 10px;
		font-size: 0.75em;
		text-align: center;
	}

	.wishlist {
		a {
			span {
				display: none;
			}
		}

		.heart {
			position: relative;
			height: 17px;
			width: 20px;
		}

		.remove {
			position: relative;
			height: 17px;
			width: 20px;
		}

		svg {
			position: absolute;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.wishlist.wishlisted {
		.heart {
			path {
				fill: $color-red-5;
			}
		}
	}
}

.select-box {
	position: relative;
	box-sizing: border-box;
	padding: 0 20px;

	legend {
		display: none;
	}

	.selection {
		display: none;
		font-size: 0.875em;
		font-weight: 700;
	}
}

.select-box.open {
	position: relative;
	background: $color-white;
	box-shadow: 3px 4px 7px 0 rgba(0, 0, 0, 0.2);
	border-radius: 2px;
}

.standard-dropdown {
	width: 100%;
	overflow: hidden;
	color: $color-gray-1;
	font-size: 0.875em;
	min-height: 35px;

	> .current {
		display: block;
		color: $color-base;
		background: transparent url(../images/global/dropdown-arrow-light.svg) no-repeat 100% 50%;
		line-height: 2.2em;
		border-width: 0 0 2px;
		border-style: solid;
		border-color: $color-gray-6;
		transition: border-color 200ms ease;

		&:hover {
			border-color: $color-base;
		}
	}

	> .current.prompt {
		color: $color-red-1;
		border-color: $color-red-1;
	}

	> .current.active {
		background-image: url(../images/global/dropdown-arrow-light-up.svg);
		border-color: transparent;
		position: relative;
		padding: 0;
	}

	> .content {
		position: absolute;
		top: 100%;
		left: 0;
		overflow: hidden;
		background: $color-white;
		box-shadow: 4px 5px 7px 0 rgba(0, 0, 0, 0.2);
		z-index: 100;
		border-bottom-right-radius: 2px;
		border-bottom-left-radius: 2px;
		margin-top: -1px;
		width: 100%;
		box-sizing: border-box;

		&.position-top {
			top: auto;
			bottom: 100%;
			box-shadow: 4px -5px 7px 0 rgba(0, 0, 0, 0.2);
			margin-top: 0;
			margin-bottom: -7px;
		}

		ul {
			list-style: none;
		}

		li {
			button {
				border: none;
				background: none;
				outline: none;
				cursor: pointer;
			}

			a,
			button {
				display: block;
				margin: 0 10px;
				padding: 6px 10px;
				color: $color-base;
				outline: none;

				&:hover {
					padding: 6px 30px;
					background: $color-gray-8;
				}

				&.active {
					font-weight: 700;
				}
			}
		}
	}

	.scrollable {
		overflow: hidden;
		margin: 0;
	}

	.scrollbar {
		position: absolute;
		right: 13px;
		top: 12px;
		bottom: 20px;
		width: 9px;
		visibility: hidden;
	}

	.scrollable.scroll-enabled {
		& + .scrollbar {
			visibility: visible;
		}
	}

	.track {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		background: $color-gray-6;
	}

	.handle {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 50px;
		background: $color-gray-1;
	}
}

.product-details[data-light="true"] {
	.standard-dropdown {
		> .current {
			background-image: url(../images/global/dropdown-arrow-dark.svg);
		}

		> .current.active {
			border-color: transparent;
			position: relative;
			padding: 0;
			background-image: url(../images/global/dropdown-arrow-dark-up.svg);
		}
	}

	.standard-dropdown.single {
		> .current {
			background-image: none;
		}
	}

	.product-notifications {
		ul {
			li {
				&::before {
					color: $color-base;
				}
			}
		}
	}

	.product-actions {
		a {
			color: $color-base;
		}
	}

	.wishlist {
		.heart {
			path {
				fill: $color-base;
			}
		}
	}

	.wishlisted {
		.heart {
			path {
				fill: $color-red-5;
			}
		}
	}
}

.standard-dropdown.single {
	> .current {
		background-image: none;

		&:hover {
			border-color: $color-gray-6;
		}
	}
}

.size-options {
	button {
		font-size: 100%;
	}
	.standard-dropdown {
		> .content {
			li {
				button {
					width: calc(100% - 6px);
				}

				a,
				button {
					margin: 0 3px;
					padding: 6px 10px;

					&:hover {
						padding: 6px 10px;
						background: $color-gray-8;
					}

					&.active {
						font-weight: 700;
					}
				}
			}

			&.position-top {
				.scrollable {
					margin: 20px 0 12px;
				}
			}
		}

		.scrollable {
			overflow: hidden;
			margin: 12px 0 20px;
		}
	}
	display: table;
	width: 100%;

	&::after {
		display: block;
		content: "";
		clear: both;
	}

	.options {
		display: table-cell;
		width: 40px;
		padding: 0 20px 0 0;

		.guide {
			display: block;
			font-size: 0.875em;
			white-space: nowrap;
			border-bottom: 2px solid $color-gray-6;
			line-height: 2.2em;
			cursor: pointer;
			color: $color-base;
			text-decoration: none;

			&:hover {
				border-color: $color-base;
			}
		}
	}
}

.product-size {
	.selection {
		cursor: pointer;
		font-weight: 400;
	}

	.selection.selected {
		cursor: auto;
		font-weight: 700;
	}
	display: table-cell;

	.standard-dropdown {
		> .content {
			li {
				display: inline-block;
				width: 33.333%;
				padding: 0 0 10px 0;
				text-align: center;

				&:first-child {
					display: none;
				}
			}

			li.na {
				button {
					text-decoration: line-through;
					color: $color-gray-17;
					cursor: default;

					&:hover {
						background: none;
					}
				}
			}
		}
	}
}

.product-size.open {
	border-bottom-right-radius: 0;

	.standard-dropdown {
		> .content {
			ul {
				margin: 0 auto;
				list-style: none;
				padding: 0 4px;
			}

			.scroll-enabled {
				ul {
					padding-right: 23px;
				}
			}
		}
	}
}

.product-submit {
	width: calc(100% - 40px);
	display: block;
	border: 0;
	background: $color-base;
	margin: 20px;
	padding: 20px 0;
	border-radius: 4px;
	color: $color-white;
	font-size: 0.875em;
	outline: none;
	cursor: pointer;
	font-weight: 700;
	transition: background 200ms 50ms ease;
	box-sizing: border-box;

	@media (hover: hover) {
		&:hover {
			background: $color-gray-1;
		}
	}

	&.submit-disabled {
		&:hover {
			background: $color-base;
		}
	}

	&--app-exclusive {
		background-color: $light-color;
		color: $color-gray-5;
		cursor: auto;

		&:hover {
			background-color: $light-color;
			color: $color-gray-5;
		}
		&.submit-disabled {
			&:hover {
				background: $light-color;
			}
		}
	}

	&.in-progress {
		cursor: default;
		color: $color-white;
		background: $color-gray-1;
	}
}

.product-notifications {
	float: left;
	width: 80%;
	box-sizing: border-box;
	padding: 0 0 0 20px;

	ul {
		list-style: none;
		font-size: 0.875em;

		li {
			padding: 4px 0 4px 23px;
			position: relative;

			&::before {
				position: absolute;
				top: 9px;
				left: 0;
				content: "\e616";
				font-family: $font-secondary;
				font-size: 0.625rem;
				color: $color-gray-17;
				transition: all 0.4s;
			}

			&:hover {
				&::before {
					color: $color-base;
				}
			}
		}

		li.more {
			a {
				text-decoration: underline;
			}
		}

		.more {
			&::before {
				content: "\e60c";
				left: 3px;
			}
		}
	}

	a {
		color: $color-base;
	}

	.availability.active {
		> a {
			display: inline-block;
			color: $color-green-4;
		}

		.extra {
			display: block;
			margin-left: 20px;
		}
	}

	.availability {
		&::before {
			color: $color-green-4;
		}

		.extra {
			display: none;
		}
	}
}

.product-actions {
	float: right;
	width: 20%;
	text-align: right;
	padding: 5px 20px 0 0;
	box-sizing: border-box;

	a {
		display: block;
		margin: 0 0 10px 5px;
		color: $color-gray-17;
	}

	.wishlist {
		display: inline-block;
		transition: 0.2s all;
		height: 17px;
		width: 20px;
		position: relative;

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			left: -10px;
			top: -10px;
			padding: 10px;
			transition: 0.2s all;
			text-align: center;
			height: 20px;
			width: 20px;
		}

		.message {
			display: block;
			position: absolute;
			right: 100%;
			top: 8px;
			color: $color-blue-1;
			padding: 3px 5px;
			border-radius: 4px;
			margin: 0;
			opacity: 0;
			white-space: nowrap;
			font-weight: 400;
			font-size: 0.875em;
			z-index: 20;
			background: $color-white;
			transition: opacity 0.4s ease 1s, z-index 0.4s ease 1s;
		}

		.message.show {
			transition: opacity 0.4s ease, z-index 0.4s ease;
			opacity: 1;
			z-index: 100;
		}

		.remove {
			display: none;

			path {
				fill: $color-red-5;
			}
		}

		.heart {
			padding: 0 0 0 2px;

			path {
				fill: $color-gray-1;
			}
		}
	}

	.wishlist.wishlisted {
		opacity: 1;
	}

	.share-panel-trigger {
		display: block;
		margin: 0 0 10px 5px;
	}
}

.touch {
	.product-details {
		.wishlist {
			svg {
				position: absolute;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.wishlist.wishlisted {
			.heart {
				path {
					fill: $color-red-5;
				}
			}
		}
	}

	.product-details[data-light="true"] {
		.wishlisted {
			.heart {
				path {
					fill: $color-red-5;
				}
			}
		}
	}
}

.share-panel {
	display: inline-block;
	list-style: none;
	position: relative;

	ul {
		position: absolute;
		top: 100%;
		right: 0;
		list-style: none;
		opacity: 0;
		visibility: hidden;
		text-align: center;
		transition: all 200ms 50ms ease;

		li {
			a {
				margin: 0 0 10px;
				color: $color-base;

				path {
					fill: $color-base;
				}

				polygon {
					fill: $color-base;
				}

				circle {
					fill: $color-base;
				}
			}
		}
	}

	a {
		transition: 0.2s color;
		cursor: pointer;
	}

	.share-panel-trigger {
		transition: 0.2s color;
		cursor: pointer;

		span {
			display: none;
		}

		&::after {
			font-family: $font-secondary;
			content: "\e615";
		}
	}

	&:hover {
		a {
			color: $color-base;
		}

		.share-panel-trigger {
			color: $color-base;
		}
	}

	> li {
		padding: 0 0 0 5px;
	}
}

.share-panel.horizontal {
	ul {
		width: 160px;
		padding: 0 0 0 15px;
		top: 0;
		left: 100%;
	}

	li {
		float: left;
		display: block;
		text-align: center;
		padding: 0 0 0 15px;
	}

	> li {
		padding: 0 0 0 15px;
	}
}

.share-panel-trigger.active {
	& + ul {
		opacity: 1;
		visibility: visible;
	}
}

.product-size-guide {
	background: $color-gray-12;
	color: $color-white;
	padding: 50px 0;

	.content {
		width: 100%;
		max-width: 1680px;
		margin: 0 auto;
		text-align: center;

		> h1 {
			font-size: 1em;
			margin-bottom: 20px;
		}
	}

	.slideshow {
		overflow: hidden;
	}

	.slides {
		position: relative;
		list-style: none;
		z-index: 0;
	}

	.slide {
		&:first-child {
			position: relative;
		}
		background: $color-gray-12;
	}
}

.product-suggestion {
	overflow-x: hidden;

	.product-grid {
		margin: 0 auto;
	}
}

.close {
	& ~ span {
		display: none;
	}
}

.product-banner-app-exclusive {
	background: $color-blue-1;
	padding: 20px 0;
	color: $color-white;
	font-size: 0.75rem;
	line-height: 0.9rem;
	font-weight: 700;
	text-align: center;

	& a {
		color: $color-white;
		text-decoration: underline;
	}
}

@media screen and (max-width: $desktop) {
	.product-detail-page {
		.breadcrumb {
			display: none;
		}
	}
}

@media screen and (max-width: $tablet) {
	.product-detail-page {
		.background-colour {
			&:nth-child(even)[data-items="3"] {
				display: none;
			}
		}
	}

	.section-title {
		padding: 50px 15px 50px 15px;

		.title {
			text-align: left;
		}
	}
}

@media (min-width: calc($desktop + 1px)) {
	.product-media {
		.main {
			.image-holder {
				img {
					max-width: 100%;
					width: auto;
					max-height: calc(100vh - 150px);
				}
			}
		}
	}

	.product-banner-app-exclusive {
		padding: 15px;

		@media (min-width: 2400px) {
			top: -118px;
		}
	}
}

@media (max-width: 1300px) {
	.product-media {
		.image-holder {
			width: 45%;
		}
	}
}

@media (min-width: 850px) {
	.product-media {
		.image-holder {
			padding-bottom: 0;
			margin-left: 0;
		}

		.main {
			position: absolute;
			width: 100%;
			height: 100%;

			.image {
				display: none;
			}
		}

		.additional {
			padding-left: 20px;
			left: 0 !important; /* stylelint-disable-line declaration-no-important */
		}

		.slideshow.loading {
			.additional {
				position: relative;

				img {
					border-color: $color-white;
				}

				&::after {
					position: absolute;
					z-index: 1;
					content: "";
					display: block;
					width: 50px;
					height: 50px;
					background: $color-white;
					top: 2px;
					left: 22px;
				}
			}
		}
	}
}

@media only screen and (min-device-pixel-ratio: 1.5) {
	.standard-dropdown {
		> .current.active {
			background-image: url(../images/global/dropdown-arrow-light-up.svg);
			background-position: top 50% right 0;
			border-color: $color-gray-6;
			position: relative;
			top: auto;
			left: auto;
		}
	}

	.product-actions {
		a {
			padding: 5px;
		}
	}
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
	.standard-dropdown {
		> .current.active {
			background-image: url(../images/global/dropdown-arrow-light-up.svg);
			background-position: top 50% right 0;
			border-color: $color-gray-6;
			position: relative;
			top: auto;
			left: auto;
		}
	}

	.product-actions {
		a {
			padding: 5px;
		}
	}
}

@media screen and (min-width: calc($desktop + 1px)) {
	.product-actions {
		.wishlist {
			&:hover {
				.heart {
					path {
						fill: #e0504d;
					}
				}
			}
		}

		.wishlist.wishlisted {
			&:hover {
				.heart {
					path {
						fill: #262626;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1265px) and (min-width: calc($mobile-lg + 1px)) {
	.product-suggestion {
		.product-grid {
			max-width: 720px;

			.product-grid-item {
				width: 50%;
			}
		}
	}
}

@media (max-width: 850px) {
	.product-detail-page {
		.breadcrumb {
			display: none;
		}

		.standard-seo {
			padding: 20px;
			.extra {
				height: 0;
				overflow: hidden;
			}

			.options {
				.read-more {
					display: inline-block;
					position: relative;
					text-decoration: underline;
					border: none;
					text-transform: none;
					color: $color-gray-1;
				}
			}
		}

		.standard-feed {
			.content {
				.title {
					margin-bottom: 0;
				}
			}
		}

		.product-banner-app-exclusive {
			margin-top: 30px;
		}
	}

	.product-media {
		height: auto;
		min-height: 0;

		.slides {
			min-width: 0;

			.main {
				position: relative;
				height: auto;

				.hover-area {
					display: none;
				}

				img {
					cursor: auto;
					border: 0;
					height: 100%;
				}
			}
		}

		.content {
			.image {
				transform: none;
			}
		}

		.additional {
			width: 100%;
			height: auto;
			left: 100%;
			z-index: 9;

			img {
				cursor: auto;
				border: 0;
			}
		}

		.image-holder {
			float: none;
			width: 100%;
			height: 100%;
			text-align: center;
		}
	}

	.product-details {
		.product-title {
			font-size: 1em;
			padding: 10px 10px;
		}

		.holder {
			.content-holder {
				> .content {
					width: 100%;
					position: static;
					transform: translateX(0);
					max-width: 100%;
					box-sizing: border-box;
					padding: 30px 15px 0;
				}
			}
		}

		.select-box {
			padding: 0 10px;
		}

		.select-box.open {
			padding: 0 10px;
			margin: 0;
		}

		.colour {
			label {
				.alt-radio {
					position: relative;
					width: 25px;
					height: 25px;
					border-radius: 50%;
					cursor: pointer;
				}
			}

			input {
				&:checked {
					& + label {
						.alt-radio {
							&::after {
								content: "";
								position: absolute;
								top: -5px;
								left: -5px;
								width: 31px;
								height: 31px;
								border: 2px solid $color-base;
								border-radius: 50%;
							}
						}
					}
				}
			}

			&:hover {
				> .tip {
					display: none;
				}
			}
		}

		.selected-colour {
			display: none;
		}

		.product-notifications {
			ul {
				li {
					padding: 8px 0 8px 23px;

					&::before {
						top: 12px;
					}
				}

				li.more {
					display: none;
				}
			}
		}

		.product-actions {
			padding: 4px 10px 0 0;
		}
	}

	.product-submit {
		width: calc(100% - 20px);
		margin: 30px 10px 20px;
	}

	.product-notifications {
		padding: 0 0 0 10px;
	}

	.technical-view {
		.technical-detail {
			max-width: none;
		}
	}

	.technical-detail {
		&::after {
			content: "";
			display: table;
			clear: both;
		}

		.details {
			float: left;
			width: 50%;
		}

		.detail {
			display: block;
			position: static;
		}

		.technical-drawing {
			float: left;
			width: 50%;

			img {
				max-width: 215px;
				position: relative;
				left: 25%;
			}
		}
	}

	.product-suggestion {
		.grid {
			padding: 0 10px;
		}
	}
}

@media (max-width: $mobile) {
	.product-suggestion {
		.product-grid {
			padding: 0 5px;

			.content {
				padding-bottom: 0;
			}

			.product-grid-item {
				width: 100%;
			}
		}
	}
}

@media only screen and (min-device-pixel-ratio: 1.5) and (max-width: 850px),
	only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: 850px) {
	.product-details {
		.product-title {
			font-size: 1.063em;
		}
	}

	.standard-dropdown {
		font-size: 0.938em;
	}

	.product-submit {
		font-size: 0.938em;
		padding: 25px 0;
	}

	.product-notifications {
		ul {
			font-size: 0.938em;
		}
	}

	.product-detail-page {
		.standard-information-item {
			> .inner {
				font-size: 0.938em;
			}
		}
	}

	.section-title {
		.title {
			font-size: 1.063em;
		}
	}
}
