/*******************************/
/*PRODUCT GRID
/*******************************/
.product-grid {
	margin: 20px auto;
	max-width: 1680px;
	position: relative;
	width: 90%;
	box-sizing: border-box;

	.content {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		padding-bottom: 60px;
	}

	.product-grid-item {
		box-sizing: border-box;
		color: $color-white;
		display: flex;
		align-content: center;
		flex-direction: column;
		font-size: 1rem;
		padding: 0 5px 10px;
		text-decoration: none;
		width: 25%;
		position: relative;
	}

	.product-grid-item.medium {
		width: 50%;
	}

	.product-grid-item.large {
		width: 50%;
	}

	.pagination {
		display: block;

		ul {
			list-style-type: none;
		}

		li {
			display: block;
			text-align: center;
		}

		a {
			text-decoration: none;
			padding: 20px;
			display: inline-block;
			color: $color-base;
		}
	}

	.search-pagination {
		display: block;

		ul {
			list-style-type: none;
		}

		li {
			display: block;
			text-align: center;
		}

		a {
			text-decoration: none;
			padding: 20px;
			display: inline-block;
			color: $color-base;
		}
	}

	.no-results {
		text-align: center;

		p {
			width: 100%;
			padding: 100px 20px;
		}
	}
}

.product-grid-item.product-sold-out,
.product-grid-item.product-coming-soon,
.product-grid-item.product-noprice,
.product-grid-item.product-app-exclusive {
	cursor: default;
	position: relative;

	.product-grid-item-img {
		&::after {
			border: 1px solid $color-gray-5;
			color: $color-gray-5;
			content: "";
			left: 50%;
			font-size: 0.8125em;
			line-height: 15px;
			padding: 10px 20px;
			border-radius: 5px;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			z-index: 12;
			white-space: nowrap;
		}

		&::before {
			display: block;
			width: 100%;
			height: 100%;
			content: "";
			position: absolute;
			z-index: 11;
			background: $color-gray-18;
			opacity: 0.8;
		}
	}

	.overlay {
		background: rgba(38, 38, 38, 0.5);
		opacity: 1;
	}
}

.product-grid-item.product-sold-out {
	.product-grid-item-img {
		&::after {
			content: attr(data-label-sold-out);
		}
	}
}

.product-grid-item.product-coming-soon,
.product-grid-item.product-noprice {
	.product-grid-item-img {
		&::after {
			content: attr(data-label-coming-soon);
		}
	}
}

.product-grid-item.product-app-exclusive {
	.product-grid-item-img {
		&::after {
			content: attr(data-label-app-exclusive);
		}
	}
}

.product-grid-item {
	.wishlist {
		position: absolute;
		right: 5px;
		top: 0;
		display: block;
		width: 20px;
		height: 20px;
		padding: 10px;
		z-index: 30;
		opacity: 0;
		transition: opacity 0.4s;

		.remove {
			display: none;
		}

		.heart {
			padding: 0 0 0 2px;

			path {
				transition: fill 0.2s linear;
				fill: $color-gray-1;
			}
		}

		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.wishlist.wishlisted {
		opacity: 1;

		.heart {
			path {
				fill: $color-red-5;
			}
		}
	}

	.title {
		font-size: 0.813em;
		font-weight: 700;
		line-height: 1.125rem;
		padding: 0 10px;

		span {
			display: inline-block;
			margin-right: 3px;
		}

		> p {
			width: 100%;
			padding: 0;
			line-height: normal;
		}
	}

	span {
		display: inline-block;
		padding: 0;
	}

	.center {
		left: 0;
		position: absolute;
		right: 0;
		transform: translateY(-50%);
		top: 50%;
	}

	.price {
		display: block;
		font-size: 0.875em;
		line-height: 1.25rem;
		font-weight: 700;
	}

	.sale {
		color: $color-red-1;
	}

	.tags {
		display: block;
		font-size: 0.75em;
		padding: 5px 10px 0;

		ul {
			list-style: none;
			max-width: 249px;
			margin: 0 auto;
			font-size: 0;

			.sale {
				color: $color-red-1;
			}
		}

		li {
			color: $color-white;
			display: inline-block;
			font-size: 0.75rem;

			&::after {
				content: ",\00a0";
			}

			&:last-child {
				&::after {
					content: "";
				}
			}
		}
	}

	.strikeout {
		text-decoration: line-through;
		color: $color-gray-3;
		padding: 0 2px 0 0;

		.sale {
			text-decoration: line-through;
			color: $color-red-1;
		}
	}

	.sizes {
		ul {
			list-style: none;
			max-width: 249px;
			margin: 0 auto;
			font-size: 0;
		}

		li {
			color: $color-white;
			display: inline-block;
			font-size: 0.75rem;
			text-align: center;
			margin: 0 4px;
		}

		.unavailable {
			color: $color-gray-3;
			text-decoration: line-through;
		}
		bottom: 10px;
		left: 0;
		position: absolute;
		right: 0;
		top: auto;
		z-index: 2;

		dt {
			font-size: 0.75em;
			font-weight: normal;
			line-height: 1.125rem;
			color: $color-white;
		}

		dd {
			font-size: 0.75em;
			font-weight: normal;
			line-height: 1.125rem;
			color: $color-white;
			padding: 0 10px;
		}
	}

	.overlay {
		background: rgba(38, 38, 38, 0.9);
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		opacity: 0;
		transition: opacity 0.4s;
		display: block;
		margin-top: 0;
		z-index: 2;
	}

	&:hover {
		.overlay {
			transition: opacity 0.4s;
			opacity: 1;
		}

		.wishlist {
			transition: opacity 0.4s;
			opacity: 1;
		}

		.product-grid-item-data {
			transition: opacity 0.4s;
			opacity: 1;
		}
	}
}

.wishlist {
	.message {
		display: none;
		position: absolute;
		right: 100%;
		top: 8px;
		color: $color-blue-1;
		padding: 3px 5px;
		border-radius: 4px;
		margin: 0;
		opacity: 0;
		white-space: nowrap;
		font-weight: 400;
		font-size: 0.875em;
		z-index: 20;
		background: $color-white;
		transition: opacity 0.4s ease 1s, z-index 0.4s ease 1s;
	}

	.message.show {
		display: block;
		transition: opacity 0.4s ease, z-index 0.4s ease;
		opacity: 1;
		z-index: 100;
	}
}

.touch {
	.product-grid-item {
		.wishlist {
			right: 5px;

			svg {
				position: static;
				transform: translateY(0);
			}
		}
	}
}

.product-grid.uniform-large {
	.product-grid-item {
		width: 50%;
	}

	.product-grid-item.medium {
		width: 50%;
	}
}

.product-grid.uniform-mobile-small {
	.product-grid-item.mobile-small {
		width: 50%;
	}
}

.product-grid.uniform-mobile-large {
	.product-grid-item.mobile-large {
		width: 100%;
	}
}

.product-grid.uniform-small {
	.product-grid-item.medium {
		width: 25%;
	}

	.product-grid-item.large {
		width: 25%;
	}
}

.product-grid-item-container {
	position: relative;
	display: block;
	color: $color-white;
}

.uniform-small {
	.product-grid-item-data {
		bottom: 0;
		opacity: 0;
		left: 0;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		margin: 0 auto;
		width: auto;
		height: auto;
	}

	.large {
		.product-grid-item-data {
			bottom: 0;
			opacity: 0;
			left: 0;
			position: absolute;
			right: 0;
			text-align: center;
			top: 0;
			margin: 0 auto;
			width: auto;
			height: auto;
		}
	}

	.product-grid-item.large {
		.sizes {
			bottom: 10px;
			left: 0;
			position: absolute;
			right: 0;
			top: auto;
			z-index: 2;
		}
	}
}

.large {
	.product-grid-item-data {
		width: 272px;
		height: 311px;
		right: auto;
		bottom: auto;
		left: 50%;
		top: 50%;
		margin-left: -136px;
		margin-top: -155px;
	}
}

.uniform-large {
	.product-grid-item-data {
		width: 272px;
		height: 311px;
		right: auto;
		bottom: auto;
		left: 50%;
		top: 50%;
		margin-left: -136px;
		margin-top: -155px;
	}

	.product-grid-item {
		.sizes {
			top: 255px;
		}
	}
}

.info-showing {
	.product-grid-item-data {
		min-height: 110px;
		color: $color-gray-15;
		display: block;
		opacity: 1;
		position: static;
		text-align: center;
		margin: 0 auto;
		width: auto;
		height: auto;
	}

	.product-grid-item {
		.title {
			padding: 16px 10px 0;

			.color {
				font-weight: 400;
				color: $color-gray-13;
			}
		}

		.center {
			position: static;
			transform: none;
		}

		.tags {
			padding-bottom: 20px;
			font-weight: normal;

			li {
				color: $color-gray-1;
				padding: 0;
			}
		}

		.sizes {
			bottom: 0;
			color: $color-white;
			position: absolute;
			opacity: 0;
			top: 0;

			dl {
				position: absolute;
				left: 0;
				right: 0;
				top: 42.25%;
				transform: translateY(-50%);
			}
		}

		&:hover {
			.sizes {
				transition: opacity 0.4s;
				opacity: 1;
			}
		}
	}

	.product-grid-item.pant {
		.price {
			padding: 0;
		}
	}
}

.product-grid-item-img {
	overflow: hidden;
	position: relative;

	img {
		min-height: 113.97%;
		width: 100%;
	}
}

.product-grid-item-data {
	bottom: 0;
	opacity: 0;
	left: 0;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	margin: 0 auto;
	width: auto;
	height: auto;
}

.info-showing.uniform-small {
	.large {
		.product-grid-item-data {
			display: block;
			opacity: 1;
			position: static;
			text-align: center;
			margin: 0 auto;
			width: auto;
			height: auto;
		}
	}

	.product-grid-item {
		.large {
			.center {
				position: static;
				transform: none;
			}
		}
	}

	.product-grid-item.large {
		.sizes {
			bottom: 0;
			color: $color-white;
			position: absolute;
			opacity: 0;
			top: 0;
		}

		&:hover {
			.sizes {
				transition: opacity 0.4s;
				opacity: 1;
			}
		}
	}
}

.product-grid-item.pant {
	.center {
		top: 32%;
	}

	.price {
		padding: 0 0 10px;
	}

	.sizes {
		li {
			width: 16.66%;
			margin: 0;
		}

		dd {
			text-align: left;
		}
	}
}

.product-grid-item.large {
	.sizes {
		top: 255px;
	}
}

.infinite-scroll-loading-display {
	display: none;
	width: 100%;
	text-align: center;
}

.product-grid-slider {
	position: relative;
	padding-bottom: 122.35%;
	background: $color-gray-18;

	.swiper-container {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.swiper-slide {
		img {
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
			transform: translate(-50%, -50%);
			position: absolute;
			left: 50%;
			top: 50%;
		}
	}

	.swiper-container-horizontal {
		> .swiper-pagination-bullets {
			bottom: 0;
			display: flex;
		}
	}

	.bullet {
		flex: 1 1 auto;
		padding: 0;
		width: 100%;
		height: 3px;
		margin: 0;
		border: none;
		border-radius: 0;
		background-color: $color-gray-5;
		opacity: 0.05;
	}

	.bullet.is-active {
		background-color: $color-gray-5;
		opacity: 0.15;
	}

	.preloader {
		&::after {
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			background: linear-gradient(110deg, $color-gray-8 8%, $color-gray-16 18%, $color-gray-8 33%);
			background-size: 300% 100%;
			@media only screen and (min-width: calc($desktop + 1px)) {
				animation: 2s shine linear infinite;
			}
		}
	}
}

@media only screen and (min-width: calc($desktop + 1px)) {
	.product-grid-item {
		.wishlist {
			&:hover {
				.heart {
					path {
						fill: $color-red-5;
					}
				}
			}
		}

		.wishlist.wishlisted {
			&:hover {
				.heart {
					path {
						fill: $color-gray-1;
					}
				}
			}
		}

		&.product-sold-out,
		&.product-coming-soon,
		&.product-noprice,
		&.product-app-exclusive {
			&:hover .product-grid-item-img {
				&::after {
					border: 1px solid $color-gray-10;
					color: $color-gray-10;
				}

				&::before {
					background: $color-gray-5;
				}
			}
		}
	}

	.product-grid-slider {
		display: none;
	}
}

@media only screen and (max-width: $desktop) {
	.product-grid-item {
		.wishlist {
			opacity: 1;
		}

		.descr {
			span {
				font-weight: 700;
			}
		}
	}

	.info-showing {
		.product-grid-item-data {
			padding-bottom: 15px;
		}
	}

	.too-long {
		max-height: 36px;
		overflow: hidden;
		display: -webkit-box !important; /* stylelint-disable-line declaration-no-important */
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
		box-orient: vertical; /* stylelint-disable-line */
		text-overflow: ellipsis;
		width: 110%;
	}

	.too-long-color {
		max-height: 18px;
		overflow: hidden;
		display: -webkit-box !important; /* stylelint-disable-line declaration-no-important */
		-webkit-line-clamp: 1;
		line-clamp: 1;
		-webkit-box-orient: vertical;
		box-orient: vertical; /* stylelint-disable-line */
		text-overflow: ellipsis;
		width: 110%;
	}
}

@media (max-width: 1265px) {
	.product-grid {
		.product-grid-item {
			width: 33.33%;
		}

		.product-grid-item.medium {
			width: 66.66%;
		}

		.product-grid-item.large {
			width: 66.66%;
		}
	}

	.product-grid.uniform-small {
		.product-grid-item {
			width: 33.33%;
		}

		.product-grid-item.medium {
			width: 33.33%;
		}

		.product-grid-item.large {
			width: 33.33%;
		}
	}
}

@media (max-width: $desktop) {
	.product-grid {
		width: 100%;
		max-width: 756px;

		.product-grid-item {
			width: 33.33%;
		}

		.product-grid-item.medium {
			width: 33.33%;
		}

		.product-grid-item.large {
			width: 33.33%;
		}
	}

	.product-grid.product-pagination {
		.prev {
			a {
				&::after {
					top: 0;
				}
			}
		}

		.next {
			a {
				&::after {
					top: 0;
				}
			}
		}

		ul {
			padding: 0;
		}
	}

	.product-grid.uniform-large {
		.product-grid-item {
			width: 50%;
		}

		.product-grid-item.medium {
			width: 50%;
		}

		.product-grid-item.large {
			width: 50%;
		}
	}

	.product-grid.uniform-small {
		.product-grid-item {
			width: 33.33%;
		}

		.product-grid-item.medium {
			width: 33.33%;
		}

		.product-grid-item.large {
			width: 33.33%;
		}
	}

	.large {
		.product-grid-item-data {
			width: auto;
		}
	}

	.uniform-large {
		.product-grid-item-data {
			width: auto;
		}
	}

	.product-grid-item {
		.sizes {
			display: none;
		}

		.overlay {
			display: none;
		}
	}
}

@media (max-width: $tablet) {
	.product-grid {
		max-width: 564px;

		.product-grid-item {
			width: 50%;
		}

		.product-grid-item.medium {
			width: 50%;
		}

		.product-grid-item.large {
			width: 50%;
		}
	}

	.product-grid.product-pagination {
		padding: 18px 20px 0 18px;
	}

	.product-grid.uniform-large {
		max-width: 564px;

		.product-grid-item {
			width: 100%;
		}

		.product-grid-item.medium {
			width: 100%;
		}

		.product-grid-item.large {
			width: 100%;
		}
	}

	.product-grid.uniform-small {
		.product-grid-item {
			width: 50%;
		}

		.product-grid-item.medium {
			width: 50%;
		}

		.product-grid-item.large {
			width: 50%;
		}
	}
}

@media (max-width: $mobile-lg) {
	.product-grid.uniform-small {
		.product-grid-item {
			width: 50%;
			padding: 0 7px;
		}
	}

	.product-grid {
		.product-grid-item {
			width: 50%;
			padding: 0 7px;
		}

		.product-grid-item.medium {
			width: 50%;
			padding: 0 7px;
		}

		.product-grid-item.large {
			width: 50%;
			padding: 0 7px;
		}

		.content {
			padding: 0 5px;
		}
	}
}

@media (min-width: calc($desktop + 1px)) {
	.product-grid-item {
		&__image {
			display: block !important; /* stylelint-disable-line declaration-no-important */

			&--hover {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				opacity: 0;
				z-index: -1;
			}
		}

		&:hover,
		&:focus {
			.product-grid-item__image {
				&--hover {
					opacity: 1;
					z-index: 10;
				}
			}
		}
	}

	.product-grid-item-img {
		padding-bottom: 113.97%;
	}

	.product-grid-item-img-holder {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex !important; /* stylelint-disable-line declaration-no-important */
		align-items: center;
	}
}

@media only screen and (min-device-pixel-ratio: 1.5) and (max-width: $tablet),
	only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: $tablet) {
	.info-showing {
		.large {
			.product-grid-item-data {
				padding: 10px 0 0 0;
				display: block;
				position: static;
				text-align: left;
				transform-style: flat;
				margin: 0 auto;
				width: auto;
			}
		}

		.product-grid-item-data {
			display: block;
			position: static;
			text-align: left;
			transform-style: flat;
			margin: 0 auto;
			width: auto;
			min-height: 0;
			padding: 13px 0 17px 0;
		}

		.product-grid-item {
			.title {
				padding: 0 40px 0 0;
			}

			.tags {
				padding: 5px 0 0;
			}

			&:hover {
				.sizes {
					display: none;
				}
			}
		}
	}

	.info-showing.uniform-small {
		.large {
			.product-grid-item-data {
				padding: 10px 0 0 0;
				display: block;
				position: static;
				text-align: left;
				transform-style: flat;
				margin: 0 auto;
				width: auto;
			}
		}

		.product-grid-item.large {
			.sizes {
				display: none;
			}

			&:hover {
				.sizes {
					display: none;
				}
			}
		}
	}

	.product-grid-item {
		.tags {
			font-size: 0.938em;

			ul {
				margin: 0;
			}
		}

		&:hover {
			.overlay {
				display: none;
			}
		}

		.sizes {
			display: none;
		}

		.title {
			font-size: 0.938em;
			color: $color-gray-5;

			> span {
				color: $color-gray-13;
			}
		}

		.price {
			font-size: 0.938em;
			color: $color-gray-5;
			font-weight: 400;
		}
	}
}

@keyframes shine {
	to {
		background-position-x: -300%;
	}
}

@keyframes shine {
	to {
		background-position-x: -300%;
	}
}
