/*ONLINE STOCKISTS PAGE*/
.online-stockists-page {
	.breadcrumb {
		a {
			color: $color-white;
			text-decoration: none;

			&:hover {
				color: $color-gray-17;
			}
		}

		li {
			&::after {
				color: $color-white;
			}
		}
	}

	nav {
		background: url("../images/stores/online-stockist-background.jpg");
		background-size: cover;
	}

	.form {
		margin: 0 auto;
		padding: 60px 0 60px;
		max-width: 554px;
		width: 100%;
		position: relative;
	}

	select {
		border-width: 1px;
		border-style: solid;
		border-color: $color-white;
		color: $color-gray-17;
		border-radius: 4px;
		padding: 20px 20px 21px 20px;
		margin: 0 auto 10px;
		outline: 0;
		width: 100%;
		font-size: 0.875em;
		box-sizing: border-box;
		-moz-appearance: none;
		appearance: none;
		-webkit-appearance: none;
		transition: color 0.4s, background-colour 0.4s;
		background: transparent url("../images/global/dropdown-arrow-light.svg") no-repeat right 20px center;

		&:focus {
			background-image: url("../images/global/dropdown-arrow-light-up.svg");
			outline: none;
		}

		&:-webkit-autofill {
			-webkit-box-shadow: 0 0 0 1000px transparent inset;
		}

		option {
			color: $color-base;
		}

		option[disabled] {
			border: 0;
			color: $color-gray-1;
		}

		&::-ms-expand {
			display: none;
		}
	}

	p {
		color: $color-white;
		font-size: 0.875em;
		line-height: 1.5em;
	}

	.stockists {
		margin: 0 auto;
		max-width: 1680px;
		width: 87.34%;
		overflow: hidden;

		article {
			&::after {
				clear: both;
				content: "";
				display: block;
			}
			padding: 40px 0 0;
			display: none;
		}

		article.active {
			display: block;
		}

		li {
			float: left;
			box-sizing: border-box;
			width: 25%;
			padding: 0 20px 40px 20px;

			&:nth-child(4n + 1) {
				padding: 0 20px 30px 0;
				clear: left;
			}

			&:nth-child(4n) {
				padding: 0 0 30px 20px;
			}
		}
	}

	ul {
		list-style: none;
	}

	dl {
		font-size: 1rem;
		line-height: 1.5rem;
		word-wrap: break-word;
	}

	dt {
		font-weight: 700;
	}

	a {
		color: $color-gray-1;
		text-decoration: underline;
		transition: color 0.4s;

		&:hover {
			color: $color-base;
		}
	}
}

.touch {
	.online-stockists-page {
		.form {
			padding: 40px 0;
		}

		select {
			font-size: 1em;
			padding: 24px 20px 25px;
		}
	}
}

@media (max-width: $desktop) {
	.online-stockists-page {
		.stockists {
			li {
				width: 33.33%;
				padding: 0 20px 30px 20px;

				&:nth-child(4n + 1),
				&:nth-child(4n),
				&:nth-child(3n + 1),
				&:nth-child(3n) {
					padding: 0 20px 30px 20px;
					clear: none;
				}
			}
		}
	}
}

@media (max-width: $tablet) {
	.online-stockists-page {
		padding: 60px 0 0;

		.breadcrumb {
			display: none;
		}

		.stockists {
			width: 100%;
			padding: 10px 0 60px;

			li {
				width: 50%;
				padding: 0 20px 0 20px;
				position: relative;
				margin: 0 0 30px;

				&:nth-child(3n + 1),
				&:nth-child(3n),
				&:nth-child(4n + 1),
				&:nth-child(4n),
				&:nth-child(2n),
				&:nth-child(2n + 1) {
					padding: 0 20px 0 20px;
					clear: none;
				}
			}
		}

		ul {
			padding: 0 20px;
		}

		nav {
			padding: 0 20px;
		}

		.form {
			padding: 60px 0;
		}
	}
}

@media (max-width: $mobile) {
	.online-stockists-page {
		.stockists {
			li {
				width: 100%;
				padding: 0;

				&:nth-child(3n + 1),
				&:nth-child(3n),
				&:nth-child(4n + 1),
				&:nth-child(4n),
				&:nth-child(2n),
				&:nth-child(2n + 1) {
					padding: 0;
					clear: none;
				}
			}
		}
	}
}

/* stylelint-disable-next-line max-line-length */
@media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
	.online-stockists-page {
		h1 {
			padding: 35px 20px 35px;
		}

		select {
			font-size: 1em;
			padding: 24px 20px 25px;
		}
	}
}
