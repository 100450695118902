/* Pips */
.standard-slideshow {
	position: relative;

	.content {
		position: relative;
		margin: 0 auto;
	}

	.slides {
		position: relative;
		margin: 0 auto;
		overflow: hidden;
		list-style: none;
	}

	.slide {
		position: absolute;
		width: 100%;
		top: 0;
		left: 100%;
		background: $color-white;

		&:first-child {
			position: relative;
			left: 0;
		}
	}

	.slide.mobile {
		display: none;
	}

	.navigation {
		list-style: none;
		transition: all 200ms 200ms ease;

		a {
			display: block;
			position: absolute;
			top: 0;
			width: 10%;
			height: 100%;
			transition: all 200ms 100ms ease;
			z-index: 10;
			text-align: center;
			font-size: 1.5em;
			color: $color-white;
		}

		.next {
			right: 0;
		}

		.previous {
			left: 0;
		}
	}

	.navigation.hidden {
		opacity: 0;
		visibility: hidden;
	}

	.next {
		> span {
			display: none;
		}

		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			font-family: $font-secondary;
			content: "\e60a";
			font-size: 1.25rem;
			transition: 0.4s all;
			display: block;
			width: 100%;
		}

		&:hover {
			&::after {
				font-size: 1.45rem;
			}
		}
	}

	.previous {
		> span {
			display: none;
		}

		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			font-family: $font-secondary;
			font-size: 1.25rem;
			transition: 0.4s all;
			display: block;
			width: 100%;
			content: "\e609";
		}

		&:hover {
			&::after {
				font-size: 1.45rem;
			}
		}
	}

	.down {
		&:hover {
			&::after {
				font-size: 1.45rem;
			}
		}
	}
}

.standard-slideshow[data-max-width="full"] {
	.content {
		max-width: 100%;
	}
}

.standard-slideshow[data-max-width="1920"] {
	.content {
		max-width: 1920px;
	}
}

.standard-slideshow[data-max-width="1680"] {
	.content {
		max-width: 1680px;
	}
}

.standard-slideshow[data-max-width="1000"] {
	.content {
		max-width: 1000px;
	}
}

.standard-tabs {
	.pips {
		bottom: 0;
	}
}

.pips {
	text-align: center;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 20px;
	z-index: 100;
	transition: opacity 200ms 200ms ease, visibility 200ms 200ms ease;

	li {
		display: inline-block;
		padding: 0 3px;
	}
}

.pips.hidden {
	opacity: 0;
	visibility: hidden;
}

.pip {
	a {
		display: block;
		width: 9px;
		height: 9px;
		border: 1px solid $color-white;
		border-radius: 50%;
		transition: border 200ms ease, background 200ms ease;
	}
}

.pip.active {
	a {
		background: $color-white;
	}
}

.light {
	.pip {
		a {
			border-color: $color-base;
		}
	}

	.pip.active {
		a {
			background: $color-base;
		}
	}
}

.dark {
	.pip {
		a {
			border-color: $color-white;
		}
	}

	.pip.active {
		a {
			background: $color-white;
		}
	}
}

.standard-slideshow.light {
	.navigation {
		a {
			color: $color-base;
		}
	}
}

.standard-slideshow.dark {
	.navigation {
		a {
			color: $color-white;
		}
	}
}

@media (max-width: $tablet) {
	.standard-slideshow {
		.navigation {
			.next {
				display: none;
			}

			.previous {
				display: none;
			}
		}
	}

	.pips {
		bottom: 10px;
	}
}
