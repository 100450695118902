/*---------------------------------------------*/
/* Article collection
/*
/* Could be considered a grid, but is
/* quite an ad-hoc grid.
/*---------------------------------------------*/
.article-collection {
	.content {
		width: 100%;
		max-width: 1680px;
		margin: 0 auto;
		text-align: center;
		padding-top: 60px;
	}

	article {
		display: inline-block;
		vertical-align: top;
		margin: 40px 26px 60px 30px;
		text-align: left;

		> a {
			display: block;
			position: relative;

			img {
				border: 0;
				width: 100%;
			}

			.image {
				position: relative;
				max-width: 750px;
				height: auto;
				transition: all 200ms 100ms ease;
			}

			.overlay {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background: rgba(38, 38, 38, 0.7);
				opacity: 0;
				transition: opacity 200ms 100ms ease;
			}

			&:hover {
				.overlay {
					opacity: 1;
				}

				.title {
					color: $color-gray-2;
				}
			}
		}

		.title {
			margin-top: 16px;
			color: $color-base;
			font-size: 1em;
			font-weight: 700;
			display: block;

			span {
				line-height: 1.5em;
				transition: all 200ms 100ms ease;
				border-bottom: 2px solid transparent;
			}
		}

		.subtitle {
			margin-top: 6px;
			color: $color-base;
			display: block;
		}

		.notification {
			display: table-cell;
			position: absolute;
			top: -29px;
			left: 20px;
			width: 50px;
			height: 50px;
			text-align: center;
			background: $color-blue-3;
			border-radius: 50%;
			padding: 5px;
			color: $color-white;
			text-transform: uppercase;
			font-size: 0.75em;
			font-weight: 700;

			span {
				line-height: 50px;
			}
		}
	}

	article[data-vertical-align="top"] {
		margin-top: 0;
	}

	article[data-vertical-align="bottom"] {
		margin-top: 80px;
	}

	.tags {
		display: flex;
		list-style: none;
		max-width: 250px;
		margin-top: 12px;

		> li {
			display: inline-flex;
			margin: 0 5px 0 0;

			a {
				color: $color-gray-3;
				font-size: 0.75em;
				text-transform: uppercase;
				transition: color 200ms ease;

				&:hover {
					color: $color-gray-1;
				}
			}
		}
	}

	.cta {
		padding-top: 30px;
		display: block;

		a {
			display: inline-block;
			color: $color-white;
			text-decoration: none;
			padding: 13px 18px;
			background: $color-blue-1;
			border-radius: 4px;
			font-size: 0.875em;
			transition: background-color 0.2s;
			font-weight: 700;

			&:hover {
				background-color: $color-blue-2;
			}
		}
	}

	.pagination {
		ul {
			list-style: none;
		}
	}
}

.journal-landing-page {
	.infinite-scroll-loading-display {
		max-width: 1680px;
		margin: 0 auto 40px auto;
		text-align: center;
	}
}

@media (max-width: $tablet) {
	.article-collection {
		article {
			margin-top: 0;
		}

		article[data-vertical-align="top"] {
			margin-top: 0;
		}

		article[data-vertical-align="bottom"] {
			margin-top: 0;
		}
	}
}

@media (max-width: $mobile-lg) {
	.article-collection {
		article {
			margin: 0 30px 60px 30px;

			.title {
				span {
					line-height: 1.6em;
				}
			}

			> a {
				&:hover {
					.title {
						span {
							line-height: 1.6em;
						}
					}
				}
			}
		}

		.cta {
			a {
				padding: 18px 30px;
				font-size: 1em;
			}
		}
	}
}

@media only screen and (min-device-pixel-ratio: 1.5) and (max-width: $tablet),
	only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: $tablet) {
	.article-collection {
		.cta {
			a {
				font-size: 1rem;
				line-height: 1.5rem;
				padding: 13px 18px;
				min-width: 170px;
			}
		}
	}
}
