.standard-feed {
	padding: 60px 0 120px 0;

	.content {
		max-width: 1680px;
		margin: 0 auto;

		&::after {
			display: table;
			content: "";
			clear: both;
		}

		.title {
			text-align: center;
			font-size: 1em;
			font-weight: 700;
			margin: 0 0 35px;
		}
	}

	.slideshow {
		.slides {
			list-style: none;
		}

		.pips {
			display: none;
			bottom: -35px;
		}

		.pip {
			padding: 0 5px;

			a {
				border-color: $color-gray-1;
				width: 10px;
				height: 10px;
			}
		}

		.pip.active {
			a {
				background: $color-gray-1;
			}
		}
	}
}

.standard-feed-item {
	float: left;
	width: 33.333%;
	text-align: center;
	margin: 35px 0 0 0;

	.inner {
		position: relative;
		max-width: 310px;
		margin: 0 auto;
		padding: 20px;
		background: $color-white;
		transition: padding 200ms 100ms ease, margin-top 200ms 100ms ease;

		&:hover {
			margin-top: -30px;
			padding: 50px 30px 20px 30px;

			> .title {
				opacity: 1;
				visibility: visible;
			}

			> .copy {
				top: 100%;
				opacity: 1;
				visibility: visible;
			}
		}

		> .title {
			position: absolute;
			margin-top: -33px;
			left: 0;
			right: 0;
			font-size: 0.875em;
			color: $color-gray-1;
			text-transform: uppercase;
			opacity: 0;
			visibility: hidden;
			transition: opacity 200ms 100ms, visibility 200ms 100ms ease;

			&::before {
				content: "@";
			}
		}

		> .copy {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			background: $color-white;
			padding: 0 30px 20px 30px;
			font-size: 0.875em;
			line-height: 1.375rem;
			opacity: 0;
			visibility: hidden;
			transition: opacity 200ms 100ms ease, visibility 200ms 100ms ease, top 250ms 100ms ease;
		}
	}

	img {
		position: relative;
		width: 100%;
		max-width: 310px;
		height: auto;
		z-index: 1;
	}
}

.standard-feed-item.open {
	.inner {
		margin-top: -30px;
		padding: 50px 30px 20px 30px;

		> .title {
			opacity: 1;
			visibility: visible;
		}

		> .copy {
			top: 100%;
			opacity: 1;
			visibility: visible;
		}
	}
}

@media (max-width: $desktop) {
	.standard-feed {
		padding: 30px 0;

		.slideshow {
			position: relative;

			.pips {
				display: block;
			}

			.slides {
				position: relative;
				overflow: hidden;
				margin-bottom: 20px;
			}
		}

		.slides {
			.slide {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				text-align: center;
				box-sizing: border-box;
				padding: 0 20px;
			}
		}

		.slide {
			&:first-child {
				position: relative;
			}
		}

		.content {
			> .title {
				margin-bottom: 15px;
			}
		}
	}

	.standard-feed-item {
		width: 100%;
		float: none;

		.inner {
			&:hover {
				margin-top: 0;
				padding: 20px;
			}

			> .title {
				position: static;
				opacity: 1;
				visibility: visible;
				margin-top: 0;
			}

			> .copy {
				position: static;
				opacity: 1;
				visibility: visible;
				padding: 20px 30px 10px 30px;
			}
		}
	}
}

@media only screen and (min-device-pixel-ratio: 1.5) and (max-width: $tablet),
	only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: $tablet) {
	.standard-feed {
		.content {
			.title {
				font-size: 1.063em;
			}
		}
	}

	.standard-feed-item {
		.inner {
			> .title {
				font-size: 0.938em;
			}

			> .copy {
				font-size: 0.813em;
			}
		}
	}
}
