.global-banner {
	position: sticky;
	left: 0;
	right: 0;
	top: 0;
	z-index: 11;
	line-height: 1.3em;
	text-align: center;
	overflow: hidden;
	display: none;
	padding: 0 112px;
	min-height: 60px;
	max-height: 60px;

	.content {
		height: 100%;
		margin: 0 auto;
		padding: 0;
	}

	.underlined {
		border-width: 0 0 1px;
		transition: border-color 4s ease;
		border-style: solid;
		padding: 0;
		display: inline;
	}

	.detail {
		.title {
			font-size: 0.75em;
			font-weight: bold;
			text-transform: uppercase;
			line-height: 1.3em;
		}

		.description {
			font-size: 0.75em;
			line-height: 1.3em;
		}
	}

	.close {
		display: none;
		margin: 0 auto;
		position: absolute;
		top: 50%;
		right: 28px;
		width: 56px;
		transform: translateY(-50%);
		font-size: 0.875rem;
		transition: all 0.2s;
		z-index: 10;
		text-align: right;

		&:hover {
			color: $color-gray-9;
		}

		&::after {
			content: "\e605";
			font-family: $font-secondary;
			padding: 10px 20px;
			cursor: pointer;
		}

		span {
			position: relative;
			cursor: pointer;
			display: none;
		}
	}
}

.global-banner[data-show-banner="true"] {
	display: flex;
	align-items: center;
}

.global-banner[data-cookie-exists="true"] {
	display: none;
}

.main-header {
	.global-banner[data-repeat-text="true"] {
		.content {
			width: 6000%;
			max-width: none;
			margin: 0 0 0 -3000%;
			position: relative;
			left: 50%;
		}
	}

	.global-banner {
		.detail {
			font-size: 0.75rem;
			font-weight: 700;
		}
	}
}

.global-banner[data-repeat-text="true"] {
	padding: 0 1rem;

	.content {
		white-space: nowrap;
		overflow: hidden;
	}

	.detail {
		display: inline-block;
		padding: 0;
		max-width: none;
	}
}

.global-banner[data-repeat-text="false"] {
	padding: 0 112px;

	.detail {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0;
	}
}

.global-banner[data-text-color="light"] {
	color: $color-white;

	a {
		color: $color-white !important; /* stylelint-disable-line declaration-no-important */
		border-color: $color-white !important; /* stylelint-disable-line declaration-no-important */

		&:hover {
			color: $color-gray-17 !important; /* stylelint-disable-line declaration-no-important */
			border-color: $color-gray-17 !important; /* stylelint-disable-line declaration-no-important */
		}
	}
}

.global-banner[data-text-color="dark"] {
	color: $color-base;

	a {
		&:hover {
			color: $color-gray-1 !important; /* stylelint-disable-line declaration-no-important */
			border-color: $color-gray-1 !important; /* stylelint-disable-line declaration-no-important */
		}
		color: $color-base !important; /* stylelint-disable-line declaration-no-important */
		border-color: $color-base !important; /* stylelint-disable-line declaration-no-important */
	}
}

.global-banner[data-closable="true"] {
	.close {
		display: block;
	}
}

.brand-banner {
	display: block;
	position: relative;
	font-size: 0.75rem;
	line-height: 0.875rem;
	text-transform: uppercase;
	text-decoration: none;
	margin: 0;
	font-weight: 700;
	transition: color 0.2s ease;
}

.brand-banner.global-banner--bgwhite {
	background: $color-white;
	padding: 0;
	box-shadow: inset 0 -10px 15px -10px rgba(38, 38, 38, 0.5);

	.content {
		padding: 10px 0;
		text-align: right;
		max-width: 1680px;

		.logo {
			padding: 0 20px;
			position: relative;
			display: inline-block;
			vertical-align: middle;

			img {
				max-width: 120px;
				height: auto;
				width: 100%;
				min-height: 26px;
			}
		}

		p {
			display: inline-block;
			vertical-align: middle;
			padding: 5px;
			color: $color-base;

			span {
				text-decoration: underline;
				letter-spacing: 2px;
				font-weight: 500;
			}
		}
	}
}

.brand-banner.global-banner {
	.content {
		margin: 0 auto;
		max-width: 900px;
		z-index: 3;
	}
}

@media (max-width: $desktop) {
	.global-banner {
		max-height: none;
	}

	.main-header {
		.global-banner {
			.content {
				max-width: 80%;
			}
		}

		.global-banner--bgwhite {
			.content {
				.logo {
					img {
						max-width: 90px;
					}
				}
			}
		}
	}

	.brand-banner.global-banner {
		.content {
			max-width: 80%;
		}
	}

	.brand-banner.global-banner--bgwhite {
		.content {
			max-width: 100%;

			.logo {
				img {
					max-width: 90px;
					min-height: 20px;
				}
			}
		}
	}
}

@media (max-width: $tablet) {
	.main-header {
		.global-banner--bgwhite {
			.content {
				max-width: 80%;
				float: none;
				text-align: center;

				.logo {
					display: none;
				}
			}
		}

		.global-banner {
			.detail {
				a {
					text-transform: underline;
					padding: 0;
				}
			}
		}
	}

	.brand-banner.global-banner--bgwhite {
		.content {
			max-width: 80%;
			float: none;
			text-align: center;

			.logo {
				display: none;
			}
		}
	}

	.global-banner[data-repeat-text="false"] {
		padding: 5px 40px;
	}

	.global-banner {
		.close {
			right: 0;
		}

		.underlined {
			border: none;
		}
	}
}

@media (max-width: $mobile-lg) {
	.main-header {
		.global-banner {
			.content {
				max-width: 70%;
			}
		}

		.global-banner--bgwhite {
			.content {
				max-width: 80%;
			}
		}
	}

	.brand-banner.global-banner {
		.content {
			max-width: 70%;
		}
	}

	.brand-banner.global-banner--bgwhite {
		.content {
			max-width: 80%;
		}
	}
}
