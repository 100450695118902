.flyout-menu {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 101;
	transform: translateX(-100%);
	transition: transform 0.5s;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	background-color: $color-white;

	input[type="button"] {
		-webkit-appearance: none !important; /* stylelint-disable-line declaration-no-important */
		-webkit-border-radius: 0 !important; /* stylelint-disable-line declaration-no-important */
	}

	&.is-open {
		transform: translateX(0);
	}

	&__header {
		height: 55px;
		display: flex;
		border-bottom: 1px solid $color-gray-9;

		&__button {
			display: flex;
			align-items: center;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			background: none;
			border: none;
			margin: 0;
			padding: 0;
			cursor: pointer;
			border-left: 1px solid $color-gray-9;
			height: 55px;
			width: 55px;

			> div,
			> svg {
				pointer-events: none;
				width: 14px;
			}

			&.close-flyout-btn {
				border-left: none;
				border-right: 1px solid $color-gray-9;
				margin-right: auto;
			}

			.country-flag {
				width: 14px;
				height: 14px;
				border-radius: 100%;
				background-position: 53%;
				background-size: cover;
			}
		}
	}

	&__content {
		position: relative;
		min-height: 100px;
		height: calc(100dvh - 56px);

		&__social-links {
			display: flex;
			list-style-type: none;
			padding: 0 20.5px 0 15px;
			border-bottom: 1px solid $color-gray-9;
			height: 55px;
			align-items: center;

			.social-link {
				display: flex;
				margin-right: 35px;
				align-items: center;

				&--twitter svg {
					width: 14px;
					height: auto;
					stroke: #4a4a4a;
				}

				&--instagram svg {
					width: 14px;
				}
			}
		}
	}

	&__footer {
		width: 100%;
		background-color: $color-white;
		height: 55px;
		font-size: 13px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 20.5px 0 15px;
		box-sizing: border-box;
		border-top: 1px solid $color-gray-9;
		z-index: 101;
		position: sticky;
		top: calc(100vh - 55px);
	}

	/* Flyout Overlays */
	.flyout-overlay {
		display: none;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 102;

		&[data-overlay-id="country"] {
			height: 1538px;
		}

		&--login {
			width: 100%;
			position: absolute;
			top: 0;
			bottom: 0;

			.flyout-menu__footer {
				border-top: none;
			}
		}

		&.is-open {
			display: block;

			.heading {
				font-size: $defaultFontSize;
				border-bottom: 1px solid $color-gray-9;
				height: 55px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: bold;
				text-transform: uppercase;
				background-color: $color-gray-10;
			}
		}
	}

	.flyout-overlay.is-open[data-overlay-id="country"] + footer {
		display: none;
	}
}
