/*CONTACT US*/
.contact-us {
	padding-top: 60px;

	.form {
		.content {
			margin: 0 auto;
			max-width: 1680px;
			width: 87.34%;
		}
		background-color: $color-gray-16;

		.title {
			font-size: 0.875em;
			padding: 0 0 5px;
			line-height: 1.5rem;
		}
	}

	header {
		margin: 0 auto;
		max-width: 1680px;
		width: 87.34%;
		text-align: center;
	}

	h1 {
		font-size: 1.875em;
		font-weight: 700;
		line-height: 2.375rem;
		padding: 24px 0 61px;
	}

	form {
		margin: 0 auto;
		max-width: 720px;
		min-height: 200px;
		padding: 60px 0 70px;
		position: relative;
	}

	.column {
		float: left;
		width: 350px;
		margin: 0 20px 0 0;

		& + .column {
			margin: 0;
		}
	}

	.overlay {
		text-align: center;
		background: rgba(255, 255, 255, 0.9);
		opacity: 0;
		position: fixed;
		transition: opacity 0.4s, z-index 0.4s;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;

		img {
			text-align: center;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			max-width: 400px;
		}

		p {
			text-align: center;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			max-width: 400px;
		}
	}

	.overlay.active {
		left: 50%;
		opacity: 1;
		transform: translateX(-50%);
		width: 100vw;
		position: fixed;
		height: 100vh;
		z-index: 499;
	}

	input {
		width: 100%;
		max-width: 350px;
		font-size: 0.875rem;
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		appearance: none;
		overflow: hidden;
	}

	select {
		max-width: 350px;
		overflow: hidden;
		border-width: 1px;
		border-style: solid;
		border-color: $color-base;
		font-size: 0.875rem;
		color: $color-base;
		border-radius: 4px;
		padding: 20px 20px 21px 20px;
		margin: 0 0 20px;
		outline: 0;
		box-sizing: border-box;
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		appearance: none;
		transition: color 0.4s, background-colour 0.4s;
		width: 100%;
		background: transparent url("../images/global/dropdown-arrow-light.svg") no-repeat 95% 50%;

		&::-ms-expand {
			display: none;
		}

		&:focus {
			background-image: url("../images/global/dropdown-arrow-up-light.svg");
			outline: none;
			background-color: $color-white;
			color: $color-base;
		}

		&:-webkit-autofill {
			-webkit-box-shadow: 0 0 0 1000px white inset;
		}
	}

	.standard-select {
		width: 100%;
		max-width: 350px;
		font-size: 0.875rem;
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		appearance: none;
		overflow: hidden;
		position: relative;
	}

	.comments {
		textarea {
			width: 100%;
			height: 200px;
			resize: none;
			font-size: 0.875rem;
			line-height: 1.25rem;
			margin: 20px 0 20px 0;
		}
		max-width: 720px;
	}

	textarea {
		border-width: 1px;
		border-style: solid;
		border-color: $color-base;
		font-size: 0.875rem;
		color: $color-base;
		border-radius: 4px;
		padding: 20px 20px 21px 20px;
		margin: 0 0 20px;
		outline: 0;
		background: transparent;
		box-sizing: border-box;
		-webkit-appearance: none;
		transition: color 0.4s, background-colour 0.4s;

		&:focus {
			background-color: $color-white;
		}
	}

	label {
		font-size: 0.875rem;
	}

	select[disabled] {
		border: 0;
	}

	p {
		font-size: 0.875em;
		padding: 0 0 20px;
		line-height: 1.5rem;
		max-width: 340px;
		color: $color-gray-1;
	}

	.subject {
		padding: 10px 0 0;
		max-width: 600px;
		clear: left;

		&::after {
			clear: both;
			content: "";
			display: block;
		}
	}

	.standard-radio {
		width: 200px;
		float: left;
	}

	.error-message {
		padding: 0 0 20px 0;
		color: $color-red-1;
		font-size: 0.85em;
	}

	.error-message.disabled {
		padding: 0;
	}

	.submit-holder {
		margin: 20px 0 0 0;
		text-align: center;
	}

	.faqs {
		padding: 50px 20px 80px;
		max-width: 574px;
		margin: 0 auto;

		.title {
			font-size: 1em;
			padding-bottom: 50px;
			line-height: 1em;
			text-align: center;
			font-weight: 700;
		}

		ul {
			list-style: none;
		}

		li {
			margin: 0 0 30px;
			display: block;
		}

		a {
			color: $color-base;
			display: block;
			padding: 20px 30px 21px;
			font-size: 0.875em;
			line-height: 1.5rem;
			background-color: $color-gray-16;
			transition: background-color 0.2s;
			text-decoration: none;
			border-radius: 4px;

			&:hover {
				background-color: $color-gray-8;
			}
		}
	}

	.legal-terms {
		label {
			display: inline-block;
		}
	}
}

@media (max-width: $tablet) {
	.contact-us {
		header {
			width: 100%;
		}

		h1 {
			padding: 40px 20px;
		}

		.column {
			width: 100%;
			margin: 0;
			float: none;
		}

		input {
			width: 100%;
			max-width: 100%;
		}

		.standard-select {
			width: 100%;
			max-width: 100%;
		}

		select {
			width: 100%;
			max-width: 100%;
		}

		textarea {
			width: 100%;
			max-width: 100%;
		}

		.form {
			.content {
				width: 100%;
			}

			form {
				padding: 20px;
			}

			.submit {
				margin: 0 0 30px;
			}
		}
	}
}

@media (max-width: $mobile) {
	.contact-us {
		.standard-radio {
			width: 50%;
			float: left;
		}

		.faqs {
			padding: 50px 20px;

			li {
				margin: 0 0 20px;
				display: block;
			}

			a {
				padding: 20px 20px 21px;
			}
		}
	}
}

@media only screen and (min-device-pixel-ratio: 1.5) and (max-width: $tablet),
	only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: $tablet) {
	.contact-us {
		.standard-checkbox {
			.alt-checkbox {
				width: 39px;
				height: 39px;
			}

			.copy {
				display: inline-block;
				padding: 4px 0 0;
			}
		}

		.standard-radio {
			.alt-radio {
				width: 39px;
				height: 39px;
			}

			.copy {
				display: inline-block;
				padding: 4px 0 0;
			}
		}

		.standard-form {
			input {
				padding: 24px 20px 25px;
			}

			select {
				padding: 24px 20px 25px;
			}

			textarea {
				padding: 24px 20px 25px;
			}
		}
	}
}
