/*STORE PAGE*/
.store-page {
	min-height: 100vh;

	.content {
		margin: 0 auto;
		max-width: 1680px;
		width: 87.34%;
	}

	header {
		&::after {
			clear: both;
			content: "";
			display: block;
		}

		h1 {
			font-size: 1.875em;
			line-height: 2.375rem;
			font-weight: 700;
			text-align: center;
			padding: 25px 0 61px;
		}
	}

	.breadcrumb {
		a {
			text-decoration: none;
		}
	}

	.share {
		clear: both;
		float: left;
		position: relative;
		overflow: hidden;
		width: 20px;
		padding: 24px 0 0;
		transition: width 0.4s;

		&:hover {
			width: 150px;

			a {
				color: $color-base;
			}
		}

		.container {
			position: relative;
			width: 150px;
			float: left;
		}

		ul {
			position: absolute;
			left: 24px;
		}

		li {
			float: left;
			text-align: center;
			list-style: none;
			margin: 2px 0 0 15px;

			li {
				a {
					&::after {
						content: "";
						padding: 0;
					}
				}
			}
		}

		a {
			text-decoration: none;
			transition: color 0.4s;

			svg {
				width: 18px;
			}

			span {
				display: none;
			}

			&::after {
				font-family: $font-secondary;
				content: "\e615";
				padding: 0 2px;
				display: block;
			}
		}
	}

	.share.open {
		width: 150px;
	}

	.container {
		&::after {
			clear: both;
			display: block;
			content: "";
		}

		> a {
			float: left;
		}
	}

	.map {
		clear: both;
		position: relative;
		height: 406px;

		.placeholder {
			display: block;

			img {
				width: 100%;
			}
		}

		.map-canvas {
			height: 100%;
		}
	}

	.map-holder {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: auto;
	}

	dl {
		width: 272px;
		padding: 40px 20px 40px 0;
		font-size: 1em;
		line-height: 1.5rem;
		float: left;

		&::after {
			clear: both;
			content: "";
			display: block;
		}
	}

	a {
		color: $color-gray-1;
		text-decoration: underline;

		&:hover {
			color: $color-base;
		}

		> div {
			&:only-child {
				display: none;
			}
		}
	}

	.phone {
		a {
			color: $color-base;
			cursor: default;
			text-decoration: none;
		}
	}

	.address {
		padding: 0 0 24px;

		span {
			display: block;

			&:nth-last-child(2) {
				display: inline;
			}

			&:nth-last-child(3) {
				display: inline;
			}
		}
	}

	.times {
		dt {
			float: left;
			width: 110px;
			padding: 0 10px 0 0;
		}

		dd {
			float: left;
		}
	}

	.imagery {
		img {
			width: 100%;
		}
	}

	table {
		margin-top: 3px;
	}

	td {
		&:last-of-type {
			padding-bottom: 8px !important; /* stylelint-disable-line declaration-no-important */
		}
	}
}

#react-storefinder {
	h1 {
		padding-top: 122px !important; /* stylelint-disable-line declaration-no-important */
	}
}

@media only screen and (min-device-pixel-ratio: 1.5) and (max-width: $tablet) {
	.store-page {
		header {
			h1 {
				padding: 61px 0 61px;
			}
		}
	}
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: $tablet) {
	.store-page {
		header {
			h1 {
				padding: 61px 0 61px;
			}
		}
	}
}

@media (max-width: $tablet) {
	#react-storefinder {
		h1 {
			padding-top: 65px !important; /* stylelint-disable-line declaration-no-important */
		}
	}

	.store-page {
		.content {
			padding: 0 20px;
			width: auto;
		}

		.breadcrumb {
			display: none;
		}

		header {
			h1 {
				font-size: 1.5em;
				line-height: 1.875rem;
				padding: 40px 0;
			}
		}

		.phone {
			a {
				padding: 0 0 5px;
				margin: 0 0 5px;
				display: inline-block;
			}
		}

		.map {
			background-color: $color-gray-16;
			max-height: 406px;
			min-height: 200px;
			height: auto;
		}

		.share {
			li {
				a {
					&::after {
						display: none;
					}
				}
			}
		}
	}

	.touch {
		.store-page {
			.share {
				ul {
					position: static;
				}

				li {
					margin: 0 20px 0 0;
				}
				width: 230px;

				.container {
					position: relative;
					width: 230px;
					height: 35px;
					float: left;
				}

				a {
					&::after {
						padding: 0 4px;
						font-size: 1.5em;
					}
					width: 30px;
					display: block;

					svg {
						width: 100%;
					}
				}
			}

			.container {
				> a {
					display: none;
				}
			}

			.phone {
				a {
					color: $color-gray-1;
					text-decoration: underline;
				}
			}
		}
	}
}

@media (max-width: 642px) {
	.store-page {
		dl {
			width: 210px;
		}
	}
}

@media (max-width: $mobile) {
	.store-page {
		.info {
			padding: 30px 0 10px;
		}

		dl {
			padding: 0 0 20px;
			float: none;
		}
	}
}
