#reviewError {
	color: $color-red-4;
	text-align: center;
}

.checkout_error {
	color: $color-red-4;
	text-align: center;
}

#cardexpiremonth {
	width: 50%;
	display: inline-block;
	float: left;
	box-sizing: border-box;
	position: relative;
	-webkit-appearance: none;
	padding-right: 5px;

	&::after {
		content: "";
		width: 30px;
		height: 30px;
		background-color: $color-gray-16;
		display: block;
		background-image: url(../images/global/dropdown-arrow-light.svg);
		position: absolute;
		top: 15px;
		right: 10px;
		background-position: 95% 50%;
		background-repeat: no-repeat;
		pointer-events: none;
	}
}

#cardexpireyear {
	width: 50%;
	display: inline-block;
	float: left;
	box-sizing: border-box;
	position: relative;
	-webkit-appearance: none;
	padding-left: 5px;

	&::after {
		content: "";
		width: 30px;
		height: 30px;
		background-color: $color-gray-16;
		display: block;
		background-image: url(../images/global/dropdown-arrow-light.svg);
		position: absolute;
		top: 15px;
		background-position: 95% 50%;
		background-repeat: no-repeat;
		pointer-events: none;
		right: 5px;
	}
}

#card-holder {
	font-size: 14px;
	color: $color-gray-3;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-appearance: none;

	&:focus {
		background: $color-gray-16 !important; /* stylelint-disable-line declaration-no-important */
	}

	&::-webkit-input-placeholder {
		color: $color-gray-1;
	}

	&::-moz-placeholder {
		color: $color-gray-1;
	}

	&:-ms-input-placeholder {
		color: $color-gray-1;
	}

	&:-moz-placeholder {
		color: $color-gray-1;
	}
}

#bankGroupType {
	font-size: 14px;
	color: $color-gray-3;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-appearance: none;
}

.cardDatelabel {
	margin-bottom: 10px;
	margin-left: 0;
	float: none;
}

.cartYearlabel {
	float: right;
	width: calc(50% - 5px);
}

@media (max-width: $mobile-lg) {
	#cardexpiremonth {
		width: 100%;
		padding: 0;
		padding-bottom: 40px;

		&::after {
			right: 5px;
		}
	}

	#cardexpireyear {
		width: 100%;
		padding: 0;
	}

	.cartYearlabel {
		position: relative;
		top: 110px;
		right: 124px;
		float: none;
	}
}
