/* Tooltip component */
.tooltip {
	width: 15px;
	height: 15px;
	border-radius: 7.5px;
	position: relative;
	background: $color-gray-5;
	cursor: pointer;
	color: $color-white;
	display: inline-block;
	line-height: 15px;
	text-align: center;
	margin-left: 10px;

	&__close {
		display: block;
		width: 15px;
		height: 15px;
		padding: 0;
		margin: 6px 6px 50px 0;
		border-radius: 7.5px;
		border: none;
		float: right;
		background: $color-gray-10;
	}

	&__content {
		display: none;
		content: "";
		position: absolute;
		z-index: 10;
		left: 100%;
		right: auto;
		padding: 10px;
		margin-top: -30px;
	}

	&-inner {
		width: 215px;
		padding: 10px;
		box-shadow: 0 2px 4px 0 rgba(54, 54, 54, 0.25);
		border-radius: 3px;
		background: $color-white;
		color: $color-black;
		text-align: left;
		font-size: 0.75em;
		font-weight: 700;
	}

	&--stick-right {
		position: static;

		& .tooltip__content {
			left: auto;
			right: 20px;
			padding: 0;
		}
	}
}

@media screen and (min-width: calc($tablet + 1px)) {
	.tooltip {
		&:hover .tooltip__content {
			display: block;
		}

		&__close {
			display: none;
		}
	}
}

@media (max-width: $tablet) {
	.tooltip {
		&.is-open {
			.tooltip__content {
				display: block;
			}
		}
	}
}
