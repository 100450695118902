/*LOGIN*/
/* CB: Changed the checkbox so you don't have to use JS for it */
.standard-form {
	fieldset {
		transition: 0.4s all;
		border-width: 0;
	}

	legend {
		display: none;
	}

	label {
		display: none;
	}

	.info-label {
		display: block;
	}

	input,
	.submit {
		font-size: 1em;
		border-width: 1px;
		border-style: solid;
		border-color: $color-base;
		color: $color-base;
		border-radius: 4px;
		padding: 20px 20px 21px 20px;
		margin: 0 0 20px;
		width: 100%;
		outline: 0;
		box-sizing: border-box;
		background: transparent;
		-webkit-appearance: none;
		transition: color 0.4s, background-colour 0.4s;

		&:-webkit-autofill {
			-webkit-box-shadow: 0 0 0 1000px white inset;
		}

		&:focus {
			outline: none;
			background-color: $color-white;
		}
	}

	select {
		color: $color-gray-1;
		font-size: 1em;
		border-width: 1px;
		border-style: solid;
		border-color: $color-base;
		border-radius: 4px;
		padding: 20px 20px 21px 20px;
		margin: 0 0 20px;
		width: 100%;
		outline: 0;
		box-sizing: border-box;
		background: transparent;
		-webkit-appearance: none;
		transition: color 0.4s, background-colour 0.4s;
	}

	input[disabled] {
		border: 0;
	}

	input[type="text"] {
		&:focus {
			background-color: $color-white;
			color: $color-base;
		}
	}

	input[type="tel"] {
		&:focus {
			background-color: $color-white;
			color: $color-base;
		}
	}

	input[type="password"] {
		&:focus {
			background-color: $color-white;
			color: $color-base;
		}
	}

	input.error {
		margin-bottom: 7px;
		color: $color-red-1;
		border-color: $color-red-1;

		&:focus {
			margin-bottom: 7px;
			color: $color-red-1;
			border-color: $color-red-1;
		}
	}

	.dob-fields {
		input {
			float: left;
			width: 30%;
			padding-left: 0;
			padding-right: 0;
			text-align: center;
			box-sizing: border-box;

			&:nth-child(2) {
				margin: 0 5%;
			}
		}
	}

	.dummy-field {
		position: absolute;
		top: 0;
		left: 0;
		font-size: 1em;
		width: 100%;
		padding: 20px 20px 21px 20px;
		border: 1px solid transparent;
		box-sizing: border-box;
	}

	.field {
		position: relative;
	}

	.error-message {
		p {
			clear: left;
			font-size: 0.75rem;
			padding: 0 15px 10px;
			color: $color-red-1;
			text-align: left;
		}
	}

	&::-webkit-input-placeholder {
		color: $color-gray-1;
	}

	&:-moz-placeholder {
		color: $color-gray-1;
	}

	&::-moz-placeholder {
		color: $color-gray-1;
	}

	&:-ms-input-placeholder {
		color: $color-gray-1;
	}

	.error {
		input {
			border-color: $color-red-1;
			color: $color-red-1;
		}

		textarea {
			border-color: $color-red-1;
			color: $color-red-1;
		}

		&::-webkit-input-placeholder {
			color: $color-red-1;
		}

		&:-moz-placeholder {
			color: $color-red-1;
		}

		&::-moz-placeholder {
			color: $color-red-1;
		}

		&:-ms-input-placeholder {
			color: $color-red-1;
		}
	}

	.submit {
		border-color: $color-blue-1;
		background-color: $color-blue-1;
		color: $color-white;
		cursor: pointer;
		transition: background-color 0.1s linear;
		font-weight: 700;

		&:focus {
			background-color: $color-blue-1;
		}

		&:hover {
			border-color: $color-blue-2;
			background-color: $color-blue-2;
		}
	}

	.submit.disabled {
		opacity: 0.5;
	}
}

.login-recaptcha {
	div {
		margin: 0 auto;
	}
}

.address-page {
	.standard-form {
		input {
			border-color: $color-gray-17;
			color: $color-gray-17;
		}

		input[required] {
			border-color: $color-base;
			color: $color-base;
		}

		input[type="submit"] {
			border-color: $color-blue-1;
			color: $color-white;
		}

		.error {
			input[required].error {
				border-color: $color-red-1;
			}
		}
	}
}

.standard-checkbox {
	&::after {
		clear: both;
		content: "";
		display: block;
	}

	label {
		display: inline-block;
		float: left;
		line-height: 1.813rem;
	}

	input {
		width: auto;
		display: none;

		&:checked {
			& + label {
				.alt-checkbox {
					background-color: $color-white;

					&::after {
						position: absolute;
						top: 50%;
						left: 50%;
						font-size: 10px;
						line-height: 1em;
						font-family: $font-secondary;
						content: "\e616";
						transform: translateX(-50%) translateY(-50%);
					}
				}
			}
		}
	}

	.alt-checkbox {
		position: relative;
		float: left;
		width: 29px;
		height: 29px;
		line-height: 3.625rem;
		font-size: 1em;
		border-width: 1px;
		border-style: solid;
		border-color: $color-base;
		border-radius: 4px;
		margin: 0 10px 0 0;
		background: transparent;
		display: inline-block;
		box-sizing: border-box;
		transition: color 0.4s, background-colour 0.4s, border-color 0.4s;
		cursor: pointer;
	}

	.error-message {
		clear: left;
	}
	margin: 0 0 20px;
}

.standard-radio {
	&::after {
		clear: both;
		content: "";
		display: block;
	}

	label {
		display: inline-block;
		float: left;
		line-height: 1.813rem;

		span {
			span {
				font-weight: 700;
			}
		}
	}

	input {
		width: auto;
		display: none;

		&:checked {
			& + label {
				.alt-radio {
					background-color: $color-white;

					&::after {
						position: absolute;
						top: 50%;
						left: 50%;
						font-size: 10px;
						line-height: 1em;
						font-family: $font-secondary;
						content: "\e616";
						transform: translateX(-50%) translateY(-50%);
					}
				}
			}
		}
	}

	.alt-radio {
		position: relative;
		float: left;
		width: 29px;
		height: 29px;
		line-height: 3.625rem;
		font-size: 1em;
		border-width: 1px;
		border-style: solid;
		border-color: $color-base;
		border-radius: 4px;
		margin: 0 10px 0 0;
		background: transparent;
		display: inline-block;
		box-sizing: border-box;
		transition: color 0.4s, background-colour 0.4s, border-color 0.4s;
	}

	.error-message {
		clear: left;
	}
	margin: 0 0 20px;
}

.error {
	.standard-checkbox {
		label {
			color: $color-red-1;
		}

		.alt-checkbox {
			border-color: $color-red-1;
		}
	}

	.standard-radio {
		label {
			color: $color-red-1;
		}

		.alt-radio {
			border-color: $color-red-1;
		}
	}
}

.error.standard-checkbox {
	label {
		color: $color-red-1;
	}

	.alt-checkbox {
		border-color: $color-red-1;
	}
}

.error.standard-radio {
	label {
		color: $color-red-1;
	}

	.alt-radio {
		border-color: $color-red-1;
	}
}

.checkout {
	.login-page {
		fieldset {
			position: relative;
		}

		.terms-field {
			.copy {
				display: block;
			}
		}

		.main-title {
			border-width: 1px 0 0;
		}

		.open {
			.main-title {
				border-width: 1px 0;
			}
		}
	}

	.standard-form.new-addresses {
		input {
			&:focus {
				margin-bottom: 20px;
			}
		}
	}
}

.login-page {
	.column {
		&:nth-child(3n + 2) {
			&:not(.new) {
				padding: 0 3.035%;
			}
		}
	}

	.forgotten {
		text-align: right;
	}

	.existing {
		> .title {
			border-width: 1px;
			border-style: solid;
			border-color: $color-gray-17;
			text-align: center;
			height: 247px;
			margin: 0 0 30px;
		}

		.title {
			display: none;
		}
	}

	.create-account {
		.title {
			border-width: 1px;
			border-style: solid;
			border-color: $color-gray-17;
			text-align: center;
			height: 247px;
			margin: 0 0 30px;
		}
		transition: 0.4s all;

		form {
			display: none;

			.content {
				padding: 0;
			}
		}
	}

	.guest {
		.title {
			border-width: 1px;
			border-style: solid;
			border-color: $color-gray-17;
			text-align: center;
			height: 247px;
			margin: 0 0 30px;
		}
		transition: 0.4s all;

		form {
			display: none;

			.content {
				padding: 0;
			}
		}
	}

	.title {
		a {
			height: 100%;
			display: block;
			transition: 0.3s all;
			text-decoration: none;

			&:hover {
				background-color: $color-white;
			}

			span {
				display: block;
				position: relative;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	.forgotten-password {
		margin: 20px 0 0;
		position: relative;
		display: none;

		.content {
			padding: 20px;
			background-color: $color-blue-1;
			color: $color-white;
		}

		&::before {
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 6px 6px 6px;
			border-color: transparent transparent $color-blue-1 transparent;
			position: absolute;
			right: 75px;
			top: -6px;
		}

		.close {
			position: absolute;
			top: 20px;
			right: 20px;
			display: block;
			text-decoration: none;
			color: $color-white;
			transition: 0.4s color;
			cursor: pointer;

			&:hover {
				color: $color-gray-17;
			}

			&::after {
				font-family: $font-secondary;
				content: "\e605";
			}

			span {
				display: none;
			}
		}

		.title {
			font-size: 1rem;
			padding: 0 0 20px;
		}

		.description {
			line-height: 1.25rem;
			padding: 0 20px 20px 0;
		}

		input {
			background-color: $color-blue-4;
			color: $color-white;
			border-color: $color-white;

			&::placeholder {
				color: $color-white;
			}

			&::-webkit-input-placeholder {
				color: $color-white;
			}

			&:-moz-placeholder {
				color: $color-white;
			}

			&:-ms-input-placeholder {
				color: $color-white;
			}
		}

		.submit {
			font-weight: 700;
			color: $color-blue-1;
			background-color: $color-white;
			margin: 0;

			&:hover {
				border-color: $color-white;
			}
		}
	}

	.exisiting {
		transition: 0.4s all;
	}

	.create-account.active {
		.title {
			a {
				background-color: $color-white;
			}
		}
	}

	.guest.active {
		.title {
			a {
				background-color: $color-white;
			}
		}
	}

	.column.disabled {
		opacity: 0.65;
		height: auto;
		visibility: visible;
	}
}

.touch {
	.standard-checkbox {
		.alt-checkbox {
			width: 38px;
			height: 38px;
		}

		input {
			& + label {
				.copy {
					display: inline-block;
					padding-top: 4px;
				}
			}
		}
	}
}

@media (max-width: $tablet) {
	.checkout {
		.login-page {
			.content {
				padding-bottom: 0;
			}
		}
	}

	.login-page {
		.existing {
			> .title {
				height: 100px;
				margin: 0 0 20px;
			}

			form {
				padding: 0 0 20px;
			}
		}

		.create-account {
			.title {
				height: 100px;
				margin: 0 0 20px;
			}

			form {
				padding: 0 0 20px;
			}
		}

		.guest {
			.title {
				height: 100px;
				margin: 0 0 20px;
			}

			form {
				padding: 0 0 20px;
			}
		}

		.existing.column {
			float: none;
			padding: 0;
		}

		.create-account.column {
			float: none;
			padding: 0;
		}

		.guest.column {
			float: none;
			padding: 0;
		}

		.column {
			&:nth-child(3n + 2) {
				&:not(.new) {
					padding: 0;
				}
			}
		}

		.forgotten-password {
			margin: 0 0 20px;
		}
	}
}

.privacy-label {
	line-height: 1rem !important; /* stylelint-disable-line declaration-no-important */
}

.login-checkbox {
	border: none;
	font-size: 12px;
}

.checkbox-label-text {
	padding-left: 40px;
}
