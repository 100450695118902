/**
 * Footer Newsletter component
 */

.newsletter {
	background-color: $color-blue-1;
	color: $color-white;
	padding: 17px 20px 150px 20px;
	font-size: 0.875rem;

	@media (min-width: calc($tablet + 1px)) {
		padding-bottom: 17px;
	}

	&__heading {
		font-weight: normal;
		width: 100%;
		text-align: left;
		margin-bottom: 17px;
	}

	&__footer-form {
		margin: 0 auto;
		max-width: 850px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: 300px;

		&.active {
			width: 100%;

			&.standard-form input {
				&:invalid {
					border-color: $color-red-3;
				}
			}

			.newsletter__heading {
				opacity: 1;
				margin-bottom: 17px;
			}

			.revealed-inputs {
				max-width: 999px;
				max-height: 999px;
				transition: max-width 0.5s, max-height 0.5s 0.5s;
			}

			.newsletter__footer-form__info {
				max-height: 999px;
			}
		}

		&.error {
			.standard-radio .alt-radio,
			.standard-radio label {
				color: $color-white;
				border-color: $color-black;
			}
		}

		a {
			color: $color-white;
		}

		&.standard-form input {
			padding: 13px 18px;
			font-size: 0.875rem;

			&.submit-button {
				background: $color-black;
				color: $color-white;
				border: none;
				margin: 1px;
				cursor: pointer;
				font-weight: bold;
				text-transform: none;
			}
		}

		&.standard-form input,
		.standard-radio .alt-radio {
			background: $color-white;
			color: $color-black;
		}

		.message__wrapper {
			min-height: 0;
		}

		.revealed-inputs {
			max-width: 300px;
			max-height: 55px;
			width: 100%;
			transition: all 0.5s;
			overflow: hidden;
		}

		.interest-input {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.fashion-interest-info {
				text-align: left;
				white-space: nowrap;
				margin-right: 10px;
			}

			.input-group {
				width: 100%;
				max-width: 420px;
				margin: 17px 0;
				display: flex;
				justify-content: space-between;
			}

			label {
				margin-right: 16px;
			}
		}

		&__info {
			font-size: 12px;
			text-align: left;
			max-height: 0;
			overflow: hidden;
			transition: max-height 0.5s;
		}

		@media (min-width: calc($desktop + 1px)) {
			width: 440px;

			.revealed-inputs {
				width: 720px;
			}

			.interest-input {
				flex-direction: row;
				justify-content: space-between;
				align-items: center;

				.input-group {
					justify-content: flex-start;
				}
			}

			.contact-input {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
			}

			&.standard-form input {
				flex: 0 0 290px;
				margin: 0 10px 10px 0;

				&#submit {
					flex: 0 0 120px;
				}
			}

			&.active .revealed-inputs input {
				flex: 1 1 33%;
			}
		}
	}

	input {
		box-sizing: border-box;

		&::after {
			display: block;
			content: "";
			clear: both;
		}
	}

	.email {
		font-size: 0.875em;
		height: 3.625rem;
		border-radius: 5px 0 0 5px;
		border-width: 1px 0 1px 1px;
		border-style: solid;
		border-color: $color-base;
		color: $color-gray-1;
		width: 290px;
		padding: 0 0 0 20px;
		outline: none;
		margin: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		float: left;
		transition: 0.3s all;
	}

	.error {
		.email {
			border-color: $color-red-3;
			color: $color-red-3;
		}
	}

	.success {
		.email {
			color: $color-gray-17;
			background: $color-gray-1;
			border-color: $color-gray-1;
			opacity: 0.75;
		}

		.submit {
			color: $color-gray-17;
			background: $color-gray-1;
			border-color: $color-gray-1;
			opacity: 0.75;
		}

		span {
			&::after {
				color: $color-gray-17;
				border-color: $color-gray-1;
				opacity: 0.75;
				background: transparent;
			}
		}
	}

	.submit {
		height: 3.625rem;
		width: 30px;
		border-style: solid;
		border-color: $color-base;
		border-radius: 0 5px 5px 0;
		border-width: 1px 1px 1px 0;
		background: $color-white;
		outline: none;
		margin: 0;
		padding: 0;
		overflow: hidden;
		white-space: nowrap;
		text-indent: 105%;
		-webkit-appearance: none;
		-moz-appearance: none;
		float: left;
		transition: 0.3s all;
	}

	fieldset {
		align-items: center;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		margin: 0;
		width: auto;

		@media (min-width: calc($tablet + 1px)) {
			display: block;
		}

		.email {
			border-radius: 5px;
			border: 1px solid $color-base;
			float: none;
			height: 43px;
			margin: 0 auto;
			width: 300px;

			@media (min-width: calc($tablet + 1px)) {
				display: inline-block;
				margin: 0;
			}
		}

		.legal {
			margin: 0 auto;
			max-width: 420px;
			display: none;

			@media (min-width: calc($tablet + 1px)) {
				text-align: left;
			}
		}

		.submit-button {
			float: none;
			background: $color-blue-1;
			border-radius: 4px;
			transition: background 0.2s ease;
			border: 0;
			color: $color-white;
			font-size: 0.875em;
			margin: 20px auto 0;
			min-width: 120px;
			padding: 13px 18px;
			text-transform: capitalize;

			@media (min-width: calc($tablet + 1px)) {
				display: inline-block;
				flex-grow: 0;
				margin: 0;
			}
		}
	}

	/*TODO: review */
	.active {
		position: relative;

		.email {
			color: $color-base;
			transition: all 0.2s;
		}

		.email.error {
			color: $color-red-3;
		}
	}

	.enabled {
		span {
			&::after {
				color: $color-base;
				font-family: $font-secondary;
				content: "\e616";
				font-size: 0.875em;
				display: block;
				position: absolute;
				top: 23px;
				right: 40px;
				transition: all 0.2s;
			}
		}
	}

	.message {
		color: $color-base;
		padding: 20px 20px 0;
		font-size: 0.875em;
		transition: all 0.4s;
	}

	.message.error {
		color: $color-red-3;
	}
}

@media (max-width: $tablet) {
	.newsletter {
		padding: 17px 0 100px;
	}
}

@media (max-width: $tablet) {
	footer fieldset {
		width: 350px;
	}

	.newsletter {
		.email {
			padding: 10px 0 10px 17px;
			height: 3.65rem;
			font-size: 1em;
			width: 90%;
		}

		.submit {
			width: 10%;
			height: 3.65rem;
			padding: 20px 0 21px;
		}
	}
}
